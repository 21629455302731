import React from "react";
import Sort from "./Sort";
import cx from "classnames";
import { tableHeaderClass } from "Screener/Table/HeaderCell";

const HeaderValue = ({
  handleClick,
  label,
  shortcode,
  state,
}) => {
  const headerClass = cx(
    tableHeaderClass,
    `
      max-w-[100px]
    `
  );

  return (
    <th
      onClick={handleClick}
      className={headerClass}
    >
      <div className="flex border-b pr-1 pl-2 py-2 mb-3">
        <Sort
          className="grow flex justify-end"
          shortcode={shortcode}
          sortBy={state.sortBy}
          sortDirection={state.sortDirection}
        />

        <div className="text-right truncate">
          {label}
        </div>
      </div>
    </th>
  );
}

export default HeaderValue;
