import Link from "common/Link";
import React from "react";
import { useParams } from "react-router-dom";

const Description = () => {
  const {
    fincialsTableCode,
  } = useParams();

  let description;
  let url;

  switch(fincialsTableCode) {
    case "income-statement":
      description = `An Income Statement, also known as a Profit and Loss Statement, summarizes a company's revenues, expenses, and profits over a specific period. It highlights how revenue is transformed into net income, showcasing operational efficiency. Key components include total revenue, cost of goods sold (COGS), gross profit, operating expenses, and net profit. Businesses use the Income Statement to assess financial performance, make strategic decisions, and ensure profitability. Investors and stakeholders analyze it to gauge the company’s financial health and future growth potential.`;
      url = "/wiki/income-statement"
      break;

    case "balance-sheet":
      description = `A Balance Sheet is a financial statement that provides a snapshot of a company's assets, liabilities, and shareholders' equity at a specific point in time. It follows the formula: Assets = Liabilities + Shareholders' Equity. Key components include current and non-current assets, current and long-term liabilities, and equity. The Balance Sheet helps businesses and investors evaluate financial stability, liquidity, and capital structure. It's essential for assessing the company's ability to meet its obligations and fund future operations.`;
      url = "/wiki/balance-sheet"
      break;

    case "cash-flow":
      description = `A Cash Flow Statement is a financial report that details the cash inflows and outflows of a company over a specific period. It is divided into three sections: operating activities, investing activities, and financing activities. This statement helps assess the company’s liquidity, solvency, and overall financial health. By tracking cash movements, it provides insights into the company's ability to generate cash to fund operations, pay debts, and invest in growth. Businesses and investors use it to evaluate the sustainability of a company's cash flow and its financial stability.`;
      url = "/wiki/cash-flow"
      break;
  }

  if (!description && !url) { return null; }

  return (
    <div className="bg-amber-50 p-5">
      <div className="text-sm">
        {description}
      </div>

      <div className="mt-2">
        <Link url={url}>
          Read more &rarr;
        </Link>
      </div>
    </div>
  )
}

export default Description;
