import Form from "./Form";
import React from "react";
import ScreenerLink from "./ScreenerLink";
import { DELETE_SCREENER } from "queries/screener";
import { SAVED_SCREENERS_QUERY } from "queries/screener";
import { UPDATE_SCREENER } from "queries/screener";
import { useMutation } from "@apollo/client";
import { useState } from "react";

const Entry = ({
  id,
  token,
  name,
  notifications,
}) => {
  const [updateingScreener, setEditingScreener] = useState(false);

  const [updateScreener, { loading: mutationLoading }] = useMutation(UPDATE_SCREENER, {
    refetchQueries: [
      SAVED_SCREENERS_QUERY
    ]
  });

  const handleSave = (screenerName, notifications) => {
    setEditingScreener(false);

    updateScreener({
      variables: {
        screenerId: id,
        name: screenerName,
        notifications: notifications,
      }
    })
  }

  const [deleteScreener] = useMutation(DELETE_SCREENER, {
    refetchQueries: [
      SAVED_SCREENERS_QUERY
    ]
  });

  const handleDelete = () => {
    deleteScreener({
      variables: {
        screenerId: id
      }
    })
  }

  const toggleNotifications = () => {
    handleSave(name, !notifications);
  }

  return (
    <div className="flex mb-2" >
      {updateingScreener && (
        <Form
          notifications={notifications}
          disableSave={mutationLoading}
          value={name}
          handleSave={handleSave}
          handleCancel={() => setEditingScreener(false)}
        />
      )}

      {!updateingScreener && (
        <ScreenerLink
          name={name}
          notifications={notifications}
          toggleNotifications={toggleNotifications}
          token={token}
          handleDelete={handleDelete}
          handleEdit={() => setEditingScreener(true)}
        />
      )}
    </div>
  );
}

export default Entry;
