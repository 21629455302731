import Boolean from "./CriterionBox/Boolean";
import React from "react";
import Industry from "./CriterionBox/Industry";
import Sector from "./CriterionBox/Sector";
import Simple from "./CriterionBox/Simple";
import Shortcode from "./CriterionBox/Shortcode";
import Undefined from "./CriterionBox/Undefined";
import Value from "./CriterionBox/Value";
import { HalflingIcon } from "common/Icon";
import { ScreenerFilterContext } from "Screener/Context";
import { useContext } from "react";

const CriterionBox = ({
  indicator,
  portalReference,
}) => {
  const {
    dispatch
  } = useContext(ScreenerFilterContext);

  const removeIndicatorFromQuery = () => {
    dispatch({
      type: "REMOVE_INDICATOR_FROM_QUERY",
      shortcode: indicator.shortcode,
    });

    dispatch({
      type: "DEFAULT",
      stateChanges: {
        filterBoxStyle: ""
      }
    });
  }

  const setIndicatorVisibility = () => {
    dispatch({
      type: "SET_CRITERION_VALUE",
      id: indicator.id,
      shortcode: indicator.shortcode,
      value: indicator.value,
      showInTable: !indicator.showInTable,
    });
  }

  const setValueDisplayType = () => {
    dispatch({
      type: "SET_CRITERION_VALUE",
      id: indicator.id,
      shortcode: indicator.shortcode,
      value: indicator.value,
      showInTable: indicator.showInTable,
      valueDisplayType: indicator.valueDisplayType ? null : "plot"
    });
  }

  let BoxComponent;

  switch(indicator.indicatorRecord.screenerType) {
    case "shortcode":
      BoxComponent = Shortcode;
      break;

    case "sector":
      BoxComponent = Sector;
      break;

    case "simple":
      BoxComponent = Simple;
      break;

    case "value":
      BoxComponent = Value;
      break;

    case "boolean":
      BoxComponent = Boolean;
      break;

    case "industry":
      BoxComponent = Industry;
      break;

    default:
      BoxComponent = Undefined;
  }

  return (
    <div className="flex">
      <div
        className="bg-emerald-800 px-2 py-1 mr-2 text-slate-50 cursor-default mb-2.5"
        onClick={(event) => event.stopPropagation()}
      >
        <div className="flex">
          <div className="m-auto mr-2">
            {indicator.showInTable && (
              <HalflingIcon
                name="eye"
                className="fill-green-600 hover:fill-slate-50 cursor-pointer"
                onClick={setIndicatorVisibility}
              />
            )}

            {!indicator.showInTable && (
              <HalflingIcon
                name="eye-off"
                className="fill-red-200 hover:fill-slate-50 cursor-pointer"
                onClick={setIndicatorVisibility}
              />
            )}

          </div>

          {indicator.indicatorRecord.chartable && (
            <div className="m-auto mr-2">
              <HalflingIcon
                name={indicator.valueDisplayType === "plot" ? "stats-bars" : "hash"}
                className="fill-green-600 hover:fill-slate-50 cursor-pointer"
                onClick={setValueDisplayType}
              />
            </div>
          )}

          <BoxComponent {...{ indicator, portalReference }} />

          {indicator.indicatorRecord.screenerRemovable && (
            <div className="m-auto">
              <HalflingIcon
                name="circle-remove"
                className="fill-slate-50 cursor-pointer"
                onClick={removeIndicatorFromQuery}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default CriterionBox;
