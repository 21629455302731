import Button from "common/Button";
import Heading from "common/Heading";
import Link from "common/Link";
import React from "react";
import Subscription from "./Account/Subscription";
import makeRequest from "utils/makeRequest";
import { ACCOUNT_QUERY } from "queries";
import { useQuery } from "@apollo/client";

const Account = () => {
  const {
    data: {
      account: {
        foreverFree,
        powerpack = true
      } ={}
    } ={}
  } = useQuery(ACCOUNT_QUERY);

  const handleLogout = async () => {
    await makeRequest({
      url: "/vector/users/sign_out",
      method: "DELETE"
    })

    window.location.href = "/";
  }

  return (
    <div className="pb-5 my-5">
      <Heading variant="small">
        Your Account
      </Heading>

      <div>
        <div className="pb-3">
          <Link
            url="/account_settings"
          >
            Settings
          </Link>
        </div>

        {!foreverFree && (
          <Subscription
            powerpack={powerpack}
          />
        )}

        <div className="pt-3 mt-3 text-center">
          <Button
            className="w-full"
            colorVariant="red"
            variant="small"
            onClick={handleLogout}
          >
            Logout
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Account;
