import Chart from "Chart";
import FuturesPriceChange from "./FuturesPriceChange";
import Heading from "common/Heading";
import IndexesPriceChange from "./IndexesPriceChange";
import React from "react";

const Indexes = () => {
  const chartParams = [
    {
      name: "Indexes",
      series: [
        { descriptor: "GSPC:index", indicator: "DAILY_PRICE:D", type: "line", color: "#f44141", primary: true },
        { descriptor: "DJI:index", indicator: "DAILY_PRICE:D", type: "line", color: "#4286f4" },
        { descriptor: "NDX:index", indicator: "DAILY_PRICE:D", type: "line", color: "#d39f3d" }
      ]
    }
  ]

  return (
    <div className="flex-shrink md:w-[300px]">
      <div>
        <Heading variant="tiny">
          Indexes, YTD
        </Heading>

        <div className="mb-3">
          <Chart
            chartParams={chartParams}
            compare
            startDate="ytd"
            height="200px"
            options={{
              tooltip: {
                enabled: false
              },
              plotOptions: {
                series: {
                  states: {
                    hover: {
                      enabled: false
                    }
                  }
                }
              },
            }}
          />
        </div>

        <div className="mb-10">
          <IndexesPriceChange
            range="ytd"
          />
        </div>
      </div>

      <div className="mb-5">
        <Heading variant="tiny">
          Indexes, intraday
        </Heading>

        <IndexesPriceChange
          hideColorLegend
          range="intraday"
        />
      </div>

      <div className="mb-3">
        <Heading variant="tiny">
          Index Futures, intraday
        </Heading>

        <FuturesPriceChange />
      </div>
    </div>
  )
}

export default Indexes;
