import Checkbox from "common/Checkbox";
import React from "react";
import Trigger from "common/Trigger";
import { ScreenerFilterContext } from "Screener/Context";
import { createPortal } from "react-dom";
import { sortBy } from "lodash";
import { useContext } from "react";
import { useState } from "react";

const IndustryList = ({
  portalElement,
}) => {
  const {
    state,
    dispatch
  } = useContext(ScreenerFilterContext);

  const industryIndicator = state.indicatorsInQuery.find((indicator) => {
    return indicator.shortcode === "industry";
  });

  const [selectedIndustries, setSelectedIndustries] = useState(
    industryIndicator.value
  );

  const isChecked = (industryName) => {
    return selectedIndustries.includes(industryName);
  }

  const handleChange = (event) => {
    let value;

    if (event.target.checked) {
      value = [
        ...new Set([
          ...selectedIndustries,
          event.target.name
        ])
      ]
    } else {
      value = selectedIndustries.filter((industry) => {
        return industry !== event.target.name;
      });
    }

    setSelectedIndustries(value);

    dispatch({
      type: "SET_CRITERION_VALUE",
      shortcode: "industry",
      value,
    });
  }

  const industryList = sortBy(state.industries, ["name"]);

  const clearSelection = () => {
    setSelectedIndustries([]);

    dispatch({
      type: "SET_CRITERION_VALUE",
      shortcode: "industry",
      value: [],
    });
  }

  return (
    <React.Fragment>
      {createPortal(
        (
          <React.Fragment>
            <Trigger onClick={clearSelection}>
              Clear Industry Selection
            </Trigger>
          </React.Fragment>
        ),
        portalElement
      )}

      <div className="grid grid-cols-4 w-full">
        {industryList.map((descriptor) => (
          <div key={descriptor.shortcode}>
            <Checkbox
              id={descriptor.name}
              name={descriptor.name}
              type="checkbox"
              onChange={handleChange}
              checked={isChecked(descriptor.name)}
            >
              {descriptor.name}
            </Checkbox>
          </div>
        ))}
      </div>
    </React.Fragment>
  );
}

export default IndustryList;
