import Heading from "common/Heading";
import Link from "common/Link";
import React from "react";
import { Helmet } from "react-helmet";
import { INDICATORS_QUERY } from "queries";
import { uniqBy } from "lodash";
import { useQuery } from "@apollo/client";

const Indicators = () => {
  const {
    data: {
      indicators =[]
    } ={}
  } = useQuery(INDICATORS_QUERY);

  const indicatorSections = [
    "Income Statement",
    "Balance Sheet",
    "Cash Flow",
    "Metrics Ratios",
  ]

  const renderableIndicators = uniqBy(indicators, "code").filter((indicator) => {
    return indicatorSections.includes(indicator.section)
  });

  const groupedIndicators = Object.groupBy(renderableIndicators, ({ section }) => section);

  const description = `Financial Indicators from Income statement, Balance sheet and `+
    `Cashflow statement.`;

  return (
    <div className="max-w-3xl m-auto bg-white p-5">
      <React.Fragment>
        <Helmet>
          <title>Financial Indicators — stockrow</title>
          <meta name="description" content={description} />
        </Helmet>
      </React.Fragment>

      <Heading variant="large">
        Financial Indicators
      </Heading>

      <div className="text-lg grid gap-5 mb-10">
        <p>
          This section provides a detailed overview of the key financial indicators
          commonly found in the Income Statement, Balance Sheet, and Cash Flow
          Statement. These metrics are essential for anyone looking to understand a
          company’s financial health and performance, serving as a useful reference for
          investors, analysts, and financial professionals.
        </p>

        <p>
          The Income Statement section covers indicators related to profitability,
          including revenue, cost of goods sold (COGS), gross profit, operating income,
          and net income. These metrics offer insights into the company’s ability to
          generate profit and manage its expenses.
        </p>


        <p>
          In the Balance Sheet section, the focus is on a company's financial position at
          a specific point in time. This includes assets, liabilities, and equity,
          providing a snapshot of what the company owns, what it owes, and the
          shareholders' stake. Key metrics such as current assets, current liabilities,
          long-term debt, and shareholders' equity are highlighted to assess liquidity,
          solvency, and capital structure.
        </p>

        <p>
          The Cash Flow Statement section outlines indicators that track the flow of cash
          in and out of the business, including cash flows from operating activities,
          investing activities, and financing activities. These indicators are crucial
          for understanding a company’s liquidity, operational efficiency, and financial
          flexibility.
        </p>

        <p>
          By exploring these sections, readers can gain a comprehensive understanding of
          the various factors that influence financial performance and stability, making
          this guide a valuable resource for interpreting and utilizing key financial
          indicators.
        </p>
      </div>

      {indicatorSections.map((section) => (
        <div className="mb-10">
          <Heading>
            {section}
          </Heading>

          <div className="sm:columns-1 md:columns-3">
            {(groupedIndicators[section] || []).map((indicator) => (
              <div
                className="mb-1"
                key={indicator.code}
              >
                <Link url={`/wiki/${indicator.code.toLowerCase()}`}>
                  {indicator.name.split(",").slice(0, -1).join(",")}
                </Link>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  )
};

export default Indicators;
