import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import Link from "common/Link";

const Logo = ({
  size,
  variant,
  layout,
  className,
  isLink
}) => {
  const sizeMap = {
    small: { width: 50, strokeCoefficent: 2 },
    default: { width: 87, strokeCoefficent: 1 },
    large: { width: 300, strokeCoefficent: 0.5 },
  };

  const colorMap = {
    white: {
      outerColor: "#f1f5f9",
      innerColor: "#f1f5f9",
      fontColor: "#f1f5f9",
    },
    black: {
      outerColor: "#1e293b",
      innerColor: "#1e293b",
      fontColor: "#1e293b",
    },
    color: {
      outerColor: "#f87171",
      innerColor: "#065f46",
      fontColor: "#065f46",
    },
  };

  const strokeCoefficent = sizeMap[size].strokeCoefficent;

  const logoBoxStyle = {
    width: sizeMap[size].width,
    height: (205 / 450) * sizeMap[size].width,
    marginRight: sizeMap[size].width * 0.15,
  }

  const fontSize = 0.5 * logoBoxStyle.height;

  const fontStyle = {
    fontSize: `${fontSize}px`,
    color: colorMap[variant].fontColor,
  }

  const baseStyle = {
    position: "absolute",
    margin: "auto",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    zIndex: 2
  };

  const horizontalStyle = {
    ...baseStyle,

    background: colorMap[variant].innerColor,
    height: `${2.5 * strokeCoefficent}%`,
    width: "100%",
  }

  const verticalInnerStyle = {
    ...baseStyle,

    background: colorMap[variant].innerColor,
    height: "44%",
    width: `${1.5 * strokeCoefficent}%`,
  }

  const verticalOuterStyle = {
    ...baseStyle,

    width: `${1.5 * strokeCoefficent}%`,
    height: "100%",
    background: colorMap[variant].outerColor,
    zIndex: 1
  }

  const showLogo = layout !== "logoOnly";
  const baseLayoutClass = `
    flex
    w-fit
  `;
  const fontClass = `
    leading-none
    mt-auto
    mb-auto
    text-2xl
    text-slate-600
  `;
  const linkClass = `
    text-[#065f46]
    border-[#14b889]
  `;

  let layoutClass = cx(baseLayoutClass, className)

  if (layout === "vertical") {
    layoutClass = cx(layoutClass, "flex-col")
  }

  return (
    <div
      data-testid="stockrow-logo"
      className={layoutClass}
    >
      <div
        className="flex-shrink relative"
        style={logoBoxStyle}
      >
        <div style={horizontalStyle} />
        <div style={verticalInnerStyle} />
        <div style={verticalOuterStyle} />
      </div>

      {showLogo && (
        <div
          className={fontClass}
          style={fontStyle}
        >
          {isLink && (
            <Link
              url="/"
              className={linkClass}
            >
              stockrow
            </Link>
          )}

          {!isLink && (
            <React.Fragment>
              stockrow
            </React.Fragment>
          )}
        </div>
      )}
    </div>
  )
}

Logo.propTypes = {
  size: PropTypes.oneOf(["small", "default", "large"]),
  variant: PropTypes.oneOf(["color", "black", "white"]),
  layout: PropTypes.oneOf(["logoOnly", "horizontal", "vertical"]),
  className: PropTypes.string,
  isLink: PropTypes.bool,
};

Logo.defaultProps = {
  size: "default",
  variant: "color",
  layout: "horizontal",
  className: "",
  isLink: true,
};

export default Logo;
