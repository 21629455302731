import DocumentHeader from "./Homepage/DocumentHeader";
import Indexes from "./Homepage/Indexes";
import MarketNews from "./Homepage/MarketNews";
import React from "react";
import Treemap from "./Homepage/Treemap";

import NewsSummary from "./Homepage/NewsSummary"

const Homepage = () => {
  return (
    <React.Fragment>
      <DocumentHeader />

      <section className="md:flex gap-x-5 z-0 mb-5">
        <div className="shrink">
          <Indexes />
        </div>

        <div className="grow">
          <Treemap />
        </div>
      </section>

    <section className="grid lg:grid-cols-1 xl:grid-cols-10 gap-3">
      <div className="xl:col-span-5 2xl:col-span-4">
        <NewsSummary />
      </div>

      <div className="xl:col-span-5 2xl:col-span-6">
        <MarketNews />
      </div>
    </section>
    </React.Fragment>
  )
}

export default Homepage;
