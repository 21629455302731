import Button from "common/Button";
import DocumentHeader from "./ForgottenPassword/DocumentHeader";
import ErrorMessage from "common/ErrorMessage";
import Heading from "common/Heading";
import React from "react";
import SharedLinks from "./Login/SharedLinks";
import TextInput from "common/TextInput";
import { useForm } from "hooks/useForm";
import { useState } from "react";

const ForgottenPassword = () => {
  const [formSubmitted, setFormSubmitted] = useState(false);
  const callback = () => {
    setFormSubmitted(true)
  }

  const {
    handleChange,
    handleSubmit,
    submitDisabled,
    errors,
  } = useForm({
    formName: "forgotten_password_form",
    submitCallback: callback
  });

  return (
    <div className="m-auto w-96">
      <DocumentHeader />

      <Heading>
        Forgotten Password
      </Heading>

      {!formSubmitted && (
        <form
          onChange={handleChange}
          onSubmit={handleSubmit}
        >
          <TextInput
            autoFocus
            label="E-mail"
            id="email"
            name="email"
            state={errors.email ? "error" : "default" }
          />

          <ErrorMessage
            message={errors.email}
          />
          <Button
            type="submit"
            disabled={submitDisabled}
          >
            Submit
          </Button>
        </form>
      )}

      {formSubmitted && (
        <div>
          <p className="mb-5">
            If the email address you have provided exists in our database, we
            will send a magic login link to that address.
          </p>

          <p className="mb-5">
            Using that link, you will be able to access the app and change your password.
          </p>

          <p className="mb-5">
            The link will expire in approximately thirty minutes.
          </p>
        </div>
      )}

      <SharedLinks />
    </div>
  );
}

export default ForgottenPassword;
