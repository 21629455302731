import Button from "common/Button";
import Checkbox from "common/Checkbox";
import DocumentHeader from "./CreateAccount/DocumentHeader";
import ErrorMessage from "common/ErrorMessage";
import Heading from "common/Heading";
import Link from "common/Link";
import React from "react";
import SharedLinks from "./Login/SharedLinks";
import TextInput from "common/TextInput";
import { useForm } from "hooks/useForm";

const CreateAccount = () => {
  const handleAccountCreation = async () => {
    window.location.href = "/vector/billing/subscription/new";
  }

  const {
    handleChange,
    handleSubmit,
    submitDisabled,
    errors,
  } = useForm({
    formName: "create_account_form",
    submitCallback: handleAccountCreation
  });

  return (
    <div className="m-auto w-96">
      <DocumentHeader />

      <Heading>
        Create New Account
      </Heading>

      <form
        onChange={handleChange}
        onSubmit={handleSubmit}
      >
        <TextInput
          autoFocus
          label="Your name"
          id="fullname"
          name="fullname"
          state={errors.fullname? "error" : "default" }
        />

        <ErrorMessage
          message={errors.fullname}
        />

        <TextInput
          label="E-mail"
          id="email"
          name="email"
          state={errors.email ? "error" : "default" }
        />

        <ErrorMessage
          message={errors.email}
        />

        <TextInput
          label="Password"
          id="password"
          name="password"
          variant="password"
          state={errors.password ? "error" : "default" }
        />

        <ErrorMessage
          message={errors.password}
        />

        <Checkbox
          id="agree_to_tos"
          name="agree_to_tos"
        >
          I agree to <Link url="/terms">Terms of Service</Link>
        </Checkbox>

        <ErrorMessage
          message={errors.agree_to_tos}
        />

        <Button
          type="submit"
          disabled={submitDisabled}
        >
          Submit
        </Button>
      </form>

      <SharedLinks />
    </div>

  );
}

export default CreateAccount;
