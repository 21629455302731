import React from "react";
import Flag from "./Flags/Flag";
import Legend from "./Flags/Legend";
import { KEYSTATS_QUERY } from "queries";
import { useParams } from "react-router-dom";
import { useQuery } from '@apollo/client';

const Flags = () => {
  const { descriptorShortcode } = useParams();

  const {
    loading,
    data: {
      keystats: {
        flags: {
          data =[],
          legend =[]
        } ={}
      } = {}
    } = {}
  } = useQuery(KEYSTATS_QUERY, {
    fetchPolicy: "no-cache",
    variables: {
      shortcode: descriptorShortcode
    }
  });

  if (loading) { return null; }

  const cssMap = {
    index: "bg-gray-100 text-gray-600",
    insiders: "bg-yellow-100 text-yellow-800",
    growth: "bg-green-100 text-green-700",
  }

  return (
    <div>
      <div className="flex">
        {data.map((flag, index) => (
          <Flag
            key={index}
            label={flag.label}
            shortcode={flag.shortcode}
            css={cssMap[flag.type]}
            type={flag.type}
          />
        ))}
      </div>

      <div className="mt-5 flex gap-3">
        {legend.map((label, index) => (
          <Legend
            key={index}
            label={label.label}
            legend={label.legend}
            css={cssMap[label.type]}
            isLast={index === legend.length - 1}
          />
        ))}
      </div>
    </div>
  )
}

export default Flags;
