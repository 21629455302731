import React from "react";
import { DESCRIPTOR_QUERY } from "queries";
import { useQuery } from '@apollo/client';
import { useParams } from "react-router-dom";
import LoadingBox from "common/LoadingBox";
import Heading from "common/Heading";

const BusinessDescription = () => {
  const { descriptorShortcode } = useParams();

  const { loading, data } = useQuery(DESCRIPTOR_QUERY, {
    variables: {
      shortcode: descriptorShortcode,
    }
  });

  if (loading) { return <LoadingBox /> }

  return (
    <div className="text-base lg:w-2/3">
      <Heading>Business Profile</Heading>

      {data.descriptor.businessDescription}
    </div>
  )
}

export default BusinessDescription;
