import DescriptionSearch from "common/DescriptorSearch";
import React from "react";
import { useNavigate } from "react-router-dom";

const Search = () => {
  const navigate = useNavigate();
  const changeCompany = (descriptor) => {
    navigate(`/${descriptor.shortcode}`);

    return null;
  };

  return (
    <DescriptionSearch
      handleSelect={changeCompany}
    />
  )
};

export default Search;
