import React, { forwardRef } from "react";
import PropTypes from "prop-types";

import cx from "classnames";

const Button = forwardRef(
  (
    {
      variant,
      onClick,
      children,
      disabled,
      className,
      colorVariant,
      href,
    },
    ref
  ) => {
    let sizeClasses;
    let colorClasses;

    switch(variant) {
      case "small":
        sizeClasses = `
          text-sm
          px-3
          py-1
          border-b-[0.25rem]
          active:mt-[0.25rem]
        `;
        break;
      case "large":
        sizeClasses = `
          text-xl
          px-7
          py-2
          border-b-[0.25rem]
          active:mt-[0.25rem]
        `;
        break;
      default:
        sizeClasses = `
          text-base
          px-5
          py-1
          border-b-[0.25rem]
          active:mt-[0.25rem]
        `;
        break;
    }

    switch(colorVariant) {
      case "red":
        colorClasses = `
          bg-red-800
          border-red-950
          text-slate-50
        `;
        break;

      case "gray":
        colorClasses = `
          bg-slate-500
          border-slate-950
          text-slate-50
        `;
        break;

      default:
        colorClasses =`
          bg-emerald-800
          border-slate-900
          text-slate-50
        `;
        break;
    }

    const baseClasses = `
      enabled:active:border-0
      rounded
      font-mono
      text-center
    `;

    const cssClasses = cx(
      baseClasses,
      sizeClasses,
      colorClasses,
      className,
      {
        "disabled:opacity-50": disabled,
        "disabled:text-slate-300": disabled,
        "disabled:mt-0": disabled,
        "cursor-not-allowed": disabled,
      }
    );

    const buttonProps = {
      disabled,
      href,
      onClick,
      ref,
      className: cssClasses,
    }

    if (href === undefined) {
      return (
        <button {...buttonProps}>
          {children}
        </button>
      );
    } else {
      return (
        <a {...buttonProps}>
          {children}
        </a>
      );
    }

  }
);

Button.propTypes = {
  variant: PropTypes.oneOf(["small", "default", "large"]),
  colorVariant: PropTypes.oneOf(["default", "red", "gray"]),
  onClick: PropTypes.func,
  children: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  href: PropTypes.string,
};

Button.defaultProps = {
  variant: "default",
  colorVariant: "default",
  onClick: undefined,
  href: undefined,
};

export default Button;
