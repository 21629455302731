import Highcharts from "highcharts/highstock";
import HighchartsPriceIndicator from "highcharts/modules/price-indicator";
import HighchartsReact from "highcharts-react-official";
import React from "react";
import { useCallback } from "react";
import { useMemo } from "react";
import { merge } from "lodash";

if (typeof Highcharts === "object") {
  HighchartsPriceIndicator(Highcharts);
}

const greenColor = "#16a34a";
const redColor = "#ef4444";

const ChartBox = ({
  series,
  height,
  compare = false,
  yAxis,
  legend = false,
  options = {}
}) => {
  const hasPriceAxis = useMemo(() => {
    return yAxis.some((axis) => {
      return axis.id === "price";
    })
  }, [yAxis]);

  const hasPriceData = useMemo(() => {
    return series.some((datapoints) => {
      return datapoints.yAxis === "price";
    })
  }, [series]);

  const priceSeries = useMemo(() => {
    return series.find(datapoints => datapoints.yAxis === "price")?.data;
  }, [series])

  const priceData = useMemo(() => {
    if (hasPriceData) {
      return priceSeries;
    } else {
      return [];
    }
  }, [hasPriceData, priceSeries])

  const getVolumeBarColor = useCallback(() => {
    return "#cbd5e1";
  }, [priceData])

  const generateVolumeSeries = useCallback(() => {
    return {
      id: ":VOLUME",
      name: priceData.name,
      type: "column",
      yAxis: "volume",
      turboThreshold: 0,
      zIndex: 0,
      enableMouseTracking: false,
      dataGrouping: {
      },
      data: priceData.map((datapoint, index) => {
        return {
          x: datapoint[0],
          y: datapoint[5],
          color: getVolumeBarColor(datapoint, index)
        }
      })
    }
  }, [priceData, getVolumeBarColor]);

  const chartAxis = [...yAxis];
  const chartSeries = [...series];

  if (hasPriceData && hasPriceAxis) {
    chartSeries.push(generateVolumeSeries());
    chartAxis.push(
      {
        "id": "volume",
        "gridLineWidth": 0,
        "gridLineColor": "#e5e5e5",
        "opposite": true,
        "visible": false,
        "top": "70%",
        "height": "30%",
        "labels": {
          "align": "left"
        }
      }
    );
  }

  const defaultOptions = {
    chart: {
      style: { fontFamily: "Gill Sans" },
      alignTicks: false,
      height,
    },
    rangeSelector: {
      enabled: false
    },
    scrollbar: {
      enabled: false
    },
    legend: {
      enabled: legend,
      align: "left",
      itemStyle: {
        fontFamily: "ET Book",
        fontSize: "10px",
        color: "#000000",
        fontWeight: "normal"
      }
    },
    navigator: {
      enabled: false,
    },
    plotOptions: {
      series: {
        compare: compare ? "percent" : false,
        animation: false,
        cropThreshold: 0,
      },
      line: {
        lineWidth: 1,
      },
      candlestick: {
        color: redColor,
        lineColor: redColor,
        upColor: greenColor,
        upLineColor: greenColor,
      }
    },
    tooltip: {
      split: false,
      shadow: false,
      borderWidth: 0,
      backgroundColor: "rgba(255,255,255,0.8)",
      positioner: () => {
        return { x: 0, y: 50 };
      }
    },
    credits: {
      enabled: false,
    },
    series: chartSeries,
    yAxis: chartAxis,
  }

  const chartOptions = merge(
    defaultOptions,
    options
  );

  return (
    <HighchartsReact
      highcharts={Highcharts}
      constructorType="stockChart"
      options={chartOptions}
    />
  )
}

export default ChartBox;
