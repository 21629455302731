import Link from "common/Link";
import React from "react";

const Subscription = ({
  powerpack
}) => {
  return (
    <div>
      {!powerpack && (
        <Link
          url="/vector/billing/subscription/new"
          remote
        >
          Activate Powerpack
        </Link>
      )}

      {powerpack && (
        <Link
          url="/vector/billing/subscription"
          remote
        >
          Manage Subscription
        </Link>
      )}
    </div>
  );
}

export default Subscription;
