import PropTypes from "prop-types";
import React from "react";
import cx from "classnames";
import formatNumber from "utils/formatNumber";
import { forwardRef } from "react";

const FormattedNumber = forwardRef(
  (
    {
      number,
      unit,
      millionize,
      highlightPositive,
      className
    },
    ref
  ) => {
    if (`${number}`.includes("‡")) {
      return (
        <span className="blur-sm">
          {number}
        </span>
      )
    }
    const numberToDisplay = formatNumber(
      number, unit, millionize
    )

    const hasTextSizeClass = [
      "text-xs",
      "text-sm",
      "text-base",
      "text-lg",
      "text-xl",
      "text-2xl"
    ].every((klassName) => {
      if (className) {
        return !className.includes(klassName);
      } else {
        return true
      }
    });

    const numberClass = cx(`
      font-mono
    `,
      {
        "text-red-700": (number < 0),
        "text-green-700": (number > 0 && highlightPositive),
        "text-slate-900": (number === 0 || (number > 0 && !highlightPositive)),
        "text-xs": hasTextSizeClass,
      },
      className
    );

    return (
      <span
        ref={ref}
        className={numberClass}
        data-testid="number"
      >
        {numberToDisplay}
      </span>
    );
  }
);

FormattedNumber.propTypes = {
  number: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  unit: PropTypes.string,
  millionize: PropTypes.bool,
  highlightPositive: PropTypes.bool,
  className: PropTypes.string,
}

FormattedNumber.defaultProps = {
  unit: "cash",
  millionize: true,
  highlightPositive: false,
}

export default FormattedNumber;
