import React from "react";
import { HalflingIcon } from "common/Icon";

const Sort = ({
  className,
  shortcode,
  sortBy,
  sortDirection
}) => {
  return (
    <div className={className}>
      {shortcode === sortBy && sortDirection === "ASC" && (
        <HalflingIcon
          className="fill-slate-400"
          name="chevron-thin-up"
        />
      )}

      {shortcode === sortBy && sortDirection === "DESC" && (
        <HalflingIcon
          className="fill-slate-400"
          name="chevron-thin-down"
        />
      )}
    </div>
  )
}

export default Sort;
