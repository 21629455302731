import React from "react";
import PropTypes from "prop-types";

const LoadingBox = ({ height }) => {
  const squareClass = `
    h-full
    relative
    isolate
    space-y-5
    overflow-hidden
    bg-zinc-100
    before:absolute
    before:inset-0
    before:-translate-x-full
    before:animate-[shimmer_2s_infinite]
    before:border-t
    before:bg-gradient-to-r
    before:from-transparent
    before:via-stone-400
    before:to-transparent
  `;

  return (
    <div
      data-testid="loading-placeholder"
      className=""
      style={{ height }}
    >
      <div className={squareClass}>
      </div>
    </div>
  );
};

LoadingBox.propTypes = {
  height: PropTypes.string,
}

LoadingBox.defaultProps = {
  height: "100px",
}

export default LoadingBox;
