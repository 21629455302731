import React from "react";
import HeaderDefault from "./Cell/HeaderDefault";
import HeaderShortcode from "./Cell/HeaderShortcode";
import HeaderValue from "./Cell/HeaderValue";
import { ScreenerTableContext } from "Screener/Context";
import { useContext } from "react";

const tableHeaderClass = `
  font-normal
  text-slate-500
  hover:text-slate-900
  text-sm
  truncate
  p-0
  cursor-pointer
`;

const HeaderCell = ({
  type,
  label,
  shortcode,
}) => {
  const {
    dispatch,
    state,
  } = useContext(ScreenerTableContext);

  const handleClick = () => {
    dispatch({
      type: "CHANGE_SORT_DIRECTION",
      sortBy: shortcode,
    });
  }

  let CellComponent;

  switch(type) {
    case "header_shortcode":
      CellComponent = HeaderShortcode;
      break;

    case "header_value":
      CellComponent = HeaderValue;
      break;

    default:
      CellComponent = HeaderDefault;
  }

  return (
    <CellComponent
      handleClick={handleClick}
      label={label}
      shortcode={shortcode}
      state={state}
    >
    </CellComponent>
  );
}

export default HeaderCell;
export { tableHeaderClass };
