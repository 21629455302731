import React from "react";
import Checkbox from "common/Checkbox";
import { uniq } from "lodash";
import { without } from "lodash";
import { NewsContext } from "common/News/Context";
import { useContext } from "react";

const FineTune = () => {
  const {
    dispatch,
    state: {
      availableFilters,
      coverage,
      showLinks,
    } ={},
  } = useContext(NewsContext);

  const handleCoverageChange = (event) => {
    let newCoverage;

    if (event.target.checked) {
      newCoverage = without(coverage, "general")
    } else {
      newCoverage = uniq([
        ...coverage,
        "general"
      ]);
    }

    dispatch({
      action: "DEFAULT",
      stateChanges: {
        coverage: newCoverage
      }
    });
  }

  const handleFilterChange = (event) => {
    dispatch({
      action: "DEFAULT",
      stateChanges: {
        [event.target.name]: event.target.checked
      }
    });
  }

  return (
    <React.Fragment>
      {availableFilters.includes("general") && (
        <Checkbox
          id="hideGeneral"
          checked={!coverage.includes("general")}
          name="hideGeneral"
          onChange={handleCoverageChange}
        >
          Hide general
        </Checkbox>
      )}

      {availableFilters.includes("links") && (
        <Checkbox
          id="showLinks"
          checked={showLinks}
          name="showLinks"
          onChange={handleFilterChange}
        >
          Show links
        </Checkbox>
      )}
    </React.Fragment>
  );
}

export default FineTune;
