import React from "react";
import cx from "classnames";

const Descriptor = ({
  shortcode,
  name,
  itemVariant,
}) => {
  const descriptorClass = cx(
    `
      cursor-pointer
      flex
      pb-1
      pt-1.5
      px-2
    `,
    {
      "text-xs": itemVariant !== "large"
    }
  );

  const shortcodeClass = cx(
    `
      font-mono
      text-slate-600
      flex-none
    `,
    {
      "w-24": itemVariant === "large",
      "w-16": itemVariant !== "large"
    }
  );

  return (
    <div className={descriptorClass}>
      <div className={shortcodeClass}>
        {shortcode}
      </div>

      <div className="flex-1">
        {name}
      </div>
    </div>
  );
}

export default Descriptor;
