import fz from "fuzzaldrin-plus/dist-browser/fuzzaldrin-plus.js";

const fuzzer = (candidates, query, options = {}) => {
  let i;
  let scoredCandidates = [];
  let score;
  let candidatesResult = [];

  const scoreProvider = fz.score;

  const pluckCandidates = (a) => {
    return a.candidate;
  };

  const sortCandidates = (a, b) => {
    return b.score - a.score;
  };

  if (!options.key) {
  } else {
    if (typeof options.key.length == "undefined") {
      options.key = [{ key: options.key, weight: 1 }];
    }

    let candidate;

    for (i in candidates) {
      score = 0;

      for(let keyIndex in options.key) {
        candidate = candidates[i][options.key[keyIndex].key];
        score += scoreProvider(candidate,query) * options.key[keyIndex].weight;
      }

      if (score > 0) {
        scoredCandidates.push({
          candidate: candidates[i],
          score: score
        });
      }
    }

    scoredCandidates.sort(sortCandidates);
    candidatesResult = scoredCandidates.map(pluckCandidates);

    if (options.maxResults) {
      candidatesResult = candidatesResult.slice(0, options.maxResults);
    }

    return candidatesResult;
  }
}

export default fuzzer;
