import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import HighchartsTreemap from "highcharts/modules/treemap";
import LoadingBox from "common/LoadingBox";
import React from "react";
import Trigger from "common/Trigger";
import chartOptions from "./Treemap/chartOptions";
import { TREEMAP_CHART_QUERY } from "queries/index";
import { useQuery } from '@apollo/client';
import { useState } from "react";

if (typeof Highcharts === "object") {
  HighchartsTreemap(Highcharts);
}

const Treemap = () => {
  const [chartRange, setChartRange] = useState("ytd");

  const { loading, data } = useQuery(TREEMAP_CHART_QUERY, {
    variables: {
      range: chartRange
    },
    pollInterval: 20000,
  });

  if (loading) {
    return (
      <div className="flex-grow">
        <LoadingBox height="605px" />
      </div>
    )
  }

  const options = chartOptions(
    JSON.parse(data.treemapChart.chartData)
  );

  const handleClick = () => {
    const newRange = chartRange === "ytd" ? "1d" : "ytd";
    setChartRange(newRange);
  }

  const triggerLabel = chartRange === "ytd" ? "Switch to Intraday" : "Switch to YTD";

  return (
    <div className="flex-grow">
      <div>
        <HighchartsReact
          highcharts={Highcharts}
          constructorType="stockChart"
          options={options}
        />
      </div>

      <div className="text-sm">
        <Trigger
          onClick={handleClick}
        >
          {triggerLabel}
        </Trigger>
      </div>

    </div>
  );
}

export default Treemap;
