import React from "react";

const Flag = ({
  flag
}) => {
  const flagClass = `
    inline-flex
    items-center
    rounded-md
    bg-gray-200
    px-1.5
    py-0.5
    text-xs
    font-medium
    text-gray-600
  `;

  if (flag === "specific" || flag === "market") { return null; }

  return (
    <span className={flagClass}>
      {flag}
    </span>
  )
}

export default Flag;
