import React from "react";
import RegularPayout from "./RegularPayout";
import TableLegend from "common/TableLegend";

const Payouts = ({ payouts }) => {
  const payoutCounts = payouts.map(payout => payout.payouts.length);
  const gridBasis = Math.max(...payoutCounts);

  return (
    <div className="mb-5">
      <table className="w-full">
        <thead className="text-xs font-mono text-slate-400">
          <tr>
            <th className="py-1 w-7">
            </th>

            <th className="font-normal text-right pt-1 pb-2 pr-3 w-[60px]">
              total
            </th>

            <th className="font-normal pt-1 pb-2">
              individual payouts
            </th>
          </tr>
        </thead>

        <tbody>
          {payouts.map((dividend, index) => (
            <RegularPayout
              key={index}
              dividend={dividend}
              gridBasis={gridBasis}
            />
          ))}
        </tbody>
      </table>

      <TableLegend>
        <span className="italic">
          predictions in italic,
          </span> <span className="bg-slate-100 text-slate-800 px-2 py-1 mr-0.5">special payouts</span> not
          included in total or ratios
      </TableLegend>
    </div>
  )
}

export default Payouts;
