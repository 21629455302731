import FormattedNumber from "common/FormattedNumber";
import React from "react";
import Sparkline from "./Sparkline";
import cx from "classnames";

const Row = ({ financialsTableRow }) => {
  const cellClass = (index) => {
    return cx(
      `
        text-right
        py-0.5
        px-1
      `,
      {
        "italic": index > 9
      }
    );
  }

  const rowClass = cx(
    {
      "bg-slate-100": financialsTableRow.highlight
    }
  );

  return (
      <tr className={rowClass}>
        {financialsTableRow.values.map((value, index) => (
          <td
            key={index}
            className={cellClass(index)}
          >
            <FormattedNumber
              number={value}
              unit={financialsTableRow.unit}
              className="text-xs"
              milionize
              colorize
            />
          </td>
        ))}

        <td className="text-sm pl-3 flex items-center">
          <div className="mr-0.5">
            <Sparkline
              values={financialsTableRow.values}
            />
          </div>

          <div className="w-[90px]">
            {financialsTableRow.label}
          </div>
        </td>
      </tr>
  );
}

export default Row;
