import AnnualGrowth from "KeystatsReport/AnnualGrowth";
import AssetsLiabilities from "KeystatsReport/AssetsLiabilities";
import CapitalRatios from "KeystatsReport/CapitalRatios";
import Dividends from "KeystatsReport/Dividends";
import InsiderDecisions from "KeystatsReport/InsiderDecisions";
import QuarterlyTable from "KeystatsReport/QuarterlyTable";
import React from "react";

const Sidebar = () => {
  return (
    <React.Fragment>
      <InsiderDecisions />

      <CapitalRatios />

      <Dividends />

      <AssetsLiabilities />

      <AnnualGrowth />

      <QuarterlyTable
        heading="Revenue"
        dataKey="revenueTable"
      />

      <QuarterlyTable
        heading="Operating Cash Flow"
        dataKey="operatingCashflowTable"
      />

      <QuarterlyTable
        heading="Free Cash Flow"
        dataKey="freeCashflowTable"
      />

      <QuarterlyTable
        heading="EPS"
        dataKey="epsTable"
        dataUnit="cash-ratio"
      />
    </React.Fragment>
  );
}

export default Sidebar;
