import Entry from "./Screener/Entry";
import Heading from "common/Heading";
import Link from "common/Link";
import React from "react";
import Save from "./Screener/Save";
import cx from "classnames";
import { SAVED_SCREENERS_QUERY } from "queries/screener";
import { defaultTriggerClass } from "common/Trigger";
import { useLocation } from "react-router-dom";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";

const Screener = () => {
  const location = useLocation();
  const { screenerToken } = useParams();
  const {
    data: {
      savedScreeners =[]
    } ={}
  } = useQuery(SAVED_SCREENERS_QUERY);

  const showControls = useMemo(() => {
    return location.pathname.includes("screener");
  }, [location]);

  const showHeading = useMemo(() => {
    return savedScreeners.length > 0 || showControls;
  }, [savedScreeners, showControls]);

  const applyStyle = useMemo(() => {
    return savedScreeners.length > 0 || showControls;
  }, [savedScreeners, showControls]);

  const componentClass = useMemo(() => {
    return cx(
    ``,
    {
      "border-b border-green-900 pb-5": applyStyle
    }
    );
  }, [applyStyle]);

  if (!showHeading && !showControls) { return null; }

  return (
    <div className={componentClass}>
      {showHeading && (
        <Heading variant="small">
          Screeners
        </Heading>
      )}

      {savedScreeners.map((screener) => (
        <Entry
          key={screener.id}
          id={screener.id}
          name={screener.name}
          notifications={screener.notifications}
          token={screener.token}
        />
      ))}

      {showControls && (
        <div className="text-sm mb-3 mt-5">
          <Save />

          <Link
            url={`/vector/exports/screener/${screenerToken}`}
            className={defaultTriggerClass}
            underline={false}
            remote
          >
            Export screener
          </Link>
        </div>
      )}
    </div>
  );
}

export default Screener;
