import Button from "common/Button";
import DocumentHeader from "./ContactUs/DocumentHeader";
import ErrorMessage from "common/ErrorMessage";
import Heading from "common/Heading";
import React from "react";
import TextInput from "common/TextInput";
import { useForm } from "hooks/useForm";
import { useState } from "react";

const ContactUs = () => {
  const [sentMessageVisible, setSentMessageVisibility] = useState(false);

  const callback = () => {
    setSentMessageVisibility(true);
  }

  const {
    handleChange,
    handleSubmit,
    submitDisabled,
    errors,
  } = useForm({
    formName: "contact_form",
    submitCallback: callback
  });

  return (
    <div className="max-w-3xl m-auto bg-white p-5">
      <DocumentHeader />

      <Heading>
        Contact Us
      </Heading>

      {sentMessageVisible && (
        <div>
          Thanks! We will be in touch soon.
        </div>
      )}

      {!sentMessageVisible && (
        <div>
          <form
            onChange={handleChange}
            onSubmit={handleSubmit}
          >
            <TextInput
              autoFocus
              label="E-mail"
              id="email"
              name="email"
              state={errors.email ? "error" : "default" }
            />

            <ErrorMessage
              message={errors.email}
            />

            <TextInput
              area
              label="Message"
              id="message"
              name="message"
              state={errors.email ? "error" : "default" }
            />

            <ErrorMessage
              message={errors.message}
            />

            <Button
              type="submit"
              disabled={submitDisabled}
            >
              Submit
            </Button>
          </form>
        </div>
      )}
    </div>
  )
}

export default ContactUs;
