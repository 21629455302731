import FormattedNumber from "common/FormattedNumber";
import React from "react";
import cx from "classnames";

const Ratios = ({
  label,
  style,
  unit,
  value,
}) => {
  const ratioClass = cx(
    `
      flex
      px-2
      -mx-2
      py-0.5
    `,
    style
  );

  return (
    <div className={ratioClass}>
      <div className="w-1/2 font-normal text-sm m-auto">
        {label}
      </div>

      <div className="w-1/2 text-right">
        <FormattedNumber
          number={value}
          unit={unit}
          className="text-sm"
        />
      </div>
    </div>
  )
}

export default Ratios;
