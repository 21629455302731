import Descriptor from "./DescriptorSearch/Descriptor";
import LoadingBox from "common/LoadingBox";
import React from "react";
import SearchableList from "common/SearchableList";
import TextInput from "common/TextInput";
import { DESCRIPTORS_QUERY } from "queries";
import { useEffect } from "react";
import { useQuery } from "@apollo/client";
import { useRef } from "react";
import { useState } from "react";

const DescriptionSearch = ({
  handleSelect,
  itemVariant = "large",
  itemLimit = 20,
  handleEscape = () => {},
}) => {
  const [searchPhrase, setSearchPhrase] = useState("");
  const searchRef = useRef(null);
  const containerRef = useRef(null);

  const {
    loading,
    data: {
      descriptors =[]
    } ={}
  } = useQuery(DESCRIPTORS_QUERY, {
    variables: {
      descriptorType: "company",
    }
  });

  const handleKeyDown = (event) => {
    switch (event.keyCode) {
      case 27: // ESC
        searchRef.current.blur();
        handleEscape();

        break;

      default:
        break;
    }
  }

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    }
  }, []);

  const handleBlur = () => {
    setSearchPhrase("");
  }

  const handleClick = (event, item) => {
    event?.preventDefault();

    setSearchPhrase("");
    handleSelect(item);
  }

  const handleChange = (event) => {
    setSearchPhrase(event.target.value);
  }

  if (loading) {
    return <LoadingBox height="52px" />
  }

  return (
    <div
      className="relative"
      ref={containerRef}
    >
      <TextInput
        value={searchPhrase}
        onChange={handleChange}
        onBlur={handleBlur}
        label="Ticker or company name"
        ref={searchRef}
        autoFocus
      />

      <SearchableList
        phrase={searchPhrase}
        list={descriptors}
        searchableKeys={["shortcode", "name"]}
        handleClick={handleClick}
        itemLimit={itemLimit}
        itemRenderer={(item) => {
          return (
            <Descriptor
              name={item.name}
              shortcode={item.shortcode}
              itemVariant={itemVariant}
            />
          );
        }}
      />
    </div>
  )
};

export default DescriptionSearch;
