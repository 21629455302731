import React from "react";
import cx from "classnames";
import { HalflingIcon } from "common/Icon";
import { ScreenerFilterContext } from "Screener/Context";
import { useContext } from "react";

const List = ({
  indicators
}) => {
  const {
    state,
    dispatch
  } = useContext(ScreenerFilterContext);

  const indicatorIsHighlighted = (indicatorId) => {
    const highlightedIndicator = indicators[state.highlightedIndicatorIndex];

    return cx(
      `
        absolute
        left-[-4px]
        top-[6px]
      `,
      {
        "hidden": indicatorId !== highlightedIndicator.shortcode,
      }
    );
  }

  const handleClick = (event, shortcode) => {
    event.stopPropagation();

    const clickedIndicator = indicators.find(i => i.shortcode === shortcode);

    dispatch({
      type: "DEFAULT",
      stateChanges: {
        highlightedIndicatorIndex: 0,
      }
    });

    dispatch({
      type: "SET_INDICATOR_QUERY",
      value: "",
    });

    dispatch({
      type: "ADD_INDICATOR_TO_QUERY",
      indicator: clickedIndicator,
    });
  }

  return (
    <React.Fragment>
      {indicators.map((indicator) => (
        <div
          key={indicator.shortcode}
          className="relative px-4 py-1 cursor-pointer hover:bg-slate-200"
          onClick={(event) => handleClick(event, indicator.shortcode)}
        >
          <div className={indicatorIsHighlighted(indicator.shortcode)}>
            <HalflingIcon name="chevron-thin-right" />
          </div>
          {indicator.name}
        </div>
      ))}
    </React.Fragment>
  )
}

export default List;
