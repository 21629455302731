import Link from "common/Link";
import React from "react";
import { useParams } from "react-router-dom";

const TableNavigation = () => {
  let {
    descriptorShortcode,
    fincialsTableCode,
  } = useParams();

  let showTTM = true;

  if (fincialsTableCode === "balance-sheet") { showTTM = false };

  return (
      <div className="hidden md:flex -mt-3 mb-10 text-sm">
        <Link
          url={`/${descriptorShortcode}/${fincialsTableCode}/q-desc`}
          className="mr-4"
        >
          Quarterly (Descending)
        </Link>

        <Link
          url={`/${descriptorShortcode}/${fincialsTableCode}/q-asc`}
          className="mr-4"
        >
          Quarterly (Ascending)
        </Link>

        <Link
          url={`/${descriptorShortcode}/${fincialsTableCode}/a-desc`}
          className="mr-4"
        >
          Annual (Descending)
        </Link>

        <Link
          url={`/${descriptorShortcode}/${fincialsTableCode}/a-asc`}
          className="mr-4"
        >
          Annual (Ascending)
        </Link>

        {showTTM && (
          <React.Fragment>
            <Link
              url={`/${descriptorShortcode}/${fincialsTableCode}/t-desc`}
              className="mr-4"
            >
              TTM (Descending)
            </Link>

            <Link
              url={`/${descriptorShortcode}/${fincialsTableCode}/t-asc`}
              className="mr-4"
            >
              TTM (Ascending)
            </Link>
          </React.Fragment>
        )}
      </div>
  )
};

export default TableNavigation;
