import DocumentHeader from "./Screener/DocumentHeader";
import FilterInput from "Screener/FilterInput";
import React from "react";
import Table from "Screener/Table";
import UnauthorizedNotification from "common/UnauthorizedNotification";
import { SCREENER_TOKEN_QUERY } from "queries/screener";
import { useCallback } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { useRef } from "react";
import { useState } from "react";

const Screener = () => {
  const [initialIndicators, setInitialIndicators] = useState([]);
  const [indicatorsForQuery, setIndicatorsForQuery] = useState([]);
  const [sortBy, setSortBy] = useState("shortcode");
  const [sortDirection, setSortDirection] = useState("ASC");
  const navigate = useNavigate();

  const { screenerToken: tokenParam } = useParams();

  const {
    data: {
      screenerToken ={}
    } ={}
  } = useQuery(SCREENER_TOKEN_QUERY, {
    variables: {
      token: tokenParam
    }
  });

  useEffect(() => {
    if(screenerToken.indicatorsForQuery) {
      setSortBy(screenerToken.sortBy);
      setSortDirection(screenerToken.sortDirection);
      setInitialIndicators(screenerToken.indicatorsForQuery);
    }
  }, [screenerToken])

  const handleQueryChange = (indicators) => {
    setIndicatorsForQuery(indicators);
  }

  const tokenRef = useRef(null);
  tokenRef.current = tokenParam;

  const handleTokenChange = useCallback((token) => {
    if(tokenRef.current !== token && token !== "") {
      navigate(`/screener/${token}`);
    }
  }, [navigate]);

  const unauthorizedCopy = {
    title: `
      For users without a powerpack screener results are limited to five random
      companies
    `,
    description: `
      With powerpack you will get access to unlimited screener results, XLS exports,
      configurable screener notifications, ability to save you predifined screeners
      and more.
    `
  }

  return (
    <div>
      <DocumentHeader />

      {initialIndicators.length > 0 && (
        <div className="mb-4">
          <FilterInput
            initialIndicators={initialIndicators}
            setQuery={handleQueryChange}
          />
        </div>
      )}

      <UnauthorizedNotification {...unauthorizedCopy} />

      {indicatorsForQuery.length > 0 && (
        <Table
          sortBy={sortBy}
          sortDirection={sortDirection}
          indicators={indicatorsForQuery}
          handleTokenChange={handleTokenChange}
        />
      )}
    </div>
  )
}

export default Screener;
