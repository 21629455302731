import React from "react";
import Simple from "./Body/Simple";
import Virtualized from "./Body/Virtualized";

const Body = ({
  rows
}) => {
  const Component = rows.length > 100 ? Virtualized : Simple;

  return (
    <Component
      rows={rows}
    />
  )
}

export default Body;
