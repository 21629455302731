import React from "react";

const Sparkline = ({
  values
}) => {
  const numerciValues = values.slice(0, 10).filter(value => (/\d+/).test(value));

  const maxValue = Math.max(...numerciValues);
  const minValue = Math.min(...numerciValues);

  const pointsDistance = maxValue - minValue;
  const tickSize = pointsDistance / 10;

  const linesMap = values.slice(0, 10).map((value) => {
    if ((/\d+/).test(value)) {
      const distanceFromMin = value - minValue;
      return Math.round(distanceFromMin / tickSize) + 1;
    } else {
      return 0;
    }
  });

  return (
    <div className="flex items-end">
      {linesMap.map((value, index) => (
        <div
          className="bg-slate-700"
          key={index}
          style={{
            width: "1px",
            marginRight: "1px",
            height: `${value}px`,
          }}
        />
      ))}
    </div>
  )
}

export default Sparkline;
