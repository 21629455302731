import React, { forwardRef } from "react";
import PropTypes from "prop-types";

const Checkbox = forwardRef(
  (
    {
      id,
      name,
      children,
      ...props
    },
    ref,
  ) => {
    return (
      <div className="flex">
        <div className="shrink mr-2">
          <input
            type="checkbox"
            ref={ref}
            id={id}
            name={name}
            className="cursor-pointer"
            {...props}
          />
        </div>

        <div className="grow">
          <label
            className="cursor-pointer"
            htmlFor={id}
          >
            {children}
          </label>
        </div>
      </div>
    )
  }
)

Checkbox.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  children: PropTypes.node,
}

export default Checkbox;
