import React from "react";

const Undefined = () => {
  return (
    <React.Fragment>
      <div className="mx-1 bg-red-300 text-neutral-900 px-5">
        Critical Error. Abort, Retry, Cancel?
      </div>
    </React.Fragment>
  );
}

export default Undefined;
