import LoadingBox from "common/LoadingBox";
import PriceChange from "common/PriceChange";
import React from "react";
import { FUTURE_PRICES_QUERY } from "queries";
import { useQuery } from "@apollo/client";

const FuturesPriceChange = () => {
  const {
    loading,
    data
  } = useQuery(FUTURE_PRICES_QUERY, {
    variables: {
      shortcodes: ["ES:future", "YM:future", "NQ:future"],
    },
    pollInterval: 20000,
  });

  if (loading) return <LoadingBox height="100px" />

  const sortingArr = [
    "ES", "YM", "NQ"
  ];

  const futuresPrices = [...data.futuresPrices].sort((previous, next) => {
    return sortingArr.indexOf(previous.shortcode) - sortingArr.indexOf(next.shortcode);
  });

  return (
    <div>
      {futuresPrices.map((price, index) =>
        <PriceChange
          key={index}
          label={price.futureName}
          price={price.close}
          absoluteChange={price.absoluteChange}
          relativeChange={price.relativeChange}
        />
      )}
    </div>
  )
}

export default FuturesPriceChange;
