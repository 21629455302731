import Button from "common/Button";
import Heading from "common/Heading";
import Link from "common/Link";
import React from "react";
import { HalflingIcon } from "common/Icon";
import { Icon } from "common/Icon";
import { useNavigate } from "react-router-dom";

const Callout = () => {
  const navigate = useNavigate();

  const powerpackDefinition = [
    {
      heading: "Watchlist",
      text: `
        Keep track of companies that you follow and research.
      `
    },
    {
      heading: "10 Years of Data",
      text: `
        Full access to our data with predictions and indicators that we
        calculate daily.
      `
    },
    {
      heading: "Screener",
      text: `
        Full access to our screener with tons of custom values and
        customizable email notifications.
      `
    },
    {
      heading: "XLS Exports",
      text: `
        Excel export of financials and screeners you define and save.
      `
    },
  ];

  const handleClick = () => {
    navigate("/account/create");
  }

  return (
    <React.Fragment>
      <div className="flex flex-none justify-between bg-neutral-200 h-16 w-full">
        <div className="m-auto">
          <Link
            url="/account/login"
            className="m-5"
          >
            Login
          </Link>
        </div>

        <div className="m-auto">
          <Link
            url="/account/create"
          >
            Create Account
          </Link>
        </div>
      </div>

      <div className="flex grow flex-col gap-y-5 overflow-y-auto px-5">
        <div className="mb-0 mt-8">
          <Icon
            name="package"
            className="fill-slate-800"
            style={{ width: "80px" }}
          />
        </div>

        <Heading>
          Powerpack
        </Heading>

        <div className="mb-3">
          Unlock full stockrow access for only $19/month and boost yourself
          as an investor.
        </div>

        <div className="mb-5">
          {powerpackDefinition.map((item, index) => (
            <div
              className="flex mb-6"
              key={index}
            >
              <div className="shrink mr-1">
                <HalflingIcon
                  className="fill-lime-600"
                  name="check"
                />
              </div>

              <div className="grow">
                <Heading
                  className="text-teal-800"
                  variant="tiny"
                >
                  {item.heading}
                </Heading>

                <p className="text-sm text-neutral-500">
                  {item.text}
                </p>
              </div>
            </div>
          ))}
        </div>

        <div>
          <Button
            variant="small"
            className="w-full bg-cyan-700"
            onClick={handleClick}
          >
            Get your Powerpack
          </Button>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Callout;
