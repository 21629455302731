import Flag from "./Flag";
import Link from "common/Link";
import React from "react";
import Score from "./Score";
import getPublishTime from "./getPublishTime";
import { NewsContext } from "common/News/Context";
import { useContext } from "react";

const Article = ({
  article: {
    flag,
    justification,
    publishedAt,
    score,
    source,
    text,
    title,
    url,
  } ={}
}) => {
  const {
    state: {
      showLinks,
      showJustification,
    } ={},
  } = useContext(NewsContext);

  return (
    <div className="break-inside-avoid-column">
      <div className="flex gap-3 text-sm">
        <Score score={parseInt(score)} />

        <Flag flag={flag} />

        <span className="text-slate-400">
          {source}, {getPublishTime(publishedAt)}
        </span>
      </div>

      {showLinks && (
        <div className="text-sm">
          <Link url={url}>
            {title}
          </Link>
        </div>
      )}

      <div className="text-sm">
        {text} {showJustification && justification}<br /><br />
      </div>
    </div>
  )
}

export default Article;
