import React from "react";
import { Outlet } from "react-router-dom";

import BackgroundPattern from "./pattern.svg";

const BlankRoot = () => {
  return (
    <div
      style={{ backgroundImage: `url(${BackgroundPattern})` }}
    >
      <div
        className="flex h-[calc(100vh)]"
      >
        <div className="h-full grow overflow-scroll">
          <div className="p-5">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
}

export default BlankRoot;
