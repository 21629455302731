import React from "react";
import Default from "./Cell/Default";
import Boolean from "./Cell/Boolean";
import Value from "./Cell/Value";
import Hyperlink from "./Cell/Hyperlink";

const Cell = ({
  cell,
}) => {
  let CellComponent;

  switch(cell.type) {
    case "link":
      CellComponent = Hyperlink;
      break;

    case "value":
      CellComponent = Value;
      break;

    case "boolean":
      CellComponent = Boolean;
      break;

    default:
      CellComponent = Default;
  }

  return (
    <td>
      <CellComponent {...cell} />
    </td>
  );
}

export default Cell;
