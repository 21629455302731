import Heading from "common/Heading";
import React from "react";
import Refresh from "./Filter/Refresh";
import Scores from "./Filter/Scores";
import FineTune from "./Filter/FineTune"

const Filter = () => {
  return (
    <div className="flex flex-col gap-5 pt-4 pb-7 border-t border-t-slate-300 border-b border-b-slate-300 mb-2">
      <div className="grid grid-cols-7 text-sm text-slate-700">
        <div className="col-span-5 bg-gray-200 p-3">
          <Heading variant="tiny">
            News by impact score
          </Heading>

          <div className="grid md:grid-cols-5">
            <Scores />
          </div>
        </div>

        <div className="col-span-2 p-3 bg-gray-100">
          <Heading variant="tiny">
            Fine-tune
          </Heading>

          <div className="grid md:grid-cols-2">
            <FineTune />
          </div>
        </div>
      </div>

      <div>
        <Refresh />
      </div>
    </div>
  )
}

export default Filter;
