import makeRequest from "utils/makeRequest";
import useDebounce from "hooks/useDebouce";
import { useEffect } from "react";
import { useReducer } from "react";
import { useRef } from "react";

const useForm = ({
  formName,
  submitCallback = null,
  fieldValues = {},
}) => {
  const initialRender = useRef(true);
  const initialFormState = {
    fieldValues,
    dirtyFields: new Set(),
    submitDisabled: true,
    validationErrors: {}
  }

  const formReducer = (state, action) => {
    switch (action.type) {
      case "SET_FORM_VALUE":
        const dirtyFields = new Set(state.dirtyFields).add(action.field);
        const fieldValues = { ...state.fieldValues };

        fieldValues[action.field] = action.value;

        return {
          ...state,
          dirtyFields,
          fieldValues,
        }

      case "SET_ERRORS":
        const validationErrors = {};
        const submitDisabled = action.errors
          .map(e => e.valid)
          .some(e => e === false) || state.dirtyFields.size === 0;

        action.errors.forEach((error) => {
          if (error.valid) { return }
          if (!state.dirtyFields.has(error.name)) { return }

          validationErrors[error.name] = error.message;
        });

        return {
          ...state,
          validationErrors,
          submitDisabled
        }

      case "SUBMIT_FORM":
        return {
          ...state,
          submitDisabled: true
        }

      default:
        break;
    }
  }

  const [state, dispatch] = useReducer(formReducer, initialFormState);

  const handleChange = (event) => {
    let value;

    if (event.target.type === "checkbox") {
      value = event.target.checked;
    } else {
      value = event.target.value;
    }

    dispatch({
      type: "SET_FORM_VALUE",
      field: event.target.name,
      value: value,
    })
  }

  const validateForm = useDebounce(async () => {
    const body = {
      formName,
      formData: state.fieldValues
    }

    const response = await makeRequest({
      url: "/vector/forms/validation",
      body
    });

    dispatch({
      type: "SET_ERRORS",
      errors: response.data
    });
  }, 500);

  const handleSubmit = async (event) => {
    event.preventDefault();

    dispatch({
      type: "SUBMIT_FORM",
    })

    const body = {
      formName,
      formData: state.fieldValues
    }

    const response = await makeRequest({
      url: "/vector/forms/persistance",
      body
    });

    if (submitCallback) { submitCallback(response) }
  }

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    } else {
      validateForm();
    }
  }, [state.dirtyFields, validateForm])

  return {
    handleChange,
    handleSubmit,
    submitDisabled: state.submitDisabled,
    errors: state.validationErrors,
  }
};

export { useForm };
