import Groups from "./IndicatorList/Groups";
import List from "./IndicatorList/List";
import React from "react";
import Trigger from "common/Trigger";
import fuzzer from "utils/fuzzer";
import { ScreenerFilterContext } from "Screener/Context";
import { pullAllBy } from "lodash";
import { useCallback } from "react";
import { useContext } from "react";
import { useMemo } from "react";
import { useState } from "react";
import { useEffect } from "react";

const IndicatorList = ({
  indicatorInputReference
}) => {
  const {
    dispatch,
    state,
  } = useContext(ScreenerFilterContext);

  const [activeGroup, setActiveGroup] = useState("All");

  const indicatorsFilteredByQuery = useMemo(() => {
    let filteredIndicators = [...state.indicators];

    filteredIndicators = pullAllBy(
      filteredIndicators,
      state.indicatorsInQuery,
      "shortcode",
    )

    if (state.indicatorQuery !== "") {
      filteredIndicators = fuzzer(
        filteredIndicators,
        state.indicatorQuery,
        {
          key: [
            { key: "name", weight: 4 },
            { key: "keywords", weight: 0.5 }
          ],
          usePathScoring: true,
        }
      );
    }

    return filteredIndicators;
  }, [state.indicatorQuery, state.indicatorsInQuery]);

  const indicatorsToDisplay = useMemo(() => {
    let filteredIndicators = indicatorsFilteredByQuery;

    if (activeGroup !== "All") {
      filteredIndicators = filteredIndicators.filter(indicator => indicator.group === activeGroup);
    }

    return filteredIndicators;
  }, [activeGroup, indicatorsFilteredByQuery]);

  useEffect(() => {
    dispatch({
      type: "DEFAULT",
      stateChanges: {
        visibleIndicatorsCount: indicatorsToDisplay.length,
        visibleIndicators: indicatorsToDisplay,
      }
    });
  }, [indicatorsToDisplay]);

  const hideList = useCallback((event) => {
    event.stopPropagation();

    indicatorInputReference.current.blur();

    dispatch({
      type: "DEFAULT",
      stateChanges: {
        filterBoxStyle: "",
      }
    });

    dispatch({
      type: "SET_INDICATOR_QUERY",
      value: "",
    });
  }, [dispatch, indicatorInputReference]);

  const listClass = `
    absolute
    border
    border-emerald-800
    bg-white
    shadow-xl
    h-32
    h-[65vh]
    w-full
    z-20
    flex
    flex-col
    mt-2
  `;

  const handleClick = (event) => {
    event.stopPropagation();
  }

  return (
    <div
      className={listClass}
      onClick={handleClick}
    >
      <div className="flex px-5 py-3 bg-neutral-200">
        <div className="mr-5">
          Type to search, use arrow keys to move selection. To add indicator to query hit
          "Enter" or click on it.
        </div>

        <div>
          <Trigger
            onClick={hideList}
            colorVariant="red"
          >
            Close (Esc)
          </Trigger>
        </div>
      </div>

      <div className="flex flex-1 overflow-hidden mt-2 px-5 py-3">
        <div className="w-[300px] pr-2 mr-2 overflow-scroll">
          <Groups
            activeGroup={activeGroup}
            indicators={indicatorsFilteredByQuery}
            setActiveGroup={setActiveGroup}
          />
        </div>

        <div className="overflow-scroll grow flex-1">
          <List
            indicators={indicatorsToDisplay}
          />
        </div>
      </div>
    </div>
  );
}

export default IndicatorList;
