const industryScreenerDefinition = (industry) => {
  return [
    {
      "shortcode": "shortcode",
      "showInTable": true,
      "value": [],
      "indicatorRecord": {
        "screenerType": "shortcode"
      }
    },
    {
      "shortcode": "name",
      "showInTable": true,
      "value": "",
      "indicatorRecord": {
        "screenerType": "simple"
      }
    },
    {
      "shortcode": "industry",
      "showInTable": false,
      "value": [industry],
      "indicatorRecord": {
        "screenerType": "industry"
      }
    },
    {
      "shortcode": "DAILY_PRICE:D",
      "showInTable": true,
      "value": "",
      "indicatorRecord": {
        "screenerType": "value"
      }
    },
    {
      "shortcode": "MARKETCAP:N",
      "showInTable": true,
      "value": "",
      "indicatorRecord": {
        "screenerType": "value"
      }
    },
    {
      "shortcode": "PE:D",
      "showInTable": true,
      "value": "",
      "indicatorRecord": {
        "screenerType": "value"
      }
    }
  ]
}

export default industryScreenerDefinition;
