import Checkbox from "common/Checkbox";
import React from "react";
import { NewsContext } from "common/News/Context";
import { useContext } from "react";

const Scores = () => {
  const {
    dispatch,
    state: {
      activeScores,
    } ={},
  } = useContext(NewsContext);

  const filterableScores = [
    { score: 5, label: "Profound" },
    { score: 4, label: "Significant" },
    { score: 3, label: "Noticeable" },
    { score: 2, label: "Minor" },
    { score: 1, label: "Miniscule" },
  ];

  const handleScoreChange = (event) => {
    const changedScore = parseInt(event.target.name);
    let newActiveScores;

    if (event.target.checked) {
      newActiveScores = new Set([
        ...activeScores,
        changedScore,
      ]);
    } else {
      newActiveScores = activeScores.difference(
        new Set([changedScore])
      )
    }

    dispatch({
      action: "DEFAULT",
      stateChanges: {
        activeScores: newActiveScores
      }
    });
  }

  return (
    <React.Fragment>
      {filterableScores.map((scoreItem) => (
        <Checkbox
          id={`score-${scoreItem.score}`}
          name={`${scoreItem.score}`}
          key={`score-${scoreItem.score}`}
          onChange={handleScoreChange}
          checked={activeScores.has(parseInt(scoreItem.score))}
        >
        {scoreItem.score} — {scoreItem.label}
        </Checkbox>
      ))}
    </React.Fragment>
  );
}

export default Scores;
