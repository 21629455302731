import React from "react";

const Default = ({
  value
}) => {
  return (
    <div className="p-1">
      <div className="text-base truncate">
        {value}
      </div>
    </div>
  );
}

export default Default;
