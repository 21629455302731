import { gql } from '@apollo/client';

//
// Queries
//

const SAVED_SCREENERS_QUERY = gql`
  query GetSavedScreeners {
    savedScreeners {
      id
      token
      name
      notifications
    }
  }
`;
export { SAVED_SCREENERS_QUERY };

const SCREENER_QUERY = gql`
  query GetScreener($screenerParams: JSON!, $sample: Boolean) {
    screener(screenerParams: $screenerParams, sample: $sample) {
      headers
      rows
      token
      count
    }
  }
`;
export { SCREENER_QUERY };

const SCREENER_TOKEN_QUERY = gql`
  query GetScreenerToken($token: String) {
    screenerToken(token: $token) {
      sortBy
      sortDirection
      indicatorsForQuery
    }
  }
`;
export { SCREENER_TOKEN_QUERY };

//
// Mutations
//

const SAVE_SCREENER = gql`
  mutation saveScreener($token: String!, $name: String!) {
    saveScreener(token: $token, name: $name) {
      savedScreener {
        name
        token
        notifications
      }
    }
  }
`;
export { SAVE_SCREENER };

const DELETE_SCREENER = gql`
  mutation DeleteScreener($screenerId: String!) {
    deleteScreener(screenerId: $screenerId) {
      id
    }
  }
`;
export { DELETE_SCREENER };

const UPDATE_SCREENER = gql`
  mutation UpdateScreener($screenerId: String!, $name: String!, $notifications: Boolean!) {
    updateScreener(screenerId: $screenerId, name: $name, notifications: $notifications) {
      id
    }
  }
`;
export { UPDATE_SCREENER };

const SAVE_SCREENER_TOKEN = gql`
  mutation findOrCreateScreenerToken($sortBy: String!, $sortDirection: String!, $indicatorsForQuery: JSON!) {
    findOrCreateScreenerToken(sortBy: $sortBy, sortDirection: $sortDirection, indicatorsForQuery: $indicatorsForQuery) {
      screenerToken {
        token
        sortBy
        sortDirection
        indicatorsForQuery
      }
    }
  }
`;
export { SAVE_SCREENER_TOKEN };
