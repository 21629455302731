import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import cx from "classnames";

const baseClass = `
  border-b
  border-dashed
  cursor-pointer
`

const colorMap = {
  "default": `
    border-slate-800
    text-sky-700
    hover:border-red-400
    hover:text-red-700
  `,
  "red": `
    border-red-600
    text-red-700
    hover:border-red-300
    hover:text-red-800

  `,
  "white": `
    border-slate-300
    text-white
    hover:border-slate-400
    hover:text-slate-200
  `,
}

const defaultTriggerClass = cx(
  baseClass,
  colorMap["default"],
);

const Trigger = forwardRef(
  (
    {
      onClick,
      colorVariant,
      children
    },
    ref
  ) => {
    const triggerClass = cx(
      baseClass,
      colorMap[colorVariant],
    );

    return (
      <span
        ref={ref}
        role="button"
        onClick={onClick}
        className={triggerClass}
      >
        {children}
      </span>
    )
  }
)

Trigger.propTypes = {
  colorVariant: PropTypes.oneOf(["default", "red", "white"]),
  onClick: PropTypes.func,
  children: PropTypes.node,
}

Trigger.defaultProps = {
  colorVariant: "default"
}

export default Trigger;
export { defaultTriggerClass };
