import LoadingBox from "common/LoadingBox";
import PriceChange from "common/PriceChange";
import React from "react";
import ScreenerLink from "common/ScreenerLink";
import screenerDefinition from "./Indexes/screenerDefinition";
import { VALUE_CHANGE_QUERY } from "queries";
import { useQuery } from "@apollo/client";

const IndexesPriceChange = ({
  range,
  hideColorLegend = false
}) => {
  const {
    loading,
    data
  } = useQuery(VALUE_CHANGE_QUERY, {
    variables: {
      indicators: ["DAILY_PRICE:D"],
      shortcodes: ["GSPC:index", "DJI:index", "NDX:index"],
      range: range
    },
    pollInterval: 20000,
  });

  if (loading) return <LoadingBox height="100px" />

  const sortingArr = [
    "GSPC:index", "DJI:index", "NDX:index"
  ];

  const indexPrices = [...data.valueChanges].sort((previous, next) => {
    return sortingArr.indexOf(previous.shortcode) - sortingArr.indexOf(next.shortcode);
  });

  const labelColor = (shortcode, name) => {
    if (hideColorLegend) { return name }

    const color = {
      "GSPC:index": "#f44141",
      "DJI:index": "#4286f4",
      "NDX:index": "#d39f3d"
    }[shortcode]

    const indexIndicator = {
      "GSPC:index": "SNP_500_MEMBER:N",
      "DJI:index": "DOJ_MEMBER:N",
      "NDX:index": "NASDAQ_100_MEMBER:N",
    }[shortcode];

    const indicators = screenerDefinition(indexIndicator);

    return (
      <div className="flex">
        <div
          className="shrink rounded-full mr-1 my-auto"
          style={{ width: "9px", height: "9px", backgroundColor: color }}
        >
        </div>

        <div className="grow">
          <ScreenerLink
            indicators={indicators}
            sortBy="shortcode"
            sortDirection="ASC"
          >
            {name}
          </ScreenerLink>
        </div>
      </div>
    )
  }

  return (
    <div>
      {indexPrices.map((valueChange, index) =>
        <PriceChange
          key={index}
          label={labelColor(valueChange.shortcode, valueChange.descriptorName)}
          price={valueChange.currentValue}
          absoluteChange={valueChange.absoluteChange}
          relativeChange={valueChange.relativeChange}
        />
      )}
    </div>
  )
}

export default IndexesPriceChange;
