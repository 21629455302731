import React from "react";
import Row from "Screener/Table/Row";
import { useState } from "react";
import { throttle } from "lodash";
import { ScreenerTableContext } from "Screener/Context";
import { useContext } from "react";

const Virtualized = ({
  rows
}) => {
  const {
    state,
  } = useContext(ScreenerTableContext);

  const containsValueDisplayType = state.indicatorsForQuery.map((indicator) => {
    return indicator.valueDisplayType;
  }).includes("plot");

  // Height of each row in screener
  const itemRowHeight = containsValueDisplayType ? 216 : 35;

  // Height of the screen
  const screenHeight = Math.max(
    document.documentElement.clientHeight,
    window.innerHeight || 0
  );

  // Render more rows than visible so there are no empty lines
  // when scrolling
  const offset = screenHeight;

  // Get number of rows to render
  const numberOfRowsToRender = Math.floor((screenHeight + offset) / itemRowHeight);

  const [displayStart, setDisplayStart] = useState(0);
  const [displayEnd, setDisplayEnd] = useState(0);
  const [scrollPosition, setScrollPosition] = useState(0);

  const setDisplayPositions = React.useCallback(
    (scroll) => {
      // we want to start rendering a bit above the visible screen
      const scrollWithOffset = Math.floor(scroll - numberOfRowsToRender - offset / 2);
      // start position should never be less than 0
      const displayStartPosition = Math.round(
        Math.max(0, Math.floor(scrollWithOffset / itemRowHeight))
      );

      // end position should never be larger than our rows array
      const displayEndPosition = Math.round(
        Math.min(displayStartPosition + numberOfRowsToRender, rows.length)
      );

      setDisplayStart(displayStartPosition);
      setDisplayEnd(displayEndPosition);
    }, [rows.length]);

  // We want to set the display positions on renering
  React.useEffect(() => {
    setDisplayPositions(scrollPosition);
  }, [scrollPosition, setDisplayPositions]);

  // add event listeners so we can change the scroll position, and alter what rows to display
  React.useEffect(() => {
    const onScroll = throttle(() => {
      const scrollTop = window.scrollY;
      if (rows.length !== 0) {
        setScrollPosition(scrollTop);
        setDisplayPositions(scrollTop);
      }
    }, 100);

    window.addEventListener("scroll", onScroll);

    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, [setDisplayPositions, rows.length]);

  const rowsToRender = [];

  rowsToRender.push(
    <tr
      key="firstFillerRow"
      style={{ height: displayStart * itemRowHeight }}
    />
  );

  for (let i = displayStart; i < displayEnd; ++i) {
    const row = rows[i];
    if (row !== undefined) {
      rowsToRender.push(
        <Row
          key={i}
          index={i}
          row={row}
        />
      );
    }
  }

  rowsToRender.push(
    <tr
      key="lastFillerRow"
      style={{ height: (rows.length - displayEnd) * itemRowHeight }}
    />
  );

  return (
    <tbody>
      {rowsToRender}
    </tbody>
  )
}

export default Virtualized;
