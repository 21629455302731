import FormattedNumber from "common/FormattedNumber";
import React from "react";

const InsiderOwnership = ({
  insiderOwnership
}) => {
  return (
    <div className="mt-3">
      {insiderOwnership.map((ratio, index) => (
        <div
          className="flex px-2 -mx-2 py-0.5"
          key={index}
        >
          <div className="w-1/2 font-normal text-sm m-auto">
            {ratio.label}
          </div>

          <div
            key={index}
            className="w-1/2 text-right"
          >
            <FormattedNumber
              number={ratio.value}
              unit={ratio.unit}
              className="text-sm"
            />
          </div>
        </div>
      ))}
    </div>
  );
}

export default InsiderOwnership;
