const makeRequest = async ({ url, method = "POST", body }) => {
  const request = {
    headers: {
      "Accept": "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
    method,
  }

  const response = await fetch(url, request);

  let result = {};

  if (response.status === 200) {
    result["status"] = "success";
    result["data"] = await response.json();
  } else {
    result["status"] = "error";
    result["data"] = null;
  }

  return result;
}

export default makeRequest;
