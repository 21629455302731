import React from "react";
import Sort from "./Sort";
import cx from "classnames";
import { tableHeaderClass } from "Screener/Table/HeaderCell";

const HeaderDefault = ({
  handleClick,
  label,
  shortcode,
  state,
}) => {
  const headerClass = cx(
    tableHeaderClass,
    `
      w-44
    `
  );

  return (
    <th
      onClick={handleClick}
      className={headerClass}
    >
      <div className="flex border-b px-1 py-2 mb-3">
        <div className="text-left">
          {label}
        </div>

        <Sort
          shortcode={shortcode}
          sortBy={state.sortBy}
          sortDirection={state.sortDirection}
        />
      </div>
    </th>
  );
}

export default HeaderDefault;
