import Description from "./Description";
import FinancialsChart from "./FinancialsChart";
import FormattedNumber from "common/FormattedNumber";
import React from "react";
import TableNavigation from "./TableNavigation";
import UnauthorizedNotification from "common/UnauthorizedNotification";
import cx from "classnames";
import { HalflingIcon } from "common/Icon";
import { ACCOUNT_QUERY } from "queries";
import { FINANCIALS_TABLE_QUERY } from "queries/index";
import { useParams } from "react-router-dom";
import { useQuery } from '@apollo/client';

const MainTable = () => {
  let {
    descriptorShortcode,
    fincialsTableCode,
    financialsTableParams,
  } = useParams();

  if (financialsTableParams === undefined) { financialsTableParams = "q-desc" }

  const { loading, data } = useQuery(FINANCIALS_TABLE_QUERY, {
    variables: {
      shortcode: descriptorShortcode,
      code: [fincialsTableCode, financialsTableParams].join("-")
    }
  });

  const {
    loading: accountLoading,
    data: {
      account = {
        unauthorized: false
      }
    } = {}
  } = useQuery(ACCOUNT_QUERY);

  if (loading || accountLoading) return (
    <div>
      Loading
    </div>
  );

  const financialsTableData = JSON.parse(data.financialsTable.tableData);

  const baseRowClass = `
  `;

  const baseLabelClass = `
    my-auto
    text-sm
    pl-2
  `;

  const rowClass = (additionalClasses) => {
    return cx(
      additionalClasses,
      baseRowClass,
    )
  }

  const labelClass = (additionalClasses) => {
    return cx(
      additionalClasses,
      baseLabelClass,
    )
  }

  const chartClass = cx(`
    `,
    {
      "blur": !account.powerpack
    }
  );

  const unauthorizedCopy = {
    title: `
      Financial statements are limited to most recent four years or quarters for non-powerpack
      users
    `,
    description: `
      Ten years worth of data (where available), most important trends and XLS
      exports are available for our Powerpack users.
    `
  }

  const firstColumnClass = `
    sticky
    left-0
    w-72
  `;

  const columnClass = `
    z-0
    w-24
    py-1
  `;

  return (
    <React.Fragment>
      <TableNavigation />

      <UnauthorizedNotification {...unauthorizedCopy} />

      <div className="grid grid-cols-10 gap-5">
        <div className="sm:col-span-10 md:col-span-6 lg:col-span-7">
          <div className={chartClass}>
            <FinancialsChart />
          </div>
        </div>

        <div className="sm:col-span-10 md:col-span-4 lg:col-span-3">
          <Description />
        </div>
      </div>


      <div className="overflow-scroll">
        <table className="w-full table-fixed">
          <thead>
            <tr>
              <th className={firstColumnClass} />

              {financialsTableData.headers.map((header, index) => (
                <th
                  className={columnClass}
                  key={index}
                >
                  <div className="text-xs font-mono text-slate-400 text-right font-normal pb-1">
                    {header}
                  </div>
                </th>
              ))}
            </tr>
          </thead>

          <tbody>
            {financialsTableData.values.map((valuesRow, rowIndex) => (
              <tr key={rowIndex} className={rowClass(valuesRow.styles.row_styles)}>
                <td className={firstColumnClass}>
                  <div className={labelClass(valuesRow.styles.label_styles)}>
                    <div className="flex gap-2">
                      <div>{valuesRow.label}</div>

                      <div>
                        <a href={`/wiki/${valuesRow.code.toLowerCase()}`}>
                          <HalflingIcon name="link" className="fill-neutral-300" />
                        </a>
                      </div>
                    </div>
                  </div>
                </td>

                {valuesRow.values.map((value, index) => (
                  <td
                    className={columnClass}
                    key={index}
                  >
                    <div className="pr-2 text-right">
                      <FormattedNumber
                        unit={valuesRow.unit}
                        number={value}
                      />
                    </div>
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </React.Fragment>
  )
};

export default MainTable;
