import { debounce } from "lodash";
import { useCallback } from "react";
import { useRef } from "react";

const useDebounce = (callback, delay) => {
  const callbackRef = useRef()
  callbackRef.current = callback;

  return useCallback(
    debounce(
      (...args) => callbackRef.current(...args),
      delay,
    ), []
  );
}

export default useDebounce;
