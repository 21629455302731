import Account from "AccountHub/Account";
import FinancialsTable from "AccountHub/FinancialsTable";
import Logo from "common/Logo";
import Navigation from "AccountHub/Navigation";
import React from "react";
import Screener from "AccountHub/Screener";
import Watchlist from "AccountHub/Watchlist";
import { ACCOUNT_QUERY } from "queries";
import { useQuery } from "@apollo/client";

const AccountHub = () => {
  const {
    data: {
      account: {
        powerpack = true
      } ={}
    } ={}
  } = useQuery(ACCOUNT_QUERY);

  return (
    <React.Fragment>
      <div className="flex lg:hidden h-16 shrink-0 items-center bg-neutral-200 px-6">
        <Logo />
      </div>

      <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-neutral-100 px-5">
        <Navigation />

        {powerpack && (
          <div className="flex flex-col space-y-5 pt-5">
            <FinancialsTable />

            <Screener />

            <Watchlist />
          </div>
        )}

        <Account />
      </div>
    </React.Fragment>
  );
}

export default AccountHub;
