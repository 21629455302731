import Button from "common/Button";
import DocumentHeader from "./Login/DocumentHeader";
import ErrorMessage from "common/ErrorMessage";
import Heading from "common/Heading";
import React from "react";
import SharedLinks from "./Login/SharedLinks";
import TextInput from "common/TextInput";
import { ACCOUNT_QUERY } from "queries";
import { useForm } from "hooks/useForm";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@apollo/client";

const Login = () => {
  const navigate = useNavigate();
  const { refetch } = useQuery(ACCOUNT_QUERY);
  const callback = () => {
    navigate("/");
    refetch();
  }
  const {
    handleChange,
    handleSubmit,
    submitDisabled,
    errors,
  } = useForm({
    formName: "login_form",
    submitCallback: callback
  });

  return (
    <div className="m-auto w-96">
      <DocumentHeader />

      <Heading>
        Login
      </Heading>

      <form
        onChange={handleChange}
        onSubmit={handleSubmit}
      >
        <TextInput
          autoFocus
          label="E-mail"
          id="email"
          name="email"
          state={errors.email ? "error" : "default" }
        />

        <ErrorMessage
          message={errors.email}
        />

        <TextInput
          label="Password"
          id="password"
          name="password"
          variant="password"
          state={errors.password ? "error" : "default" }
        />

        <ErrorMessage
          message={errors.password}
        />

        <Button
          type="submit"
          disabled={submitDisabled}
        >
          Submit
        </Button>
      </form>

      <SharedLinks />
    </div>
  );
}

export default Login;
