import PropTypes from "prop-types";
import React from "react";
import cx from "classnames";

const TableLegend = ({
  className,
  children
}) => {
  const legendClass = cx(
    `
      text-slate-400
      font-mono
      text-xs
      mt-1
    `,
    className
  );

  return (
    <div className={legendClass}>
      {children}
    </div>
  );
}

TableLegend.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.node,
  ]),
  className: PropTypes.string,
};

export default TableLegend;
