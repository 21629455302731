import React from "react";
import Trigger from "common/Trigger";
import { ScreenerFilterContext } from "Screener/Context";
import { useContext } from "react";

const Sector = ({
  indicator,
}) => {
  const {
    dispatch,
    state,
  } = useContext(ScreenerFilterContext);

  const handleSectorSelect = (event) => {
    event.preventDefault();

    const filterBoxStyle = state.filterBoxStyle === "sector" ? "" : "sector";

    dispatch({
      type: "DEFAULT",
      stateChanges: {
        filterBoxStyle
      }
    });
  }

  const sectorIndicator = state.indicatorsInQuery.find((indicator) => {
    return indicator.shortcode === "sector";
  });

  const triggerText = sectorIndicator.value.length === 0 ?
    "All" : `${sectorIndicator.value.length} / ${state.sectors.length}`;

  return (
    <React.Fragment>
      <div className="mx-1">
        {indicator.indicatorRecord.name}

        <span className="mx-3">
          <Trigger
            colorVariant="white"
            onClick={handleSectorSelect}
          >
            ({triggerText})
          </Trigger>
        </span>
      </div>
    </React.Fragment>
  );
}

export default Sector;
