import React from "react";
import { Icon } from "common/Icon";

const EmptyScreener = () => {
  return (
    <div className="my-10">
      <div className="flex justify-center mb-5">
        <div>
          <Icon name="robot" className="fill-red-700" />
        </div>

        <div className="text-red-700 text-xl font-mono ml-3">
          Beep Boop Beep
        </div>
      </div>

      <div className="flex justify-center">
        <div>
          Unfortunately no companies match your screener criteria
        </div>
      </div>
    </div>

  );
}

export default EmptyScreener;
