import Cell from "./Cell";
import React from "react";

const Row = ({
  row,
}) => {
  const descriptorShortcode = row.find((cell) => {
    return cell.shortcode === "shortcode"
  }).value.toLowerCase();

  return (
    <tr className="hover:bg-slate-100">
      {row.map((cell, index) => (
        <Cell
          cell={{descriptorShortcode, ...cell}}
          key={index}
        />
      ))}
    </tr>
  );
}

export default Row;
