import AccountHub from "AccountHub";
import Callout from "Callout";
import Footer from "./Footer";
import Navigation from "Navigation";
import React from "react";
import { ACCOUNT_QUERY } from "queries";
import { Dialog } from "@headlessui/react";
import { Fragment } from "react";
import { Icon } from "common/Icon";
import { Outlet } from "react-router-dom";
import { Transition } from "@headlessui/react";
import { useQuery } from "@apollo/client";
import { useState } from "react";

const Root = () => {
  const {
    loading,
    data: {
      account = {}
    } = {}
  } = useQuery(ACCOUNT_QUERY);
  const [sidebarIsVisible, setSidebarVisibility] = useState(false)

  if (loading) { return null }

  const SidebarComponent = account.authorized ?
    AccountHub :
    Callout;

  return (
    <div>
      <Transition.Root show={sidebarIsVisible} as={Fragment}>
        <Dialog as="div" className="relative z-50 2xl:hidden" onClose={setSidebarVisibility}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-900/80" />
          </Transition.Child>

          <div className="fixed inset-0 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                    <button type="button" className="-m-2.5 p-2.5" onClick={() => setSidebarVisibility(false)}>
                      <span className="sr-only">Close sidebar</span>

                      <Icon
                        name="circle-remove"
                        className="text-white fill-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>

                <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-neutral-100 pb-4">
                  <SidebarComponent />
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      <div className="2xl:pr-72">
        <Navigation
          setSidebarVisibility={setSidebarVisibility}
        />

        <div className="py-4 px-6">
          <Outlet />
        </div>

        <Footer />
      </div>

      <div className="hidden 2xl:fixed 2xl:inset-y-0 2xl:right-0 2xl:z-50 2xl:flex 2xl:w-72 2xl:flex-col bg-neutral-100 pb-4">
        <SidebarComponent />
      </div>
    </div>
  );
}

export default Root;
