import React from "react";
import PropTypes from "prop-types";

const ErrorMessage = ({ message }) => {
  const style = { height: "17px" }
  const errorMessageClass = `
    mt-1
    mb-1
    px-2.5
    text-red-800
    text-xs
    order-last
  `;

  return (
    <div
      className={errorMessageClass}
      style={style}
    >
      {message}
    </div>
  );
}

ErrorMessage.propTypes = {
  message: PropTypes.string,
}

export default ErrorMessage;
