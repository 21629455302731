import Article from "./Article";
import Heading from "common/Heading";
import React from "react";
import { useMemo } from "react";

const Day = ({
  articles,
  groupName,
}) => {
  const sortedArticles = useMemo(() => {
    return articles.sort((a, b) => parseInt(b.score) - parseInt(a.score));
  }, [articles]);

  if (sortedArticles.length < 1) { return null; }

  return (
    <div className="border-b border-slate-300 pb-5">
      <Heading variant="tiny">
        {groupName}
      </Heading>

      <div className="columns-xs gap-5">
        {sortedArticles.map((article) => (
          <Article
            article={article}
            key={article.id}
          />
        ))}
      </div>
    </div>
  );
}

export default Day;
