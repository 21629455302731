import React from "react";
import Trigger from "common/Trigger";
import { ScreenerFilterContext } from "Screener/Context";
import { useContext } from "react";

const Industry = ({
  indicator,
}) => {
  const {
    dispatch,
    state,
  } = useContext(ScreenerFilterContext);

  const handleIndustrySelect = (event) => {
    event.preventDefault();

    const filterBoxStyle = state.filterBoxStyle === "industry" ? "" : "industry";

    dispatch({
      type: "DEFAULT",
      stateChanges: {
        filterBoxStyle
      }
    });
  }

  const industryIndicator = state.indicatorsInQuery.find((indicator) => {
    return indicator.shortcode === "industry";
  });

  const triggerText = industryIndicator.value.length === 0 ?
    "All" : `${industryIndicator.value.length} / ${state.industries.length}`;

  return (
    <React.Fragment>
      <div className="mx-1">
        {indicator.indicatorRecord.name}
      </div>

      <span className="mx-3">
        <Trigger
          colorVariant="white"
          onClick={handleIndustrySelect}
        >
          ({triggerText})
        </Trigger>
      </span>
    </React.Fragment>
  );
}

export default Industry;
