import React from "react";

import FormattedNumber from "common/FormattedNumber";

const Ratios = ({ ratios }) => {
  return (
    <React.Fragment>
      {ratios.map((ratio, index) => (
        <div key={index} className="flex py-0.5">
          <div className="w-1/2 text-sm m-auto">
            {ratio.label}
          </div>

          <div className="w-1/2 text-right">
            <FormattedNumber
              number={ratio.value}
              unit={ratio.unit}
              millionize={false}
              className="text-sm"
            />
          </div>
        </div>
      ))}
    </React.Fragment>
  )
}

export default Ratios;
