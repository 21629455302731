import DocumentHeader from "./FinancialsTable/DocumentHeader";
import React from "react";
import Table from "./FinancialsTable/Table";
import { Navigate } from "react-router-dom";
import { useMemo } from "react";
import { useParams } from "react-router-dom";

const FinancialsTable = () => {
  let {
    descriptorShortcode,
    fincialsTableCode,
    financialsTableParams,
  } = useParams();

  const shouldRedirect = useMemo(() => {
    return /[a-z]/.test(descriptorShortcode) || financialsTableParams === undefined;
  }, [descriptorShortcode, financialsTableParams]);

  return (
    <React.Fragment>
      <DocumentHeader />

      {shouldRedirect && (
        <Navigate
          replace={true}
          to={`/${descriptorShortcode.toUpperCase()}/${fincialsTableCode}/q-desc`}
        />
      )}

      {!shouldRedirect && (
        <Table />
      )}
    </React.Fragment>
  )
}

export default FinancialsTable;
