import Chart from "Chart";
import { useParams } from "react-router-dom";

const FinancialsChart = () => {
  let {
    descriptorShortcode,
    fincialsTableCode,
    financialsTableParams,
  } = useParams();

  const descriptor = `${descriptorShortcode}:company`;

  let dimension;

  switch(true) {
    case financialsTableParams.includes("q-"): dimension = "Q"; break;
    case financialsTableParams.includes("a-"): dimension = "A"; break;
    case financialsTableParams.includes("t-"): dimension = "T"; break;
    default: break;
  }

  const quarterlyIncome = [
    {
      name: "Financials",
      series: [
        { descriptor: descriptor, indicator: `REVENUE:${dimension}`, type: "column", color: "#fdba74", primary: true },
        { descriptor: descriptor, indicator: `NETINC:${dimension}`, type: "column", color: "#15803d" },
        { descriptor: descriptor, indicator: `NETINCMARGIN:${dimension}`, type: "line", color: "#7f1d1d" },
      ]
    }
  ]

  const quarterlyBalance = [
    {
      name: "Financials",
      series: [
        { descriptor: descriptor, indicator: `DEBT:${dimension}`, type: "column", color: "#fdba74", primary: true },
        { descriptor: descriptor, indicator: `ASSETTOT:${dimension}`, type: "column", color: "#15803d" },
        { descriptor: descriptor, indicator: `DEBTASSETS:${dimension}`, type: "line", color: "#7f1d1d" },
      ]
    }
  ]

  const quarterlyCashflow = [
    {
      name: "Financials",
      series: [
        { descriptor: descriptor, indicator: `OPCF:${dimension}`, type: "line", color: "#fdba74", primary: true },
        { descriptor: descriptor, indicator: `NCFI:${dimension}`, type: "line", color: "#15803d" },
        { descriptor: descriptor, indicator: `NCFA:${dimension}`, type: "line", color: "#7f1d1d" },
      ]
    }
  ]

  let chartParams;

  switch(fincialsTableCode) {
    case "income-statement": chartParams = quarterlyIncome; break;
    case "balance-sheet": chartParams = quarterlyBalance; break;
    case "cash-flow": chartParams = quarterlyCashflow; break;
    default: break;
  }

  return (
    <div>
      {chartParams && (
        <Chart
          chartParams={chartParams}
          legend
          height="300px"
        />
      )}
    </div>
  )
}

export default FinancialsChart;
