import Link from "common/Link";
import Logo from "common/Logo";
import React from "react";
import Search from "./Navigation/Search";
import Time from "./Navigation/Time";
import { Icon } from "common/Icon";

const Navigation = ({
  setSidebarVisibility = () => {}
}) => {
  const navbarClass = `
    bg-slate-200
    flex
    gap-x-4
    h-16
    items-center
    lg:px-8
    px-4
    shadow-sm
    shrink-0
    sm:gap-x-6
    sm:px-6
    sticky
    top-0
    z-40
  `;

  return (
    <div className={navbarClass}>
      <button
        type="button"
        className="-m-2.5 p-2.5 text-gray-700 2xl:hidden"
        onClick={() => setSidebarVisibility(true)}
      >
        <span className="sr-only">Open sidebar</span>

        <Icon name="menu" />
      </button>

      <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
        <div className="hidden xl:flex m-auto">
          <Logo />
        </div>

        <div className="flex-1 m-auto" action="#" method="GET">
          <Search />
        </div>

        <div className="hidden lg:flex items-center gap-x-4 lg:gap-x-6">
          <Link
            className="text-lg"
            url="/screener"
          >
            Screener
          </Link>
        </div>

        <Time />
      </div>
    </div>
  );
}

export default Navigation;
