import React from "react";
import cx from "classnames";

const Score = ({
  score
}) => {
  const scoreClass = cx(
    `
      inline-flex
      items-center
      rounded-md
      px-2
      py-0.5
      text-xs
      font-medium
    `,
    {
      "text-slate-600 bg-slate-100": score === 1,
      "text-slate-900 bg-yellow-100": score === 2,
      "text-slate-900 bg-yellow-300": score === 3,
      "text-slate-50 bg-yellow-600": score === 4,
      "text-slate-50 bg-red-950": score === 5
    }
  );

  return (
    <span className={scoreClass}>
      {score}
    </span>
  )
}

export default Score;
