import React from "react";
import { useForm } from "hooks/useForm";
import Heading from "common/Heading";
import TextInput from "common/TextInput";
import Button from "common/Button";
import ErrorMessage from "common/ErrorMessage";

const AccountSettings = ({
  email,
  fullname,
}) => {
  const handleAccountUpdate = () => {
    setPassword("");
  }

  const {
    handleChange,
    handleSubmit,
    submitDisabled,
    errors,
  } = useForm({
    formName: "account_settings_form",
    submitCallback: handleAccountUpdate,
    fieldValues: {
      email,
      fullname
    }
  });

  const [fullnameValue, setFullname] = React.useState(fullname);
  const [emailValue, setEmail] = React.useState(email);
  const [passwordValue, setPassword] = React.useState("");

  return (
    <div className="m-auto w-96">
      <Heading>
        Account Settings
      </Heading>

      <form
        onChange={handleChange}
        onSubmit={handleSubmit}
      >
        <TextInput
          label="Your name"
          id="fullname"
          name="fullname"
          value={fullnameValue}
          onChange={(e) => setFullname(e.target.value) }
          state={errors.fullname? "error" : "default" }
        />

        <ErrorMessage
          message={errors.fullname}
        />

        <TextInput
          label="E-mail"
          id="email"
          name="email"
          value={emailValue}
          onChange={(e) => setEmail(e.target.value) }
          state={errors.email ? "error" : "default" }
        />

        <ErrorMessage
          message={errors.email}
        />

        <hr
          className="pt-2 pb-3"
        />

        <div className="text-sm pb-2 text-neutral-600">
          Leave blank if you don't want to change your password
        </div>

        <TextInput
          label="Password"
          id="password"
          name="password"
          variant="password"
          value={passwordValue}
          onChange={(e) => setPassword(e.target.value) }
          state={errors.password ? "error" : "default" }
        />

        <ErrorMessage
          message={errors.password}
        />

        <div className="mt-5">
          <Button
            type="submit"
            disabled={submitDisabled}
          >
            Save changes
          </Button>
        </div>
      </form>
    </div>
  )
}

export default AccountSettings;
