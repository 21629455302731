import DocumentHeader from "./KeystatsReport/DocumentHeader";
import React from "react";
import Report from "./KeystatsReport/Report";
import { Navigate } from "react-router-dom";
import { useMemo } from "react";
import { useParams } from "react-router-dom";

const KeystatsReport = () => {
  const { descriptorShortcode } = useParams();

  const shouldRedirect = useMemo(() => {
    return /[a-z]/.test(descriptorShortcode);
  }, [descriptorShortcode]);

  return (
    <React.Fragment>
      <DocumentHeader />

      {shouldRedirect && (
        <Navigate
          replace={true}
          to={`/${descriptorShortcode.toUpperCase()}`}
        />
      )}

      {!shouldRedirect && (
        <Report />
      )}
    </React.Fragment>
  )
}

export default KeystatsReport;
