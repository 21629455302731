import CriterionBox from "./CriterionBox";
import IndicatorList from "./IndicatorList";
import IndustryList from "./IndustryList";
import ListShell from "./ListShell";
import ShortcodeList from "./ShortcodeList";
import QueryInput from "./QueryInput";
import React from "react";
import SectorList from "./SectorList";
import cx from "classnames";
import { ScreenerFilterContext } from "Screener/Context";
import { intersection } from "lodash";
import { intersectionBy } from "lodash";
import { useCallback } from "react";
import { useEffect } from "react";
import { useReducer } from "react";
import { useRef } from "react";

const FilterBox = ({
  indicators,
  initialIndicators,
  industries,
  sectors,
  setQuery,
}) => {
  const indicatorInputReference = useRef(null);
  const filterBoxReference = useRef(null);
  const portalReference = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (filterBoxReference.current && !filterBoxReference.current.contains(event.target)) {
        indicatorInputReference.current.blur();

        dispatch({
          type: "DEFAULT",
          stateChanges: {
            filterBoxStyle: "",
          }
        });

        dispatch({
          type: "SET_INDICATOR_QUERY",
          value: "",
        });
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    }
  }, []);

  const defaultValue = useCallback((screenerType, value = "") => {
    switch (screenerType) {
      case "shortcode":
        const shortcodeValue = Array.isArray(value) ? value : [];

        return shortcodeValue;
      case "sector":
        const sectorsValue = Array.isArray(value) ? value : [];
        const defaultSectors = sectors.map(d => d.name);

        return intersection(sectorsValue, defaultSectors);
      case "boolean":
        if (value === "1.0" || value === "0.0") {
          return value;
        } else {
          return "1.0";
        }
      default:
        return value;
    }
  }, [sectors]);

  const handleClick = () => {
    indicatorInputReference.current.focus();
  }

  const filterReducer = (state, action) => {
    switch (action.type) {
      case "SET_INDICATOR_QUERY":
        return {
          ...state,
          indicatorQuery: action.value,
        }

      case "ADD_INDICATOR_TO_QUERY":
        return {
          ...state,
          filterBoxStyle: "",
          indicatorsInQuery: [
            ...state.indicatorsInQuery,
            {
              shortcode: action.indicator.shortcode,
              value: defaultValue(action.indicator.screenerType),
              showInTable: true,
              indicatorRecord: action.indicator,
            }
          ]
        }

      case "REMOVE_INDICATOR_FROM_QUERY":
        const removedIndicatorsInQuery = [
          ...state.indicatorsInQuery.filter(i => i.shortcode !== action.shortcode)
        ];

        return {
          ...state,
          filterBoxStyle: "",
          indicatorsInQuery: removedIndicatorsInQuery,
        }

      case "SET_CRITERION_VALUE":
        const indicatorsInQuery = [...state.indicatorsInQuery];
        const changedIndex = indicatorsInQuery.findIndex(indicator => indicator.shortcode === action.shortcode);

        indicatorsInQuery[changedIndex]["value"] = action.value;
        indicatorsInQuery[changedIndex]["valueDisplayType"] = action.valueDisplayType;

        if (action.showInTable !== undefined) {
          indicatorsInQuery[changedIndex]["showInTable"] = action.showInTable;
        }

        return {
          ...state,
          indicatorsInQuery
        }

      default:
        return {
          ...state,
          ...action.stateChanges
        }
    }
  }

  const [state, dispatch] = useReducer(filterReducer, {
    groups: [
      "All",
      ...new Set(indicators.map(indicator => indicator.group))
    ],
    sectors: sectors,
    industries: industries,
    indicatorQuery: "",
    indicators: indicators,
    filterBoxStyle: "",
    indicatorsInQuery: [],
    visibleIndicatorsCount: indicators.length,
    highlightedIndicatorIndex: 0,
    visibleIndicators: indicators,
  });

  const boxClass = cx(`
    block
    px-2.5
    pt-2.5
    w-full
    text-sm
    bg-slate-100
    border-0
    border-b-2
    min-h-[50px]
    flex
    flex-wrap
    cursor-text
    relative
  `,
    {
      "border-emerald-800": state.filterBoxStyle !== "",
    }
  );

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (filterBoxReference.current && !filterBoxReference.current.contains(event.target)) {
        indicatorInputReference.current.blur();

        dispatch({
          type: "DEFAULT",
          stateChanges: {
            filterBoxStyle: "",
          }
        });

        dispatch({
          type: "SET_INDICATOR_QUERY",
          value: "",
        });
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    }
  }, []);

  useEffect(() => {
    const indicatorsForQuery = intersectionBy(
      indicators,
      initialIndicators,
      "shortcode"
    );

    const indicatorsInQuery = initialIndicators.map((indicator) => {
      const indicatorRecord = indicatorsForQuery.find(i => i.shortcode === indicator.shortcode);

      return {
        "shortcode": indicatorRecord.shortcode,
        "value": defaultValue(indicatorRecord.screenerType, indicator.value),
        "skipFocus": true,
        "showInTable": indicator.showInTable,
        "indicatorRecord": indicatorRecord,
        "valueDisplayType": indicator.valueDisplayType,
      };
    });

    dispatch({
      type: "DEFAULT",
      stateChanges: {
        indicatorsInQuery
      }
    });
  }, [
    initialIndicators,
    defaultValue,
    indicators
  ]);

  useEffect(() => {
    setQuery(state.indicatorsInQuery);
  }, [
    dispatch,
    setQuery,
    state.indicatorsInQuery,
  ]);

  return (
    <ScreenerFilterContext.Provider value={{ state, dispatch }}>
      <div
        onClick={handleClick}
        ref={filterBoxReference}
        className="relative"
      >
        <div className={boxClass}>
          {state.indicatorsInQuery.map((indicator) => (
            <CriterionBox
              key={indicator.shortcode}
              indicator={indicator}
              portalReference={portalReference}
            />
          ))}

         <QueryInput indicatorInputReference={indicatorInputReference} />
        </div>

        {state.filterBoxStyle === "list" && (
          <IndicatorList indicatorInputReference={indicatorInputReference} />
        )}

        {state.filterBoxStyle === "sector" && (
          <ListShell
            innerContent={SectorList}
          />
        )}

        {state.filterBoxStyle === "industry" && (
          <ListShell
            innerContent={IndustryList}
          />
        )}

        {state.filterBoxStyle === "shortcode" && (
          <ListShell
            innerContent={ShortcodeList}
          />
        )}

        <div ref={portalReference} />
      </div>
    </ScreenerFilterContext.Provider>
  );
}

export default FilterBox;
