import React from "react";
import { ScreenerFilterContext } from "Screener/Context";
import { reactiveTableState } from "Screener/Table";
import { useContext } from "react";
import { useReactiveVar } from "@apollo/client";
import { useRef } from "react";
import { useState } from "react";

const QueryInput = ({
  indicatorInputReference,
}) => {
  const {
    state,
    dispatch
  } = useContext(ScreenerFilterContext);

  const { loading } = useReactiveVar(reactiveTableState);

  const inputWidthRef = useRef();
  const [inputWidth, setInputWidth] = useState(10);

  React.useEffect(() => {
    setInputWidth(inputWidthRef.current.offsetWidth + 10)
  }, [state.indicatorQuery])

  const handleQueryChange = (event) => {
    dispatch({
      type: "SET_INDICATOR_QUERY",
      value: event.target.value,
    });
  }

  const setBoxVisibility = () => {
    dispatch({
      type: "DEFAULT",
      stateChanges: {
        filterBoxStyle: "list"
      }
    });
  }

  const handleKeypress = (event) => {
    switch (event.keyCode) {
      case 13: // ENTER
        event.stopPropagation();

        const addedIndicator = state.visibleIndicators[state.highlightedIndicatorIndex];

        if (addedIndicator !== undefined) {
          dispatch({
            type: "DEFAULT",
            stateChanges: {
              highlightedIndicatorIndex: 0,
            }
          });

          dispatch({
            type: "SET_INDICATOR_QUERY",
            value: "",
          });

          dispatch({
            type: "ADD_INDICATOR_TO_QUERY",
            indicator: addedIndicator,
          });

          indicatorInputReference.current.blur();
        }

        break;

      case 27: // ESC
        indicatorInputReference.current.blur();

        dispatch({
          type: "SET_INDICATOR_QUERY",
          value: "",
        });

        dispatch({
          type: "DEFAULT",
          stateChanges: {
            indicatorQuery: "",
            filterBoxStyle: "",
          }
        });

        break;

        case 38: // UP
          if (state.highlightedIndicatorIndex !== 0) {
            dispatch({
              type: "DEFAULT",
              stateChanges: {
                highlightedIndicatorIndex: state.highlightedIndicatorIndex - 1
              }
            });
          }

          break;

        case 40: // DOWN
          if ((state.highlightedIndicatorIndex + 1) < state.visibleIndicatorsCount) {
            dispatch({
              type: "DEFAULT",
              stateChanges: {
                highlightedIndicatorIndex: state.highlightedIndicatorIndex + 1
              }
            });
          }

          break;

      default:
        break;
    }
  }

  return (
    <div className="flex">
      <span
        className="absolute -left-full -top-full"
        ref={inputWidthRef}
      >
        {state.indicatorQuery}
      </span>

      <input
        ref={indicatorInputReference}
        type="text"
        className="focus:outline-none border-0 box-content bg-transparent mb-1"
        value={state.indicatorQuery}
        onChange={handleQueryChange}
        onFocus={setBoxVisibility}
        onKeyUp={handleKeypress}
        style={{ width: inputWidth }}
        disabled={loading}
      />
    </div>
  );
}

export default QueryInput;
