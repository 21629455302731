import Link from "common/Link";
import React from "react";
import { useParams } from "react-router-dom";

const Navigation = () => {
  const { descriptorShortcode } = useParams();

  return (
    <React.Fragment>
      {descriptorShortcode && (
        <div className="lg:hidden flex flex-col pb-5 border-b border-green-900">
          <div className="pb-2">
            <Link
              url={`/${descriptorShortcode}`}
              className="mr-10"
              end={true}
            >
              Overview
            </Link>
          </div>

          <div>
            <Link
              url={`/${descriptorShortcode}/income-statement`}
              className="mr-4"
            >
              Income Statement
            </Link>
          </div>

          <div>
            <Link
              url={`/${descriptorShortcode}/balance-sheet`}
              className="mr-4"
            >
              Balance Sheet
            </Link>
          </div>

          <div>
            <Link
              url={`/${descriptorShortcode}/cash-flow`}
              className="mr-10"
            >
              Cash Flow
            </Link>
          </div>
        </div>
      )}
    </React.Fragment>
  );
}

export default Navigation;
