import FormattedNumber from "common/FormattedNumber";
import Heading from "common/Heading";
import React from "react";
import cx from "classnames";

const Table = ({
  tableData
}) => {
  const cellClass = (totals, label = false, additionalClasses = "") => {
    return cx(
      additionalClasses,
      {
        "text-left": label,
        "text-right": !label,
        "border-t": totals,
        "pt-2": totals
      }
    );
  }

  return (
    <table className="w-full table-fixed">
      <thead>
        <tr>
          <th style={{ width: "65px" }}>
          </th>

          {tableData.headers.map((header, index) => (
            <th
              className="text-right text-xs font-normal font-mono text-slate-400 pb-1"
              key={index}
            >
              {header}
            </th>
          ))}
        </tr>
      </thead>

      <tbody>
        {tableData.rows.map((valueObject, rowIndex) => (
          <tr key={rowIndex}>
            <td className={cellClass(valueObject.totals, true, "text-sm text-left")}>
              {valueObject.label}
            </td>

            {valueObject.values.map((value, valueIndex) => (
              <td
                className={cellClass(valueObject.totals)}
                key={valueIndex}
              >
                <FormattedNumber
                  number={value}
                  millionize
                  unit="cash"
                />
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  )
}

export default Table;
