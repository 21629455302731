import ChartBox from "./Chart/ChartBox";
import LoadingBox from "common/LoadingBox";
import React from "react";
import formatNumber from "utils/formatNumber";
import { CHART_QUERY } from "queries/index";
import { useQuery } from "@apollo/client";

const Chart = ({
  chartParams,
  compare = false,
  endDate,
  height,
  startDate,
  legend = false,
  options = {}
}) => {
  const {
    loading,
    data: {
      timeseriesChart: {
        chartData ={}
      } ={}
    } ={}
  } = useQuery(CHART_QUERY, {
    variables: {
      chartParams: chartParams,
      startDate: startDate,
      endDate: endDate,
    }
  });

  if (loading) {
    return (
      <LoadingBox
        height={height}
      />
    );
  }

  let mutableChartData  = structuredClone(chartData);

  const seriesData = mutableChartData.map((chartGroup) => {
    const series = chartGroup.series.map((chart) => {
      return chart;
    });

    const yAxis = chartGroup.axes.map((axis) => {

      axis["labels"]["formatter"] = function() {
        const value = compare ? this.value / 100 : this.value;
        const unit = compare ? "percentage" : axis.id;

        return(
          formatNumber(value, unit)
        )
      }

      return axis
    });

    return [yAxis, series];
  })

  return (
    <React.Fragment>
      {seriesData.map((series, index) =>
        <ChartBox
          height={height}
          key={index}
          series={series[1]}
          compare={compare}
          yAxis={series[0]}
          legend={legend}
          options={options}
        />
      )}
    </React.Fragment>
  )
}

export default Chart;
