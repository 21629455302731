import Heading from "common/Heading";
import React from "react";

const ValueList = ({
  indicator,
  valueValid,
}) => {
  const allowedOperators = [
      {
        symbol: "-10..20.2",
        description: `all values between -10 and 20.2 including
          those two numbers`
      },
      {
        symbol: "12m...14m",
        description: `all values greater than 12 and less than 14 million`
      },
      {
        symbol: "&lt;10.2",
        description: `everything less than 10.2`
      },
      {
        symbol: "&gt;22.2b",
        description: `everything greater than 22.2 billions`
      },
      {
        symbol: "&lt;=-15.3",
        description: `all values that are equal to -15.3 or less
          than that`
      },
      {
        symbol: "&gt;=0.5",
        description: `everything equal to 0.5 or greater than
          that`
      }
    ]

  const listClass = `
    absolute
    border
    border-emerald-800
    bg-white
    shadow-xl
    w-full
    z-20
    flex
    flex-col
    mt-2
  `;

  return (
    <div className={listClass}>
      <div className="flex px-5 py-3 bg-neutral-200">
        {valueValid && (
          <div>
            Enter value to specify screener criteria or leave blank to display in table
          </div>
        )}

        {!valueValid && (
          <div className="text-red-700">
            Your query looks incorrect; we can’t use it to look for companies
          </div>
        )}

        <div className="mx-5 text-slate-400">—</div>

        <div className="text-green-800">
          Enter to Submit
        </div>

        <div className="mx-5 text-slate-400">—</div>

        <div className="text-orange-800">
          Esc to Reset and Close
        </div>
      </div>

      <div className="px-5 py-3 text-sm">
        <div className="grid grid-cols-2 gap-5">
          <div>
            <Heading variant="tiny">
              {indicator.indicatorRecord.name}
            </Heading>

            <div className="text-sm text-neutral-600">
              {indicator.indicatorRecord.description}
            </div>
          </div>

          <div>
            <p className="mb-3">
              When writing queries you don’t need to type out zeroes and can
              use <span className="bg-orange-100 font-mono px-3">m</span> for millions
              and <span className="bg-orange-100 font-mono px-3">b</span> for billions;<br />
              i.e. <span className="bg-orange-100 font-mono px-3">500m</span> or <span className="bg-orange-100 font-mono px-3">20b</span>
            </p>

            <p className="mb-3">
              Here are examples of queries you can use:
            </p>

            <ul className="list-none mt-2">
              {allowedOperators.map((operator, index) => (
                <li className="mb-1" key={index}>
                  <span className="bg-orange-100 font-mono px-3" dangerouslySetInnerHTML={{ __html: operator.symbol }}/> — {operator.description}
                </li>
              ))}
            </ul>

          </div>
        </div>
      </div>
    </div>
  )
}

export default ValueList;
