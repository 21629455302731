import FilterBox from "./FilterInput/FilterBox";
import LoadingBox from "common/LoadingBox";
import React from "react";
import { DESCRIPTORS_QUERY, } from "queries/index";
import { INDICATORS_QUERY } from "queries/index";
import { useQuery } from "@apollo/client";

const FilterInput = ({
  initialIndicators,
  setQuery,
}) => {
  const {
    loading: loadingIndicators,
    data: {
      indicators =[]
    } ={}
  } = useQuery(INDICATORS_QUERY);

  const {
    loading: loadingSegments,
    data: {
      descriptors: sectors =[]
    } ={}
  } = useQuery(
    DESCRIPTORS_QUERY, {
    variables: {
      descriptorType: "sector"
    }
  });

  const {
    loading: loadingIndustries,
    data: {
      descriptors: industries =[]
    } ={}
  } = useQuery(
    DESCRIPTORS_QUERY, {
    variables: {
      descriptorType: "industry"
    }
  });

  if (loadingIndicators || loadingSegments || loadingIndustries) {
    return <LoadingBox />;
  } else {
    return <FilterBox
      indicators={indicators}
      industries={industries}
      initialIndicators={initialIndicators}
      sectors={sectors}
      setQuery={setQuery}
    />;
  }
}

export default FilterInput;
