const screenerDefinition = (indexIndicator) => {
  return (
    [
      {
        "shortcode":"shortcode",
        "showInTable":true,
        "value":[],
        "indicatorRecord": {
          "screenerType": "shortcode"
        }
      },
      {
        "shortcode": "name",
        "showInTable": true,
        "value": "",
        "indicatorRecord": {
          "screenerType": "simple"
        }
      },
      {
        "shortcode": "sector",
        "showInTable": true,
        "value": [],
        "indicatorRecord": {
          "screenerType": "sector"
        }
      },
      {
        "shortcode":"DAILY_PRICE:D",
        "showInTable":true,
        "value":"",
        "valueDisplayType":"plot",
        "indicatorRecord": {
          "screenerType":"value",
        }
      },
      {
        "shortcode":"REVENUE:Q",
        "showInTable":true,
        "value":"",
        "valueDisplayType":"plot",
        "indicatorRecord": {
          "screenerType":"value",
        }
      },
      {
        "shortcode":"EPSD:Q",
        "showInTable":true,
        "value":"",
        "indicatorRecord": {
          "screenerType":"value",
        }
      },
      {
        "shortcode":"PE:D",
        "showInTable":true,
        "value":"",
        "indicatorRecord": {
          "screenerType":"value",
        }
      },
      {
        "shortcode": indexIndicator,
        "showInTable":false,
        "value":"1.0",
        "indicatorRecord": {
          "screenerType":"boolean",
        }
      }
    ]
  );
}

export default screenerDefinition;
