import Button from "common/Button";
import Heading from "common/Heading";
import Link from "common/Link";
import React from "react";
import { ACCOUNT_QUERY } from "queries";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@apollo/client";

const UnauthorizedNotification = ({
  title,
  description
}) => {
  const navigate = useNavigate();
  const {
    loading,
    data: {
      account: {
        authorized,
        unauthorized,
        powerpack = true
      } ={}
    } ={}
  } = useQuery(ACCOUNT_QUERY);

  const handleRegistrationClick = () => {
    navigate("/account/create");
  }

  const handleActivationClick = () => {
    window.location.href = "/vector/billing/subscription";
  }

  if (loading) { return null; }

  return (
    <React.Fragment>
      {!powerpack && (
        <div className="p-5 bg-yellow-100 my-5">
          <div className="text-center">
            <Heading variant="small">
              {title}
            </Heading>
          </div>

          <div className="text-center">
            {description}
          </div>

          {authorized && (
            <div className="mt-5 text-center">
              <Button
                variant="small"
                onClick={handleActivationClick}
              >
                Activate Powerpack
              </Button>
            </div>
          )}

          {unauthorized && (
            <div className="mt-5 text-center">
              <Button
                variant="small"
                onClick={handleRegistrationClick}
              >
                Register an account and get your Powerpack now
              </Button> or <Link url="/account/login">Login</Link>
            </div>
          )}
        </div>
      )}
    </React.Fragment>
  )
}

export default UnauthorizedNotification
