import numeral from "numeral";

const formatNumber = (number, unit, millionize = false) => {
  const getFormatFromUnit = (unit) => {
    let format;

    if (["growth", "percentage"].includes(unit)) {
      format = "(0,0.00%)";
    } else if (["ratio", "cash ratio"].includes(unit)) {
      format = "(0,0.00)";
    } else if (["cash", "number", "shares"].includes(unit) && millionize) {
      format = "(0,0.00m)";
    } else if (["price"].includes(unit)) {
      format = "(0,0.00)";
    } else {
      format = "(0.00a)";
    }

    return format;
  }

  if (number != null && /^-?[0-9.]+$/.test(number)) {
    const format = getFormatFromUnit(unit);
    return numeral(number).format(format);
  } else if (number != null) {
    return number;
  } else {
    return "—";
  }
}

export default formatNumber;
