import React from "react"
import PropTypes from "prop-types";

const LoadingLines = ({ lines, height, lineHeight }) => {
  const lineClass = `
    relative
    isolate
    space-y-5
    overflow-hidden
    bg-zinc-100
    before:absolute
    before:inset-0
    before:-translate-x-full
    before:animate-[shimmer_2s_infinite]
    before:border-t
    before:bg-gradient-to-r
    before:from-transparent
    before:via-stone-400
    before:to-transparent
  `;

  return (
    <div
      data-testid="loading-placeholder"
      className="grid content-evenly"
      style={{ height }}
    >
      {[...Array(lines)].map((line, index) => (
        <div
          className={lineClass}
          key={index}
          style={{ height: lineHeight }}
        >
        </div>
      ))}
    </div>
  );
}

LoadingLines.propTypes = {
  lines: PropTypes.number,
  height: PropTypes.string,
  lineHeight: PropTypes.string,
};

LoadingLines.defaultProps = {
  lines: 3,
  height: "100px",
  lineHeight: "20px",
};

export default LoadingLines;
