import Heading from "common/Heading";
import Link from "common/Link";
import React from "react";
import { defaultTriggerClass } from "common/Trigger";
import { useParams } from "react-router-dom";

const FinancialsTable = () => {
  let {
    descriptorShortcode,
    financialsTableParams,
  } = useParams();

  if (descriptorShortcode === undefined) { return null; }

  const sortDirection = `${financialsTableParams || "desc"}`.match(/asc|desc/)[0];

  return (
    <div className="border-b border-green-900 pb-5">
      <Heading variant="small">
        Financials
      </Heading>

      <div className="text-sm mb-3">
        <Link
          url={`/vector/exports/financials/${descriptorShortcode}?direction=${sortDirection}`}
          className={defaultTriggerClass}
          underline={false}
          remote
        >
          Export to XLS
        </Link>
      </div>
    </div>
  );
}

export default FinancialsTable;
