import React from "react";
import HeaderCell from "./HeaderCell";

const Header = ({
  headers,
}) => {
  return (
    <thead>
      <tr>
        {headers.map((header, index) => (
          <HeaderCell
            label={header.label}
            shortcode={header.shortcode}
            type={header.type}
            key={index}
          />
        ))}
      </tr>
    </thead>
  );
}

export default Header;
