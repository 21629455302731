import React from "react";
import { Helmet } from "react-helmet";

const DocumentHeader = () => {
  const description =`Restore password to your stockrow.com account in case ` +
    `you have forgotten it.`;

  return (
    <React.Fragment>
      <Helmet>
        <title>Forgotten Password — stockrow</title>
        <meta name="description" content={description} />
      </Helmet>
    </React.Fragment>
  )
}

export default DocumentHeader;
