import Fuse from "fuse.js";
import React from "react";
import cx from "classnames";
import { useCallback } from "react";
import { useEffect } from "react";
import { useMemo } from "react";
import { useState } from "react";

const SearchableList = ({
  list,
  phrase,
  searchableKeys,
  itemLimit,
  itemRenderer = (item) => <div>{item}</div>,
  handleClick = () => {}
}) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const fuse = useMemo(() => {
    return new Fuse(
      list,
      {
        keys: searchableKeys
      }
    )
  }, [list]);

  const searchResult = useMemo(() => {
    const result = fuse.search(phrase).slice(0, itemLimit);
    return result.map(entry => entry.item);
  }, [
    fuse,
    phrase,
  ]);

  const handleKeyDown = useCallback((event) => {
    switch (event.keyCode) {
      case 13: // Enter
        const activeItem = searchResult[activeIndex];

        if (activeItem) {
          handleClick(null, activeItem);
        }

        break;

      case 38: // Up
        event.preventDefault();

        setActiveIndex((previousIndex) => {
          if (previousIndex > 0) {
            return previousIndex - 1;
          } else {
            return previousIndex;
          }
        });

        break;

      case 40: // Down
        event.preventDefault();

        setActiveIndex((previousIndex) => {
          if (previousIndex + 1 < searchResult.length) {
            return previousIndex + 1;
          } else {
            return previousIndex;
          }
        });

        break;

      default:
        break;
    }
  }, [
    activeIndex,
    searchResult,
  ]);

  useEffect(() => {
    if (activeIndex >= searchResult.length) {
      setActiveIndex(
        Math.max(0, searchResult.length - 1)
      );
    }
  }, [
    activeIndex,
    searchResult,
  ]);

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    }
  }, [
    activeIndex,
    searchResult,
  ]);

  const listClasses = `
    mt-1
    bg-white
    px-4
    py-2
    border
    border-emerald-800
    shadow-xl
    absolute
    w-full
    max-h-[65vh]
    overflow-scroll
  `;

  const itemClass = (index) => cx(
    {
      "bg-slate-100": index === activeIndex
    }
  );

  return (
    <React.Fragment>
      {searchResult.length > 0 && (
        <div className={listClasses}>
          {searchResult.map((item, index) => (
            <div
              onMouseDown={(event) => handleClick(event, item)}
              onMouseEnter={() => setActiveIndex(index)}
              className={itemClass(index)}
              key={index}
            >
              {itemRenderer(item)}
            </div>
          ))}
        </div>
      )}
    </React.Fragment>
  )
}

export default SearchableList;
