// =============================================================================
//
// Base Start
//
import { ReactComponent as BaseMenu } from './base/menu.svg';
import { ReactComponent as BasePackage } from './base/package.svg';
import { ReactComponent as BaseRobot } from './base/robot.svg';
import { ReactComponent as BaseCircleRemove } from './base/circle-remove.svg';
// import { ReactComponent as BaseKnight } from './base/knight.svg';
// import { ReactComponent as BasePassport } from './base/passport.svg';
// import { ReactComponent as BaseTShirt } from './base/t-shirt.svg';
// import { ReactComponent as BaseUnderline } from './base/underline.svg';
// import { ReactComponent as BaseDirectionsArrow } from './base/directions-arrow.svg';
// import { ReactComponent as BaseTriangleAlert } from './base/triangle-alert.svg';
// import { ReactComponent as BaseMusicAlt } from './base/music-alt.svg';
// import { ReactComponent as BaseDiceThree } from './base/dice-three.svg';
// import { ReactComponent as BaseDrink } from './base/drink.svg';
// import { ReactComponent as BaseOneDayDelivery } from './base/one-day-delivery.svg';
// import { ReactComponent as BasePowerCordPlugOff } from './base/power-cord-plug-off.svg';
// import { ReactComponent as BaseIceLolly } from './base/ice-lolly.svg';
// import { ReactComponent as BaseSearch } from './base/search.svg';
// import { ReactComponent as BaseNotes } from './base/notes.svg';
// import { ReactComponent as BaseMeat } from './base/meat.svg';
// import { ReactComponent as BaseHandHeart } from './base/hand-heart.svg';
// import { ReactComponent as BaseHomeForSale } from './base/home-for-sale.svg';
// import { ReactComponent as BaseCartCheckout } from './base/cart-checkout.svg';
// import { ReactComponent as BaseDockLeft } from './base/dock-left.svg';
// import { ReactComponent as BaseNuts } from './base/nuts.svg';
// import { ReactComponent as BaseEnvelopeUp } from './base/envelope-up.svg';
// import { ReactComponent as BasePairedOff } from './base/paired-off.svg';
// import { ReactComponent as BaseReflectY } from './base/reflect-y.svg';
// import { ReactComponent as BaseTrafficLightsOff } from './base/traffic-lights-off.svg';
// import { ReactComponent as BaseHandDonate } from './base/hand-donate.svg';
// import { ReactComponent as BaseBriefcaseAlt } from './base/briefcase-alt.svg';
// import { ReactComponent as BaseLasso } from './base/lasso.svg';
// import { ReactComponent as BaseEraser } from './base/eraser.svg';
// import { ReactComponent as BaseRook } from './base/rook.svg';
// import { ReactComponent as BaseReceipt } from './base/receipt.svg';
// import { ReactComponent as BaseMultifunctionPrinter } from './base/multifunction-printer.svg';
// import { ReactComponent as BaseBedSingle } from './base/bed-single.svg';
// import { ReactComponent as BasePayment } from './base/payment.svg';
// import { ReactComponent as BaseParkingMeter } from './base/parking-meter.svg';
// import { ReactComponent as BaseCashRegister } from './base/cash-register.svg';
// import { ReactComponent as BaseArrowDown } from './base/arrow-down.svg';
// import { ReactComponent as BaseBarbedWire } from './base/barbed-wire.svg';
// import { ReactComponent as BaseSquareEmptyPlay } from './base/square-empty-play.svg';
// import { ReactComponent as BaseForwardEmail } from './base/forward-email.svg';
// import { ReactComponent as BaseCheque } from './base/cheque.svg';
// import { ReactComponent as BaseFlashlight } from './base/flashlight.svg';
// import { ReactComponent as BaseServerCheck } from './base/server-check.svg';
// import { ReactComponent as BaseList } from './base/list.svg';
// import { ReactComponent as BaseBuoy } from './base/buoy.svg';
// import { ReactComponent as BaseSoundSurround } from './base/sound-surround.svg';
// import { ReactComponent as BaseVolumeOff } from './base/volume-off.svg';
// import { ReactComponent as BaseUsbDeviceNo } from './base/usb-device-no.svg';
// import { ReactComponent as BaseTemperature } from './base/temperature.svg';
// import { ReactComponent as BaseInitialLetter } from './base/initial-letter.svg';
// import { ReactComponent as BaseRecycling } from './base/recycling.svg';
// import { ReactComponent as BaseSquareEdit } from './base/square-edit.svg';
// import { ReactComponent as BaseWinnersStars } from './base/winners-stars.svg';
// import { ReactComponent as BaseCircleSelected } from './base/circle-selected.svg';
// import { ReactComponent as BaseTennis } from './base/tennis.svg';
// import { ReactComponent as BaseBed } from './base/bed.svg';
// import { ReactComponent as BaseInvoiceMinus } from './base/invoice-minus.svg';
// import { ReactComponent as BaseTranslateAlt } from './base/translate-alt.svg';
// import { ReactComponent as BaseFactoryAlt } from './base/factory-alt.svg';
// import { ReactComponent as BaseLegalSectionSign } from './base/legal-section-sign.svg';
// import { ReactComponent as BaseDivision } from './base/division.svg';
// import { ReactComponent as BaseWind } from './base/wind.svg';
// import { ReactComponent as BaseLampTable } from './base/lamp-table.svg';
// import { ReactComponent as BaseRewindFifteen } from './base/rewind-fifteen.svg';
// import { ReactComponent as BaseSubscript } from './base/subscript.svg';
// import { ReactComponent as BaseConstructionCone } from './base/construction-cone.svg';
// import { ReactComponent as BaseReflectX } from './base/reflect-x.svg';
// import { ReactComponent as BaseLayersPlus } from './base/layers-plus.svg';
// import { ReactComponent as BaseStatsCircleOneDay } from './base/stats-circle-one-day.svg';
// import { ReactComponent as BaseCutlery } from './base/cutlery.svg';
// import { ReactComponent as BaseLockOff } from './base/lock-off.svg';
// import { ReactComponent as BaseMobilePhoneNfc } from './base/mobile-phone-nfc.svg';
// import { ReactComponent as BaseRestart } from './base/restart.svg';
// import { ReactComponent as BaseTarget } from './base/target.svg';
// import { ReactComponent as BaseScissors } from './base/scissors.svg';
// import { ReactComponent as BaseSquareAlert } from './base/square-alert.svg';
// import { ReactComponent as BaseDatabasePlus } from './base/database-plus.svg';
// import { ReactComponent as BaseUserParents } from './base/user-parents.svg';
// import { ReactComponent as BaseRepeat } from './base/repeat.svg';
// import { ReactComponent as BaseFlashNo } from './base/flash-no.svg';
// import { ReactComponent as BaseLuggage } from './base/luggage.svg';
// import { ReactComponent as BaseFlagTriangle } from './base/flag-triangle.svg';
// import { ReactComponent as BaseHourglass } from './base/hourglass.svg';
// import { ReactComponent as BaseMicrowave } from './base/microwave.svg';
// import { ReactComponent as BaseSubtitlesPlus } from './base/subtitles-plus.svg';
// import { ReactComponent as BaseBullets } from './base/bullets.svg';
// import { ReactComponent as BaseLayersCogwheel } from './base/layers-cogwheel.svg';
// import { ReactComponent as BaseCallIp } from './base/call-ip.svg';
// import { ReactComponent as BaseDiceOne } from './base/dice-one.svg';
// import { ReactComponent as BaseMedicine } from './base/medicine.svg';
// import { ReactComponent as BaseSquareEmpty } from './base/square-empty.svg';
// import { ReactComponent as BaseReply } from './base/reply.svg';
// import { ReactComponent as BaseMetro } from './base/metro.svg';
// import { ReactComponent as BasePowerPlantWater } from './base/power-plant-water.svg';
// import { ReactComponent as BaseSocketB } from './base/socket-b.svg';
// import { ReactComponent as BaseSpeechBubbleAlert } from './base/speech-bubble-alert.svg';
// import { ReactComponent as BaseSecurityCamera } from './base/security-camera.svg';
// import { ReactComponent as BaseSafeDigital } from './base/safe-digital.svg';
// import { ReactComponent as BaseBeer } from './base/beer.svg';
// import { ReactComponent as BaseSpeakers } from './base/speakers.svg';
// import { ReactComponent as BaseTranslate } from './base/translate.svg';
// import { ReactComponent as BaseIdBinder } from './base/id-binder.svg';
// import { ReactComponent as BaseVotingUrn } from './base/voting-urn.svg';
// import { ReactComponent as BaseBellRinging } from './base/bell-ringing.svg';
// import { ReactComponent as BaseFilterMinus } from './base/filter-minus.svg';
// import { ReactComponent as BaseUmbrella } from './base/umbrella.svg';
// import { ReactComponent as BaseUser } from './base/user.svg';
// import { ReactComponent as BaseVolumeDown } from './base/volume-down.svg';
// import { ReactComponent as BaseFullscreen } from './base/fullscreen.svg';
// import { ReactComponent as BaseIdBadge } from './base/id-badge.svg';
// import { ReactComponent as BaseForwardThirty } from './base/forward-thirty.svg';
// import { ReactComponent as BaseUserChild } from './base/user-child.svg';
// import { ReactComponent as BaseGun } from './base/gun.svg';
// import { ReactComponent as BaseFileMinus } from './base/file-minus.svg';
// import { ReactComponent as BaseFireplace } from './base/fireplace.svg';
// import { ReactComponent as BaseSquareMore } from './base/square-more.svg';
// import { ReactComponent as BaseCoffeeBeans } from './base/coffee-beans.svg';
// import { ReactComponent as BaseFolderCheck } from './base/folder-check.svg';
// import { ReactComponent as BasePool } from './base/pool.svg';
// import { ReactComponent as BaseDollar } from './base/dollar.svg';
// import { ReactComponent as BaseInboxUp } from './base/inbox-up.svg';
// import { ReactComponent as BaseListLetters } from './base/list-letters.svg';
// import { ReactComponent as BaseTemperatureHigh } from './base/temperature-high.svg';
// import { ReactComponent as BaseEnvelopeNo } from './base/envelope-no.svg';
// import { ReactComponent as BaseDryer } from './base/dryer.svg';
// import { ReactComponent as BaseLighter } from './base/lighter.svg';
// import { ReactComponent as BasePlaylistVideo } from './base/playlist-video.svg';
// import { ReactComponent as BaseMilk } from './base/milk.svg';
// import { ReactComponent as BaseHome } from './base/home.svg';
// import { ReactComponent as BaseBatteryHalf } from './base/battery-half.svg';
// import { ReactComponent as BaseSafe } from './base/safe.svg';
// import { ReactComponent as BaseShowerAlt } from './base/shower-alt.svg';
// import { ReactComponent as BaseMusic } from './base/music.svg';
// import { ReactComponent as BaseCircleEmptyDown } from './base/circle-empty-down.svg';
// import { ReactComponent as BaseDirectionEmpty } from './base/direction-empty.svg';
// import { ReactComponent as BaseNoSymbol } from './base/no-symbol.svg';
// import { ReactComponent as BaseBrightnessUp } from './base/brightness-up.svg';
// import { ReactComponent as BaseArrowThinLeft } from './base/arrow-thin-left.svg';
// import { ReactComponent as BaseTasks } from './base/tasks.svg';
// import { ReactComponent as BaseSubtitlesOff } from './base/subtitles-off.svg';
// import { ReactComponent as BaseEject } from './base/eject.svg';
// import { ReactComponent as BaseIrisScan } from './base/iris-scan.svg';
// import { ReactComponent as BaseSpeechBubbleInfo } from './base/speech-bubble-info.svg';
// import { ReactComponent as BaseSquareTriangleDown } from './base/square-triangle-down.svg';
// import { ReactComponent as BaseChevronDown } from './base/chevron-down.svg';
// import { ReactComponent as BaseBackpack } from './base/backpack.svg';
// import { ReactComponent as BaseFirefighters } from './base/firefighters.svg';
// import { ReactComponent as BaseCloudCheck } from './base/cloud-check.svg';
// import { ReactComponent as BaseStrikethrough } from './base/strikethrough.svg';
// import { ReactComponent as BaseSocketG } from './base/socket-g.svg';
// import { ReactComponent as BaseMouseWireless } from './base/mouse-wireless.svg';
// import { ReactComponent as BaseParagraphCenter } from './base/paragraph-center.svg';
// import { ReactComponent as BaseChemistry } from './base/chemistry.svg';
// import { ReactComponent as BaseBadminton } from './base/badminton.svg';
// import { ReactComponent as BaseForest } from './base/forest.svg';
// import { ReactComponent as BaseShirt } from './base/shirt.svg';
// import { ReactComponent as BaseDock } from './base/dock.svg';
// import { ReactComponent as BaseGay } from './base/gay.svg';
// import { ReactComponent as BaseCandle } from './base/candle.svg';
// import { ReactComponent as BaseBuildingsPark } from './base/buildings-park.svg';
// import { ReactComponent as BaseRotate } from './base/rotate.svg';
// import { ReactComponent as BaseBringToFront } from './base/bring-to-front.svg';
// import { ReactComponent as BaseHoney } from './base/honey.svg';
// import { ReactComponent as BaseDisappearing } from './base/disappearing.svg';
// import { ReactComponent as BaseTicket } from './base/ticket.svg';
// import { ReactComponent as BaseWirelessCharging } from './base/wireless-charging.svg';
// import { ReactComponent as BaseSyringeEmpty } from './base/syringe-empty.svg';
// import { ReactComponent as BaseWastePipe } from './base/waste-pipe.svg';
// import { ReactComponent as BaseSquareTriangleUp } from './base/square-triangle-up.svg';
// import { ReactComponent as BaseCallRedirect } from './base/call-redirect.svg';
// import { ReactComponent as BaseRewindThirty } from './base/rewind-thirty.svg';
// import { ReactComponent as BaseExport } from './base/export.svg';
// import { ReactComponent as BaseDropOff } from './base/drop-off.svg';
// import { ReactComponent as BaseUnknown } from './base/unknown.svg';
// import { ReactComponent as BaseSquareCheckbox } from './base/square-checkbox.svg';
// import { ReactComponent as BaseCrop } from './base/crop.svg';
// import { ReactComponent as BaseTShirtSleeves } from './base/t-shirt-sleeves.svg';
// import { ReactComponent as BaseEqualizerBars } from './base/equalizer-bars.svg';
// import { ReactComponent as BaseCookie } from './base/cookie.svg';
// import { ReactComponent as BaseTag } from './base/tag.svg';
// import { ReactComponent as BaseLawnMower } from './base/lawn-mower.svg';
// import { ReactComponent as BasePowerPlantWind } from './base/power-plant-wind.svg';
// import { ReactComponent as BaseBriefcase } from './base/briefcase.svg';
// import { ReactComponent as BasePresentation } from './base/presentation.svg';
// import { ReactComponent as BaseCircleLeft } from './base/circle-left.svg';
// import { ReactComponent as BaseUserSquared } from './base/user-squared.svg';
// import { ReactComponent as BaseAlignBottom } from './base/align-bottom.svg';
// import { ReactComponent as BaseCelery } from './base/celery.svg';
// import { ReactComponent as BaseTerminalEmpty } from './base/terminal-empty.svg';
// import { ReactComponent as BaseVolumeBluetooth } from './base/volume-bluetooth.svg';
// import { ReactComponent as BaseSquarePlay } from './base/square-play.svg';
// import { ReactComponent as BaseSaveAs } from './base/save-as.svg';
// import { ReactComponent as BaseCircleEmpty } from './base/circle-empty.svg';
// import { ReactComponent as BaseShower } from './base/shower.svg';
// import { ReactComponent as BaseUserVoice } from './base/user-voice.svg';
// import { ReactComponent as BaseVideoPlayEmpty } from './base/video-play-empty.svg';
// import { ReactComponent as BaseHeadset } from './base/headset.svg';
// import { ReactComponent as BaseCircleEmptyUp } from './base/circle-empty-up.svg';
// import { ReactComponent as BaseCarrot } from './base/carrot.svg';
// import { ReactComponent as BaseNoseOff } from './base/nose-off.svg';
// import { ReactComponent as BaseMap } from './base/map.svg';
// import { ReactComponent as BaseInbox } from './base/inbox.svg';
// import { ReactComponent as BaseCircleAlert } from './base/circle-alert.svg';
// import { ReactComponent as BaseHardDriveEject } from './base/hard-drive-eject.svg';
// import { ReactComponent as BaseComments } from './base/comments.svg';
// import { ReactComponent as BaseUsbDevice } from './base/usb-device.svg';
// import { ReactComponent as BaseSidebarRight } from './base/sidebar-right.svg';
// import { ReactComponent as BaseCot } from './base/cot.svg';
// import { ReactComponent as BasePower } from './base/power.svg';
// import { ReactComponent as BaseDatabase } from './base/database.svg';
// import { ReactComponent as BaseDiceFive } from './base/dice-five.svg';
// import { ReactComponent as BaseCallMusic } from './base/call-music.svg';
// import { ReactComponent as BaseChemistryAlt } from './base/chemistry-alt.svg';
// import { ReactComponent as BaseFileCloud } from './base/file-cloud.svg';
// import { ReactComponent as BaseSatelliteDish } from './base/satellite-dish.svg';
// import { ReactComponent as BaseTextWidth } from './base/text-width.svg';
// import { ReactComponent as BaseCircleMenu } from './base/circle-menu.svg';
// import { ReactComponent as BaseUserGirl } from './base/user-girl.svg';
// import { ReactComponent as BaseSatelliteDishAlt } from './base/satellite-dish-alt.svg';
// import { ReactComponent as BaseCooksHat } from './base/cooks-hat.svg';
// import { ReactComponent as BaseFilterCogwheel } from './base/filter-cogwheel.svg';
// import { ReactComponent as BaseSquareNewWindow } from './base/square-new-window.svg';
// import { ReactComponent as BaseCartOff } from './base/cart-off.svg';
// import { ReactComponent as BaseTornado } from './base/tornado.svg';
// import { ReactComponent as BaseSquareIndeterminate } from './base/square-indeterminate.svg';
// import { ReactComponent as BaseFile } from './base/file.svg';
// import { ReactComponent as BaseMotorbike } from './base/motorbike.svg';
// import { ReactComponent as BasePuzzle } from './base/puzzle.svg';
// import { ReactComponent as BaseNose } from './base/nose.svg';
// import { ReactComponent as BaseSquareEmptyRight } from './base/square-empty-right.svg';
// import { ReactComponent as BaseBrain } from './base/brain.svg';
// import { ReactComponent as BaseMale } from './base/male.svg';
// import { ReactComponent as BaseCircleEmptyAlert } from './base/circle-empty-alert.svg';
// import { ReactComponent as BaseSubtitles } from './base/subtitles.svg';
// import { ReactComponent as BaseTeaPot } from './base/tea-pot.svg';
// import { ReactComponent as BasePowerPlantSolar } from './base/power-plant-solar.svg';
// import { ReactComponent as BaseVoicemail } from './base/voicemail.svg';
// import { ReactComponent as BaseHandPointingLeft } from './base/hand-pointing-left.svg';
// import { ReactComponent as BaseContrast } from './base/contrast.svg';
// import { ReactComponent as BaseRoad } from './base/road.svg';
// import { ReactComponent as BaseTextHeight } from './base/text-height.svg';
// import { ReactComponent as BaseSocketE } from './base/socket-e.svg';
// import { ReactComponent as BaseGraphicTablet } from './base/graphic-tablet.svg';
// import { ReactComponent as BaseDoorHandle } from './base/door-handle.svg';
// import { ReactComponent as BaseTerminal } from './base/terminal.svg';
// import { ReactComponent as BaseHetero } from './base/hetero.svg';
// import { ReactComponent as BaseLayersLock } from './base/layers-lock.svg';
// import { ReactComponent as BaseSafeOpen } from './base/safe-open.svg';
// import { ReactComponent as BaseVectorPathCircle } from './base/vector-path-circle.svg';
// import { ReactComponent as BaseHeartEmpty } from './base/heart-empty.svg';
// import { ReactComponent as BaseFolderCogwheel } from './base/folder-cogwheel.svg';
// import { ReactComponent as BaseRadar } from './base/radar.svg';
// import { ReactComponent as BaseRepeatOnce } from './base/repeat-once.svg';
// import { ReactComponent as BaseBlacksmith } from './base/blacksmith.svg';
// import { ReactComponent as BaseChevronUp } from './base/chevron-up.svg';
// import { ReactComponent as BaseChatConversationOff } from './base/chat-conversation-off.svg';
// import { ReactComponent as BaseSpade } from './base/spade.svg';
// import { ReactComponent as BaseBasketOut } from './base/basket-out.svg';
// import { ReactComponent as BaseStroller } from './base/stroller.svg';
// import { ReactComponent as BaseStepBack } from './base/step-back.svg';
// import { ReactComponent as BaseRuble } from './base/ruble.svg';
// import { ReactComponent as BaseNearbyAlt } from './base/nearby-alt.svg';
// import { ReactComponent as BaseFolderOpen } from './base/folder-open.svg';
// import { ReactComponent as BaseShopDoor } from './base/shop-door.svg';
// import { ReactComponent as BaseEnvelopeFull } from './base/envelope-full.svg';
// import { ReactComponent as BaseKeyboard } from './base/keyboard.svg';
// import { ReactComponent as BaseCommentsOff } from './base/comments-off.svg';
// import { ReactComponent as BaseRemoteControl } from './base/remote-control.svg';
// import { ReactComponent as BaseUserFlag } from './base/user-flag.svg';
// import { ReactComponent as BaseBath } from './base/bath.svg';
// import { ReactComponent as BaseQrCode } from './base/qr-code.svg';
// import { ReactComponent as BaseHandPointingRight } from './base/hand-pointing-right.svg';
// import { ReactComponent as BaseSkipToEnd } from './base/skip-to-end.svg';
// import { ReactComponent as BaseLuggageAlt } from './base/luggage-alt.svg';
// import { ReactComponent as BaseHeating } from './base/heating.svg';
// import { ReactComponent as BaseCorrectionAuto } from './base/correction-auto.svg';
// import { ReactComponent as BaseMapMarker } from './base/map-marker.svg';
// import { ReactComponent as BaseWheat } from './base/wheat.svg';
// import { ReactComponent as BaseMobilePhoneCogwheel } from './base/mobile-phone-cogwheel.svg';
// import { ReactComponent as BaseLock } from './base/lock.svg';
// import { ReactComponent as BaseCookies } from './base/cookies.svg';
// import { ReactComponent as BaseFlagWaving } from './base/flag-waving.svg';
// import { ReactComponent as BaseLogIn } from './base/log-in.svg';
// import { ReactComponent as BaseTrafficLights } from './base/traffic-lights.svg';
// import { ReactComponent as BaseCloudRefresh } from './base/cloud-refresh.svg';
// import { ReactComponent as BaseCloudUpload } from './base/cloud-upload.svg';
// import { ReactComponent as BaseFire } from './base/fire.svg';
// import { ReactComponent as BaseCallOutgoing } from './base/call-outgoing.svg';
// import { ReactComponent as BaseBuildingsCity } from './base/buildings-city.svg';
// import { ReactComponent as BaseMobilePhoneLock } from './base/mobile-phone-lock.svg';
// import { ReactComponent as BaseShoppingBag } from './base/shopping-bag.svg';
// import { ReactComponent as BasePiggyBank } from './base/piggy-bank.svg';
// import { ReactComponent as BaseVignetting } from './base/vignetting.svg';
// import { ReactComponent as BaseCallLock } from './base/call-lock.svg';
// import { ReactComponent as BaseFilterOff } from './base/filter-off.svg';
// import { ReactComponent as BaseFingerprintScan } from './base/fingerprint-scan.svg';
// import { ReactComponent as BasePhoneRotate } from './base/phone-rotate.svg';
// import { ReactComponent as BaseHandOpen } from './base/hand-open.svg';
// import { ReactComponent as BaseFolderAlert } from './base/folder-alert.svg';
// import { ReactComponent as BasePen } from './base/pen.svg';
// import { ReactComponent as BaseForwardFive } from './base/forward-five.svg';
// import { ReactComponent as BaseScissorsCutting } from './base/scissors-cutting.svg';
// import { ReactComponent as BaseChevronRight } from './base/chevron-right.svg';
// import { ReactComponent as BaseMonitors } from './base/monitors.svg';
// import { ReactComponent as BaseTextResize } from './base/text-resize.svg';
// import { ReactComponent as BaseMemoryCardLock } from './base/memory-card-lock.svg';
// import { ReactComponent as BaseClipboard } from './base/clipboard.svg';
// import { ReactComponent as BaseCircleQuestion } from './base/circle-question.svg';
// import { ReactComponent as BaseSmartBracelet } from './base/smart-bracelet.svg';
// import { ReactComponent as BaseEuro } from './base/euro.svg';
// import { ReactComponent as BaseLink } from './base/link.svg';
// import { ReactComponent as BaseCorn } from './base/corn.svg';
// import { ReactComponent as BaseCarRental } from './base/car-rental.svg';
// import { ReactComponent as BaseTShirtV } from './base/t-shirt-v.svg';
// import { ReactComponent as BaseCertificate } from './base/certificate.svg';
// import { ReactComponent as BaseVideoOff } from './base/video-off.svg';
// import { ReactComponent as BaseKey } from './base/key.svg';
// import { ReactComponent as BaseUserWorker } from './base/user-worker.svg';
// import { ReactComponent as BaseThreeDimensional } from './base/three-dimensional.svg';
// import { ReactComponent as BaseCogwheels } from './base/cogwheels.svg';
// import { ReactComponent as BaseMobilePhoneCharger } from './base/mobile-phone-charger.svg';
// import { ReactComponent as BaseKeyRounded } from './base/key-rounded.svg';
// import { ReactComponent as BaseSailboat } from './base/sailboat.svg';
// import { ReactComponent as BaseSquareEmptyMinus } from './base/square-empty-minus.svg';
// import { ReactComponent as BaseSquareEmptyRemove } from './base/square-empty-remove.svg';
// import { ReactComponent as BaseDoorOpen } from './base/door-open.svg';
// import { ReactComponent as BaseArrowRight } from './base/arrow-right.svg';
// import { ReactComponent as BaseSquareLeft } from './base/square-left.svg';
// import { ReactComponent as BaseOctagonRemove } from './base/octagon-remove.svg';
// import { ReactComponent as BaseSkipToNext } from './base/skip-to-next.svg';
// import { ReactComponent as BaseDockRight } from './base/dock-right.svg';
// import { ReactComponent as BaseBrush } from './base/brush.svg';
// import { ReactComponent as BaseDivingScuba } from './base/diving-scuba.svg';
// import { ReactComponent as BaseCampfire } from './base/campfire.svg';
// import { ReactComponent as BaseLogOut } from './base/log-out.svg';
// import { ReactComponent as BaseCirclePlay } from './base/circle-play.svg';
// import { ReactComponent as BaseWatchSquared }from './base/watch-squared.svg';
// import { ReactComponent as BaseEarOff } from './base/ear-off.svg';
// import { ReactComponent as BaseMemoryCardEject } from './base/memory-card-eject.svg';
// import { ReactComponent as BaseAmbulance } from './base/ambulance.svg';
// import { ReactComponent as BasePark } from './base/park.svg';
// import { ReactComponent as BaseDustbin } from './base/dustbin.svg';
// import { ReactComponent as BaseUserRounded } from './base/user-rounded.svg';
// import { ReactComponent as BaseStopwatch } from './base/stopwatch.svg';
// import { ReactComponent as BaseSettings } from './base/settings.svg';
// import { ReactComponent as BaseWalletLock } from './base/wallet-lock.svg';
// import { ReactComponent as BaseSortAttributesAlt } from './base/sort-attributes-alt.svg';
// import { ReactComponent as BaseUserFemale } from './base/user-female.svg';
// import { ReactComponent as BaseCurves } from './base/curves.svg';
// import { ReactComponent as BaseRugby } from './base/rugby.svg';
// import { ReactComponent as BaseLayersDown } from './base/layers-down.svg';
// import { ReactComponent as BaseSquareMenu } from './base/square-menu.svg';
// import { ReactComponent as BaseCart } from './base/cart.svg';
// import { ReactComponent as BaseEnvelope } from './base/envelope.svg';
// import { ReactComponent as BaseArchive } from './base/archive.svg';
// import { ReactComponent as BaseBlockMove } from './base/block-move.svg';
// import { ReactComponent as BaseBasketCheck } from './base/basket-check.svg';
// import { ReactComponent as BaseSquareEmail } from './base/square-email.svg';
// import { ReactComponent as BaseMagnet } from './base/magnet.svg';
// import { ReactComponent as BaseTextBackground } from './base/text-background.svg';
// import { ReactComponent as BaseUserIncognito } from './base/user-incognito.svg';
// import { ReactComponent as BaseBedSleeping } from './base/bed-sleeping.svg';
// import { ReactComponent as BaseMissile } from './base/missile.svg';
// import { ReactComponent as BaseGameController } from './base/game-controller.svg';
// import { ReactComponent as BaseCartOut } from './base/cart-out.svg';
// import { ReactComponent as BaseMagicWand } from './base/magic-wand.svg';
// import { ReactComponent as BaseSquareCheck } from './base/square-check.svg';
// import { ReactComponent as BaseImport } from './base/import.svg';
// import { ReactComponent as BaseWebcam } from './base/webcam.svg';
// import { ReactComponent as BaseSelfWindingCord } from './base/self-winding-cord.svg';
// import { ReactComponent as BaseEnvelopeMinus } from './base/envelope-minus.svg';
// import { ReactComponent as BaseAxesTwoDimensional } from './base/axes-two-dimensional.svg';
// import { ReactComponent as BaseFolderStar } from './base/folder-star.svg';
// import { ReactComponent as BaseBatteryThreeQuarters } from './base/battery-three-quarters.svg';
// import { ReactComponent as BaseBookOpen } from './base/book-open.svg';
// import { ReactComponent as BaseForklift } from './base/forklift.svg';
// import { ReactComponent as BaseFilesQueue } from './base/files-queue.svg';
// import { ReactComponent as BaseProjector } from './base/projector.svg';
// import { ReactComponent as BaseUserParentsAlt } from './base/user-parents-alt.svg';
// import { ReactComponent as BaseServer } from './base/server.svg';
// import { ReactComponent as BaseTv } from './base/tv.svg';
// import { ReactComponent as BaseFilterPlus } from './base/filter-plus.svg';
// import { ReactComponent as BaseCorrectionTilted } from './base/correction-tilted.svg';
// import { ReactComponent as BasePound } from './base/pound.svg';
// import { ReactComponent as BaseMc } from './base/mc.svg';
// import { ReactComponent as BaseUserPlus } from './base/user-plus.svg';
// import { ReactComponent as BaseLp } from './base/lp.svg';
// import { ReactComponent as BasePhoneHorizontal } from './base/phone-horizontal.svg';
// import { ReactComponent as BaseBasketIn } from './base/basket-in.svg';
// import { ReactComponent as BaseBatteryCharging } from './base/battery-charging.svg';
// import { ReactComponent as BaseCanister } from './base/canister.svg';
// import { ReactComponent as BaseSatellite } from './base/satellite.svg';
// import { ReactComponent as BasePercentSign } from './base/percent-sign.svg';
// import { ReactComponent as BaseLayers } from './base/layers.svg';
// import { ReactComponent as BaseDna } from './base/dna.svg';
// import { ReactComponent as BaseRotateVertical } from './base/rotate-vertical.svg';
// import { ReactComponent as BaseSortNumericallyAlt } from './base/sort-numerically-alt.svg';
// import { ReactComponent as BaseRadio } from './base/radio.svg';
// import { ReactComponent as BaseFireworksAlt } from './base/fireworks-alt.svg';
// import { ReactComponent as BaseTaxi } from './base/taxi.svg';
// import { ReactComponent as BaseBook } from './base/book.svg';
// import { ReactComponent as BaseChatConversation } from './base/chat-conversation.svg';
// import { ReactComponent as BaseCarWheelWrench } from './base/car-wheel-wrench.svg';
// import { ReactComponent as BaseCircleEmptyCheck } from './base/circle-empty-check.svg';
// import { ReactComponent as BaseProgress } from './base/progress.svg';
// import { ReactComponent as BaseBitcoin } from './base/bitcoin.svg';
// import { ReactComponent as BaseComputerAllInOne } from './base/computer-all-in-one.svg';
// import { ReactComponent as BaseFishes } from './base/fishes.svg';
// import { ReactComponent as BaseSkull } from './base/skull.svg';
// import { ReactComponent as BaseSquareEmptyLeft } from './base/square-empty-left.svg';
// import { ReactComponent as BaseSubtitlesMinus } from './base/subtitles-minus.svg';
// import { ReactComponent as BaseWalletPlus } from './base/wallet-plus.svg';
// import { ReactComponent as BaseEyedropper } from './base/eyedropper.svg';
// import { ReactComponent as BaseTrain } from './base/train.svg';
// import { ReactComponent as BaseVectorPathEdit } from './base/vector-path-edit.svg';
// import { ReactComponent as BaseLesbian } from './base/lesbian.svg';
// import { ReactComponent as BaseUserMinus } from './base/user-minus.svg';
// import { ReactComponent as BaseWallet } from './base/wallet.svg';
// import { ReactComponent as BaseCardSpades } from './base/card-spades.svg';
// import { ReactComponent as BasePushPin } from './base/push-pin.svg';
// import { ReactComponent as BaseCircleCheck } from './base/circle-check.svg';
// import { ReactComponent as BaseComputerNetworkAlt } from './base/computer-network-alt.svg';
// import { ReactComponent as BaseBell } from './base/bell.svg';
// import { ReactComponent as BaseDashboard } from './base/dashboard.svg';
// import { ReactComponent as BaseFlash } from './base/flash.svg';
// import { ReactComponent as BasePlaylist } from './base/playlist.svg';
// import { ReactComponent as BaseCode } from './base/code.svg';
// import { ReactComponent as BaseCake } from './base/cake.svg';
// import { ReactComponent as BasePlant } from './base/plant.svg';
// import { ReactComponent as BaseShredder } from './base/shredder.svg';
// import { ReactComponent as BaseEnvelopeStar } from './base/envelope-star.svg';
// import { ReactComponent as BaseUserLock } from './base/user-lock.svg';
// import { ReactComponent as BaseTongue } from './base/tongue.svg';
// import { ReactComponent as BaseBasketCheckout } from './base/basket-checkout.svg';
// import { ReactComponent as BaseFlag } from './base/flag.svg';
// import { ReactComponent as BasePodcast } from './base/podcast.svg';
// import { ReactComponent as BaseHoneybee } from './base/honeybee.svg';
// import { ReactComponent as BaseShopWindow } from './base/shop-window.svg';
// import { ReactComponent as BaseEyeOff } from './base/eye-off.svg';
// import { ReactComponent as BaseExchange } from './base/exchange.svg';
// import { ReactComponent as BaseMobilePhoneOff } from './base/mobile-phone-off.svg';
// import { ReactComponent as BaseChevronLastDown } from './base/chevron-last-down.svg';
// import { ReactComponent as BaseTvStreaming } from './base/tv-streaming.svg';
// import { ReactComponent as BaseScaleKitchen } from './base/scale-kitchen.svg';
// import { ReactComponent as BaseStop } from './base/stop.svg';
// import { ReactComponent as BaseNewspaper } from './base/newspaper.svg';
// import { ReactComponent as BaseSnowflake } from './base/snowflake.svg';
// import { ReactComponent as BaseStepForward } from './base/step-forward.svg';
// import { ReactComponent as BaseCluster } from './base/cluster.svg';
// import { ReactComponent as BaseBomb } from './base/bomb.svg';
// import { ReactComponent as BaseAlignVertical } from './base/align-vertical.svg';
// import { ReactComponent as BaseStarHalf } from './base/star-half.svg';
// import { ReactComponent as BaseUsbDeviceLock } from './base/usb-device-lock.svg';
// import { ReactComponent as BaseFireworks } from './base/fireworks.svg';
// import { ReactComponent as BaseUserVrPlus } from './base/user-vr-plus.svg';
// import { ReactComponent as BaseTemperatureAlert } from './base/temperature-alert.svg';
// import { ReactComponent as BaseCardDiamonds } from './base/card-diamonds.svg';
// import { ReactComponent as BaseRuler } from './base/ruler.svg';
// import { ReactComponent as BaseTurtle } from './base/turtle.svg';
// import { ReactComponent as BaseDisinfectionSpray } from './base/disinfection-spray.svg';
// import { ReactComponent as BaseCursor } from './base/cursor.svg';
// import { ReactComponent as BaseVectorPathLine } from './base/vector-path-line.svg';
// import { ReactComponent as BaseTransportMixed } from './base/transport-mixed.svg';
// import { ReactComponent as BaseCartCheck } from './base/cart-check.svg';
// import { ReactComponent as BaseSwitchOff } from './base/switch-off.svg';
// import { ReactComponent as BaseCloudAlert } from './base/cloud-alert.svg';
// import { ReactComponent as BaseFax } from './base/fax.svg';
// import { ReactComponent as BaseBiohazard } from './base/biohazard.svg';
// import { ReactComponent as BaseSquares } from './base/squares.svg';
// import { ReactComponent as BaseFactory } from './base/factory.svg';
// import { ReactComponent as BaseBold } from './base/bold.svg';
// import { ReactComponent as BaseDirection } from './base/direction.svg';
// import { ReactComponent as BaseFence } from './base/fence.svg';
// import { ReactComponent as BaseBoat } from './base/boat.svg';
// import { ReactComponent as BaseHash } from './base/hash.svg';
// import { ReactComponent as BaseSaw } from './base/saw.svg';
// import { ReactComponent as BaseMapTourist } from './base/map-tourist.svg';
// import { ReactComponent as BaseEquals } from './base/equals.svg';
// import { ReactComponent as BasePlus } from './base/plus.svg';
// import { ReactComponent as BaseBowling } from './base/bowling.svg';
// import { ReactComponent as BaseCheck } from './base/check.svg';
// import { ReactComponent as BaseRabbit } from './base/rabbit.svg';
// import { ReactComponent as BaseHardDrive } from './base/hard-drive.svg';
// import { ReactComponent as BaseTent } from './base/tent.svg';
// import { ReactComponent as BaseQuadBike } from './base/quad-bike.svg';
// import { ReactComponent as BaseCircleEmptyInfo } from './base/circle-empty-info.svg';
// import { ReactComponent as BaseBookLog } from './base/book-log.svg';
// import { ReactComponent as BaseQueen } from './base/queen.svg';
// import { ReactComponent as BaseMapCadastral } from './base/map-cadastral.svg';
// import { ReactComponent as BaseResizeVertical } from './base/resize-vertical.svg';
// import { ReactComponent as BaseHeadphones } from './base/headphones.svg';
// import { ReactComponent as BaseFishesBait } from './base/fishes-bait.svg';
// import { ReactComponent as BaseCreditCardOff } from './base/credit-card-off.svg';
// import { ReactComponent as BaseBalanceScales } from './base/balance-scales.svg';
// import { ReactComponent as BaseNeighborhood } from './base/neighborhood.svg';
// import { ReactComponent as BaseEmail } from './base/email.svg';
// import { ReactComponent as BaseWifi } from './base/wifi.svg';
// import { ReactComponent as BaseCars } from './base/cars.svg';
// import { ReactComponent as BaseMolluscs } from './base/molluscs.svg';
// import { ReactComponent as BaseWatch } from './base/watch.svg';
// import { ReactComponent as BaseAudioDescriptionD } from './base/audio-description-d.svg';
// import { ReactComponent as BaseUserNo } from './base/user-no.svg';
// import { ReactComponent as BaseWalletCheck } from './base/wallet-check.svg';
// import { ReactComponent as BaseKing } from './base/king.svg';
// import { ReactComponent as BaseGraphicTabletWireless } from './base/graphic-tablet-wireless.svg';
// import { ReactComponent as BaseSuitcaseDoctor } from './base/suitcase-doctor.svg';
// import { ReactComponent as BaseHomeFlood } from './base/home-flood.svg';
// import { ReactComponent as BaseCircleMore } from './base/circle-more.svg';
// import { ReactComponent as BaseBookPlay } from './base/book-play.svg';
// import { ReactComponent as BaseBrightnessDown } from './base/brightness-down.svg';
// import { ReactComponent as BaseSquareEmptyUpload } from './base/square-empty-upload.svg';
// import { ReactComponent as BaseDog } from './base/dog.svg';
// import { ReactComponent as BaseOctagonRemoveEmpty } from './base/octagon-remove-empty.svg';
// import { ReactComponent as BaseFan } from './base/fan.svg';
// import { ReactComponent as BaseDirectionFull } from './base/direction-full.svg';
// import { ReactComponent as BasePalette } from './base/palette.svg';
// import { ReactComponent as BaseReplay } from './base/replay.svg';
// import { ReactComponent as BaseCircleEmptyPlus } from './base/circle-empty-plus.svg';
// import { ReactComponent as BaseCircleHospital } from './base/circle-hospital.svg';
// import { ReactComponent as BaseDirections } from './base/directions.svg';
// import { ReactComponent as BaseVolleyball } from './base/volleyball.svg';
// import { ReactComponent as BaseFaceScan } from './base/face-scan.svg';
// import { ReactComponent as BaseUserFamily } from './base/user-family.svg';
// import { ReactComponent as BaseInboxPlus } from './base/inbox-plus.svg';
// import { ReactComponent as BaseInboxMinus } from './base/inbox-minus.svg';
// import { ReactComponent as BaseDropDown } from './base/drop-down.svg';
// import { ReactComponent as BaseServerCogwheel } from './base/server-cogwheel.svg';
// import { ReactComponent as BaseFolderPlus } from './base/folder-plus.svg';
// import { ReactComponent as BaseMemoryCard } from './base/memory-card.svg';
// import { ReactComponent as BaseShieldHalf } from './base/shield-half.svg';
// import { ReactComponent as BaseMic } from './base/mic.svg';
// import { ReactComponent as BaseUserVrMinus } from './base/user-vr-minus.svg';
// import { ReactComponent as BaseCopy } from './base/copy.svg';
// import { ReactComponent as BaseTram } from './base/tram.svg';
// import { ReactComponent as BaseFish } from './base/fish.svg';
// import { ReactComponent as BaseForwardFifteen } from './base/forward-fifteen.svg';
// import { ReactComponent as BaseTerminalIsolated } from './base/terminal-isolated.svg';
// import { ReactComponent as BaseToiletPaperAlt } from './base/toilet-paper-alt.svg';
// import { ReactComponent as BaseSpray } from './base/spray.svg';
// import { ReactComponent as BaseCourtGavelAlt } from './base/court-gavel-alt.svg';
// import { ReactComponent as BaseReload } from './base/reload.svg';
// import { ReactComponent as BaseServerMinus } from './base/server-minus.svg';
// import { ReactComponent as BaseAlignHorizontal } from './base/align-horizontal.svg';
// import { ReactComponent as BaseBag } from './base/bag.svg';
// import { ReactComponent as BaseCoins } from './base/coins.svg';
// import { ReactComponent as BaseHandOk } from './base/hand-ok.svg';
// import { ReactComponent as BaseFinishLine } from './base/finish-line.svg';
// import { ReactComponent as BaseUserStar } from './base/user-star.svg';
// import { ReactComponent as BaseEnvelopeLock } from './base/envelope-lock.svg';
// import { ReactComponent as BaseComputerPcTower } from './base/computer-pc-tower.svg';
// import { ReactComponent as BaseUserVr } from './base/user-vr.svg';
// import { ReactComponent as BaseTeaLeaves } from './base/tea-leaves.svg';
// import { ReactComponent as BaseRefresh } from './base/refresh.svg';
// import { ReactComponent as BaseMarriage } from './base/marriage.svg';
// import { ReactComponent as BaseFingerprintLock } from './base/fingerprint-lock.svg';
// import { ReactComponent as BaseInvoiceCheck } from './base/invoice-check.svg';
// import { ReactComponent as BaseGovernmentOff } from './base/government-off.svg';
// import { ReactComponent as BaseText } from './base/text.svg';
// import { ReactComponent as BaseUsbDeviceCogwheel } from './base/usb-device-cogwheel.svg';
// import { ReactComponent as BaseMarker } from './base/marker.svg';
// import { ReactComponent as BaseAlignRight } from './base/align-right.svg';
// import { ReactComponent as BasePersonalHygiene } from './base/personal-hygiene.svg';
// import { ReactComponent as BaseMobilePhoneScan } from './base/mobile-phone-scan.svg';
// import { ReactComponent as BaseLightbulb } from './base/lightbulb.svg';
// import { ReactComponent as BaseCallRecord } from './base/call-record.svg';
// import { ReactComponent as BaseVectorPathCurve } from './base/vector-path-curve.svg';
// import { ReactComponent as BaseSave } from './base/save.svg';
// import { ReactComponent as BaseLamp } from './base/lamp.svg';
// import { ReactComponent as BaseHeadVoice } from './base/head-voice.svg';
// import { ReactComponent as BaseHomeFire } from './base/home-fire.svg';
// import { ReactComponent as BaseCircleUp } from './base/circle-up.svg';
// import { ReactComponent as BaseLayersMinus } from './base/layers-minus.svg';
// import { ReactComponent as BaseSquareEmptyQuestion } from './base/square-empty-question.svg';
// import { ReactComponent as BaseCorrectionSkewedAlt } from './base/correction-skewed-alt.svg';
// import { ReactComponent as BaseResizeHorizontal } from './base/resize-horizontal.svg';
// import { ReactComponent as BaseStart } from './base/start.svg';
// import { ReactComponent as BaseRoundabout } from './base/roundabout.svg';
// import { ReactComponent as BaseBarrel } from './base/barrel.svg';
// import { ReactComponent as BaseEnvelopeEmpty } from './base/envelope-empty.svg';
// import { ReactComponent as BaseChevronLastRight } from './base/chevron-last-right.svg';
// import { ReactComponent as BaseOven } from './base/oven.svg';
// import { ReactComponent as BaseParagraphJustify } from './base/paragraph-justify.svg';
// import { ReactComponent as BaseMemoryCardCogwheel } from './base/memory-card-cogwheel.svg';
// import { ReactComponent as BaseSwitchOn } from './base/switch-on.svg';
// import { ReactComponent as BasePaperclip } from './base/paperclip.svg';
// import { ReactComponent as BasePolice } from './base/police.svg';
// import { ReactComponent as BaseDrinkingWater } from './base/drinking-water.svg';
// import { ReactComponent as BaseLinkRemove } from './base/link-remove.svg';
// import { ReactComponent as BaseWinners } from './base/winners.svg';
// import { ReactComponent as BaseSquareDown } from './base/square-down.svg';
// import { ReactComponent as BaseSyringe } from './base/syringe.svg';
// import { ReactComponent as BaseBeerAlt } from './base/beer-alt.svg';
// import { ReactComponent as BaseSyncAlert } from './base/sync-alert.svg';
// import { ReactComponent as BaseGloves } from './base/gloves.svg';
// import { ReactComponent as BaseSpeechBubbleQuestion } from './base/speech-bubble-question.svg';
// import { ReactComponent as BaseBrainAi } from './base/brain-ai.svg';
// import { ReactComponent as BaseCarBattery } from './base/car-battery.svg';
// import { ReactComponent as BasePhoneOld } from './base/phone-old.svg';
// import { ReactComponent as BaseFingerprintRemove } from './base/fingerprint-remove.svg';
// import { ReactComponent as BaseClawHammer } from './base/claw-hammer.svg';
// import { ReactComponent as BaseNearby } from './base/nearby.svg';
// import { ReactComponent as BaseSuperscript } from './base/superscript.svg';
// import { ReactComponent as BaseBatteryLow } from './base/battery-low.svg';
// import { ReactComponent as BaseSquareEmptyDownload } from './base/square-empty-download.svg';
// import { ReactComponent as BaseSidebar } from './base/sidebar.svg';
// import { ReactComponent as BasePalettePackage } from './base/palette-package.svg';
// import { ReactComponent as BaseMultifunctionKnife } from './base/multifunction-knife.svg';
// import { ReactComponent as BaseCrown } from './base/crown.svg';
// import { ReactComponent as BaseRewindFive } from './base/rewind-five.svg';
// import { ReactComponent as BaseTongueOff } from './base/tongue-off.svg';
// import { ReactComponent as BasePlay } from './base/play.svg';
// import { ReactComponent as BaseMemoryCardNo } from './base/memory-card-no.svg';
// import { ReactComponent as BaseTemperatureSet } from './base/temperature-set.svg';
// import { ReactComponent as BaseParagraphLeft } from './base/paragraph-left.svg';
// import { ReactComponent as BaseChatMessageCheck } from './base/chat-message-check.svg';
// import { ReactComponent as BaseMobilePhoneAlert } from './base/mobile-phone-alert.svg';
// import { ReactComponent as BaseVases } from './base/vases.svg';
// import { ReactComponent as BaseCommentsLock } from './base/comments-lock.svg';
// import { ReactComponent as BasePicture } from './base/picture.svg';
// import { ReactComponent as BaseRewindTen } from './base/rewind-ten.svg';
// import { ReactComponent as BaseAccessPoint } from './base/access-point.svg';
// import { ReactComponent as BaseBookLibrarySearch } from './base/book-library-search.svg';
// import { ReactComponent as BaseFactoryOff } from './base/factory-off.svg';
// import { ReactComponent as BaseSquareEmptyAlert } from './base/square-empty-alert.svg';
// import { ReactComponent as BaseCircleEmptyRight } from './base/circle-empty-right.svg';
// import { ReactComponent as BaseTable } from './base/table.svg';
// import { ReactComponent as BaseSend } from './base/send.svg';
// import { ReactComponent as BaseBarcode } from './base/barcode.svg';
// import { ReactComponent as BaseUserBoy } from './base/user-boy.svg';
// import { ReactComponent as BaseParagraphRight } from './base/paragraph-right.svg';
// import { ReactComponent as BaseCashRegisterAlt } from './base/cash-register-alt.svg';
// import { ReactComponent as BaseChevronLastUp } from './base/chevron-last-up.svg';
// import { ReactComponent as BaseGolf } from './base/golf.svg';
// import { ReactComponent as BaseWorldWest } from './base/world-west.svg';
// import { ReactComponent as BaseFolderNo } from './base/folder-no.svg';
// import { ReactComponent as BaseMapSatellite } from './base/map-satellite.svg';
// import { ReactComponent as BaseCircleEmptyPlay } from './base/circle-empty-play.svg';
// import { ReactComponent as BaseViewFull } from './base/view-full.svg';
// import { ReactComponent as BaseCeleriac } from './base/celeriac.svg';
// import { ReactComponent as BaseBalloons } from './base/balloons.svg';
// import { ReactComponent as BaseStarOfLife } from './base/star-of-life.svg';
// import { ReactComponent as BaseShieldQuarter } from './base/shield-quarter.svg';
// import { ReactComponent as BaseCamera } from './base/camera.svg';
// import { ReactComponent as BaseSquareSelected } from './base/square-selected.svg';
// import { ReactComponent as BaseCircleRight } from './base/circle-right.svg';
// import { ReactComponent as BaseRedo } from './base/redo.svg';
// import { ReactComponent as BaseFolderMinus } from './base/folder-minus.svg';
// import { ReactComponent as BaseDress } from './base/dress.svg';
// import { ReactComponent as BaseChatMessage } from './base/chat-message.svg';
// import { ReactComponent as BaseTruck } from './base/truck.svg';
// import { ReactComponent as BaseVectorPathPentagon } from './base/vector-path-pentagon.svg';
// import { ReactComponent as BaseCardClubs } from './base/card-clubs.svg';
// import { ReactComponent as BaseSquareUp } from './base/square-up.svg';
// import { ReactComponent as BaseCreditEmpty } from './base/credit-empty.svg';
// import { ReactComponent as BaseBlender } from './base/blender.svg';
// import { ReactComponent as BaseCompound } from './base/compound.svg';
// import { ReactComponent as BaseKettlebell } from './base/kettlebell.svg';
// import { ReactComponent as BaseTrending } from './base/trending.svg';
// import { ReactComponent as BaseSquareRight } from './base/square-right.svg';
// import { ReactComponent as BaseBlackboard } from './base/blackboard.svg';
// import { ReactComponent as BaseHomeFlag } from './base/home-flag.svg';
// import { ReactComponent as BaseMore } from './base/more.svg';
// import { ReactComponent as BaseToiletPaper } from './base/toilet-paper.svg';
// import { ReactComponent as BaseFileRefresh } from './base/file-refresh.svg';
// import { ReactComponent as BaseGolfGreen } from './base/golf-green.svg';
// import { ReactComponent as BaseCircleInfo } from './base/circle-info.svg';
// import { ReactComponent as BaseEgg } from './base/egg.svg';
// import { ReactComponent as BaseTextUnderline } from './base/text-underline.svg';
// import { ReactComponent as BaseGlass } from './base/glass.svg';
// import { ReactComponent as BaseLupin } from './base/lupin.svg';
// import { ReactComponent as BaseBus } from './base/bus.svg';
// import { ReactComponent as BaseAudioDescription } from './base/audio-description.svg';
// import { ReactComponent as BaseSkullCrossbones } from './base/skull-crossbones.svg';
// import { ReactComponent as BaseFeather } from './base/feather.svg';
// import { ReactComponent as BaseScalePersonal } from './base/scale-personal.svg';
// import { ReactComponent as BaseBookLibrary } from './base/book-library.svg';
// import { ReactComponent as BasePiggyBankCoins } from './base/piggy-bank-coins.svg';
// import { ReactComponent as BaseTransgender } from './base/transgender.svg';
// import { ReactComponent as BaseArrowThinUp } from './base/arrow-thin-up.svg';
// import { ReactComponent as BaseToilet } from './base/toilet.svg';
// import { ReactComponent as BaseHandPointingUp } from './base/hand-pointing-up.svg';
// import { ReactComponent as BaseLeaf } from './base/leaf.svg';
// import { ReactComponent as BaseInvoicePlus } from './base/invoice-plus.svg';
// import { ReactComponent as BaseDatabaseSearch } from './base/database-search.svg';
// import { ReactComponent as BasePaste } from './base/paste.svg';
// import { ReactComponent as BaseMustard } from './base/mustard.svg';
// import { ReactComponent as BaseCartIn } from './base/cart-in.svg';
// import { ReactComponent as BaseHandPraying } from './base/hand-praying.svg';
// import { ReactComponent as BaseBanknotes } from './base/banknotes.svg';
// import { ReactComponent as BaseChatConversationLock } from './base/chat-conversation-lock.svg';
// import { ReactComponent as BaseCirclePlus } from './base/circle-plus.svg';
// import { ReactComponent as BaseVolumeUp } from './base/volume-up.svg';
// import { ReactComponent as BaseRechargeable } from './base/rechargeable.svg';
// import { ReactComponent as BaseSortAlphabetically } from './base/sort-alphabetically.svg';
// import { ReactComponent as BaseShadows } from './base/shadows.svg';
// import { ReactComponent as BaseVr } from './base/vr.svg';
// import { ReactComponent as BaseStar } from './base/star.svg';
// import { ReactComponent as BaseHardDriveNo } from './base/hard-drive-no.svg';
// import { ReactComponent as BaseSkipToPrevious } from './base/skip-to-previous.svg';
// import { ReactComponent as BaseCloudOff } from './base/cloud-off.svg';
// import { ReactComponent as BaseDoorClose } from './base/door-close.svg';
// import { ReactComponent as BaseCd } from './base/cd.svg';
// import { ReactComponent as BaseIdBadgeVertical } from './base/id-badge-vertical.svg';
// import { ReactComponent as BaseSun } from './base/sun.svg';
// import { ReactComponent as BaseWrench } from './base/wrench.svg';
// import { ReactComponent as BaseArrowThinRight } from './base/arrow-thin-right.svg';
// import { ReactComponent as BaseEnvelopeFlag } from './base/envelope-flag.svg';
// import { ReactComponent as BaseIntersex } from './base/intersex.svg';
// import { ReactComponent as BaseWastePipeEnd } from './base/waste-pipe-end.svg';
// import { ReactComponent as BaseVectorPath } from './base/vector-path.svg';
// import { ReactComponent as BaseEnvelopePlus } from './base/envelope-plus.svg';
// import { ReactComponent as BaseCarWheelDefect } from './base/car-wheel-defect.svg';
// import { ReactComponent as BasePizza } from './base/pizza.svg';
// import { ReactComponent as BaseHob } from './base/hob.svg';
// import { ReactComponent as BaseCloudPause } from './base/cloud-pause.svg';
// import { ReactComponent as BaseUserAlert } from './base/user-alert.svg';
// import { ReactComponent as BaseAnchor } from './base/anchor.svg';
// import { ReactComponent as BaseContactless } from './base/contactless.svg';
// import { ReactComponent as BaseHammer } from './base/hammer.svg';
// import { ReactComponent as BaseSoya } from './base/soya.svg';
// import { ReactComponent as BaseGovernment } from './base/government.svg';
// import { ReactComponent as BaseUserChat } from './base/user-chat.svg';
// import { ReactComponent as BasePaired } from './base/paired.svg';
// import { ReactComponent as BaseFaceMaskGasFull } from './base/face-mask-gas-full.svg';
// import { ReactComponent as BaseCardHearts } from './base/card-hearts.svg';
// import { ReactComponent as BaseCircleRedCross } from './base/circle-red-cross.svg';
// import { ReactComponent as BaseHighlights } from './base/highlights.svg';
// import { ReactComponent as BaseFoodService } from './base/food-service.svg';
// import { ReactComponent as BaseServerRefresh } from './base/server-refresh.svg';
// import { ReactComponent as BaseTreeDeciduous } from './base/tree-deciduous.svg';
// import { ReactComponent as BasePassportAlt } from './base/passport-alt.svg';
// import { ReactComponent as BaseVolumeMax } from './base/volume-max.svg';
// import { ReactComponent as BaseFolderLock } from './base/folder-lock.svg';
// import { ReactComponent as BaseOpen } from './base/open.svg';
// import { ReactComponent as BaseEducation } from './base/education.svg';
// import { ReactComponent as BaseChevronLastLeft } from './base/chevron-last-left.svg';
// import { ReactComponent as BaseBug } from './base/bug.svg';
// import { ReactComponent as BaseUserScam } from './base/user-scam.svg';
// import { ReactComponent as BaseBringForward } from './base/bring-forward.svg';
// import { ReactComponent as BaseBookAddress } from './base/book-address.svg';
// import { ReactComponent as BaseInboxLock } from './base/inbox-lock.svg';
// import { ReactComponent as BaseMobilePhoneShaking } from './base/mobile-phone-shaking.svg';
// import { ReactComponent as BaseSesame } from './base/sesame.svg';
// import { ReactComponent as BaseHighway } from './base/highway.svg';
// import { ReactComponent as BaseHandDislike } from './base/hand-dislike.svg';
// import { ReactComponent as BaseAdjust } from './base/adjust.svg';
// import { ReactComponent as BaseCompass } from './base/compass.svg';
// import { ReactComponent as BaseDivingFree } from './base/diving-free.svg';
// import { ReactComponent as BaseFolderRefresh } from './base/folder-refresh.svg';
// import { ReactComponent as BaseBicycle } from './base/bicycle.svg';
// import { ReactComponent as BaseBank } from './base/bank.svg';
// import { ReactComponent as BaseDogTags } from './base/dog-tags.svg';
// import { ReactComponent as BaseWarehouse } from './base/warehouse.svg';
// import { ReactComponent as BaseVerifyCheck } from './base/verify-check.svg';
// import { ReactComponent as BaseCreditCard } from './base/credit-card.svg';
// import { ReactComponent as BaseListNumbers } from './base/list-numbers.svg';
// import { ReactComponent as BaseMenuClose } from './base/menu-close.svg';
// import { ReactComponent as BaseTie } from './base/tie.svg';
// import { ReactComponent as BaseVideoPlay } from './base/video-play.svg';
// import { ReactComponent as BaseUnderwear } from './base/underwear.svg';
// import { ReactComponent as BaseComputer } from './base/computer.svg';
// import { ReactComponent as BaseSlightlyFrowning } from './base/slightly-frowning.svg';
// import { ReactComponent as BaseAx } from './base/ax.svg';
// import { ReactComponent as BaseBlackboardOff } from './base/blackboard-off.svg';
// import { ReactComponent as BaseReplyAll } from './base/reply-all.svg';
// import { ReactComponent as BaseCoffeeToGo } from './base/coffee-to-go.svg';
// import { ReactComponent as BaseKiosk } from './base/kiosk.svg';
// import { ReactComponent as BasePencil } from './base/pencil.svg';
// import { ReactComponent as BaseSquareTriangleRight } from './base/square-triangle-right.svg';
// import { ReactComponent as BaseMapAerial } from './base/map-aerial.svg';
// import { ReactComponent as BaseCarWheelWrenchAlt } from './base/car-wheel-wrench-alt.svg';
// import { ReactComponent as BaseCallVideo } from './base/call-video.svg';
// import { ReactComponent as BaseSendToBack } from './base/send-to-back.svg';
// import { ReactComponent as BaseUserGroup } from './base/user-group.svg';
// import { ReactComponent as BasePowerCordPlug } from './base/power-cord-plug.svg';
// import { ReactComponent as BaseGlassWine } from './base/glass-wine.svg';
// import { ReactComponent as BasePawn } from './base/pawn.svg';
// import { ReactComponent as BaseThreeDimensionalFull } from './base/three-dimensional-full.svg';
// import { ReactComponent as BaseDisinfectionSoap } from './base/disinfection-soap.svg';
// import { ReactComponent as BaseMobilePhone } from './base/mobile-phone.svg';
// import { ReactComponent as BaseFilePlus } from './base/file-plus.svg';
// import { ReactComponent as BaseBasketball } from './base/basketball.svg';
// import { ReactComponent as BaseDiamond } from './base/diamond.svg';
// import { ReactComponent as BaseHardDriveLock } from './base/hard-drive-lock.svg';
// import { ReactComponent as BaseClock } from './base/clock.svg';
// import { ReactComponent as BaseRouter } from './base/router.svg';
// import { ReactComponent as BaseFootprints } from './base/footprints.svg';
// import { ReactComponent as BaseRetweet } from './base/retweet.svg';
// import { ReactComponent as BaseThumbnailsSmall } from './base/thumbnails-small.svg';
// import { ReactComponent as BaseSquareInfo } from './base/square-info.svg';
// import { ReactComponent as BaseScissorsHorizontal } from './base/scissors-horizontal.svg';
// import { ReactComponent as BaseCallIncoming } from './base/call-incoming.svg';
// import { ReactComponent as BaseAsianFood } from './base/asian-food.svg';
// import { ReactComponent as BaseFootball } from './base/football.svg';
// import { ReactComponent as BaseHanger } from './base/hanger.svg';
// import { ReactComponent as BaseCredit } from './base/credit.svg';
// import { ReactComponent as BaseGrater } from './base/grater.svg';
// import { ReactComponent as BaseAnnouncement } from './base/announcement.svg';
// import { ReactComponent as BaseVoiceScan } from './base/voice-scan.svg';
// import { ReactComponent as BaseDumbbell } from './base/dumbbell.svg';
// import { ReactComponent as BaseEqualizerDots } from './base/equalizer-dots.svg';
// import { ReactComponent as BaseSync } from './base/sync.svg';
// import { ReactComponent as BaseTextSmaller } from './base/text-smaller.svg';
// import { ReactComponent as BaseCall } from './base/call.svg';
// import { ReactComponent as BaseTractor } from './base/tractor.svg';
// import { ReactComponent as BaseSquareDownload } from './base/square-download.svg';
// import { ReactComponent as BaseSyncCheck } from './base/sync-check.svg';
// import { ReactComponent as BaseMonitorVertical } from './base/monitor-vertical.svg';
// import { ReactComponent as BaseCourtGavel } from './base/court-gavel.svg';
// import { ReactComponent as BaseDatabaseMinus } from './base/database-minus.svg';
// import { ReactComponent as BaseEye } from './base/eye.svg';
// import { ReactComponent as BaseHandOpenAlt } from './base/hand-open-alt.svg';
// import { ReactComponent as BaseStatsOneDay } from './base/stats-one-day.svg';
// import { ReactComponent as BaseRadioactive } from './base/radioactive.svg';
// import { ReactComponent as BaseViewHalf } from './base/view-half.svg';
// import { ReactComponent as BaseCoffeeMug } from './base/coffee-mug.svg';
// import { ReactComponent as BaseStatsCircle } from './base/stats-circle.svg';
// import { ReactComponent as BaseFlower } from './base/flower.svg';
// import { ReactComponent as BaseSocketI } from './base/socket-i.svg';
// import { ReactComponent as BaseSampler } from './base/sampler.svg';
// import { ReactComponent as BaseFlashAutomatic } from './base/flash-automatic.svg';
// import { ReactComponent as BaseBrickWall } from './base/brick-wall.svg';
// import { ReactComponent as BaseShieldEmptyStar } from './base/shield-empty-star.svg';
// import { ReactComponent as BaseSimpleTrolley } from './base/simple-trolley.svg';
// import { ReactComponent as BaseSquareRemove } from './base/square-remove.svg';
// import { ReactComponent as BaseBin } from './base/bin.svg';
// import { ReactComponent as BaseSquarePlus } from './base/square-plus.svg';
// import { ReactComponent as BaseMobilePhoneCheck } from './base/mobile-phone-check.svg';
// import { ReactComponent as BaseGift } from './base/gift.svg';
// import { ReactComponent as BaseThumbnailsList } from './base/thumbnails-list.svg';
// import { ReactComponent as BaseSeafood } from './base/seafood.svg';
// import { ReactComponent as BaseCar } from './base/car.svg';
// import { ReactComponent as BaseIceLollyEaten } from './base/ice-lolly-eaten.svg';
// import { ReactComponent as BaseMoreVertical } from './base/more-vertical.svg';
// import { ReactComponent as BaseSquareEmptyCheck } from './base/square-empty-check.svg';
// import { ReactComponent as BaseBatteryQuarter } from './base/battery-quarter.svg';
// import { ReactComponent as BaseSawBlade } from './base/saw-blade.svg';
// import { ReactComponent as BaseMicOff } from './base/mic-off.svg';
// import { ReactComponent as BaseTableTennis } from './base/table-tennis.svg';
// import { ReactComponent as BaseTrousers } from './base/trousers.svg';
// import { ReactComponent as BaseDrinkingWaterOff } from './base/drinking-water-off.svg';
// import { ReactComponent as BaseBaseball } from './base/baseball.svg';
// import { ReactComponent as BaseTeaMug } from './base/tea-mug.svg';
// import { ReactComponent as BaseShare } from './base/share.svg';
// import { ReactComponent as BaseSetWine } from './base/set-wine.svg';
// import { ReactComponent as BaseTags } from './base/tags.svg';
// import { ReactComponent as BaseArrowUp } from './base/arrow-up.svg';
// import { ReactComponent as BaseBellOff } from './base/bell-off.svg';
// import { ReactComponent as BaseCircleEmptyQuestion } from './base/circle-empty-question.svg';
// import { ReactComponent as BaseSort } from './base/sort.svg';
// import { ReactComponent as BaseStatsBarsOneDay } from './base/stats-bars-one-day.svg';
// import { ReactComponent as BaseGlobeData } from './base/globe-data.svg';
// import { ReactComponent as BaseGameControllerWireless } from './base/game-controller-wireless.svg';
// import { ReactComponent as BaseUsbDeviceEject } from './base/usb-device-eject.svg';
// import { ReactComponent as BaseUndo } from './base/undo.svg';
// import { ReactComponent as BaseVideo } from './base/video.svg';
// import { ReactComponent as BaseTreeConifer } from './base/tree-conifer.svg';
// import { ReactComponent as BaseFabric } from './base/fabric.svg';
// import { ReactComponent as BaseFingerprintCheck } from './base/fingerprint-check.svg';
// import { ReactComponent as BaseVrInside } from './base/vr-inside.svg';
// import { ReactComponent as BasePills } from './base/pills.svg';
// import { ReactComponent as BaseKioskWheels } from './base/kiosk-wheels.svg';
// import { ReactComponent as BaseDisinfectionSpraySmall } from './base/disinfection-spray-small.svg';
// import { ReactComponent as BaseFilter } from './base/filter.svg';
// import { ReactComponent as BaseTransportTrolley } from './base/transport-trolley.svg';
// import { ReactComponent as BaseIndentRight } from './base/indent-right.svg';
// import { ReactComponent as BaseWater } from './base/water.svg';
// import { ReactComponent as BaseCarWheel } from './base/car-wheel.svg';
// import { ReactComponent as BaseDoor } from './base/door.svg';
// import { ReactComponent as BaseSwitch } from './base/switch.svg';
// import { ReactComponent as BaseItalic } from './base/italic.svg';
// import { ReactComponent as BaseHandshake } from './base/handshake.svg';
// import { ReactComponent as BaseThisSideUp } from './base/this-side-up.svg';
// import { ReactComponent as BaseMedal } from './base/medal.svg';
// import { ReactComponent as BaseDumbbellOff } from './base/dumbbell-off.svg';
// import { ReactComponent as BaseLeather } from './base/leather.svg';
// import { ReactComponent as BaseCogwheel } from './base/cogwheel.svg';
// import { ReactComponent as BaseCalendar } from './base/calendar.svg';
// import { ReactComponent as BaseVideoCamera } from './base/video-camera.svg';
// import { ReactComponent as BaseGlobe } from './base/globe.svg';
// import { ReactComponent as BaseArrowLeft } from './base/arrow-left.svg';
// import { ReactComponent as BaseGasStation } from './base/gas-station.svg';
// import { ReactComponent as BaseSquareMinus } from './base/square-minus.svg';
// import { ReactComponent as BaseYen } from './base/yen.svg';
// import { ReactComponent as BaseRoute } from './base/route.svg';
// import { ReactComponent as BaseMicrochip } from './base/microchip.svg';
// import { ReactComponent as BaseCableway } from './base/cableway.svg';
// import { ReactComponent as BaseDishwasher } from './base/dishwasher.svg';
// import { ReactComponent as BaseTheaterMasks } from './base/theater-masks.svg';
// import { ReactComponent as BaseHeartbeat } from './base/heartbeat.svg';
// import { ReactComponent as BaseServerLock } from './base/server-lock.svg';
// import { ReactComponent as BaseSquareQuestion } from './base/square-question.svg';
// import { ReactComponent as BaseDiceTwo } from './base/dice-two.svg';
// import { ReactComponent as BaseInvoiceAlert } from './base/invoice-alert.svg';
// import { ReactComponent as BaseKeyboardWireless } from './base/keyboard-wireless.svg';
// import { ReactComponent as BaseScanner } from './base/scanner.svg';
// import { ReactComponent as BaseOxygenBottles } from './base/oxygen-bottles.svg';
// import { ReactComponent as BaseCallData } from './base/call-data.svg';
// import { ReactComponent as BaseDropPlus } from './base/drop-plus.svg';
// import { ReactComponent as BaseHeartBroken } from './base/heart-broken.svg';
// import { ReactComponent as BaseMountains } from './base/mountains.svg';
// import { ReactComponent as BaseMobilePhoneVibrations } from './base/mobile-phone-vibrations.svg';
// import { ReactComponent as BaseDiceSix } from './base/dice-six.svg';
// import { ReactComponent as BaseInboxDown } from './base/inbox-down.svg';
// import { ReactComponent as BaseCloud } from './base/cloud.svg';
// import { ReactComponent as BaseIndentLeft } from './base/indent-left.svg';
// import { ReactComponent as BaseKioskImmobile } from './base/kiosk-immobile.svg';
// import { ReactComponent as BaseCircleEmptyMinus } from './base/circle-empty-minus.svg';
// import { ReactComponent as BaseCup } from './base/cup.svg';
// import { ReactComponent as BaseWashingMachine } from './base/washing-machine.svg';
// import { ReactComponent as BaseInvoice } from './base/invoice.svg';
// import { ReactComponent as BaseDice } from './base/dice.svg';
// import { ReactComponent as BaseBars } from './base/bars.svg';
// import { ReactComponent as BaseAxesThreeDimensional } from './base/axes-three-dimensional.svg';
// import { ReactComponent as BaseRewind } from './base/rewind.svg';
// import { ReactComponent as BaseReloadAlt } from './base/reload-alt.svg';
// import { ReactComponent as BaseCorrectionSkewed } from './base/correction-skewed.svg';
// import { ReactComponent as BaseShoes } from './base/shoes.svg';
// import { ReactComponent as BasePhoneToHorizontal } from './base/phone-to-horizontal.svg';
// import { ReactComponent as BasePause } from './base/pause.svg';
// import { ReactComponent as BaseHandPointingDown } from './base/hand-pointing-down.svg';
// import { ReactComponent as BaseChequeOff } from './base/cheque-off.svg';
// import { ReactComponent as BaseCopyDuplicate } from './base/copy-duplicate.svg';
// import { ReactComponent as BaseLayersUp } from './base/layers-up.svg';
// import { ReactComponent as BaseBalloon } from './base/balloon.svg';
// import { ReactComponent as BaseForward } from './base/forward.svg';
// import { ReactComponent as BaseVirginFemale } from './base/virgin-female.svg';
// import { ReactComponent as BaseDiceFour } from './base/dice-four.svg';
// import { ReactComponent as BaseCircleMinus } from './base/circle-minus.svg';
// import { ReactComponent as BaseBookmark } from './base/bookmark.svg';
// import { ReactComponent as BaseTriangleRadioactive } from './base/triangle-radioactive.svg';
// import { ReactComponent as BaseIdBadgeLanyard } from './base/id-badge-lanyard.svg';
// import { ReactComponent as BaseSquareEmptyPlus } from './base/square-empty-plus.svg';
// import { ReactComponent as BaseIceCreamOff } from './base/ice-cream-off.svg';
// import { ReactComponent as BaseClean } from './base/clean.svg';
// import { ReactComponent as BaseGunOff } from './base/gun-off.svg';
// import { ReactComponent as BaseUserCheck } from './base/user-check.svg';
// import { ReactComponent as BaseStarEmpty } from './base/star-empty.svg';
// import { ReactComponent as BaseBishop } from './base/bishop.svg';
// import { ReactComponent as BaseSortAttributes } from './base/sort-attributes.svg';
// import { ReactComponent as BaseTablet } from './base/tablet.svg';
// import { ReactComponent as BaseSquareHospital } from './base/square-hospital.svg';
// import { ReactComponent as BaseChatMessageLock } from './base/chat-message-lock.svg';
// import { ReactComponent as BaseSpellCheckCheck } from './base/spell-check-check.svg';
// import { ReactComponent as BaseBatteryCharged } from './base/battery-charged.svg';
// import { ReactComponent as BaseCloudDownload } from './base/cloud-download.svg';
// import { ReactComponent as BaseSlightlySmiling } from './base/slightly-smiling.svg';
// import { ReactComponent as BaseEar } from './base/ear.svg';
// import { ReactComponent as BaseSpellCheck } from './base/spell-check.svg';
// import { ReactComponent as BaseElectricCord } from './base/electric-cord.svg';
// import { ReactComponent as BaseRandom } from './base/random.svg';
// import { ReactComponent as BaseForwardTen } from './base/forward-ten.svg';
// import { ReactComponent as BaseLockOpen } from './base/lock-open.svg';
// import { ReactComponent as BaseCat } from './base/cat.svg';
// import { ReactComponent as BaseSortAlphabeticallyAlt } from './base/sort-alphabetically-alt.svg';
// import { ReactComponent as BaseServerPlus } from './base/server-plus.svg';
// import { ReactComponent as BaseArtificialIntelligence } from './base/artificial-intelligence.svg';
// import { ReactComponent as BaseOther } from './base/other.svg';
// import { ReactComponent as BaseIceCream } from './base/ice-cream.svg';
// import { ReactComponent as BasePlaylistMusic } from './base/playlist-music.svg';
// import { ReactComponent as BaseDrop } from './base/drop.svg';
// import { ReactComponent as BaseLightBeacon } from './base/light-beacon.svg';
// import { ReactComponent as BasePeanuts } from './base/peanuts.svg';
// import { ReactComponent as BaseServerNo } from './base/server-no.svg';
// import { ReactComponent as BaseFrenchPress } from './base/french-press.svg';
// import { ReactComponent as BaseParking } from './base/parking.svg';
// import { ReactComponent as BaseEnvelopeDown } from './base/envelope-down.svg';
// import { ReactComponent as BaseSquareEmptyInfo } from './base/square-empty-info.svg';
// import { ReactComponent as BaseHeader } from './base/header.svg';
// import { ReactComponent as BaseMonitorRotate } from './base/monitor-rotate.svg';
// import { ReactComponent as BaseTicketParking } from './base/ticket-parking.svg';
// import { ReactComponent as BaseUserScamAlt } from './base/user-scam-alt.svg';
// import { ReactComponent as BaseId } from './base/id.svg';
// import { ReactComponent as BaseAirplane } from './base/airplane.svg';
// import { ReactComponent as BaseCalculator } from './base/calculator.svg';
// import { ReactComponent as BaseKioskFood } from './base/kiosk-food.svg';
// import { ReactComponent as BaseSendBackward } from './base/send-backward.svg';
// import { ReactComponent as BaseAlignTop } from './base/align-top.svg';
// import { ReactComponent as BaseWorldEast } from './base/world-east.svg';
// import { ReactComponent as BaseStats } from './base/stats.svg';
// import { ReactComponent as BaseApple } from './base/apple.svg';
// import { ReactComponent as BaseMouse } from './base/mouse.svg';
// import { ReactComponent as BaseTriangleEmptyAlert } from './base/triangle-empty-alert.svg';
// import { ReactComponent as BaseScrewdriver } from './base/screwdriver.svg';
// import { ReactComponent as BaseWalletCogwheel } from './base/wallet-cogwheel.svg';
// import { ReactComponent as BaseFemale } from './base/female.svg';
// import { ReactComponent as BaseCallPause } from './base/call-pause.svg';
// import { ReactComponent as BaseShieldCheck } from './base/shield-check.svg';
// import { ReactComponent as BaseKettle } from './base/kettle.svg';
// import { ReactComponent as BaseShoesOff } from './base/shoes-off.svg';
// import { ReactComponent as BaseFastFood } from './base/fast-food.svg';
// import { ReactComponent as BaseStarOfLifeEmpty } from './base/star-of-life-empty.svg';
// import { ReactComponent as BaseServerFlag } from './base/server-flag.svg';
// import { ReactComponent as BaseChargingStation } from './base/charging-station.svg';
// import { ReactComponent as BaseCircleDown } from './base/circle-down.svg';
// import { ReactComponent as BaseHockey } from './base/hockey.svg';
// import { ReactComponent as BaseBuilding } from './base/building.svg';
// import { ReactComponent as BaseEarphones } from './base/earphones.svg';
// import { ReactComponent as BaseBinoculars } from './base/binoculars.svg';
// import { ReactComponent as BaseCookieAlt } from './base/cookie-alt.svg';
// import { ReactComponent as BaseHistory } from './base/history.svg';
// import { ReactComponent as BaseFolder } from './base/folder.svg';
// import { ReactComponent as BaseAlarm } from './base/alarm.svg';
// import { ReactComponent as BaseVerifyEmpty } from './base/verify-empty.svg';
// import { ReactComponent as BaseStatsBars } from './base/stats-bars.svg';
// import { ReactComponent as BaseFileLock } from './base/file-lock.svg';
// import { ReactComponent as BaseBasket } from './base/basket.svg';
// import { ReactComponent as BaseUsers } from './base/users.svg';
// import { ReactComponent as BaseFingerprint } from './base/fingerprint.svg';
// import { ReactComponent as BaseFill } from './base/fill.svg';
// import { ReactComponent as BasePot } from './base/pot.svg';
// import { ReactComponent as BasePulse } from './base/pulse.svg';
// import { ReactComponent as BaseFins } from './base/fins.svg';
// import { ReactComponent as BaseCircleEmptyRemove } from './base/circle-empty-remove.svg';
// import { ReactComponent as BaseBookOpenText } from './base/book-open-text.svg';
// import { ReactComponent as BaseTimer } from './base/timer.svg';
// import { ReactComponent as BaseMonitor } from './base/monitor.svg';
// import { ReactComponent as BaseMinus } from './base/minus.svg';
// import { ReactComponent as BaseGas } from './base/gas.svg';
// import { ReactComponent as BaseWalletMinus } from './base/wallet-minus.svg';
// import { ReactComponent as BaseTimes } from './base/times.svg';
// import { ReactComponent as BaseFullscreenOff } from './base/fullscreen-off.svg';
// import { ReactComponent as BaseSquareRedCross } from './base/square-red-cross.svg';
// import { ReactComponent as BaseChevronLeft } from './base/chevron-left.svg';
// import { ReactComponent as BaseBeachUmbrella } from './base/beach-umbrella.svg';
// import { ReactComponent as BaseFilm } from './base/film.svg';
// import { ReactComponent as BasePrint } from './base/print.svg';
// import { ReactComponent as BaseMoon } from './base/moon.svg';
// import { ReactComponent as BaseHairdresser } from './base/hairdresser.svg';
// import { ReactComponent as BaseSquareTriangleLeft } from './base/square-triangle-left.svg';
// import { ReactComponent as BaseComputerNetwork } from './base/computer-network.svg';
// import { ReactComponent as BaseHardDriveCogwheel } from './base/hard-drive-cogwheel.svg';
// import { ReactComponent as BaseHandLike } from './base/hand-like.svg';
// import { ReactComponent as BaseRotateHorizontal } from './base/rotate-horizontal.svg';
// import { ReactComponent as BaseSquareUpload } from './base/square-upload.svg';
// import { ReactComponent as BaseThumbnails } from './base/thumbnails.svg';
// import { ReactComponent as BaseSet } from './base/set.svg';
// import { ReactComponent as BaseCircleEmptyLeft } from './base/circle-empty-left.svg';
// import { ReactComponent as BaseModalWindow } from './base/modal-window.svg';
// import { ReactComponent as BaseDirectionsSign } from './base/directions-sign.svg';
// import { ReactComponent as BaseSwimmingPool } from './base/swimming-pool.svg';
// import { ReactComponent as BaseNotebook } from './base/notebook.svg';
// import { ReactComponent as BaseContactlessLock } from './base/contactless-lock.svg';
// import { ReactComponent as BaseBuildingsMixed } from './base/buildings-mixed.svg';
// import { ReactComponent as BaseAlignLeft } from './base/align-left.svg';
// import { ReactComponent as BaseArrowThinDown } from './base/arrow-thin-down.svg';
// import { ReactComponent as BaseShieldStar } from './base/shield-star.svg';
// import { ReactComponent as BaseTextBigger } from './base/text-bigger.svg';
// import { ReactComponent as BaseTemperatureLow } from './base/temperature-low.svg';
// import { ReactComponent as BaseSortNumerically } from './base/sort-numerically.svg';
// import { ReactComponent as BaseSkipToStart } from './base/skip-to-start.svg';
// import { ReactComponent as BaseHeart } from './base/heart.svg';
// import { ReactComponent as BaseFont } from './base/font.svg';
// import { ReactComponent as BaseHoneycombs } from './base/honeycombs.svg';
// import { ReactComponent as BaseSetDown } from './base/set-down.svg';
// import { ReactComponent as BaseMotorOil } from './base/motor-oil.svg';
// import { ReactComponent as BaseRulers } from './base/rulers.svg';
//
// Base End
//
// =============================================================================

// =============================================================================
//
// Halflings Start
//
import { ReactComponent as HalflingChevronThinUp } from "./halflings/chevron-thin-up.svg";
import { ReactComponent as HalflingChevronThinDown } from "./halflings/chevron-thin-down.svg";
import { ReactComponent as HalflingSquareEmpty } from "./halflings/square-empty.svg";
import { ReactComponent as HalflingSquareCheckbox } from "./halflings/square-checkbox.svg";
import { ReactComponent as HalflingSquareEdit } from "./halflings/square-edit.svg";
import { ReactComponent as HalflingChevronThinRight } from "./halflings/chevron-thin-right.svg";
import { ReactComponent as HalflingEye } from "./halflings/eye.svg";
import { ReactComponent as HalflingEyeOff } from "./halflings/eye-off.svg";
import { ReactComponent as HalflingCircleRemove } from "./halflings/circle-remove.svg";
import { ReactComponent as HalflingSquareNewWindow } from "./halflings/square-new-window.svg";
import { ReactComponent as HalflingCheck } from "./halflings/check.svg";
import { ReactComponent as HalflingBellOff } from "./halflings/bell-off.svg";
import { ReactComponent as HalflingBell } from "./halflings/bell.svg";
import { ReactComponent as HalflingPencil } from "./halflings/pencil.svg";
import { ReactComponent as HalflingStatsBars } from "./halflings/stats-bars.svg";
import { ReactComponent as HalflingHash } from "./halflings/hash.svg";
// import { ReactComponent as HalflingUnderline } from "./halflings/underline.svg";
// import { ReactComponent as HalflingTriangleAlert } from "./halflings/triangle-alert.svg";
// import { ReactComponent as HalflingMusicAlt } from "./halflings/music-alt.svg";
// import { ReactComponent as HalflingPowerCordPlugOff } from "./halflings/power-cord-plug-off.svg";
// import { ReactComponent as HalflingSearch } from "./halflings/search.svg";
// import { ReactComponent as HalflingEraser } from "./halflings/eraser.svg";
// import { ReactComponent as HalflingArrowDown } from "./halflings/arrow-down.svg";
// import { ReactComponent as HalflingList } from "./halflings/list.svg";
// import { ReactComponent as HalflingVolumeOff } from "./halflings/volume-off.svg";
// import { ReactComponent as HalflingTemperature } from "./halflings/temperature.svg";
// import { ReactComponent as HalflingInitialLetter } from "./halflings/initial-letter.svg";
// import { ReactComponent as HalflingCircleSelected } from "./halflings/circle-selected.svg";
// import { ReactComponent as HalflingDivision } from "./halflings/division.svg";
// import { ReactComponent as HalflingConstructionCone } from "./halflings/construction-cone.svg";
// import { ReactComponent as HalflingSquare2d } from "./halflings/square-2d.svg";
// import { ReactComponent as HalflingCutlery } from "./halflings/cutlery.svg";
// import { ReactComponent as HalflingLockOff } from "./halflings/lock-off.svg";
// import { ReactComponent as HalflingRestart } from "./halflings/restart.svg";
// import { ReactComponent as HalflingScissors } from "./halflings/scissors.svg";
// import { ReactComponent as HalflingSquareAlert } from "./halflings/square-alert.svg";
// import { ReactComponent as HalflingRepeat } from "./halflings/repeat.svg";
// import { ReactComponent as HalflingFlagTriangle } from "./halflings/flag-triangle.svg";
// import { ReactComponent as HalflingHourglass } from "./halflings/hourglass.svg";
// import { ReactComponent as HalflingSquareRightAlt } from "./halflings/square-right-alt.svg";
// import { ReactComponent as HalflingUser } from "./halflings/user.svg";
// import { ReactComponent as HalflingVolumeDown } from "./halflings/volume-down.svg";
// import { ReactComponent as HalflingFullscreen } from "./halflings/fullscreen.svg";
// import { ReactComponent as HalflingSquareMore } from "./halflings/square-more.svg";
// import { ReactComponent as HalflingHome } from "./halflings/home.svg";
// import { ReactComponent as HalflingBatteryHalf } from "./halflings/battery-half.svg";
// import { ReactComponent as HalflingMusic } from "./halflings/music.svg";
// import { ReactComponent as HalflingNoSymbol } from "./halflings/no-symbol.svg";
// import { ReactComponent as HalflingArrowThinLeft } from "./halflings/arrow-thin-left.svg";
// import { ReactComponent as HalflingFunction } from "./halflings/function.svg";
// import { ReactComponent as HalflingEject } from "./halflings/eject.svg";
// import { ReactComponent as HalflingSquareTriangleDown } from "./halflings/square-triangle-down.svg";
// import { ReactComponent as HalflingChevronDown } from "./halflings/chevron-down.svg";
// import { ReactComponent as HalflingStrikethrough } from "./halflings/strikethrough.svg";
// import { ReactComponent as HalflingParagraphCenter } from "./halflings/paragraph-center.svg";
// import { ReactComponent as HalflingDock } from "./halflings/dock.svg";
// import { ReactComponent as HalflingFormula } from "./halflings/formula.svg";
// import { ReactComponent as HalflingDisappearing } from "./halflings/disappearing.svg";
// import { ReactComponent as HalflingSquareTriangleUp } from "./halflings/square-triangle-up.svg";
// import { ReactComponent as HalflingDropOff } from "./halflings/drop-off.svg";
// import { ReactComponent as HalflingTag } from "./halflings/tag.svg";
// import { ReactComponent as HalflingBriefcase } from "./halflings/briefcase.svg";
// import { ReactComponent as HalflingCircleLeft } from "./halflings/circle-left.svg";
// import { ReactComponent as HalflingSquarePlay } from "./halflings/square-play.svg";
// import { ReactComponent as HalflingSaveAs } from "./halflings/save-as.svg";
// import { ReactComponent as HalflingCircleEmpty } from "./halflings/circle-empty.svg";
// import { ReactComponent as HalflingVideoPlayEmpty } from "./halflings/video-play-empty.svg";
// import { ReactComponent as HalflingInbox } from "./halflings/inbox.svg";
// import { ReactComponent as HalflingCircleAlert } from "./halflings/circle-alert.svg";
// import { ReactComponent as HalflingUsbDevice } from "./halflings/usb-device.svg";
// import { ReactComponent as HalflingPower } from "./halflings/power.svg";
// import { ReactComponent as HalflingDatabase } from "./halflings/database.svg";
// import { ReactComponent as HalflingSquare3d } from "./halflings/square-3d.svg";
// import { ReactComponent as HalflingCircleMenu } from "./halflings/circle-menu.svg";
// import { ReactComponent as HalflingSquareIndeterminate } from "./halflings/square-indeterminate.svg";
// import { ReactComponent as HalflingFile } from "./halflings/file.svg";
// import { ReactComponent as HalflingParagraphPilcrow } from "./halflings/paragraph-pilcrow.svg";
// import { ReactComponent as HalflingQuotationLeft } from "./halflings/quotation-left.svg";
// import { ReactComponent as HalflingTextLineBreak } from "./halflings/text-line-break.svg";
// import { ReactComponent as HalflingContrast } from "./halflings/contrast.svg";
// import { ReactComponent as HalflingDoorHandle } from "./halflings/door-handle.svg";
// import { ReactComponent as HalflingTerminal } from "./halflings/terminal.svg";
// import { ReactComponent as HalflingTextLeftToRight } from "./halflings/text-left-to-right.svg";
// import { ReactComponent as HalflingHeartEmpty } from "./halflings/heart-empty.svg";
// import { ReactComponent as HalflingRepeatOnce } from "./halflings/repeat-once.svg";
// import { ReactComponent as HalflingChevronUp } from "./halflings/chevron-up.svg";
// import { ReactComponent as HalflingSquareLive } from "./halflings/square-live.svg";
// import { ReactComponent as HalflingBasketOut } from "./halflings/basket-out.svg";
// import { ReactComponent as HalflingStepBack } from "./halflings/step-back.svg";
// import { ReactComponent as HalflingFolderOpen } from "./halflings/folder-open.svg";
// import { ReactComponent as HalflingQrCode } from "./halflings/qr-code.svg";
// import { ReactComponent as HalflingSkipToEnd } from "./halflings/skip-to-end.svg";
// import { ReactComponent as HalflingMapMarker } from "./halflings/map-marker.svg";
// import { ReactComponent as HalflingLock } from "./halflings/lock.svg";
// import { ReactComponent as HalflingLogIn } from "./halflings/log-in.svg";
// import { ReactComponent as HalflingFire } from "./halflings/fire.svg";
// import { ReactComponent as HalflingFilterOff } from "./halflings/filter-off.svg";
// import { ReactComponent as HalflingChevronRight } from "./halflings/chevron-right.svg";
// import { ReactComponent as HalflingCircleQuestion } from "./halflings/circle-question.svg";
import { ReactComponent as HalflingLink } from "./halflings/link.svg";
// import { ReactComponent as HalflingVideoOff } from "./halflings/video-off.svg";
// import { ReactComponent as HalflingKey } from "./halflings/key.svg";
// import { ReactComponent as HalflingArrowRight } from "./halflings/arrow-right.svg";
// import { ReactComponent as HalflingSquareLeft } from "./halflings/square-left.svg";
// import { ReactComponent as HalflingOctagonRemove } from "./halflings/octagon-remove.svg";
// import { ReactComponent as HalflingSkipToNext } from "./halflings/skip-to-next.svg";
// import { ReactComponent as HalflingLogOut } from "./halflings/log-out.svg";
// import { ReactComponent as HalflingBatteryOff } from "./halflings/battery-off.svg";
// import { ReactComponent as HalflingSquareVr } from "./halflings/square-vr.svg";
// import { ReactComponent as HalflingCirclePlay } from "./halflings/circle-play.svg";
// import { ReactComponent as HalflingSquareMenu } from "./halflings/square-menu.svg";
// import { ReactComponent as HalflingCart } from "./halflings/cart.svg";
// import { ReactComponent as HalflingEnvelope } from "./halflings/envelope.svg";
// import { ReactComponent as HalflingArchive } from "./halflings/archive.svg";
// import { ReactComponent as HalflingMagnet } from "./halflings/magnet.svg";
// import { ReactComponent as HalflingTextBackground } from "./halflings/text-background.svg";
// import { ReactComponent as HalflingCartOut } from "./halflings/cart-out.svg";
// import { ReactComponent as HalflingSquareCheck } from "./halflings/square-check.svg";
// import { ReactComponent as HalflingAxesTwoDimensional } from "./halflings/axes-two-dimensional.svg";
// import { ReactComponent as HalflingBatteryThreeQuarters } from "./halflings/battery-three-quarters.svg";
// import { ReactComponent as HalflingTv } from "./halflings/tv.svg";
// import { ReactComponent as HalflingBasketIn } from "./halflings/basket-in.svg";
// import { ReactComponent as HalflingBatteryCharging } from "./halflings/battery-charging.svg";
// import { ReactComponent as HalflingSatellite } from "./halflings/satellite.svg";
// import { ReactComponent as HalflingPercentSign } from "./halflings/percent-sign.svg";
// import { ReactComponent as HalflingLayers } from "./halflings/layers.svg";
// import { ReactComponent as HalflingManWomanCompact } from "./halflings/man-woman-compact.svg";
// import { ReactComponent as HalflingBook } from "./halflings/book.svg";
// import { ReactComponent as HalflingChatConversation } from "./halflings/chat-conversation.svg";
// import { ReactComponent as HalflingProgress } from "./halflings/progress.svg";
// import { ReactComponent as HalflingWallet } from "./halflings/wallet.svg";
// import { ReactComponent as HalflingPushPin } from "./halflings/push-pin.svg";
// import { ReactComponent as HalflingCircleCheck } from "./halflings/circle-check.svg";
// import { ReactComponent as HalflingDashboard } from "./halflings/dashboard.svg";
// import { ReactComponent as HalflingFlash } from "./halflings/flash.svg";
// import { ReactComponent as HalflingPlaylist } from "./halflings/playlist.svg";
// import { ReactComponent as HalflingCode } from "./halflings/code.svg";
// import { ReactComponent as HalflingSquareInfoAlt } from "./halflings/square-info-alt.svg";
// import { ReactComponent as HalflingFlag } from "./halflings/flag.svg";
// import { ReactComponent as HalflingExchange } from "./halflings/exchange.svg";
// import { ReactComponent as HalflingMobilePhoneOff } from "./halflings/mobile-phone-off.svg";
// import { ReactComponent as HalflingChevronLastDown } from "./halflings/chevron-last-down.svg";
// import { ReactComponent as HalflingStop } from "./halflings/stop.svg";
// import { ReactComponent as HalflingSnowflake } from "./halflings/snowflake.svg";
// import { ReactComponent as HalflingStepForward } from "./halflings/step-forward.svg";
// import { ReactComponent as HalflingSquareAlertAlt } from "./halflings/square-alert-alt.svg";
// import { ReactComponent as HalflingStarHalf } from "./halflings/star-half.svg";
// import { ReactComponent as HalflingRuler } from "./halflings/ruler.svg";
// import { ReactComponent as HalflingCursor } from "./halflings/cursor.svg";
// import { ReactComponent as HalflingSwitchOff } from "./halflings/switch-off.svg";
// import { ReactComponent as HalflingBold } from "./halflings/bold.svg";
// import { ReactComponent as HalflingDirection } from "./halflings/direction.svg";
// import { ReactComponent as HalflingEquals } from "./halflings/equals.svg";
// import { ReactComponent as HalflingPlus } from "./halflings/plus.svg";
// import { ReactComponent as HalflingHardDrive } from "./halflings/hard-drive.svg";
// import { ReactComponent as HalflingBluetooth } from "./halflings/bluetooth.svg";
// import { ReactComponent as HalflingHeadphones } from "./halflings/headphones.svg";
// import { ReactComponent as HalflingCreditCardOff } from "./halflings/credit-card-off.svg";
// import { ReactComponent as HalflingEmail } from "./halflings/email.svg";
// import { ReactComponent as HalflingWifi } from "./halflings/wifi.svg";
// import { ReactComponent as HalflingCircleMore } from "./halflings/circle-more.svg";
// import { ReactComponent as HalflingOctagonRemoveEmpty } from "./halflings/octagon-remove-empty.svg";
// import { ReactComponent as HalflingFan } from "./halflings/fan.svg";
// import { ReactComponent as HalflingDropDown } from "./halflings/drop-down.svg";
// import { ReactComponent as HalflingSquareCheckAlt } from "./halflings/square-check-alt.svg";
// import { ReactComponent as HalflingMemoryCard } from "./halflings/memory-card.svg";
// import { ReactComponent as HalflingMic } from "./halflings/mic.svg";
// import { ReactComponent as HalflingReload } from "./halflings/reload.svg";
// import { ReactComponent as HalflingRefresh } from "./halflings/refresh.svg";
// import { ReactComponent as HalflingText } from "./halflings/text.svg";
// import { ReactComponent as HalflingQuotationRight } from "./halflings/quotation-right.svg";
// import { ReactComponent as HalflingSave } from "./halflings/save.svg";
// import { ReactComponent as HalflingCircleUp } from "./halflings/circle-up.svg";
// import { ReactComponent as HalflingRoundabout } from "./halflings/roundabout.svg";
// import { ReactComponent as HalflingChevronLastRight } from "./halflings/chevron-last-right.svg";
// import { ReactComponent as HalflingParagraphJustify } from "./halflings/paragraph-justify.svg";
// import { ReactComponent as HalflingSwitchOn } from "./halflings/switch-on.svg";
// import { ReactComponent as HalflingPaperclip } from "./halflings/paperclip.svg";
// import { ReactComponent as HalflingLinkRemove } from "./halflings/link-remove.svg";
// import { ReactComponent as HalflingSquareDown } from "./halflings/square-down.svg";
// import { ReactComponent as HalflingSyringe } from "./halflings/syringe.svg";
// import { ReactComponent as HalflingBatteryLow } from "./halflings/battery-low.svg";
// import { ReactComponent as HalflingSidebar } from "./halflings/sidebar.svg";
// import { ReactComponent as HalflingCrown } from "./halflings/crown.svg";
// import { ReactComponent as HalflingPlay } from "./halflings/play.svg";
// import { ReactComponent as HalflingParagraphLeft } from "./halflings/paragraph-left.svg";
// import { ReactComponent as HalflingPageBreak } from "./halflings/page-break.svg";
// import { ReactComponent as HalflingPicture } from "./halflings/picture.svg";
// import { ReactComponent as HalflingTable } from "./halflings/table.svg";
// import { ReactComponent as HalflingSend } from "./halflings/send.svg";
// import { ReactComponent as HalflingBarcode } from "./halflings/barcode.svg";
// import { ReactComponent as HalflingParagraphRight } from "./halflings/paragraph-right.svg";
// import { ReactComponent as HalflingChevronLastUp } from "./halflings/chevron-last-up.svg";
// import { ReactComponent as HalflingCamera } from "./halflings/camera.svg";
// import { ReactComponent as HalflingSquareSelected } from "./halflings/square-selected.svg";
// import { ReactComponent as HalflingCircleRight } from "./halflings/circle-right.svg";
// import { ReactComponent as HalflingRedo } from "./halflings/redo.svg";
// import { ReactComponent as HalflingChatMessage } from "./halflings/chat-message.svg";
// import { ReactComponent as HalflingTruck } from "./halflings/truck.svg";
// import { ReactComponent as HalflingSquareUp } from "./halflings/square-up.svg";
// import { ReactComponent as HalflingCompound } from "./halflings/compound.svg";
// import { ReactComponent as HalflingTrending } from "./halflings/trending.svg";
// import { ReactComponent as HalflingSquarePlayAlt } from "./halflings/square-play-alt.svg";
// import { ReactComponent as HalflingSquareRight } from "./halflings/square-right.svg";
// import { ReactComponent as HalflingMore } from "./halflings/more.svg";
// import { ReactComponent as HalflingCircleInfo } from "./halflings/circle-info.svg";
// import { ReactComponent as HalflingLine } from "./halflings/line.svg";
// import { ReactComponent as HalflingTextUnderline } from "./halflings/text-underline.svg";
// import { ReactComponent as HalflingArrowThinUp } from "./halflings/arrow-thin-up.svg";
// import { ReactComponent as HalflingLeaf } from "./halflings/leaf.svg";
// import { ReactComponent as HalflingPaste } from "./halflings/paste.svg";
// import { ReactComponent as HalflingCartIn } from "./halflings/cart-in.svg";
// import { ReactComponent as HalflingCirclePlus } from "./halflings/circle-plus.svg";
// import { ReactComponent as HalflingVolumeUp } from "./halflings/volume-up.svg";
// import { ReactComponent as HalflingStar } from "./halflings/star.svg";
// import { ReactComponent as HalflingSkipToPrevious } from "./halflings/skip-to-previous.svg";
// import { ReactComponent as HalflingCloudOff } from "./halflings/cloud-off.svg";
// import { ReactComponent as HalflingSun } from "./halflings/sun.svg";
// import { ReactComponent as HalflingWrench } from "./halflings/wrench.svg";
// import { ReactComponent as HalflingArrowThinRight } from "./halflings/arrow-thin-right.svg";
// import { ReactComponent as HalflingVectorPath } from "./halflings/vector-path.svg";
// import { ReactComponent as HalflingChevronThinLeft } from "./halflings/chevron-thin-left.svg";
// import { ReactComponent as HalflingAnchor } from "./halflings/anchor.svg";
// import { ReactComponent as HalflingOpen } from "./halflings/open.svg";
// import { ReactComponent as HalflingEducation } from "./halflings/education.svg";
// import { ReactComponent as HalflingChevronLastLeft } from "./halflings/chevron-last-left.svg";
// import { ReactComponent as HalflingSquareMinusAlt } from "./halflings/square-minus-alt.svg";
// import { ReactComponent as HalflingAdjust } from "./halflings/adjust.svg";
// import { ReactComponent as HalflingCompass } from "./halflings/compass.svg";
// import { ReactComponent as HalflingWoman } from "./halflings/woman.svg";
// import { ReactComponent as HalflingBank } from "./halflings/bank.svg";
// import { ReactComponent as HalflingVerifyCheck } from "./halflings/verify-check.svg";
// import { ReactComponent as HalflingCreditCard } from "./halflings/credit-card.svg";
// import { ReactComponent as HalflingMenuClose } from "./halflings/menu-close.svg";
// import { ReactComponent as HalflingVideoPlay } from "./halflings/video-play.svg";
// import { ReactComponent as HalflingComputer } from "./halflings/computer.svg";
// import { ReactComponent as HalflingSlightlyFrowning } from "./halflings/slightly-frowning.svg";
// import { ReactComponent as HalflingSquareTriangleRight } from "./halflings/square-triangle-right.svg";
// import { ReactComponent as HalflingUserGroup } from "./halflings/user-group.svg";
// import { ReactComponent as HalflingPowerCordPlug } from "./halflings/power-cord-plug.svg";
// import { ReactComponent as HalflingMobilePhone } from "./halflings/mobile-phone.svg";
// import { ReactComponent as HalflingClock } from "./halflings/clock.svg";
// import { ReactComponent as HalflingRetweet } from "./halflings/retweet.svg";
// import { ReactComponent as HalflingThumbnailsSmall } from "./halflings/thumbnails-small.svg";
// import { ReactComponent as HalflingSquareInfo } from "./halflings/square-info.svg";
// import { ReactComponent as HalflingAnnouncement } from "./halflings/announcement.svg";
// import { ReactComponent as HalflingCall } from "./halflings/call.svg";
// import { ReactComponent as HalflingSquareDownload } from "./halflings/square-download.svg";
// import { ReactComponent as HalflingCoffeeMug } from "./halflings/coffee-mug.svg";
// import { ReactComponent as HalflingStatsCircle } from "./halflings/stats-circle.svg";
// import { ReactComponent as HalflingSquareRemoveAlt } from "./halflings/square-remove-alt.svg";
// import { ReactComponent as HalflingSquareRemove } from "./halflings/square-remove.svg";
// import { ReactComponent as HalflingSquareUpAlt } from "./halflings/square-up-alt.svg";
// import { ReactComponent as HalflingBin } from "./halflings/bin.svg";
// import { ReactComponent as HalflingSquarePlus } from "./halflings/square-plus.svg";
// import { ReactComponent as HalflingGift } from "./halflings/gift.svg";
// import { ReactComponent as HalflingCar } from "./halflings/car.svg";
// import { ReactComponent as HalflingMoreVertical } from "./halflings/more-vertical.svg";
// import { ReactComponent as HalflingBatteryQuarter } from "./halflings/battery-quarter.svg";
// import { ReactComponent as HalflingMicOff } from "./halflings/mic-off.svg";
// import { ReactComponent as HalflingSquarePlusAlt } from "./halflings/square-plus-alt.svg";
// import { ReactComponent as HalflingArrowUp } from "./halflings/arrow-up.svg";
// import { ReactComponent as HalflingSort } from "./halflings/sort.svg";
// import { ReactComponent as HalflingTextRightToLeft } from "./halflings/text-right-to-left.svg";
// import { ReactComponent as HalflingUndo } from "./halflings/undo.svg";
// import { ReactComponent as HalflingVideo } from "./halflings/video.svg";
// import { ReactComponent as HalflingPills } from "./halflings/pills.svg";
// import { ReactComponent as HalflingFilter } from "./halflings/filter.svg";
// import { ReactComponent as HalflingIndentRight } from "./halflings/indent-right.svg";
// import { ReactComponent as HalflingSwitch } from "./halflings/switch.svg";
// import { ReactComponent as HalflingItalic } from "./halflings/italic.svg";
// import { ReactComponent as HalflingCogwheel } from "./halflings/cogwheel.svg";
// import { ReactComponent as HalflingCalendar } from "./halflings/calendar.svg";
// import { ReactComponent as HalflingGlobe } from "./halflings/globe.svg";
// import { ReactComponent as HalflingArrowLeft } from "./halflings/arrow-left.svg";
// import { ReactComponent as HalflingSquareMinus } from "./halflings/square-minus.svg";
// import { ReactComponent as HalflingMicrochip } from "./halflings/microchip.svg";
// import { ReactComponent as HalflingSquareQuestion } from "./halflings/square-question.svg";
// import { ReactComponent as HalflingDropPlus } from "./halflings/drop-plus.svg";
// import { ReactComponent as HalflingCloud } from "./halflings/cloud.svg";
// import { ReactComponent as HalflingIndentLeft } from "./halflings/indent-left.svg";
// import { ReactComponent as HalflingDice } from "./halflings/dice.svg";
// import { ReactComponent as HalflingBars } from "./halflings/bars.svg";
// import { ReactComponent as HalflingAxesThreeDimensional } from "./halflings/axes-three-dimensional.svg";
// import { ReactComponent as HalflingRewind } from "./halflings/rewind.svg";
// import { ReactComponent as HalflingReloadAlt } from "./halflings/reload-alt.svg";
// import { ReactComponent as HalflingPause } from "./halflings/pause.svg";
// import { ReactComponent as HalflingCopyDuplicate } from "./halflings/copy-duplicate.svg";
// import { ReactComponent as HalflingForward } from "./halflings/forward.svg";
// import { ReactComponent as HalflingCircleMinus } from "./halflings/circle-minus.svg";
// import { ReactComponent as HalflingBookmark } from "./halflings/bookmark.svg";
// import { ReactComponent as HalflingClean } from "./halflings/clean.svg";
// import { ReactComponent as HalflingStarEmpty } from "./halflings/star-empty.svg";
// import { ReactComponent as HalflingSquareQuestionAlt } from "./halflings/square-question-alt.svg";
// import { ReactComponent as HalflingSquareLeftAlt } from "./halflings/square-left-alt.svg";
// import { ReactComponent as HalflingBatteryCharged } from "./halflings/battery-charged.svg";
// import { ReactComponent as HalflingSlightlySmiling } from "./halflings/slightly-smiling.svg";
// import { ReactComponent as HalflingSpellCheck } from "./halflings/spell-check.svg";
// import { ReactComponent as HalflingRandom } from "./halflings/random.svg";
// import { ReactComponent as HalflingLockOpen } from "./halflings/lock-open.svg";
// import { ReactComponent as HalflingDrop } from "./halflings/drop.svg";
// import { ReactComponent as HalflingMenu } from "./halflings/menu.svg";
// import { ReactComponent as HalflingHeader } from "./halflings/header.svg";
// import { ReactComponent as HalflingAirplane } from "./halflings/airplane.svg";
// import { ReactComponent as HalflingStats } from "./halflings/stats.svg";
// import { ReactComponent as HalflingShieldCheck } from "./halflings/shield-check.svg";
// import { ReactComponent as HalflingMan } from "./halflings/man.svg";
// import { ReactComponent as HalflingCircleDown } from "./halflings/circle-down.svg";
// import { ReactComponent as HalflingBuilding } from "./halflings/building.svg";
// import { ReactComponent as HalflingFolder } from "./halflings/folder.svg";
// import { ReactComponent as HalflingVerifyEmpty } from "./halflings/verify-empty.svg";
// import { ReactComponent as HalflingBasket } from "./halflings/basket.svg";
// import { ReactComponent as HalflingFingerprint } from "./halflings/fingerprint.svg";
// import { ReactComponent as HalflingPulse } from "./halflings/pulse.svg";
// import { ReactComponent as HalflingSquareDownAlt } from "./halflings/square-down-alt.svg";
// import { ReactComponent as HalflingMinus } from "./halflings/minus.svg";
// import { ReactComponent as HalflingTimes } from "./halflings/times.svg";
// import { ReactComponent as HalflingFullscreenOff } from "./halflings/fullscreen-off.svg";
// import { ReactComponent as HalflingChevronLeft } from "./halflings/chevron-left.svg";
// import { ReactComponent as HalflingPrint } from "./halflings/print.svg";
// import { ReactComponent as HalflingMoon } from "./halflings/moon.svg";
// import { ReactComponent as HalflingSquareTriangleLeft } from "./halflings/square-triangle-left.svg";
// import { ReactComponent as HalflingSquareUpload } from "./halflings/square-upload.svg";
// import { ReactComponent as HalflingThumbnails } from "./halflings/thumbnails.svg";
// import { ReactComponent as HalflingSet } from "./halflings/set.svg";
// import { ReactComponent as HalflingArrowThinDown } from "./halflings/arrow-thin-down.svg";
// import { ReactComponent as HalflingSkipToStart } from "./halflings/skip-to-start.svg";
// import { ReactComponent as HalflingHeart } from "./halflings/heart.svg";
// import { ReactComponent as HalflingSetDown } from "./halflings/set-down.svg";
// import { ReactComponent as HalflingRulers } from "./halflings/rulers.svg";
//
// Halflings End
//
// =============================================================================

// =============================================================================
//
// Social Start
//
import { ReactComponent as SocialMedium } from "./social/medium.svg";
// import { ReactComponent as SocialSteam } from "./social/steam.svg";
// import { ReactComponent as SocialTumblr } from "./social/tumblr.svg";
// import { ReactComponent as SocialTripadvisor } from "./social/tripadvisor.svg";
// import { ReactComponent as SocialDropbox } from "./social/dropbox.svg";
// import { ReactComponent as SocialSquarespace } from "./social/squarespace.svg";
// import { ReactComponent as SocialYelp } from "./social/yelp.svg";
// import { ReactComponent as SocialAirbnb } from "./social/airbnb.svg";
// import { ReactComponent as SocialLinux } from "./social/linux.svg";
// import { ReactComponent as SocialFoursquare } from "./social/foursquare.svg";
// import { ReactComponent as SocialAndroid } from "./social/android.svg";
// import { ReactComponent as SocialTinder } from "./social/tinder.svg";
// import { ReactComponent as SocialViber } from "./social/viber.svg";
// import { ReactComponent as SocialGithub } from "./social/github.svg";
// import { ReactComponent as SocialEtsy } from "./social/etsy.svg";
// import { ReactComponent as SocialMyspace } from "./social/myspace.svg";
// import { ReactComponent as SocialWikipedia } from "./social/wikipedia.svg";
// import { ReactComponent as SocialVk } from "./social/vk.svg";
// import { ReactComponent as SocialVimeo } from "./social/vimeo.svg";
// import { ReactComponent as SocialInstagram } from "./social/instagram.svg";
// import { ReactComponent as SocialZoom } from "./social/zoom.svg";
// import { ReactComponent as SocialBlogger } from "./social/blogger.svg";
// import { ReactComponent as SocialSpotify } from "./social/spotify.svg";
// import { ReactComponent as SocialPeriscope } from "./social/periscope.svg";
// import { ReactComponent as SocialKickstarter } from "./social/kickstarter.svg";
// import { ReactComponent as SocialSnapchat } from "./social/snapchat.svg";
// import { ReactComponent as SocialQuora } from "./social/quora.svg";
// import { ReactComponent as SocialPlaystation } from "./social/playstation.svg";
// import { ReactComponent as SocialFacebook } from "./social/facebook.svg";
// import { ReactComponent as SocialPinterest } from "./social/pinterest.svg";
// import { ReactComponent as SocialStackOverflow } from "./social/stack-overflow.svg";
// import { ReactComponent as SocialWhatsapp } from "./social/whatsapp.svg";
// import { ReactComponent as SocialStackExchange } from "./social/stack-exchange.svg";
// import { ReactComponent as SocialXing } from "./social/xing.svg";
// import { ReactComponent as SocialWindows } from "./social/windows.svg";
// import { ReactComponent as SocialYoutube } from "./social/youtube.svg";
// import { ReactComponent as SocialReddit } from "./social/reddit.svg";
// import { ReactComponent as SocialEvernote } from "./social/evernote.svg";
// import { ReactComponent as SocialDribbble } from "./social/dribbble.svg";
// import { ReactComponent as SocialXbox } from "./social/xbox.svg";
// import { ReactComponent as SocialBadoo } from "./social/badoo.svg";
// import { ReactComponent as SocialLinkedin } from "./social/linkedin.svg";
// import { ReactComponent as SocialTwitter } from "./social/twitter.svg";
// import { ReactComponent as SocialPatreon } from "./social/patreon.svg";
// import { ReactComponent as SocialDiscord } from "./social/discord.svg";
// import { ReactComponent as SocialSlack } from "./social/slack.svg";
// import { ReactComponent as SocialSoundcloud } from "./social/soundcloud.svg";
// import { ReactComponent as SocialSkype } from "./social/skype.svg";
// import { ReactComponent as SocialWordpress } from "./social/wordpress.svg";
// import { ReactComponent as SocialApple } from "./social/apple.svg";
// import { ReactComponent as SocialNetflix } from "./social/netflix.svg";
// import { ReactComponent as SocialCreativeCloud } from "./social/creative-cloud.svg";
// import { ReactComponent as SocialBehance } from "./social/behance.svg";
//
// Social End
//
// =============================================================================

// =============================================================================
//
// Filetype Start
//
import { ReactComponent as FiletypeFile } from "./filetypes/file.svg";
// import { ReactComponent as FiletypeFileNo } from "./filetypes/file-no.svg";
// import { ReactComponent as FiletypeFileSearch } from "./filetypes/file-search.svg";
// import { ReactComponent as FiletypeFileKey } from "./filetypes/file-key.svg";
// import { ReactComponent as FiletypeFileEdit } from "./filetypes/file-edit.svg";
// import { ReactComponent as FiletypeFolderVisible } from "./filetypes/folder-visible.svg";
// import { ReactComponent as FiletypeFileZip } from "./filetypes/file-zip.svg";
// import { ReactComponent as FiletypeFolderBookmark } from "./filetypes/folder-bookmark.svg";
// import { ReactComponent as FiletypeFolderLibrary } from "./filetypes/folder-library.svg";
// import { ReactComponent as FiletypeFileHeart } from "./filetypes/file-heart.svg";
// import { ReactComponent as FiletypeFilePhoto } from "./filetypes/file-photo.svg";
// import { ReactComponent as FiletypeFileVisible } from "./filetypes/file-visible.svg";
// import { ReactComponent as FiletypeFilePulse } from "./filetypes/file-pulse.svg";
// import { ReactComponent as FiletypeFolderCircleUp } from "./filetypes/folder-circle-up.svg";
// import { ReactComponent as FiletypeFileMinus } from "./filetypes/file-minus.svg";
// import { ReactComponent as FiletypeFileStats } from "./filetypes/file-stats.svg";
// import { ReactComponent as FiletypeFolderCheck } from "./filetypes/folder-check.svg";
// import { ReactComponent as FiletypeFileVector } from "./filetypes/file-vector.svg";
// import { ReactComponent as FiletypeFilePlugin } from "./filetypes/file-plugin.svg";
// import { ReactComponent as FiletypeFileAudio } from "./filetypes/file-audio.svg";
// import { ReactComponent as FiletypeFolderBurnableAlt } from "./filetypes/folder-burnable-alt.svg";
// import { ReactComponent as FiletypeFileCloud } from "./filetypes/file-cloud.svg";
// import { ReactComponent as FiletypeFileBookmark } from "./filetypes/file-bookmark.svg";
// import { ReactComponent as FiletypeFolderFavorites } from "./filetypes/folder-favorites.svg";
// import { ReactComponent as FiletypeFolderRemove } from "./filetypes/folder-remove.svg";
// import { ReactComponent as FiletypeFolderBurnable } from "./filetypes/folder-burnable.svg";
// import { ReactComponent as FiletypeFileVideo } from "./filetypes/file-video.svg";
// import { ReactComponent as FiletypeFileText } from "./filetypes/file-text.svg";
// import { ReactComponent as FiletypeFolderOpen } from "./filetypes/folder-open.svg";
// import { ReactComponent as FiletypeFolderNetworkOff } from "./filetypes/folder-network-off.svg";
// import { ReactComponent as FiletypeFolderEditing } from "./filetypes/folder-editing.svg";
// import { ReactComponent as FiletypeFolderEdit } from "./filetypes/folder-edit.svg";
// import { ReactComponent as FiletypeFolderAlert } from "./filetypes/folder-alert.svg";
// import { ReactComponent as FiletypeFolderMusic } from "./filetypes/folder-music.svg";
// import { ReactComponent as FiletypeFileFont } from "./filetypes/file-font.svg";
// import { ReactComponent as FiletypeFolderUploads } from "./filetypes/folder-uploads.svg";
// import { ReactComponent as FiletypeFileSpreadsheet } from "./filetypes/file-spreadsheet.svg";
// import { ReactComponent as FiletypeFolderGames } from "./filetypes/folder-games.svg";
// import { ReactComponent as FiletypeFileEditing } from "./filetypes/file-editing.svg";
// import { ReactComponent as FiletypeFolderUnlock } from "./filetypes/folder-unlock.svg";
// import { ReactComponent as FiletypeFolderApplications } from "./filetypes/folder-applications.svg";
// import { ReactComponent as FiletypeFileRichText } from "./filetypes/file-rich-text.svg";
// import { ReactComponent as FiletypeFileShield } from "./filetypes/file-shield.svg";
// import { ReactComponent as FiletypeFileTag } from "./filetypes/file-tag.svg";
// import { ReactComponent as FiletypeFolderArchive } from "./filetypes/folder-archive.svg";
// import { ReactComponent as FiletypeFilePackage } from "./filetypes/file-package.svg";
// import { ReactComponent as FiletypeFileImage } from "./filetypes/file-image.svg";
// import { ReactComponent as FiletypeFolderDesktop } from "./filetypes/folder-desktop.svg";
// import { ReactComponent as FiletypeFolderDocuments } from "./filetypes/folder-documents.svg";
// import { ReactComponent as FiletypeFolderSmart } from "./filetypes/folder-smart.svg";
// import { ReactComponent as FiletypeFolderInvisible } from "./filetypes/folder-invisible.svg";
// import { ReactComponent as FiletypeFolderPlus } from "./filetypes/folder-plus.svg";
// import { ReactComponent as FiletypeFileSettings } from "./filetypes/file-settings.svg";
// import { ReactComponent as FiletypeFolderTag } from "./filetypes/folder-tag.svg";
// import { ReactComponent as FiletypeFileWeb } from "./filetypes/file-web.svg";
// import { ReactComponent as FiletypeFolderInfo } from "./filetypes/folder-info.svg";
// import { ReactComponent as FiletypeFileIncoming } from "./filetypes/file-incoming.svg";
// import { ReactComponent as FiletypeFileCad } from "./filetypes/file-cad.svg";
// import { ReactComponent as FiletypeFolderPhoto } from "./filetypes/folder-photo.svg";
// import { ReactComponent as FiletypeFileQuestion } from "./filetypes/file-question.svg";
// import { ReactComponent as FiletypeFolderHeart } from "./filetypes/folder-heart.svg";
// import { ReactComponent as FiletypeFolderCircleDown } from "./filetypes/folder-circle-down.svg";
// import { ReactComponent as FiletypeFileCheck } from "./filetypes/file-check.svg";
// import { ReactComponent as FiletypeFolderShared } from "./filetypes/folder-shared.svg";
// import { ReactComponent as FiletypeFolderNetwork } from "./filetypes/folder-network.svg";
// import { ReactComponent as FiletypeFileProgram } from "./filetypes/file-program.svg";
// import { ReactComponent as FiletypeFolderNo } from "./filetypes/folder-no.svg";
// import { ReactComponent as FiletypeFolderUsers } from "./filetypes/folder-users.svg";
// import { ReactComponent as FiletypeFolderMinus } from "./filetypes/folder-minus.svg";
// import { ReactComponent as FiletypeFileIncomplete } from "./filetypes/file-incomplete.svg";
// import { ReactComponent as FiletypeFolderQuestion } from "./filetypes/folder-question.svg";
// import { ReactComponent as FiletypeFolderClock } from "./filetypes/folder-clock.svg";
// import { ReactComponent as FiletypeFileInvisible } from "./filetypes/file-invisible.svg";
// import { ReactComponent as FiletypeFolderLock } from "./filetypes/folder-lock.svg";
// import { ReactComponent as FiletypeFolderCloud } from "./filetypes/folder-cloud.svg";
// import { ReactComponent as FiletypeFolderSettings } from "./filetypes/folder-settings.svg";
// import { ReactComponent as FiletypeFolderVideo } from "./filetypes/folder-video.svg";
// import { ReactComponent as FiletypeFolderDownloads } from "./filetypes/folder-downloads.svg";
// import { ReactComponent as FiletypeFolderShield } from "./filetypes/folder-shield.svg";
// import { ReactComponent as FiletypeFileUnlock } from "./filetypes/file-unlock.svg";
// import { ReactComponent as FiletypeFileDiscImage } from "./filetypes/file-disc-image.svg";
// import { ReactComponent as FiletypeFilePlus } from "./filetypes/file-plus.svg";
// import { ReactComponent as FiletypeFileBroken } from "./filetypes/file-broken.svg";
// import { ReactComponent as FiletypeFileInfo } from "./filetypes/file-info.svg";
// import { ReactComponent as FiletypeFolderPlugin } from "./filetypes/folder-plugin.svg";
// import { ReactComponent as FiletypeFileAlert } from "./filetypes/file-alert.svg";
// import { ReactComponent as FiletypeFileMusic } from "./filetypes/file-music.svg";
// import { ReactComponent as FiletypeFileRemove } from "./filetypes/file-remove.svg";
// import { ReactComponent as FiletypeFileScript } from "./filetypes/file-script.svg";
// import { ReactComponent as FiletypeFileDatabase } from "./filetypes/file-database.svg";
// import { ReactComponent as FiletypeFolderImage } from "./filetypes/folder-image.svg";
// import { ReactComponent as FiletypeFolderKey } from "./filetypes/folder-key.svg";
// import { ReactComponent as FiletypeFolderSearch } from "./filetypes/folder-search.svg";
// import { ReactComponent as FiletypeFolderHome } from "./filetypes/folder-home.svg";
// import { ReactComponent as FiletypeFileTerminal } from "./filetypes/file-terminal.svg";
// import { ReactComponent as FiletypeFileCss } from "./filetypes/file-css.svg";
// import { ReactComponent as FiletypeFolderUser } from "./filetypes/folder-user.svg";
// import { ReactComponent as FiletypeFolder } from "./filetypes/folder.svg";
// import { ReactComponent as FiletypeFileLock } from "./filetypes/file-lock.svg";
// import { ReactComponent as FiletypeFilePresentation } from "./filetypes/file-presentation.svg";
//
// Filetype End
//
// =============================================================================

const library = [
  // =============================================================================
  //
  // Base Start
  //
  { name: "base-menu", component: BaseMenu },
  { name: "base-package", component: BasePackage },
  { name: "base-robot", component: BaseRobot },
  { name: "base-circle-remove", component: BaseCircleRemove },
  // { name: "base-access-point", component: BaseAccessPoint },
  // { name: "base-adjust", component: BaseAdjust },
  // { name: "base-airplane", component: BaseAirplane },
  // { name: "base-alarm", component: BaseAlarm },
  // { name: "base-align-bottom", component: BaseAlignBottom },
  // { name: "base-align-horizontal", component: BaseAlignHorizontal },
  // { name: "base-align-left", component: BaseAlignLeft },
  // { name: "base-align-right", component: BaseAlignRight },
  // { name: "base-align-top", component: BaseAlignTop },
  // { name: "base-align-vertical", component: BaseAlignVertical },
  // { name: "base-ambulance", component: BaseAmbulance },
  // { name: "base-anchor", component: BaseAnchor },
  // { name: "base-announcement", component: BaseAnnouncement },
  // { name: "base-apple", component: BaseApple },
  // { name: "base-archive", component: BaseArchive },
  // { name: "base-arrow-down", component: BaseArrowDown },
  // { name: "base-arrow-left", component: BaseArrowLeft },
  // { name: "base-arrow-right", component: BaseArrowRight },
  // { name: "base-arrow-thin-down", component: BaseArrowThinDown },
  // { name: "base-arrow-thin-left", component: BaseArrowThinLeft },
  // { name: "base-arrow-thin-right", component: BaseArrowThinRight },
  // { name: "base-arrow-thin-up", component: BaseArrowThinUp },
  // { name: "base-arrow-up", component: BaseArrowUp },
  // { name: "base-artificial-intelligence", component: BaseArtificialIntelligence },
  // { name: "base-asian-food", component: BaseAsianFood },
  // { name: "base-audio-description", component: BaseAudioDescription },
  // { name: "base-audio-description-d", component: BaseAudioDescriptionD },
  // { name: "base-ax", component: BaseAx },
  // { name: "base-axes-three-dimensional", component: BaseAxesThreeDimensional },
  // { name: "base-axes-two-dimensional", component: BaseAxesTwoDimensional },
  // { name: "base-backpack", component: BaseBackpack },
  // { name: "base-badminton", component: BaseBadminton },
  // { name: "base-bag", component: BaseBag },
  // { name: "base-balance-scales", component: BaseBalanceScales },
  // { name: "base-balloon", component: BaseBalloon },
  // { name: "base-balloons", component: BaseBalloons },
  // { name: "base-bank", component: BaseBank },
  // { name: "base-banknotes", component: BaseBanknotes },
  // { name: "base-barbed-wire", component: BaseBarbedWire },
  // { name: "base-barcode", component: BaseBarcode },
  // { name: "base-barrel", component: BaseBarrel },
  // { name: "base-bars", component: BaseBars },
  // { name: "base-baseball", component: BaseBaseball },
  // { name: "base-basket", component: BaseBasket },
  // { name: "base-basket-check", component: BaseBasketCheck },
  // { name: "base-basket-checkout", component: BaseBasketCheckout },
  // { name: "base-basket-in", component: BaseBasketIn },
  // { name: "base-basket-out", component: BaseBasketOut },
  // { name: "base-basketball", component: BaseBasketball },
  // { name: "base-bath", component: BaseBath },
  // { name: "base-battery-charged", component: BaseBatteryCharged },
  // { name: "base-battery-charging", component: BaseBatteryCharging },
  // { name: "base-battery-half", component: BaseBatteryHalf },
  // { name: "base-battery-low", component: BaseBatteryLow },
  // { name: "base-battery-quarter", component: BaseBatteryQuarter },
  // { name: "base-battery-three-quarters", component: BaseBatteryThreeQuarters },
  // { name: "base-beach-umbrella", component: BaseBeachUmbrella },
  // { name: "base-bed", component: BaseBed },
  // { name: "base-bed-single", component: BaseBedSingle },
  // { name: "base-bed-sleeping", component: BaseBedSleeping },
  // { name: "base-beer", component: BaseBeer },
  // { name: "base-beer-alt", component: BaseBeerAlt },
  // { name: "base-bell", component: BaseBell },
  // { name: "base-bell-off", component: BaseBellOff },
  // { name: "base-bell-ringing", component: BaseBellRinging },
  // { name: "base-bicycle", component: BaseBicycle },
  // { name: "base-bin", component: BaseBin },
  // { name: "base-binoculars", component: BaseBinoculars },
  // { name: "base-biohazard", component: BaseBiohazard },
  // { name: "base-bishop", component: BaseBishop },
  // { name: "base-bitcoin", component: BaseBitcoin },
  // { name: "base-blackboard", component: BaseBlackboard },
  // { name: "base-blackboard-off", component: BaseBlackboardOff },
  // { name: "base-blacksmith", component: BaseBlacksmith },
  // { name: "base-blender", component: BaseBlender },
  // { name: "base-block-move", component: BaseBlockMove },
  // { name: "base-boat", component: BaseBoat },
  // { name: "base-bold", component: BaseBold },
  // { name: "base-bomb", component: BaseBomb },
  // { name: "base-book", component: BaseBook },
  // { name: "base-book-address", component: BaseBookAddress },
  // { name: "base-book-library", component: BaseBookLibrary },
  // { name: "base-book-library-search", component: BaseBookLibrarySearch },
  // { name: "base-book-log", component: BaseBookLog },
  // { name: "base-book-open", component: BaseBookOpen },
  // { name: "base-book-open-text", component: BaseBookOpenText },
  // { name: "base-book-play", component: BaseBookPlay },
  // { name: "base-bookmark", component: BaseBookmark },
  // { name: "base-bowling", component: BaseBowling },
  // { name: "base-brain", component: BaseBrain },
  // { name: "base-brain-ai", component: BaseBrainAi },
  // { name: "base-brick-wall", component: BaseBrickWall },
  // { name: "base-briefcase", component: BaseBriefcase },
  // { name: "base-briefcase-alt", component: BaseBriefcaseAlt },
  // { name: "base-brightness-down", component: BaseBrightnessDown },
  // { name: "base-brightness-up", component: BaseBrightnessUp },
  // { name: "base-bring-forward", component: BaseBringForward },
  // { name: "base-bring-to-front", component: BaseBringToFront },
  // { name: "base-brush", component: BaseBrush },
  // { name: "base-bug", component: BaseBug },
  // { name: "base-building", component: BaseBuilding },
  // { name: "base-buildings-city", component: BaseBuildingsCity },
  // { name: "base-buildings-mixed", component: BaseBuildingsMixed },
  // { name: "base-buildings-park", component: BaseBuildingsPark },
  // { name: "base-bullets", component: BaseBullets },
  // { name: "base-buoy", component: BaseBuoy },
  // { name: "base-bus", component: BaseBus },
  // { name: "base-cableway", component: BaseCableway },
  // { name: "base-cake", component: BaseCake },
  // { name: "base-calculator", component: BaseCalculator },
  // { name: "base-calendar", component: BaseCalendar },
  // { name: "base-call", component: BaseCall },
  // { name: "base-call-data", component: BaseCallData },
  // { name: "base-call-incoming", component: BaseCallIncoming },
  // { name: "base-call-ip", component: BaseCallIp },
  // { name: "base-call-lock", component: BaseCallLock },
  // { name: "base-call-music", component: BaseCallMusic },
  // { name: "base-call-outgoing", component: BaseCallOutgoing },
  // { name: "base-call-pause", component: BaseCallPause },
  // { name: "base-call-record", component: BaseCallRecord },
  // { name: "base-call-redirect", component: BaseCallRedirect },
  // { name: "base-call-video", component: BaseCallVideo },
  // { name: "base-camera", component: BaseCamera },
  // { name: "base-campfire", component: BaseCampfire },
  // { name: "base-candle", component: BaseCandle },
  // { name: "base-canister", component: BaseCanister },
  // { name: "base-car", component: BaseCar },
  // { name: "base-car-battery", component: BaseCarBattery },
  // { name: "base-car-rental", component: BaseCarRental },
  // { name: "base-car-wheel", component: BaseCarWheel },
  // { name: "base-car-wheel-defect", component: BaseCarWheelDefect },
  // { name: "base-car-wheel-wrench", component: BaseCarWheelWrench },
  // { name: "base-car-wheel-wrench-alt", component: BaseCarWheelWrenchAlt },
  // { name: "base-card-clubs", component: BaseCardClubs },
  // { name: "base-card-diamonds", component: BaseCardDiamonds },
  // { name: "base-card-hearts", component: BaseCardHearts },
  // { name: "base-card-spades", component: BaseCardSpades },
  // { name: "base-carrot", component: BaseCarrot },
  // { name: "base-cars", component: BaseCars },
  // { name: "base-cart", component: BaseCart },
  // { name: "base-cart-check", component: BaseCartCheck },
  // { name: "base-cart-checkout", component: BaseCartCheckout },
  // { name: "base-cart-in", component: BaseCartIn },
  // { name: "base-cart-off", component: BaseCartOff },
  // { name: "base-cart-out", component: BaseCartOut },
  // { name: "base-cash-register", component: BaseCashRegister },
  // { name: "base-cash-register-alt", component: BaseCashRegisterAlt },
  // { name: "base-cat", component: BaseCat },
  // { name: "base-cd", component: BaseCd },
  // { name: "base-celeriac", component: BaseCeleriac },
  // { name: "base-celery", component: BaseCelery },
  // { name: "base-certificate", component: BaseCertificate },
  // { name: "base-charging-station", component: BaseChargingStation },
  // { name: "base-chat-conversation", component: BaseChatConversation },
  // { name: "base-chat-conversation-lock", component: BaseChatConversationLock },
  // { name: "base-chat-conversation-off", component: BaseChatConversationOff },
  // { name: "base-chat-message", component: BaseChatMessage },
  // { name: "base-chat-message-check", component: BaseChatMessageCheck },
  // { name: "base-chat-message-lock", component: BaseChatMessageLock },
  // { name: "base-check", component: BaseCheck },
  // { name: "base-chemistry", component: BaseChemistry },
  // { name: "base-chemistry-alt", component: BaseChemistryAlt },
  // { name: "base-cheque", component: BaseCheque },
  // { name: "base-cheque-off", component: BaseChequeOff },
  // { name: "base-chevron-down", component: BaseChevronDown },
  // { name: "base-chevron-last-down", component: BaseChevronLastDown },
  // { name: "base-chevron-last-left", component: BaseChevronLastLeft },
  // { name: "base-chevron-last-right", component: BaseChevronLastRight },
  // { name: "base-chevron-last-up", component: BaseChevronLastUp },
  // { name: "base-chevron-left", component: BaseChevronLeft },
  // { name: "base-chevron-right", component: BaseChevronRight },
  // { name: "base-chevron-up", component: BaseChevronUp },
  // { name: "base-circle-alert", component: BaseCircleAlert },
  // { name: "base-circle-check", component: BaseCircleCheck },
  // { name: "base-circle-down", component: BaseCircleDown },
  // { name: "base-circle-empty", component: BaseCircleEmpty },
  // { name: "base-circle-empty-alert", component: BaseCircleEmptyAlert },
  // { name: "base-circle-empty-check", component: BaseCircleEmptyCheck },
  // { name: "base-circle-empty-down", component: BaseCircleEmptyDown },
  // { name: "base-circle-empty-info", component: BaseCircleEmptyInfo },
  // { name: "base-circle-empty-left", component: BaseCircleEmptyLeft },
  // { name: "base-circle-empty-minus", component: BaseCircleEmptyMinus },
  // { name: "base-circle-empty-play", component: BaseCircleEmptyPlay },
  // { name: "base-circle-empty-plus", component: BaseCircleEmptyPlus },
  // { name: "base-circle-empty-question", component: BaseCircleEmptyQuestion },
  // { name: "base-circle-empty-remove", component: BaseCircleEmptyRemove },
  // { name: "base-circle-empty-right", component: BaseCircleEmptyRight },
  // { name: "base-circle-empty-up", component: BaseCircleEmptyUp },
  // { name: "base-circle-hospital", component: BaseCircleHospital },
  // { name: "base-circle-info", component: BaseCircleInfo },
  // { name: "base-circle-left", component: BaseCircleLeft },
  // { name: "base-circle-menu", component: BaseCircleMenu },
  // { name: "base-circle-minus", component: BaseCircleMinus },
  // { name: "base-circle-more", component: BaseCircleMore },
  // { name: "base-circle-play", component: BaseCirclePlay },
  // { name: "base-circle-plus", component: BaseCirclePlus },
  // { name: "base-circle-question", component: BaseCircleQuestion },
  // { name: "base-circle-red-cross", component: BaseCircleRedCross },
  // { name: "base-circle-right", component: BaseCircleRight },
  // { name: "base-circle-selected", component: BaseCircleSelected },
  // { name: "base-circle-up", component: BaseCircleUp },
  // { name: "base-claw-hammer", component: BaseClawHammer },
  // { name: "base-clean", component: BaseClean },
  // { name: "base-clipboard", component: BaseClipboard },
  // { name: "base-clock", component: BaseClock },
  // { name: "base-cloud", component: BaseCloud },
  // { name: "base-cloud-alert", component: BaseCloudAlert },
  // { name: "base-cloud-check", component: BaseCloudCheck },
  // { name: "base-cloud-download", component: BaseCloudDownload },
  // { name: "base-cloud-off", component: BaseCloudOff },
  // { name: "base-cloud-pause", component: BaseCloudPause },
  // { name: "base-cloud-refresh", component: BaseCloudRefresh },
  // { name: "base-cloud-upload", component: BaseCloudUpload },
  // { name: "base-cluster", component: BaseCluster },
  // { name: "base-code", component: BaseCode },
  // { name: "base-coffee-beans", component: BaseCoffeeBeans },
  // { name: "base-coffee-mug", component: BaseCoffeeMug },
  // { name: "base-coffee-to-go", component: BaseCoffeeToGo },
  // { name: "base-cogwheel", component: BaseCogwheel },
  // { name: "base-cogwheels", component: BaseCogwheels },
  // { name: "base-coins", component: BaseCoins },
  // { name: "base-comments", component: BaseComments },
  // { name: "base-comments-lock", component: BaseCommentsLock },
  // { name: "base-comments-off", component: BaseCommentsOff },
  // { name: "base-compass", component: BaseCompass },
  // { name: "base-compound", component: BaseCompound },
  // { name: "base-computer", component: BaseComputer },
  // { name: "base-computer-all-in-one", component: BaseComputerAllInOne },
  // { name: "base-computer-network", component: BaseComputerNetwork },
  // { name: "base-computer-network-alt", component: BaseComputerNetworkAlt },
  // { name: "base-computer-pc-tower", component: BaseComputerPcTower },
  // { name: "base-construction-cone", component: BaseConstructionCone },
  // { name: "base-contactless", component: BaseContactless },
  // { name: "base-contactless-lock", component: BaseContactlessLock },
  // { name: "base-contrast", component: BaseContrast },
  // { name: "base-cookie", component: BaseCookie },
  // { name: "base-cookie-alt", component: BaseCookieAlt },
  // { name: "base-cookies", component: BaseCookies },
  // { name: "base-cooks-hat", component: BaseCooksHat },
  // { name: "base-copy", component: BaseCopy },
  // { name: "base-copy-duplicate", component: BaseCopyDuplicate },
  // { name: "base-corn", component: BaseCorn },
  // { name: "base-correction-auto", component: BaseCorrectionAuto },
  // { name: "base-correction-skewed", component: BaseCorrectionSkewed },
  // { name: "base-correction-skewed-alt", component: BaseCorrectionSkewedAlt },
  // { name: "base-correction-tilted", component: BaseCorrectionTilted },
  // { name: "base-cot", component: BaseCot },
  // { name: "base-court-gavel", component: BaseCourtGavel },
  // { name: "base-court-gavel-alt", component: BaseCourtGavelAlt },
  // { name: "base-credit", component: BaseCredit },
  // { name: "base-credit-card", component: BaseCreditCard },
  // { name: "base-credit-card-off", component: BaseCreditCardOff },
  // { name: "base-credit-empty", component: BaseCreditEmpty },
  // { name: "base-crop", component: BaseCrop },
  // { name: "base-crown", component: BaseCrown },
  // { name: "base-cup", component: BaseCup },
  // { name: "base-cursor", component: BaseCursor },
  // { name: "base-curves", component: BaseCurves },
  // { name: "base-cutlery", component: BaseCutlery },
  // { name: "base-dashboard", component: BaseDashboard },
  // { name: "base-database", component: BaseDatabase },
  // { name: "base-database-minus", component: BaseDatabaseMinus },
  // { name: "base-database-plus", component: BaseDatabasePlus },
  // { name: "base-database-search", component: BaseDatabaseSearch },
  // { name: "base-diamond", component: BaseDiamond },
  // { name: "base-dice", component: BaseDice },
  // { name: "base-dice-five", component: BaseDiceFive },
  // { name: "base-dice-four", component: BaseDiceFour },
  // { name: "base-dice-one", component: BaseDiceOne },
  // { name: "base-dice-six", component: BaseDiceSix },
  // { name: "base-dice-three", component: BaseDiceThree },
  // { name: "base-dice-two", component: BaseDiceTwo },
  // { name: "base-direction", component: BaseDirection },
  // { name: "base-direction-empty", component: BaseDirectionEmpty },
  // { name: "base-direction-full", component: BaseDirectionFull },
  // { name: "base-directions", component: BaseDirections },
  // { name: "base-directions-arrow", component: BaseDirectionsArrow },
  // { name: "base-directions-sign", component: BaseDirectionsSign },
  // { name: "base-disappearing", component: BaseDisappearing },
  // { name: "base-dishwasher", component: BaseDishwasher },
  // { name: "base-disinfection-soap", component: BaseDisinfectionSoap },
  // { name: "base-disinfection-spray", component: BaseDisinfectionSpray },
  // { name: "base-disinfection-spray-small", component: BaseDisinfectionSpraySmall },
  // { name: "base-diving-free", component: BaseDivingFree },
  // { name: "base-diving-scuba", component: BaseDivingScuba },
  // { name: "base-division", component: BaseDivision },
  // { name: "base-dna", component: BaseDna },
  // { name: "base-dock", component: BaseDock },
  // { name: "base-dock-left", component: BaseDockLeft },
  // { name: "base-dock-right", component: BaseDockRight },
  // { name: "base-dog", component: BaseDog },
  // { name: "base-dog-tags", component: BaseDogTags },
  // { name: "base-dollar", component: BaseDollar },
  // { name: "base-door", component: BaseDoor },
  // { name: "base-door-close", component: BaseDoorClose },
  // { name: "base-door-handle", component: BaseDoorHandle },
  // { name: "base-door-open", component: BaseDoorOpen },
  // { name: "base-dress", component: BaseDress },
  // { name: "base-drink", component: BaseDrink },
  // { name: "base-drinking-water", component: BaseDrinkingWater },
  // { name: "base-drinking-water-off", component: BaseDrinkingWaterOff },
  // { name: "base-drop", component: BaseDrop },
  // { name: "base-drop-down", component: BaseDropDown },
  // { name: "base-drop-off", component: BaseDropOff },
  // { name: "base-drop-plus", component: BaseDropPlus },
  // { name: "base-dryer", component: BaseDryer },
  // { name: "base-dumbbell", component: BaseDumbbell },
  // { name: "base-dumbbell-off", component: BaseDumbbellOff },
  // { name: "base-dustbin", component: BaseDustbin },
  // { name: "base-ear", component: BaseEar },
  // { name: "base-ear-off", component: BaseEarOff },
  // { name: "base-earphones", component: BaseEarphones },
  // { name: "base-education", component: BaseEducation },
  // { name: "base-egg", component: BaseEgg },
  // { name: "base-eject", component: BaseEject },
  // { name: "base-electric-cord", component: BaseElectricCord },
  // { name: "base-email", component: BaseEmail },
  // { name: "base-envelope", component: BaseEnvelope },
  // { name: "base-envelope-down", component: BaseEnvelopeDown },
  // { name: "base-envelope-empty", component: BaseEnvelopeEmpty },
  // { name: "base-envelope-flag", component: BaseEnvelopeFlag },
  // { name: "base-envelope-full", component: BaseEnvelopeFull },
  // { name: "base-envelope-lock", component: BaseEnvelopeLock },
  // { name: "base-envelope-minus", component: BaseEnvelopeMinus },
  // { name: "base-envelope-no", component: BaseEnvelopeNo },
  // { name: "base-envelope-plus", component: BaseEnvelopePlus },
  // { name: "base-envelope-star", component: BaseEnvelopeStar },
  // { name: "base-envelope-up", component: BaseEnvelopeUp },
  // { name: "base-equalizer-bars", component: BaseEqualizerBars },
  // { name: "base-equalizer-dots", component: BaseEqualizerDots },
  // { name: "base-equals", component: BaseEquals },
  // { name: "base-eraser", component: BaseEraser },
  // { name: "base-euro", component: BaseEuro },
  // { name: "base-exchange", component: BaseExchange },
  // { name: "base-export", component: BaseExport },
  // { name: "base-eye", component: BaseEye },
  // { name: "base-eye-off", component: BaseEyeOff },
  // { name: "base-eyedropper", component: BaseEyedropper },
  // { name: "base-fabric", component: BaseFabric },
  // { name: "base-face-mask-gas-full", component: BaseFaceMaskGasFull },
  // { name: "base-face-scan", component: BaseFaceScan },
  // { name: "base-factory", component: BaseFactory },
  // { name: "base-factory-alt", component: BaseFactoryAlt },
  // { name: "base-factory-off", component: BaseFactoryOff },
  // { name: "base-fan", component: BaseFan },
  // { name: "base-fast-food", component: BaseFastFood },
  // { name: "base-fax", component: BaseFax },
  // { name: "base-feather", component: BaseFeather },
  // { name: "base-female", component: BaseFemale },
  // { name: "base-fence", component: BaseFence },
  // { name: "base-file", component: BaseFile },
  // { name: "base-file-cloud", component: BaseFileCloud },
  // { name: "base-file-lock", component: BaseFileLock },
  // { name: "base-file-minus", component: BaseFileMinus },
  // { name: "base-file-plus", component: BaseFilePlus },
  // { name: "base-file-refresh", component: BaseFileRefresh },
  // { name: "base-files-queue", component: BaseFilesQueue },
  // { name: "base-fill", component: BaseFill },
  // { name: "base-film", component: BaseFilm },
  // { name: "base-filter", component: BaseFilter },
  // { name: "base-filter-cogwheel", component: BaseFilterCogwheel },
  // { name: "base-filter-minus", component: BaseFilterMinus },
  // { name: "base-filter-off", component: BaseFilterOff },
  // { name: "base-filter-plus", component: BaseFilterPlus },
  // { name: "base-fingerprint", component: BaseFingerprint },
  // { name: "base-fingerprint-check", component: BaseFingerprintCheck },
  // { name: "base-fingerprint-lock", component: BaseFingerprintLock },
  // { name: "base-fingerprint-remove", component: BaseFingerprintRemove },
  // { name: "base-fingerprint-scan", component: BaseFingerprintScan },
  // { name: "base-finish-line", component: BaseFinishLine },
  // { name: "base-fins", component: BaseFins },
  // { name: "base-fire", component: BaseFire },
  // { name: "base-firefighters", component: BaseFirefighters },
  // { name: "base-fireplace", component: BaseFireplace },
  // { name: "base-fireworks", component: BaseFireworks },
  // { name: "base-fireworks-alt", component: BaseFireworksAlt },
  // { name: "base-fish", component: BaseFish },
  // { name: "base-fishes", component: BaseFishes },
  // { name: "base-fishes-bait", component: BaseFishesBait },
  // { name: "base-flag", component: BaseFlag },
  // { name: "base-flag-triangle", component: BaseFlagTriangle },
  // { name: "base-flag-waving", component: BaseFlagWaving },
  // { name: "base-flash", component: BaseFlash },
  // { name: "base-flash-automatic", component: BaseFlashAutomatic },
  // { name: "base-flash-no", component: BaseFlashNo },
  // { name: "base-flashlight", component: BaseFlashlight },
  // { name: "base-flower", component: BaseFlower },
  // { name: "base-folder", component: BaseFolder },
  // { name: "base-folder-alert", component: BaseFolderAlert },
  // { name: "base-folder-check", component: BaseFolderCheck },
  // { name: "base-folder-cogwheel", component: BaseFolderCogwheel },
  // { name: "base-folder-lock", component: BaseFolderLock },
  // { name: "base-folder-minus", component: BaseFolderMinus },
  // { name: "base-folder-no", component: BaseFolderNo },
  // { name: "base-folder-open", component: BaseFolderOpen },
  // { name: "base-folder-plus", component: BaseFolderPlus },
  // { name: "base-folder-refresh", component: BaseFolderRefresh },
  // { name: "base-folder-star", component: BaseFolderStar },
  // { name: "base-font", component: BaseFont },
  // { name: "base-food-service", component: BaseFoodService },
  // { name: "base-football", component: BaseFootball },
  // { name: "base-footprints", component: BaseFootprints },
  // { name: "base-forest", component: BaseForest },
  // { name: "base-forklift", component: BaseForklift },
  // { name: "base-forward", component: BaseForward },
  // { name: "base-forward-email", component: BaseForwardEmail },
  // { name: "base-forward-fifteen", component: BaseForwardFifteen },
  // { name: "base-forward-five", component: BaseForwardFive },
  // { name: "base-forward-ten", component: BaseForwardTen },
  // { name: "base-forward-thirty", component: BaseForwardThirty },
  // { name: "base-french-press", component: BaseFrenchPress },
  // { name: "base-fullscreen", component: BaseFullscreen },
  // { name: "base-fullscreen-off", component: BaseFullscreenOff },
  // { name: "base-game-controller", component: BaseGameController },
  // { name: "base-game-controller-wireless", component: BaseGameControllerWireless },
  // { name: "base-gas", component: BaseGas },
  // { name: "base-gas-station", component: BaseGasStation },
  // { name: "base-gay", component: BaseGay },
  // { name: "base-gift", component: BaseGift },
  // { name: "base-glass", component: BaseGlass },
  // { name: "base-glass-wine", component: BaseGlassWine },
  // { name: "base-globe", component: BaseGlobe },
  // { name: "base-globe-data", component: BaseGlobeData },
  // { name: "base-gloves", component: BaseGloves },
  // { name: "base-golf", component: BaseGolf },
  // { name: "base-golf-green", component: BaseGolfGreen },
  // { name: "base-government", component: BaseGovernment },
  // { name: "base-government-off", component: BaseGovernmentOff },
  // { name: "base-graphic-tablet", component: BaseGraphicTablet },
  // { name: "base-graphic-tablet-wireless", component: BaseGraphicTabletWireless },
  // { name: "base-grater", component: BaseGrater },
  // { name: "base-gun", component: BaseGun },
  // { name: "base-gun-off", component: BaseGunOff },
  // { name: "base-hairdresser", component: BaseHairdresser },
  // { name: "base-hammer", component: BaseHammer },
  // { name: "base-hand-dislike", component: BaseHandDislike },
  // { name: "base-hand-donate", component: BaseHandDonate },
  // { name: "base-hand-heart", component: BaseHandHeart },
  // { name: "base-hand-like", component: BaseHandLike },
  // { name: "base-hand-ok", component: BaseHandOk },
  // { name: "base-hand-open", component: BaseHandOpen },
  // { name: "base-hand-open-alt", component: BaseHandOpenAlt },
  // { name: "base-hand-pointing-down", component: BaseHandPointingDown },
  // { name: "base-hand-pointing-left", component: BaseHandPointingLeft },
  // { name: "base-hand-pointing-right", component: BaseHandPointingRight },
  // { name: "base-hand-pointing-up", component: BaseHandPointingUp },
  // { name: "base-hand-praying", component: BaseHandPraying },
  // { name: "base-handshake", component: BaseHandshake },
  // { name: "base-hanger", component: BaseHanger },
  // { name: "base-hard-drive", component: BaseHardDrive },
  // { name: "base-hard-drive-cogwheel", component: BaseHardDriveCogwheel },
  // { name: "base-hard-drive-eject", component: BaseHardDriveEject },
  // { name: "base-hard-drive-lock", component: BaseHardDriveLock },
  // { name: "base-hard-drive-no", component: BaseHardDriveNo },
  // { name: "base-hash", component: BaseHash },
  // { name: "base-head-voice", component: BaseHeadVoice },
  // { name: "base-header", component: BaseHeader },
  // { name: "base-headphones", component: BaseHeadphones },
  // { name: "base-headset", component: BaseHeadset },
  // { name: "base-heart", component: BaseHeart },
  // { name: "base-heart-broken", component: BaseHeartBroken },
  // { name: "base-heart-empty", component: BaseHeartEmpty },
  // { name: "base-heartbeat", component: BaseHeartbeat },
  // { name: "base-heating", component: BaseHeating },
  // { name: "base-hetero", component: BaseHetero },
  // { name: "base-highlights", component: BaseHighlights },
  // { name: "base-highway", component: BaseHighway },
  // { name: "base-history", component: BaseHistory },
  // { name: "base-hob", component: BaseHob },
  // { name: "base-hockey", component: BaseHockey },
  // { name: "base-home", component: BaseHome },
  // { name: "base-home-fire", component: BaseHomeFire },
  // { name: "base-home-flag", component: BaseHomeFlag },
  // { name: "base-home-flood", component: BaseHomeFlood },
  // { name: "base-home-for-sale", component: BaseHomeForSale },
  // { name: "base-honey", component: BaseHoney },
  // { name: "base-honeybee", component: BaseHoneybee },
  // { name: "base-honeycombs", component: BaseHoneycombs },
  // { name: "base-hourglass", component: BaseHourglass },
  // { name: "base-ice-cream", component: BaseIceCream },
  // { name: "base-ice-cream-off", component: BaseIceCreamOff },
  // { name: "base-ice-lolly", component: BaseIceLolly },
  // { name: "base-ice-lolly-eaten", component: BaseIceLollyEaten },
  // { name: "base-id", component: BaseId },
  // { name: "base-id-badge", component: BaseIdBadge },
  // { name: "base-id-badge-lanyard", component: BaseIdBadgeLanyard },
  // { name: "base-id-badge-vertical", component: BaseIdBadgeVertical },
  // { name: "base-id-binder", component: BaseIdBinder },
  // { name: "base-import", component: BaseImport },
  // { name: "base-inbox", component: BaseInbox },
  // { name: "base-inbox-down", component: BaseInboxDown },
  // { name: "base-inbox-lock", component: BaseInboxLock },
  // { name: "base-inbox-minus", component: BaseInboxMinus },
  // { name: "base-inbox-plus", component: BaseInboxPlus },
  // { name: "base-inbox-up", component: BaseInboxUp },
  // { name: "base-indent-left", component: BaseIndentLeft },
  // { name: "base-indent-right", component: BaseIndentRight },
  // { name: "base-initial-letter", component: BaseInitialLetter },
  // { name: "base-intersex", component: BaseIntersex },
  // { name: "base-invoice", component: BaseInvoice },
  // { name: "base-invoice-alert", component: BaseInvoiceAlert },
  // { name: "base-invoice-check", component: BaseInvoiceCheck },
  // { name: "base-invoice-minus", component: BaseInvoiceMinus },
  // { name: "base-invoice-plus", component: BaseInvoicePlus },
  // { name: "base-iris-scan", component: BaseIrisScan },
  // { name: "base-italic", component: BaseItalic },
  // { name: "base-kettle", component: BaseKettle },
  // { name: "base-kettlebell", component: BaseKettlebell },
  // { name: "base-key", component: BaseKey },
  // { name: "base-key-rounded", component: BaseKeyRounded },
  // { name: "base-keyboard", component: BaseKeyboard },
  // { name: "base-keyboard-wireless", component: BaseKeyboardWireless },
  // { name: "base-king", component: BaseKing },
  // { name: "base-kiosk", component: BaseKiosk },
  // { name: "base-kiosk-food", component: BaseKioskFood },
  // { name: "base-kiosk-immobile", component: BaseKioskImmobile },
  // { name: "base-kiosk-wheels", component: BaseKioskWheels },
  // { name: "base-knight", component: BaseKnight },
  // { name: "base-lamp", component: BaseLamp },
  // { name: "base-lamp-table", component: BaseLampTable },
  // { name: "base-lasso", component: BaseLasso },
  // { name: "base-lawn-mower", component: BaseLawnMower },
  // { name: "base-layers", component: BaseLayers },
  // { name: "base-layers-cogwheel", component: BaseLayersCogwheel },
  // { name: "base-layers-down", component: BaseLayersDown },
  // { name: "base-layers-lock", component: BaseLayersLock },
  // { name: "base-layers-minus", component: BaseLayersMinus },
  // { name: "base-layers-plus", component: BaseLayersPlus },
  // { name: "base-layers-up", component: BaseLayersUp },
  // { name: "base-leaf", component: BaseLeaf },
  // { name: "base-leather", component: BaseLeather },
  // { name: "base-legal-section-sign", component: BaseLegalSectionSign },
  // { name: "base-lesbian", component: BaseLesbian },
  // { name: "base-light-beacon", component: BaseLightBeacon },
  // { name: "base-lightbulb", component: BaseLightbulb },
  // { name: "base-lighter", component: BaseLighter },
  // { name: "base-link", component: BaseLink },
  // { name: "base-link-remove", component: BaseLinkRemove },
  // { name: "base-list", component: BaseList },
  // { name: "base-list-letters", component: BaseListLetters },
  // { name: "base-list-numbers", component: BaseListNumbers },
  // { name: "base-lock", component: BaseLock },
  // { name: "base-lock-off", component: BaseLockOff },
  // { name: "base-lock-open", component: BaseLockOpen },
  // { name: "base-log-in", component: BaseLogIn },
  // { name: "base-log-out", component: BaseLogOut },
  // { name: "base-lp", component: BaseLp },
  // { name: "base-luggage", component: BaseLuggage },
  // { name: "base-luggage-alt", component: BaseLuggageAlt },
  // { name: "base-lupin", component: BaseLupin },
  // { name: "base-magic-wand", component: BaseMagicWand },
  // { name: "base-magnet", component: BaseMagnet },
  // { name: "base-male", component: BaseMale },
  // { name: "base-map", component: BaseMap },
  // { name: "base-map-aerial", component: BaseMapAerial },
  // { name: "base-map-cadastral", component: BaseMapCadastral },
  // { name: "base-map-marker", component: BaseMapMarker },
  // { name: "base-map-satellite", component: BaseMapSatellite },
  // { name: "base-map-tourist", component: BaseMapTourist },
  // { name: "base-marker", component: BaseMarker },
  // { name: "base-marriage", component: BaseMarriage },
  // { name: "base-mc", component: BaseMc },
  // { name: "base-meat", component: BaseMeat },
  // { name: "base-medal", component: BaseMedal },
  // { name: "base-medicine", component: BaseMedicine },
  // { name: "base-memory-card", component: BaseMemoryCard },
  // { name: "base-memory-card-cogwheel", component: BaseMemoryCardCogwheel },
  // { name: "base-memory-card-eject", component: BaseMemoryCardEject },
  // { name: "base-memory-card-lock", component: BaseMemoryCardLock },
  // { name: "base-memory-card-no", component: BaseMemoryCardNo },
  // { name: "base-menu-close", component: BaseMenuClose },
  // { name: "base-metro", component: BaseMetro },
  // { name: "base-mic", component: BaseMic },
  // { name: "base-mic-off", component: BaseMicOff },
  // { name: "base-microchip", component: BaseMicrochip },
  // { name: "base-microwave", component: BaseMicrowave },
  // { name: "base-milk", component: BaseMilk },
  // { name: "base-minus", component: BaseMinus },
  // { name: "base-missile", component: BaseMissile },
  // { name: "base-mobile-phone", component: BaseMobilePhone },
  // { name: "base-mobile-phone-alert", component: BaseMobilePhoneAlert },
  // { name: "base-mobile-phone-charger", component: BaseMobilePhoneCharger },
  // { name: "base-mobile-phone-check", component: BaseMobilePhoneCheck },
  // { name: "base-mobile-phone-cogwheel", component: BaseMobilePhoneCogwheel },
  // { name: "base-mobile-phone-lock", component: BaseMobilePhoneLock },
  // { name: "base-mobile-phone-nfc", component: BaseMobilePhoneNfc },
  // { name: "base-mobile-phone-off", component: BaseMobilePhoneOff },
  // { name: "base-mobile-phone-scan", component: BaseMobilePhoneScan },
  // { name: "base-mobile-phone-shaking", component: BaseMobilePhoneShaking },
  // { name: "base-mobile-phone-vibrations", component: BaseMobilePhoneVibrations },
  // { name: "base-modal-window", component: BaseModalWindow },
  // { name: "base-molluscs", component: BaseMolluscs },
  // { name: "base-monitor", component: BaseMonitor },
  // { name: "base-monitor-rotate", component: BaseMonitorRotate },
  // { name: "base-monitor-vertical", component: BaseMonitorVertical },
  // { name: "base-monitors", component: BaseMonitors },
  // { name: "base-moon", component: BaseMoon },
  // { name: "base-more", component: BaseMore },
  // { name: "base-more-vertical", component: BaseMoreVertical },
  // { name: "base-motor-oil", component: BaseMotorOil },
  // { name: "base-motorbike", component: BaseMotorbike },
  // { name: "base-mountains", component: BaseMountains },
  // { name: "base-mouse", component: BaseMouse },
  // { name: "base-mouse-wireless", component: BaseMouseWireless },
  // { name: "base-multifunction-knife", component: BaseMultifunctionKnife },
  // { name: "base-multifunction-printer", component: BaseMultifunctionPrinter },
  // { name: "base-music", component: BaseMusic },
  // { name: "base-music-alt", component: BaseMusicAlt },
  // { name: "base-mustard", component: BaseMustard },
  // { name: "base-nearby", component: BaseNearby },
  // { name: "base-nearby-alt", component: BaseNearbyAlt },
  // { name: "base-neighborhood", component: BaseNeighborhood },
  // { name: "base-newspaper", component: BaseNewspaper },
  // { name: "base-no-symbol", component: BaseNoSymbol },
  // { name: "base-nose", component: BaseNose },
  // { name: "base-nose-off", component: BaseNoseOff },
  // { name: "base-notebook", component: BaseNotebook },
  // { name: "base-notes", component: BaseNotes },
  // { name: "base-nuts", component: BaseNuts },
  // { name: "base-octagon-remove", component: BaseOctagonRemove },
  // { name: "base-octagon-remove-empty", component: BaseOctagonRemoveEmpty },
  // { name: "base-one-day-delivery", component: BaseOneDayDelivery },
  // { name: "base-open", component: BaseOpen },
  // { name: "base-other", component: BaseOther },
  // { name: "base-oven", component: BaseOven },
  // { name: "base-oxygen-bottles", component: BaseOxygenBottles },
  // { name: "base-paired", component: BasePaired },
  // { name: "base-paired-off", component: BasePairedOff },
  // { name: "base-palette", component: BasePalette },
  // { name: "base-palette-package", component: BasePalettePackage },
  // { name: "base-paperclip", component: BasePaperclip },
  // { name: "base-paragraph-center", component: BaseParagraphCenter },
  // { name: "base-paragraph-justify", component: BaseParagraphJustify },
  // { name: "base-paragraph-left", component: BaseParagraphLeft },
  // { name: "base-paragraph-right", component: BaseParagraphRight },
  // { name: "base-park", component: BasePark },
  // { name: "base-parking", component: BaseParking },
  // { name: "base-parking-meter", component: BaseParkingMeter },
  // { name: "base-passport", component: BasePassport },
  // { name: "base-passport-alt", component: BasePassportAlt },
  // { name: "base-paste", component: BasePaste },
  // { name: "base-pause", component: BasePause },
  // { name: "base-pawn", component: BasePawn },
  // { name: "base-payment", component: BasePayment },
  // { name: "base-peanuts", component: BasePeanuts },
  // { name: "base-pen", component: BasePen },
  // { name: "base-pencil", component: BasePencil },
  // { name: "base-percent-sign", component: BasePercentSign },
  // { name: "base-personal-hygiene", component: BasePersonalHygiene },
  // { name: "base-phone-horizontal", component: BasePhoneHorizontal },
  // { name: "base-phone-old", component: BasePhoneOld },
  // { name: "base-phone-rotate", component: BasePhoneRotate },
  // { name: "base-phone-to-horizontal", component: BasePhoneToHorizontal },
  // { name: "base-picture", component: BasePicture },
  // { name: "base-piggy-bank", component: BasePiggyBank },
  // { name: "base-piggy-bank-coins", component: BasePiggyBankCoins },
  // { name: "base-pills", component: BasePills },
  // { name: "base-pizza", component: BasePizza },
  // { name: "base-plant", component: BasePlant },
  // { name: "base-play", component: BasePlay },
  // { name: "base-playlist", component: BasePlaylist },
  // { name: "base-playlist-music", component: BasePlaylistMusic },
  // { name: "base-playlist-video", component: BasePlaylistVideo },
  // { name: "base-plus", component: BasePlus },
  // { name: "base-podcast", component: BasePodcast },
  // { name: "base-police", component: BasePolice },
  // { name: "base-pool", component: BasePool },
  // { name: "base-pot", component: BasePot },
  // { name: "base-pound", component: BasePound },
  // { name: "base-power", component: BasePower },
  // { name: "base-power-cord-plug", component: BasePowerCordPlug },
  // { name: "base-power-cord-plug-off", component: BasePowerCordPlugOff },
  // { name: "base-power-plant-solar", component: BasePowerPlantSolar },
  // { name: "base-power-plant-water", component: BasePowerPlantWater },
  // { name: "base-power-plant-wind", component: BasePowerPlantWind },
  // { name: "base-presentation", component: BasePresentation },
  // { name: "base-print", component: BasePrint },
  // { name: "base-progress", component: BaseProgress },
  // { name: "base-projector", component: BaseProjector },
  // { name: "base-pulse", component: BasePulse },
  // { name: "base-push-pin", component: BasePushPin },
  // { name: "base-puzzle", component: BasePuzzle },
  // { name: "base-qr-code", component: BaseQrCode },
  // { name: "base-quad-bike", component: BaseQuadBike },
  // { name: "base-queen", component: BaseQueen },
  // { name: "base-rabbit", component: BaseRabbit },
  // { name: "base-radar", component: BaseRadar },
  // { name: "base-radio", component: BaseRadio },
  // { name: "base-radioactive", component: BaseRadioactive },
  // { name: "base-random", component: BaseRandom },
  // { name: "base-receipt", component: BaseReceipt },
  // { name: "base-rechargeable", component: BaseRechargeable },
  // { name: "base-recycling", component: BaseRecycling },
  // { name: "base-redo", component: BaseRedo },
  // { name: "base-reflect-x", component: BaseReflectX },
  // { name: "base-reflect-y", component: BaseReflectY },
  // { name: "base-refresh", component: BaseRefresh },
  // { name: "base-reload", component: BaseReload },
  // { name: "base-reload-alt", component: BaseReloadAlt },
  // { name: "base-remote-control", component: BaseRemoteControl },
  // { name: "base-repeat", component: BaseRepeat },
  // { name: "base-repeat-once", component: BaseRepeatOnce },
  // { name: "base-replay", component: BaseReplay },
  // { name: "base-reply", component: BaseReply },
  // { name: "base-reply-all", component: BaseReplyAll },
  // { name: "base-resize-horizontal", component: BaseResizeHorizontal },
  // { name: "base-resize-vertical", component: BaseResizeVertical },
  // { name: "base-restart", component: BaseRestart },
  // { name: "base-retweet", component: BaseRetweet },
  // { name: "base-rewind", component: BaseRewind },
  // { name: "base-rewind-fifteen", component: BaseRewindFifteen },
  // { name: "base-rewind-five", component: BaseRewindFive },
  // { name: "base-rewind-ten", component: BaseRewindTen },
  // { name: "base-rewind-thirty", component: BaseRewindThirty },
  // { name: "base-road", component: BaseRoad },
  // { name: "base-rook", component: BaseRook },
  // { name: "base-rotate", component: BaseRotate },
  // { name: "base-rotate-horizontal", component: BaseRotateHorizontal },
  // { name: "base-rotate-vertical", component: BaseRotateVertical },
  // { name: "base-roundabout", component: BaseRoundabout },
  // { name: "base-route", component: BaseRoute },
  // { name: "base-router", component: BaseRouter },
  // { name: "base-ruble", component: BaseRuble },
  // { name: "base-rugby", component: BaseRugby },
  // { name: "base-ruler", component: BaseRuler },
  // { name: "base-rulers", component: BaseRulers },
  // { name: "base-safe", component: BaseSafe },
  // { name: "base-safe-digital", component: BaseSafeDigital },
  // { name: "base-safe-open", component: BaseSafeOpen },
  // { name: "base-sailboat", component: BaseSailboat },
  // { name: "base-sampler", component: BaseSampler },
  // { name: "base-satellite", component: BaseSatellite },
  // { name: "base-satellite-dish", component: BaseSatelliteDish },
  // { name: "base-satellite-dish-alt", component: BaseSatelliteDishAlt },
  // { name: "base-save", component: BaseSave },
  // { name: "base-save-as", component: BaseSaveAs },
  // { name: "base-saw", component: BaseSaw },
  // { name: "base-saw-blade", component: BaseSawBlade },
  // { name: "base-scale-kitchen", component: BaseScaleKitchen },
  // { name: "base-scale-personal", component: BaseScalePersonal },
  // { name: "base-scanner", component: BaseScanner },
  // { name: "base-scissors", component: BaseScissors },
  // { name: "base-scissors-cutting", component: BaseScissorsCutting },
  // { name: "base-scissors-horizontal", component: BaseScissorsHorizontal },
  // { name: "base-screwdriver", component: BaseScrewdriver },
  // { name: "base-seafood", component: BaseSeafood },
  // { name: "base-search", component: BaseSearch },
  // { name: "base-security-camera", component: BaseSecurityCamera },
  // { name: "base-self-winding-cord", component: BaseSelfWindingCord },
  // { name: "base-send", component: BaseSend },
  // { name: "base-send-backward", component: BaseSendBackward },
  // { name: "base-send-to-back", component: BaseSendToBack },
  // { name: "base-server", component: BaseServer },
  // { name: "base-server-check", component: BaseServerCheck },
  // { name: "base-server-cogwheel", component: BaseServerCogwheel },
  // { name: "base-server-flag", component: BaseServerFlag },
  // { name: "base-server-lock", component: BaseServerLock },
  // { name: "base-server-minus", component: BaseServerMinus },
  // { name: "base-server-no", component: BaseServerNo },
  // { name: "base-server-plus", component: BaseServerPlus },
  // { name: "base-server-refresh", component: BaseServerRefresh },
  // { name: "base-sesame", component: BaseSesame },
  // { name: "base-set", component: BaseSet },
  // { name: "base-set-down", component: BaseSetDown },
  // { name: "base-set-wine", component: BaseSetWine },
  // { name: "base-settings", component: BaseSettings },
  // { name: "base-shadows", component: BaseShadows },
  // { name: "base-share", component: BaseShare },
  // { name: "base-shield-check", component: BaseShieldCheck },
  // { name: "base-shield-empty-star", component: BaseShieldEmptyStar },
  // { name: "base-shield-half", component: BaseShieldHalf },
  // { name: "base-shield-quarter", component: BaseShieldQuarter },
  // { name: "base-shield-star", component: BaseShieldStar },
  // { name: "base-shirt", component: BaseShirt },
  // { name: "base-shoes", component: BaseShoes },
  // { name: "base-shoes-off", component: BaseShoesOff },
  // { name: "base-shop-door", component: BaseShopDoor },
  // { name: "base-shop-window", component: BaseShopWindow },
  // { name: "base-shopping-bag", component: BaseShoppingBag },
  // { name: "base-shower", component: BaseShower },
  // { name: "base-shower-alt", component: BaseShowerAlt },
  // { name: "base-shredder", component: BaseShredder },
  // { name: "base-sidebar", component: BaseSidebar },
  // { name: "base-sidebar-right", component: BaseSidebarRight },
  // { name: "base-simple-trolley", component: BaseSimpleTrolley },
  // { name: "base-skip-to-end", component: BaseSkipToEnd },
  // { name: "base-skip-to-next", component: BaseSkipToNext },
  // { name: "base-skip-to-previous", component: BaseSkipToPrevious },
  // { name: "base-skip-to-start", component: BaseSkipToStart },
  // { name: "base-skull", component: BaseSkull },
  // { name: "base-skull-crossbones", component: BaseSkullCrossbones },
  // { name: "base-slightly-frowning", component: BaseSlightlyFrowning },
  // { name: "base-slightly-smiling", component: BaseSlightlySmiling },
  // { name: "base-smart-bracelet", component: BaseSmartBracelet },
  // { name: "base-snowflake", component: BaseSnowflake },
  // { name: "base-socket-b", component: BaseSocketB },
  // { name: "base-socket-e", component: BaseSocketE },
  // { name: "base-socket-g", component: BaseSocketG },
  // { name: "base-socket-i", component: BaseSocketI },
  // { name: "base-sort", component: BaseSort },
  // { name: "base-sort-alphabetically", component: BaseSortAlphabetically },
  // { name: "base-sort-alphabetically-alt", component: BaseSortAlphabeticallyAlt },
  // { name: "base-sort-attributes", component: BaseSortAttributes },
  // { name: "base-sort-attributes-alt", component: BaseSortAttributesAlt },
  // { name: "base-sort-numerically", component: BaseSortNumerically },
  // { name: "base-sort-numerically-alt", component: BaseSortNumericallyAlt },
  // { name: "base-sound-surround", component: BaseSoundSurround },
  // { name: "base-soya", component: BaseSoya },
  // { name: "base-spade", component: BaseSpade },
  // { name: "base-speakers", component: BaseSpeakers },
  // { name: "base-speech-bubble-alert", component: BaseSpeechBubbleAlert },
  // { name: "base-speech-bubble-info", component: BaseSpeechBubbleInfo },
  // { name: "base-speech-bubble-question", component: BaseSpeechBubbleQuestion },
  // { name: "base-spell-check", component: BaseSpellCheck },
  // { name: "base-spell-check-check", component: BaseSpellCheckCheck },
  // { name: "base-spray", component: BaseSpray },
  // { name: "base-square-alert", component: BaseSquareAlert },
  // { name: "base-square-check", component: BaseSquareCheck },
  // { name: "base-square-checkbox", component: BaseSquareCheckbox },
  // { name: "base-square-down", component: BaseSquareDown },
  // { name: "base-square-download", component: BaseSquareDownload },
  // { name: "base-square-edit", component: BaseSquareEdit },
  // { name: "base-square-email", component: BaseSquareEmail },
  // { name: "base-square-empty", component: BaseSquareEmpty },
  // { name: "base-square-empty-alert", component: BaseSquareEmptyAlert },
  // { name: "base-square-empty-check", component: BaseSquareEmptyCheck },
  // { name: "base-square-empty-download", component: BaseSquareEmptyDownload },
  // { name: "base-square-empty-info", component: BaseSquareEmptyInfo },
  // { name: "base-square-empty-left", component: BaseSquareEmptyLeft },
  // { name: "base-square-empty-minus", component: BaseSquareEmptyMinus },
  // { name: "base-square-empty-play", component: BaseSquareEmptyPlay },
  // { name: "base-square-empty-plus", component: BaseSquareEmptyPlus },
  // { name: "base-square-empty-question", component: BaseSquareEmptyQuestion },
  // { name: "base-square-empty-remove", component: BaseSquareEmptyRemove },
  // { name: "base-square-empty-right", component: BaseSquareEmptyRight },
  // { name: "base-square-empty-upload", component: BaseSquareEmptyUpload },
  // { name: "base-square-hospital", component: BaseSquareHospital },
  // { name: "base-square-indeterminate", component: BaseSquareIndeterminate },
  // { name: "base-square-info", component: BaseSquareInfo },
  // { name: "base-square-left", component: BaseSquareLeft },
  // { name: "base-square-menu", component: BaseSquareMenu },
  // { name: "base-square-minus", component: BaseSquareMinus },
  // { name: "base-square-more", component: BaseSquareMore },
  // { name: "base-square-new-window", component: BaseSquareNewWindow },
  // { name: "base-square-play", component: BaseSquarePlay },
  // { name: "base-square-plus", component: BaseSquarePlus },
  // { name: "base-square-question", component: BaseSquareQuestion },
  // { name: "base-square-red-cross", component: BaseSquareRedCross },
  // { name: "base-square-remove", component: BaseSquareRemove },
  // { name: "base-square-right", component: BaseSquareRight },
  // { name: "base-square-selected", component: BaseSquareSelected },
  // { name: "base-square-triangle-down", component: BaseSquareTriangleDown },
  // { name: "base-square-triangle-left", component: BaseSquareTriangleLeft },
  // { name: "base-square-triangle-right", component: BaseSquareTriangleRight },
  // { name: "base-square-triangle-up", component: BaseSquareTriangleUp },
  // { name: "base-square-up", component: BaseSquareUp },
  // { name: "base-square-upload", component: BaseSquareUpload },
  // { name: "base-squares", component: BaseSquares },
  // { name: "base-star", component: BaseStar },
  // { name: "base-star-empty", component: BaseStarEmpty },
  // { name: "base-star-half", component: BaseStarHalf },
  // { name: "base-star-of-life", component: BaseStarOfLife },
  // { name: "base-star-of-life-empty", component: BaseStarOfLifeEmpty },
  // { name: "base-start", component: BaseStart },
  // { name: "base-stats", component: BaseStats },
  // { name: "base-stats-bars", component: BaseStatsBars },
  // { name: "base-stats-bars-one-day", component: BaseStatsBarsOneDay },
  // { name: "base-stats-circle", component: BaseStatsCircle },
  // { name: "base-stats-circle-one-day", component: BaseStatsCircleOneDay },
  // { name: "base-stats-one-day", component: BaseStatsOneDay },
  // { name: "base-step-back", component: BaseStepBack },
  // { name: "base-step-forward", component: BaseStepForward },
  // { name: "base-stop", component: BaseStop },
  // { name: "base-stopwatch", component: BaseStopwatch },
  // { name: "base-strikethrough", component: BaseStrikethrough },
  // { name: "base-stroller", component: BaseStroller },
  // { name: "base-subscript", component: BaseSubscript },
  // { name: "base-subtitles", component: BaseSubtitles },
  // { name: "base-subtitles-minus", component: BaseSubtitlesMinus },
  // { name: "base-subtitles-off", component: BaseSubtitlesOff },
  // { name: "base-subtitles-plus", component: BaseSubtitlesPlus },
  // { name: "base-suitcase-doctor", component: BaseSuitcaseDoctor },
  // { name: "base-sun", component: BaseSun },
  // { name: "base-superscript", component: BaseSuperscript },
  // { name: "base-swimming-pool", component: BaseSwimmingPool },
  // { name: "base-switch", component: BaseSwitch },
  // { name: "base-switch-off", component: BaseSwitchOff },
  // { name: "base-switch-on", component: BaseSwitchOn },
  // { name: "base-sync", component: BaseSync },
  // { name: "base-sync-alert", component: BaseSyncAlert },
  // { name: "base-sync-check", component: BaseSyncCheck },
  // { name: "base-syringe", component: BaseSyringe },
  // { name: "base-syringe-empty", component: BaseSyringeEmpty },
  // { name: "base-t-shirt", component: BaseTShirt },
  // { name: "base-t-shirt-sleeves", component: BaseTShirtSleeves },
  // { name: "base-t-shirt-v", component: BaseTShirtV },
  // { name: "base-table", component: BaseTable },
  // { name: "base-table-tennis", component: BaseTableTennis },
  // { name: "base-tablet", component: BaseTablet },
  // { name: "base-tag", component: BaseTag },
  // { name: "base-tags", component: BaseTags },
  // { name: "base-target", component: BaseTarget },
  // { name: "base-tasks", component: BaseTasks },
  // { name: "base-taxi", component: BaseTaxi },
  // { name: "base-tea-leaves", component: BaseTeaLeaves },
  // { name: "base-tea-mug", component: BaseTeaMug },
  // { name: "base-tea-pot", component: BaseTeaPot },
  // { name: "base-temperature", component: BaseTemperature },
  // { name: "base-temperature-alert", component: BaseTemperatureAlert },
  // { name: "base-temperature-high", component: BaseTemperatureHigh },
  // { name: "base-temperature-low", component: BaseTemperatureLow },
  // { name: "base-temperature-set", component: BaseTemperatureSet },
  // { name: "base-tennis", component: BaseTennis },
  // { name: "base-tent", component: BaseTent },
  // { name: "base-terminal", component: BaseTerminal },
  // { name: "base-terminal-empty", component: BaseTerminalEmpty },
  // { name: "base-terminal-isolated", component: BaseTerminalIsolated },
  // { name: "base-text", component: BaseText },
  // { name: "base-text-background", component: BaseTextBackground },
  // { name: "base-text-bigger", component: BaseTextBigger },
  // { name: "base-text-height", component: BaseTextHeight },
  // { name: "base-text-resize", component: BaseTextResize },
  // { name: "base-text-smaller", component: BaseTextSmaller },
  // { name: "base-text-underline", component: BaseTextUnderline },
  // { name: "base-text-width", component: BaseTextWidth },
  // { name: "base-theater-masks", component: BaseTheaterMasks },
  // { name: "base-this-side-up", component: BaseThisSideUp },
  // { name: "base-three-dimensional", component: BaseThreeDimensional },
  // { name: "base-three-dimensional-full", component: BaseThreeDimensionalFull },
  // { name: "base-thumbnails", component: BaseThumbnails },
  // { name: "base-thumbnails-list", component: BaseThumbnailsList },
  // { name: "base-thumbnails-small", component: BaseThumbnailsSmall },
  // { name: "base-ticket", component: BaseTicket },
  // { name: "base-ticket-parking", component: BaseTicketParking },
  // { name: "base-tie", component: BaseTie },
  // { name: "base-timer", component: BaseTimer },
  // { name: "base-times", component: BaseTimes },
  // { name: "base-toilet", component: BaseToilet },
  // { name: "base-toilet-paper", component: BaseToiletPaper },
  // { name: "base-toilet-paper-alt", component: BaseToiletPaperAlt },
  // { name: "base-tongue", component: BaseTongue },
  // { name: "base-tongue-off", component: BaseTongueOff },
  // { name: "base-tornado", component: BaseTornado },
  // { name: "base-tractor", component: BaseTractor },
  // { name: "base-traffic-lights", component: BaseTrafficLights },
  // { name: "base-traffic-lights-off", component: BaseTrafficLightsOff },
  // { name: "base-train", component: BaseTrain },
  // { name: "base-tram", component: BaseTram },
  // { name: "base-transgender", component: BaseTransgender },
  // { name: "base-translate", component: BaseTranslate },
  // { name: "base-translate-alt", component: BaseTranslateAlt },
  // { name: "base-transport-mixed", component: BaseTransportMixed },
  // { name: "base-transport-trolley", component: BaseTransportTrolley },
  // { name: "base-tree-conifer", component: BaseTreeConifer },
  // { name: "base-tree-deciduous", component: BaseTreeDeciduous },
  // { name: "base-trending", component: BaseTrending },
  // { name: "base-triangle-alert", component: BaseTriangleAlert },
  // { name: "base-triangle-empty-alert", component: BaseTriangleEmptyAlert },
  // { name: "base-triangle-radioactive", component: BaseTriangleRadioactive },
  // { name: "base-trousers", component: BaseTrousers },
  // { name: "base-truck", component: BaseTruck },
  // { name: "base-turtle", component: BaseTurtle },
  // { name: "base-tv", component: BaseTv },
  // { name: "base-tv-streaming", component: BaseTvStreaming },
  // { name: "base-umbrella", component: BaseUmbrella },
  // { name: "base-underline", component: BaseUnderline },
  // { name: "base-underwear", component: BaseUnderwear },
  // { name: "base-undo", component: BaseUndo },
  // { name: "base-unknown", component: BaseUnknown },
  // { name: "base-usb-device", component: BaseUsbDevice },
  // { name: "base-usb-device-cogwheel", component: BaseUsbDeviceCogwheel },
  // { name: "base-usb-device-eject", component: BaseUsbDeviceEject },
  // { name: "base-usb-device-lock", component: BaseUsbDeviceLock },
  // { name: "base-usb-device-no", component: BaseUsbDeviceNo },
  // { name: "base-user", component: BaseUser },
  // { name: "base-user-alert", component: BaseUserAlert },
  // { name: "base-user-boy", component: BaseUserBoy },
  // { name: "base-user-chat", component: BaseUserChat },
  // { name: "base-user-check", component: BaseUserCheck },
  // { name: "base-user-child", component: BaseUserChild },
  // { name: "base-user-family", component: BaseUserFamily },
  // { name: "base-user-female", component: BaseUserFemale },
  // { name: "base-user-flag", component: BaseUserFlag },
  // { name: "base-user-girl", component: BaseUserGirl },
  // { name: "base-user-group", component: BaseUserGroup },
  // { name: "base-user-incognito", component: BaseUserIncognito },
  // { name: "base-user-lock", component: BaseUserLock },
  // { name: "base-user-minus", component: BaseUserMinus },
  // { name: "base-user-no", component: BaseUserNo },
  // { name: "base-user-parents", component: BaseUserParents },
  // { name: "base-user-parents-alt", component: BaseUserParentsAlt },
  // { name: "base-user-plus", component: BaseUserPlus },
  // { name: "base-user-rounded", component: BaseUserRounded },
  // { name: "base-user-scam", component: BaseUserScam },
  // { name: "base-user-scam-alt", component: BaseUserScamAlt },
  // { name: "base-user-squared", component: BaseUserSquared },
  // { name: "base-user-star", component: BaseUserStar },
  // { name: "base-user-voice", component: BaseUserVoice },
  // { name: "base-user-vr", component: BaseUserVr },
  // { name: "base-user-vr-minus", component: BaseUserVrMinus },
  // { name: "base-user-vr-plus", component: BaseUserVrPlus },
  // { name: "base-user-worker", component: BaseUserWorker },
  // { name: "base-users", component: BaseUsers },
  // { name: "base-vases", component: BaseVases },
  // { name: "base-vector-path", component: BaseVectorPath },
  // { name: "base-vector-path-circle", component: BaseVectorPathCircle },
  // { name: "base-vector-path-curve", component: BaseVectorPathCurve },
  // { name: "base-vector-path-edit", component: BaseVectorPathEdit },
  // { name: "base-vector-path-line", component: BaseVectorPathLine },
  // { name: "base-vector-path-pentagon", component: BaseVectorPathPentagon },
  // { name: "base-verify-check", component: BaseVerifyCheck },
  // { name: "base-verify-empty", component: BaseVerifyEmpty },
  // { name: "base-video", component: BaseVideo },
  // { name: "base-video-camera", component: BaseVideoCamera },
  // { name: "base-video-off", component: BaseVideoOff },
  // { name: "base-video-play", component: BaseVideoPlay },
  // { name: "base-video-play-empty", component: BaseVideoPlayEmpty },
  // { name: "base-view-full", component: BaseViewFull },
  // { name: "base-view-half", component: BaseViewHalf },
  // { name: "base-vignetting", component: BaseVignetting },
  // { name: "base-virgin-female", component: BaseVirginFemale },
  // { name: "base-voice-scan", component: BaseVoiceScan },
  // { name: "base-voicemail", component: BaseVoicemail },
  // { name: "base-volleyball", component: BaseVolleyball },
  // { name: "base-volume-bluetooth", component: BaseVolumeBluetooth },
  // { name: "base-volume-down", component: BaseVolumeDown },
  // { name: "base-volume-max", component: BaseVolumeMax },
  // { name: "base-volume-off", component: BaseVolumeOff },
  // { name: "base-volume-up", component: BaseVolumeUp },
  // { name: "base-voting-urn", component: BaseVotingUrn },
  // { name: "base-vr", component: BaseVr },
  // { name: "base-vr-inside", component: BaseVrInside },
  // { name: "base-wallet", component: BaseWallet },
  // { name: "base-wallet-check", component: BaseWalletCheck },
  // { name: "base-wallet-cogwheel", component: BaseWalletCogwheel },
  // { name: "base-wallet-lock", component: BaseWalletLock },
  // { name: "base-wallet-minus", component: BaseWalletMinus },
  // { name: "base-wallet-plus", component: BaseWalletPlus },
  // { name: "base-warehouse", component: BaseWarehouse },
  // { name: "base-washing-machine", component: BaseWashingMachine },
  // { name: "base-waste-pipe", component: BaseWastePipe },
  // { name: "base-waste-pipe-end", component: BaseWastePipeEnd },
  // { name: "base-watch", component: BaseWatch },
  // { name: "base-watch-squared", component: BaseWatchSquared },
  // { name: "base-water", component: BaseWater },
  // { name: "base-webcam", component: BaseWebcam },
  // { name: "base-wheat", component: BaseWheat },
  // { name: "base-wifi", component: BaseWifi },
  // { name: "base-wind", component: BaseWind },
  // { name: "base-winners", component: BaseWinners },
  // { name: "base-winners-stars", component: BaseWinnersStars },
  // { name: "base-wireless-charging", component: BaseWirelessCharging },
  // { name: "base-world-east", component: BaseWorldEast },
  // { name: "base-world-west", component: BaseWorldWest },
  // { name: "base-wrench", component: BaseWrench },
  // { name: "base-yen", component: BaseYen },
  //
  // Base End
  //
  // =============================================================================

  // =============================================================================
  //
  // Halflings Start
  //

  { name: "halflings-check", component: HalflingCheck },
  { name: "halflings-chevron-thin-down", component: HalflingChevronThinDown },
  { name: "halflings-chevron-thin-right", component: HalflingChevronThinRight },
  { name: "halflings-chevron-thin-up", component: HalflingChevronThinUp },
  { name: "halflings-circle-remove", component: HalflingCircleRemove },
  { name: "halflings-eye", component: HalflingEye },
  { name: "halflings-eye-off", component: HalflingEyeOff },
  { name: "halflings-square-checkbox", component: HalflingSquareCheckbox },
  { name: "halflings-square-edit", component: HalflingSquareEdit },
  { name: "halflings-square-empty", component: HalflingSquareEmpty },
  { name: "halflings-square-new-window", component: HalflingSquareNewWindow },
  { name: "halflings-bell", component: HalflingBell },
  { name: "halflings-bell-off", component: HalflingBellOff },
  { name: "halflings-pencil", component: HalflingPencil },
  { name: "halflings-stats-bars", component: HalflingStatsBars },
  { name: "halflings-hash", component: HalflingHash },
  // { name: "halflings-adjust", component: HalflingAdjust },
  // { name: "halflings-adjust", component: HalflingAdjust },
  // { name: "halflings-airplane", component: HalflingAirplane },
  // { name: "halflings-anchor", component: HalflingAnchor },
  // { name: "halflings-announcement", component: HalflingAnnouncement },
  // { name: "halflings-archive", component: HalflingArchive },
  // { name: "halflings-arrow-down", component: HalflingArrowDown },
  // { name: "halflings-arrow-left", component: HalflingArrowLeft },
  // { name: "halflings-arrow-right", component: HalflingArrowRight },
  // { name: "halflings-arrow-thin-down", component: HalflingArrowThinDown },
  // { name: "halflings-arrow-thin-left", component: HalflingArrowThinLeft },
  // { name: "halflings-arrow-thin-right", component: HalflingArrowThinRight },
  // { name: "halflings-arrow-thin-up", component: HalflingArrowThinUp },
  // { name: "halflings-arrow-up", component: HalflingArrowUp },
  // { name: "halflings-axes-three-dimensional", component: HalflingAxesThreeDimensional },
  // { name: "halflings-axes-two-dimensional", component: HalflingAxesTwoDimensional },
  // { name: "halflings-bank", component: HalflingBank },
  // { name: "halflings-barcode", component: HalflingBarcode },
  // { name: "halflings-bars", component: HalflingBars },
  // { name: "halflings-basket", component: HalflingBasket },
  // { name: "halflings-basket-in", component: HalflingBasketIn },
  // { name: "halflings-basket-out", component: HalflingBasketOut },
  // { name: "halflings-battery-charged", component: HalflingBatteryCharged },
  // { name: "halflings-battery-charging", component: HalflingBatteryCharging },
  // { name: "halflings-battery-half", component: HalflingBatteryHalf },
  // { name: "halflings-battery-low", component: HalflingBatteryLow },
  // { name: "halflings-battery-off", component: HalflingBatteryOff },
  // { name: "halflings-battery-quarter", component: HalflingBatteryQuarter },
  // { name: "halflings-battery-three-quarters", component: HalflingBatteryThreeQuarters },
  // { name: "halflings-bin", component: HalflingBin },
  // { name: "halflings-bluetooth", component: HalflingBluetooth },
  // { name: "halflings-bold", component: HalflingBold },
  // { name: "halflings-book", component: HalflingBook },
  // { name: "halflings-bookmark", component: HalflingBookmark },
  // { name: "halflings-briefcase", component: HalflingBriefcase },
  // { name: "halflings-building", component: HalflingBuilding },
  // { name: "halflings-calendar", component: HalflingCalendar },
  // { name: "halflings-call", component: HalflingCall },
  // { name: "halflings-camera", component: HalflingCamera },
  // { name: "halflings-car", component: HalflingCar },
  // { name: "halflings-cart", component: HalflingCart },
  // { name: "halflings-cart-in", component: HalflingCartIn },
  // { name: "halflings-cart-out", component: HalflingCartOut },
  // { name: "halflings-chat-conversation", component: HalflingChatConversation },
  // { name: "halflings-chat-message", component: HalflingChatMessage },
  // { name: "halflings-chevron-down", component: HalflingChevronDown },
  // { name: "halflings-chevron-last-down", component: HalflingChevronLastDown },
  // { name: "halflings-chevron-last-left", component: HalflingChevronLastLeft },
  // { name: "halflings-chevron-last-right", component: HalflingChevronLastRight },
  // { name: "halflings-chevron-last-up", component: HalflingChevronLastUp },
  // { name: "halflings-chevron-left", component: HalflingChevronLeft },
  // { name: "halflings-chevron-right", component: HalflingChevronRight },
  // { name: "halflings-chevron-thin-down", component: HalflingChevronThinDown },
  // { name: "halflings-chevron-thin-left", component: HalflingChevronThinLeft },
  // { name: "halflings-chevron-thin-right", component: HalflingChevronThinRight },
  // { name: "halflings-chevron-thin-up", component: HalflingChevronThinUp },
  // { name: "halflings-chevron-up", component: HalflingChevronUp },
  // { name: "halflings-circle-alert", component: HalflingCircleAlert },
  // { name: "halflings-circle-check", component: HalflingCircleCheck },
  // { name: "halflings-circle-down", component: HalflingCircleDown },
  // { name: "halflings-circle-empty", component: HalflingCircleEmpty },
  // { name: "halflings-circle-info", component: HalflingCircleInfo },
  // { name: "halflings-circle-left", component: HalflingCircleLeft },
  // { name: "halflings-circle-menu", component: HalflingCircleMenu },
  // { name: "halflings-circle-minus", component: HalflingCircleMinus },
  // { name: "halflings-circle-more", component: HalflingCircleMore },
  // { name: "halflings-circle-play", component: HalflingCirclePlay },
  // { name: "halflings-circle-plus", component: HalflingCirclePlus },
  // { name: "halflings-circle-question", component: HalflingCircleQuestion },
  // { name: "halflings-circle-right", component: HalflingCircleRight },
  // { name: "halflings-circle-selected", component: HalflingCircleSelected },
  // { name: "halflings-circle-up", component: HalflingCircleUp },
  // { name: "halflings-clean", component: HalflingClean },
  // { name: "halflings-clock", component: HalflingClock },
  // { name: "halflings-cloud", component: HalflingCloud },
  // { name: "halflings-cloud-off", component: HalflingCloudOff },
  // { name: "halflings-code", component: HalflingCode },
  // { name: "halflings-coffee-mug", component: HalflingCoffeeMug },
  // { name: "halflings-cogwheel", component: HalflingCogwheel },
  // { name: "halflings-compass", component: HalflingCompass },
  // { name: "halflings-compound", component: HalflingCompound },
  // { name: "halflings-computer", component: HalflingComputer },
  // { name: "halflings-construction-cone", component: HalflingConstructionCone },
  // { name: "halflings-contrast", component: HalflingContrast },
  // { name: "halflings-copy-duplicate", component: HalflingCopyDuplicate },
  // { name: "halflings-credit-card", component: HalflingCreditCard },
  // { name: "halflings-credit-card-off", component: HalflingCreditCardOff },
  // { name: "halflings-crown", component: HalflingCrown },
  // { name: "halflings-cursor", component: HalflingCursor },
  // { name: "halflings-cutlery", component: HalflingCutlery },
  // { name: "halflings-dashboard", component: HalflingDashboard },
  // { name: "halflings-database", component: HalflingDatabase },
  // { name: "halflings-dice", component: HalflingDice },
  // { name: "halflings-direction", component: HalflingDirection },
  // { name: "halflings-disappearing", component: HalflingDisappearing },
  // { name: "halflings-division", component: HalflingDivision },
  // { name: "halflings-dock", component: HalflingDock },
  // { name: "halflings-door-handle", component: HalflingDoorHandle },
  // { name: "halflings-drop", component: HalflingDrop },
  // { name: "halflings-drop-down", component: HalflingDropDown },
  // { name: "halflings-drop-off", component: HalflingDropOff },
  // { name: "halflings-drop-plus", component: HalflingDropPlus },
  // { name: "halflings-education", component: HalflingEducation },
  // { name: "halflings-eject", component: HalflingEject },
  // { name: "halflings-email", component: HalflingEmail },
  // { name: "halflings-envelope", component: HalflingEnvelope },
  // { name: "halflings-equals", component: HalflingEquals },
  // { name: "halflings-eraser", component: HalflingEraser },
  // { name: "halflings-exchange", component: HalflingExchange },
  // { name: "halflings-eye", component: HalflingEye },
  // { name: "halflings-eye-off", component: HalflingEyeOff },
  // { name: "halflings-fan", component: HalflingFan },
  // { name: "halflings-file", component: HalflingFile },
  // { name: "halflings-filter", component: HalflingFilter },
  // { name: "halflings-filter-off", component: HalflingFilterOff },
  // { name: "halflings-fingerprint", component: HalflingFingerprint },
  // { name: "halflings-fire", component: HalflingFire },
  // { name: "halflings-flag", component: HalflingFlag },
  // { name: "halflings-flag-triangle", component: HalflingFlagTriangle },
  // { name: "halflings-flash", component: HalflingFlash },
  // { name: "halflings-folder", component: HalflingFolder },
  // { name: "halflings-folder-open", component: HalflingFolderOpen },
  // { name: "halflings-formula", component: HalflingFormula },
  // { name: "halflings-forward", component: HalflingForward },
  // { name: "halflings-fullscreen", component: HalflingFullscreen },
  // { name: "halflings-fullscreen-off", component: HalflingFullscreenOff },
  // { name: "halflings-function", component: HalflingFunction },
  // { name: "halflings-gift", component: HalflingGift },
  // { name: "halflings-globe", component: HalflingGlobe },
  // { name: "halflings-hard-drive", component: HalflingHardDrive },
  // { name: "halflings-header", component: HalflingHeader },
  // { name: "halflings-headphones", component: HalflingHeadphones },
  // { name: "halflings-heart", component: HalflingHeart },
  // { name: "halflings-heart-empty", component: HalflingHeartEmpty },
  // { name: "halflings-home", component: HalflingHome },
  // { name: "halflings-hourglass", component: HalflingHourglass },
  // { name: "halflings-inbox", component: HalflingInbox },
  // { name: "halflings-indent-left", component: HalflingIndentLeft },
  // { name: "halflings-indent-right", component: HalflingIndentRight },
  // { name: "halflings-initial-letter", component: HalflingInitialLetter },
  // { name: "halflings-italic", component: HalflingItalic },
  // { name: "halflings-key", component: HalflingKey },
  // { name: "halflings-layers", component: HalflingLayers },
  // { name: "halflings-leaf", component: HalflingLeaf },
  // { name: "halflings-line", component: HalflingLine },
  { name: "halflings-link", component: HalflingLink },
  // { name: "halflings-link-remove", component: HalflingLinkRemove },
  // { name: "halflings-list", component: HalflingList },
  // { name: "halflings-lock", component: HalflingLock },
  // { name: "halflings-lock-off", component: HalflingLockOff },
  // { name: "halflings-lock-open", component: HalflingLockOpen },
  // { name: "halflings-log-in", component: HalflingLogIn },
  // { name: "halflings-log-out", component: HalflingLogOut },
  // { name: "halflings-magnet", component: HalflingMagnet },
  // { name: "halflings-man", component: HalflingMan },
  // { name: "halflings-man-woman-compact", component: HalflingManWomanCompact },
  // { name: "halflings-map-marker", component: HalflingMapMarker },
  // { name: "halflings-memory-card", component: HalflingMemoryCard },
  // { name: "halflings-menu", component: HalflingMenu },
  // { name: "halflings-menu-close", component: HalflingMenuClose },
  // { name: "halflings-mic", component: HalflingMic },
  // { name: "halflings-mic-off", component: HalflingMicOff },
  // { name: "halflings-microchip", component: HalflingMicrochip },
  // { name: "halflings-minus", component: HalflingMinus },
  // { name: "halflings-mobile-phone", component: HalflingMobilePhone },
  // { name: "halflings-mobile-phone-off", component: HalflingMobilePhoneOff },
  // { name: "halflings-moon", component: HalflingMoon },
  // { name: "halflings-more", component: HalflingMore },
  // { name: "halflings-more-vertical", component: HalflingMoreVertical },
  // { name: "halflings-music", component: HalflingMusic },
  // { name: "halflings-music-alt", component: HalflingMusicAlt },
  // { name: "halflings-no-symbol", component: HalflingNoSymbol },
  // { name: "halflings-octagon-remove", component: HalflingOctagonRemove },
  // { name: "halflings-octagon-remove-empty", component: HalflingOctagonRemoveEmpty },
  // { name: "halflings-open", component: HalflingOpen },
  // { name: "halflings-page-break", component: HalflingPageBreak },
  // { name: "halflings-paperclip", component: HalflingPaperclip },
  // { name: "halflings-paragraph-center", component: HalflingParagraphCenter },
  // { name: "halflings-paragraph-justify", component: HalflingParagraphJustify },
  // { name: "halflings-paragraph-left", component: HalflingParagraphLeft },
  // { name: "halflings-paragraph-pilcrow", component: HalflingParagraphPilcrow },
  // { name: "halflings-paragraph-right", component: HalflingParagraphRight },
  // { name: "halflings-paste", component: HalflingPaste },
  // { name: "halflings-pause", component: HalflingPause },
  // { name: "halflings-percent-sign", component: HalflingPercentSign },
  // { name: "halflings-picture", component: HalflingPicture },
  // { name: "halflings-pills", component: HalflingPills },
  // { name: "halflings-play", component: HalflingPlay },
  // { name: "halflings-playlist", component: HalflingPlaylist },
  // { name: "halflings-plus", component: HalflingPlus },
  // { name: "halflings-power", component: HalflingPower },
  // { name: "halflings-power-cord-plug", component: HalflingPowerCordPlug },
  // { name: "halflings-power-cord-plug-off", component: HalflingPowerCordPlugOff },
  // { name: "halflings-print", component: HalflingPrint },
  // { name: "halflings-progress", component: HalflingProgress },
  // { name: "halflings-pulse", component: HalflingPulse },
  // { name: "halflings-push-pin", component: HalflingPushPin },
  // { name: "halflings-qr-code", component: HalflingQrCode },
  // { name: "halflings-quotation-left", component: HalflingQuotationLeft },
  // { name: "halflings-quotation-right", component: HalflingQuotationRight },
  // { name: "halflings-random", component: HalflingRandom },
  // { name: "halflings-redo", component: HalflingRedo },
  // { name: "halflings-refresh", component: HalflingRefresh },
  // { name: "halflings-reload", component: HalflingReload },
  // { name: "halflings-reload-alt", component: HalflingReloadAlt },
  // { name: "halflings-repeat", component: HalflingRepeat },
  // { name: "halflings-repeat-once", component: HalflingRepeatOnce },
  // { name: "halflings-restart", component: HalflingRestart },
  // { name: "halflings-retweet", component: HalflingRetweet },
  // { name: "halflings-rewind", component: HalflingRewind },
  // { name: "halflings-roundabout", component: HalflingRoundabout },
  // { name: "halflings-ruler", component: HalflingRuler },
  // { name: "halflings-rulers", component: HalflingRulers },
  // { name: "halflings-satellite", component: HalflingSatellite },
  // { name: "halflings-save", component: HalflingSave },
  // { name: "halflings-save-as", component: HalflingSaveAs },
  // { name: "halflings-scissors", component: HalflingScissors },
  // { name: "halflings-search", component: HalflingSearch },
  // { name: "halflings-send", component: HalflingSend },
  // { name: "halflings-set", component: HalflingSet },
  // { name: "halflings-set-down", component: HalflingSetDown },
  // { name: "halflings-shield-check", component: HalflingShieldCheck },
  // { name: "halflings-sidebar", component: HalflingSidebar },
  // { name: "halflings-skip-to-end", component: HalflingSkipToEnd },
  // { name: "halflings-skip-to-next", component: HalflingSkipToNext },
  // { name: "halflings-skip-to-previous", component: HalflingSkipToPrevious },
  // { name: "halflings-skip-to-start", component: HalflingSkipToStart },
  // { name: "halflings-slightly-frowning", component: HalflingSlightlyFrowning },
  // { name: "halflings-slightly-smiling", component: HalflingSlightlySmiling },
  // { name: "halflings-snowflake", component: HalflingSnowflake },
  // { name: "halflings-sort", component: HalflingSort },
  // { name: "halflings-spell-check", component: HalflingSpellCheck },
  // { name: "halflings-square-2d", component: HalflingSquare2d },
  // { name: "halflings-square-3d", component: HalflingSquare3d },
  // { name: "halflings-square-alert", component: HalflingSquareAlert },
  // { name: "halflings-square-alert-alt", component: HalflingSquareAlertAlt },
  // { name: "halflings-square-check", component: HalflingSquareCheck },
  // { name: "halflings-square-check-alt", component: HalflingSquareCheckAlt },
  // { name: "halflings-square-checkbox", component: HalflingSquareCheckbox },
  // { name: "halflings-square-down", component: HalflingSquareDown },
  // { name: "halflings-square-down-alt", component: HalflingSquareDownAlt },
  // { name: "halflings-square-download", component: HalflingSquareDownload },
  // { name: "halflings-square-indeterminate", component: HalflingSquareIndeterminate },
  // { name: "halflings-square-info", component: HalflingSquareInfo },
  // { name: "halflings-square-info-alt", component: HalflingSquareInfoAlt },
  // { name: "halflings-square-left", component: HalflingSquareLeft },
  // { name: "halflings-square-left-alt", component: HalflingSquareLeftAlt },
  // { name: "halflings-square-live", component: HalflingSquareLive },
  // { name: "halflings-square-menu", component: HalflingSquareMenu },
  // { name: "halflings-square-minus", component: HalflingSquareMinus },
  // { name: "halflings-square-minus-alt", component: HalflingSquareMinusAlt },
  // { name: "halflings-square-more", component: HalflingSquareMore },
  // { name: "halflings-square-play", component: HalflingSquarePlay },
  // { name: "halflings-square-play-alt", component: HalflingSquarePlayAlt },
  // { name: "halflings-square-plus", component: HalflingSquarePlus },
  // { name: "halflings-square-plus-alt", component: HalflingSquarePlusAlt },
  // { name: "halflings-square-question", component: HalflingSquareQuestion },
  // { name: "halflings-square-question-alt", component: HalflingSquareQuestionAlt },
  // { name: "halflings-square-remove", component: HalflingSquareRemove },
  // { name: "halflings-square-remove-alt", component: HalflingSquareRemoveAlt },
  // { name: "halflings-square-right", component: HalflingSquareRight },
  // { name: "halflings-square-right-alt", component: HalflingSquareRightAlt },
  // { name: "halflings-square-selected", component: HalflingSquareSelected },
  // { name: "halflings-square-triangle-down", component: HalflingSquareTriangleDown },
  // { name: "halflings-square-triangle-left", component: HalflingSquareTriangleLeft },
  // { name: "halflings-square-triangle-right", component: HalflingSquareTriangleRight },
  // { name: "halflings-square-triangle-up", component: HalflingSquareTriangleUp },
  // { name: "halflings-square-up", component: HalflingSquareUp },
  // { name: "halflings-square-up-alt", component: HalflingSquareUpAlt },
  // { name: "halflings-square-upload", component: HalflingSquareUpload },
  // { name: "halflings-square-vr", component: HalflingSquareVr },
  // { name: "halflings-star", component: HalflingStar },
  // { name: "halflings-star-empty", component: HalflingStarEmpty },
  // { name: "halflings-star-half", component: HalflingStarHalf },
  // { name: "halflings-stats", component: HalflingStats },
  // { name: "halflings-stats-circle", component: HalflingStatsCircle },
  // { name: "halflings-step-back", component: HalflingStepBack },
  // { name: "halflings-step-forward", component: HalflingStepForward },
  // { name: "halflings-stop", component: HalflingStop },
  // { name: "halflings-strikethrough", component: HalflingStrikethrough },
  // { name: "halflings-sun", component: HalflingSun },
  // { name: "halflings-switch", component: HalflingSwitch },
  // { name: "halflings-switch-off", component: HalflingSwitchOff },
  // { name: "halflings-switch-on", component: HalflingSwitchOn },
  // { name: "halflings-syringe", component: HalflingSyringe },
  // { name: "halflings-table", component: HalflingTable },
  // { name: "halflings-tag", component: HalflingTag },
  // { name: "halflings-temperature", component: HalflingTemperature },
  // { name: "halflings-terminal", component: HalflingTerminal },
  // { name: "halflings-text", component: HalflingText },
  // { name: "halflings-text-background", component: HalflingTextBackground },
  // { name: "halflings-text-left-to-right", component: HalflingTextLeftToRight },
  // { name: "halflings-text-line-break", component: HalflingTextLineBreak },
  // { name: "halflings-text-right-to-left", component: HalflingTextRightToLeft },
  // { name: "halflings-text-underline", component: HalflingTextUnderline },
  // { name: "halflings-thumbnails", component: HalflingThumbnails },
  // { name: "halflings-thumbnails-small", component: HalflingThumbnailsSmall },
  // { name: "halflings-times", component: HalflingTimes },
  // { name: "halflings-trending", component: HalflingTrending },
  // { name: "halflings-triangle-alert", component: HalflingTriangleAlert },
  // { name: "halflings-truck", component: HalflingTruck },
  // { name: "halflings-tv", component: HalflingTv },
  // { name: "halflings-underline", component: HalflingUnderline },
  // { name: "halflings-undo", component: HalflingUndo },
  // { name: "halflings-usb-device", component: HalflingUsbDevice },
  // { name: "halflings-user", component: HalflingUser },
  // { name: "halflings-user-group", component: HalflingUserGroup },
  // { name: "halflings-vector-path", component: HalflingVectorPath },
  // { name: "halflings-verify-check", component: HalflingVerifyCheck },
  // { name: "halflings-verify-empty", component: HalflingVerifyEmpty },
  // { name: "halflings-video", component: HalflingVideo },
  // { name: "halflings-video-off", component: HalflingVideoOff },
  // { name: "halflings-video-play", component: HalflingVideoPlay },
  // { name: "halflings-video-play-empty", component: HalflingVideoPlayEmpty },
  // { name: "halflings-volume-down", component: HalflingVolumeDown },
  // { name: "halflings-volume-off", component: HalflingVolumeOff },
  // { name: "halflings-volume-up", component: HalflingVolumeUp },
  // { name: "halflings-wallet", component: HalflingWallet },
  // { name: "halflings-wifi", component: HalflingWifi },
  // { name: "halflings-woman", component: HalflingWoman },
  // { name: "halflings-wrench", component: HalflingWrench },
  //
  // Halflings End
  //
  // =============================================================================


  // =============================================================================
  //
  // Social Start
  //
  { name: "social-medium", component: SocialMedium },
  // { name: "social-steam", component: SocialSteam },
  // { name: "social-tumblr", component: SocialTumblr },
  // { name: "social-tripadvisor", component: SocialTripadvisor },
  // { name: "social-dropbox", component: SocialDropbox },
  // { name: "social-squarespace", component: SocialSquarespace },
  // { name: "social-yelp", component: SocialYelp },
  // { name: "social-airbnb", component: SocialAirbnb },
  // { name: "social-linux", component: SocialLinux },
  // { name: "social-foursquare", component: SocialFoursquare },
  // { name: "social-android", component: SocialAndroid },
  // { name: "social-tinder", component: SocialTinder },
  // { name: "social-viber", component: SocialViber },
  // { name: "social-github", component: SocialGithub },
  // { name: "social-etsy", component: SocialEtsy },
  // { name: "social-myspace", component: SocialMyspace },
  // { name: "social-wikipedia", component: SocialWikipedia },
  // { name: "social-vk", component: SocialVk },
  // { name: "social-vimeo", component: SocialVimeo },
  // { name: "social-instagram", component: SocialInstagram },
  // { name: "social-zoom", component: SocialZoom },
  // { name: "social-blogger", component: SocialBlogger },
  // { name: "social-spotify", component: SocialSpotify },
  // { name: "social-periscope", component: SocialPeriscope },
  // { name: "social-kickstarter", component: SocialKickstarter },
  // { name: "social-snapchat", component: SocialSnapchat },
  // { name: "social-quora", component: SocialQuora },
  // { name: "social-playstation", component: SocialPlaystation },
  // { name: "social-facebook", component: SocialFacebook },
  // { name: "social-pinterest", component: SocialPinterest },
  // { name: "social-stack-overflow", component: SocialStackOverflow },
  // { name: "social-whatsapp", component: SocialWhatsapp },
  // { name: "social-stack-exchange", component: SocialStackExchange },
  // { name: "social-xing", component: SocialXing },
  // { name: "social-windows", component: SocialWindows },
  // { name: "social-youtube", component: SocialYoutube },
  // { name: "social-reddit", component: SocialReddit },
  // { name: "social-evernote", component: SocialEvernote },
  // { name: "social-dribbble", component: SocialDribbble },
  // { name: "social-xbox", component: SocialXbox },
  // { name: "social-badoo", component: SocialBadoo },
  // { name: "social-linkedin", component: SocialLinkedin },
  // { name: "social-twitter", component: SocialTwitter },
  // { name: "social-patreon", component: SocialPatreon },
  // { name: "social-discord", component: SocialDiscord },
  // { name: "social-slack", component: SocialSlack },
  // { name: "social-soundcloud", component: SocialSoundcloud },
  // { name: "social-skype", component: SocialSkype },
  // { name: "social-wordpress", component: SocialWordpress },
  // { name: "social-apple", component: SocialApple },
  // { name: "social-netflix", component: SocialNetflix },
  // { name: "social-creative-cloud", component: SocialCreativeCloud },
  // { name: "social-behance", component: SocialBehance },
  //
  // Social End
  //
  // =============================================================================


  // =============================================================================
  //
  // Filetype start
  //
  { name: "filetype-file", component: FiletypeFile },
  // { name: "filetype-file-alert", component: FiletypeFileAlert },
  // { name: "filetype-file-audio", component: FiletypeFileAudio },
  // { name: "filetype-file-bookmark", component: FiletypeFileBookmark },
  // { name: "filetype-file-broken", component: FiletypeFileBroken },
  // { name: "filetype-file-cad", component: FiletypeFileCad },
  // { name: "filetype-file-check", component: FiletypeFileCheck },
  // { name: "filetype-file-cloud", component: FiletypeFileCloud },
  // { name: "filetype-file-css", component: FiletypeFileCss },
  // { name: "filetype-file-database", component: FiletypeFileDatabase },
  // { name: "filetype-file-disc-image", component: FiletypeFileDiscImage },
  // { name: "filetype-file-edit", component: FiletypeFileEdit },
  // { name: "filetype-file-editing", component: FiletypeFileEditing },
  // { name: "filetype-file-font", component: FiletypeFileFont },
  // { name: "filetype-file-heart", component: FiletypeFileHeart },
  // { name: "filetype-file-image", component: FiletypeFileImage },
  // { name: "filetype-file-incoming", component: FiletypeFileIncoming },
  // { name: "filetype-file-incomplete", component: FiletypeFileIncomplete },
  // { name: "filetype-file-info", component: FiletypeFileInfo },
  // { name: "filetype-file-invisible", component: FiletypeFileInvisible },
  // { name: "filetype-file-key", component: FiletypeFileKey },
  // { name: "filetype-file-lock", component: FiletypeFileLock },
  // { name: "filetype-file-minus", component: FiletypeFileMinus },
  // { name: "filetype-file-music", component: FiletypeFileMusic },
  // { name: "filetype-file-no", component: FiletypeFileNo },
  // { name: "filetype-file-package", component: FiletypeFilePackage },
  // { name: "filetype-file-photo", component: FiletypeFilePhoto },
  // { name: "filetype-file-plugin", component: FiletypeFilePlugin },
  // { name: "filetype-file-plus", component: FiletypeFilePlus },
  // { name: "filetype-file-presentation", component: FiletypeFilePresentation },
  // { name: "filetype-file-program", component: FiletypeFileProgram },
  // { name: "filetype-file-pulse", component: FiletypeFilePulse },
  // { name: "filetype-file-question", component: FiletypeFileQuestion },
  // { name: "filetype-file-remove", component: FiletypeFileRemove },
  // { name: "filetype-file-rich-text", component: FiletypeFileRichText },
  // { name: "filetype-file-script", component: FiletypeFileScript },
  // { name: "filetype-file-search", component: FiletypeFileSearch },
  // { name: "filetype-file-settings", component: FiletypeFileSettings },
  // { name: "filetype-file-shield", component: FiletypeFileShield },
  // { name: "filetype-file-spreadsheet", component: FiletypeFileSpreadsheet },
  // { name: "filetype-file-stats", component: FiletypeFileStats },
  // { name: "filetype-file-tag", component: FiletypeFileTag },
  // { name: "filetype-file-terminal", component: FiletypeFileTerminal },
  // { name: "filetype-file-text", component: FiletypeFileText },
  // { name: "filetype-file-unlock", component: FiletypeFileUnlock },
  // { name: "filetype-file-vector", component: FiletypeFileVector },
  // { name: "filetype-file-video", component: FiletypeFileVideo },
  // { name: "filetype-file-visible", component: FiletypeFileVisible },
  // { name: "filetype-file-web", component: FiletypeFileWeb },
  // { name: "filetype-file-zip", component: FiletypeFileZip },
  // { name: "filetype-folder", component: FiletypeFolder },
  // { name: "filetype-folder-alert", component: FiletypeFolderAlert },
  // { name: "filetype-folder-applications", component: FiletypeFolderApplications },
  // { name: "filetype-folder-archive", component: FiletypeFolderArchive },
  // { name: "filetype-folder-bookmark", component: FiletypeFolderBookmark },
  // { name: "filetype-folder-burnable", component: FiletypeFolderBurnable },
  // { name: "filetype-folder-burnable-alt", component: FiletypeFolderBurnableAlt },
  // { name: "filetype-folder-check", component: FiletypeFolderCheck },
  // { name: "filetype-folder-circle-down", component: FiletypeFolderCircleDown },
  // { name: "filetype-folder-circle-up", component: FiletypeFolderCircleUp },
  // { name: "filetype-folder-clock", component: FiletypeFolderClock },
  // { name: "filetype-folder-cloud", component: FiletypeFolderCloud },
  // { name: "filetype-folder-desktop", component: FiletypeFolderDesktop },
  // { name: "filetype-folder-documents", component: FiletypeFolderDocuments },
  // { name: "filetype-folder-downloads", component: FiletypeFolderDownloads },
  // { name: "filetype-folder-edit", component: FiletypeFolderEdit },
  // { name: "filetype-folder-editing", component: FiletypeFolderEditing },
  // { name: "filetype-folder-favorites", component: FiletypeFolderFavorites },
  // { name: "filetype-folder-games", component: FiletypeFolderGames },
  // { name: "filetype-folder-heart", component: FiletypeFolderHeart },
  // { name: "filetype-folder-home", component: FiletypeFolderHome },
  // { name: "filetype-folder-image", component: FiletypeFolderImage },
  // { name: "filetype-folder-info", component: FiletypeFolderInfo },
  // { name: "filetype-folder-invisible", component: FiletypeFolderInvisible },
  // { name: "filetype-folder-key", component: FiletypeFolderKey },
  // { name: "filetype-folder-library", component: FiletypeFolderLibrary },
  // { name: "filetype-folder-lock", component: FiletypeFolderLock },
  // { name: "filetype-folder-minus", component: FiletypeFolderMinus },
  // { name: "filetype-folder-music", component: FiletypeFolderMusic },
  // { name: "filetype-folder-network", component: FiletypeFolderNetwork },
  // { name: "filetype-folder-network-off", component: FiletypeFolderNetworkOff },
  // { name: "filetype-folder-no", component: FiletypeFolderNo },
  // { name: "filetype-folder-open", component: FiletypeFolderOpen },
  // { name: "filetype-folder-photo", component: FiletypeFolderPhoto },
  // { name: "filetype-folder-plugin", component: FiletypeFolderPlugin },
  // { name: "filetype-folder-plus", component: FiletypeFolderPlus },
  // { name: "filetype-folder-question", component: FiletypeFolderQuestion },
  // { name: "filetype-folder-remove", component: FiletypeFolderRemove },
  // { name: "filetype-folder-search", component: FiletypeFolderSearch },
  // { name: "filetype-folder-settings", component: FiletypeFolderSettings },
  // { name: "filetype-folder-shared", component: FiletypeFolderShared },
  // { name: "filetype-folder-shield", component: FiletypeFolderShield },
  // { name: "filetype-folder-smart", component: FiletypeFolderSmart },
  // { name: "filetype-folder-tag", component: FiletypeFolderTag },
  // { name: "filetype-folder-unlock", component: FiletypeFolderUnlock },
  // { name: "filetype-folder-uploads", component: FiletypeFolderUploads },
  // { name: "filetype-folder-user", component: FiletypeFolderUser },
  // { name: "filetype-folder-users", component: FiletypeFolderUsers },
  // { name: "filetype-folder-video", component: FiletypeFolderVideo },
  // { name: "filetype-folder-visible", component: FiletypeFolderVisible },
  //
  // Filetype end
  //
  // =============================================================================
];

export default library;
