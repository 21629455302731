import DescriptionSearch from "common/DescriptorSearch";
import LoadingBox from "common/LoadingBox";
import PriceChange from "common/PriceChange";
import React from "react";
import Trigger from "common/Trigger";
import { HalflingIcon } from "common/Icon";
import { gql } from '@apollo/client';
import { useMutation } from "@apollo/client";
import { useQuery } from "@apollo/client";
import { useState } from "react";

const Watchlist = () => {
  const [searchVisible, setSearchVisibility] = useState(false);
  const WATCHLIST_QUERY = gql`
    query GetWatchlist {
      watchlistEntries {
        id
        shortcode
        price
        absoluteChange
        relativeChange
      }
    }
  `;

  const ADD_WATCHLIST_ENTRY = gql`
    mutation AddWatchlistEntry($descriptorId: String!) {
      addWatchlistEntry(descriptorId: $descriptorId) {
        watchlistEntry {
          id
          shortcode
          price
          absoluteChange
          relativeChange
        }
      }
    }
  `;

  const DELETE_WATCHLIST_ENTRY = gql`
    mutation DeleteWatchlistEntry($watchlistEntryId: String!) {
      deleteWatchlistEntry(watchlistEntryId: $watchlistEntryId) {
        id
      }
    }
  `;

  const {
    data: queryData,
    loading: queryLoading
  } = useQuery(WATCHLIST_QUERY, {
    pollInterval: 2000,
  });

  const [
    addWatchlistEntry,
  ] = useMutation(ADD_WATCHLIST_ENTRY, {
    refetchQueries: [
      WATCHLIST_QUERY
    ]
  });

  const [deleteWatchlistEntry] = useMutation(DELETE_WATCHLIST_ENTRY, {
    refetchQueries: [
      WATCHLIST_QUERY
    ]
  });

  const handleSelect = (descriptor) => {
    addWatchlistEntry({
      variables: {
        descriptorId: descriptor.id
      }
    });

    setSearchVisibility(false);
  }

  const handleDelete = (watchlistEntryId) => {
    deleteWatchlistEntry({
      variables: {
        watchlistEntryId: watchlistEntryId
      }
    })
  }

  if (queryLoading) {
    return <LoadingBox />
  }

  return (
    <div className="border-b border-green-900 pb-5">
      <div className="mb-5">
        {queryData.watchlistEntries.map((entry) => (
          <div
            className="flex mb-1"
            key={entry.id}
          >
            <div className="grow">
              <PriceChange
                label={entry.shortcode}
                price={entry.price}
                absoluteChange={entry.absoluteChange}
                relativeChange={entry.relativeChange}
                linkToLabel
              />
            </div>

            <div className="shrink ml-2 my-auto">
              <HalflingIcon
                name="circle-remove"
                className="fill-neutral-400 hover:fill-red-900 cursor-pointer"
                onClick={() => handleDelete(entry.id)}
              />
            </div>
          </div>
        ))}
      </div>

      {searchVisible && (
        <div>
          <DescriptionSearch
            handleSelect={handleSelect}
            itemLimit={7}
            itemVariant="small"
            handleEscape={() => setSearchVisibility(false)}
          />

          <div className="text-center text-sm mt-3">
            Type to search or <Trigger
              colorVariant="red"
              onClick={() => setSearchVisibility(false)}
            >
              Cancel
            </Trigger>
          </div>
        </div>
      )}

      {!searchVisible && (
        <div className="text-sm">
          <Trigger onClick={() => {setSearchVisibility(true)}}>
            Watch company
          </Trigger>
        </div>
      )}
    </div>
  );
}

export default Watchlist;
