import Heading from "common/Heading";
import Ratio from "./CapitalRatios/Ratio";
import React from "react";
import TableLegend from "common/TableLegend";
import { KeystatsContext } from "./Context";
import { useContext } from "react";

const CapitalRatios = (
) => {
  const {
    capitalRatios
  } = useContext(KeystatsContext)

  return (
    <div>
      <Heading variant="tiny">
        Capital & Financial Ratios
      </Heading>

      {capitalRatios.map((ratio, index) => (
        <Ratio
          key={index}
          {...ratio}
        />
      ))}

      <TableLegend>
        in millions of $
      </TableLegend>
    </div>
  )
}

export default CapitalRatios;
