import PropTypes from "prop-types";
import React from "react";
import cx from "classnames";
import library from "./Icons/Library";

const IconBase = ({ name, variant, className, ...args }) => {
  const fullName = [variant, name].join("-");
  const libraryIcon = library.find((element) => {
    return element.name === fullName;
  });
  const IconElement = libraryIcon.component;

  const iconClass = cx(
    className,
    {
      "fill-slate-800": !className?.includes("fill-")
    }
  );

  return (
    <IconElement
      role="img"
      aria-label="Decorative Icon"
      alt="Decorative Icon"
      className={iconClass}
      {...args}
    />
  );
}


export const Icon = ({ name, className, ...args }) => {
  return (
    <IconBase
      name={name}
      className={className}
      variant="base"
      style={{ width: "32px", height: "32px" }}
      {...args}
    />
  );
}

export const HalflingIcon = ({ name, className, ...args }) => {
  return (
    <IconBase
      name={name}
      className={className}
      variant="halflings"
      style={{ width: "20px", height: "20px" }}
      {...args}
    />
  );
}

export const FiletypeIcon = ({ name, className, ...args }) => {
  return (
    <IconBase
      name={name}
      className={className}
      variant="filetype"
      style={{ width: "32px", height: "32px" }}
      {...args}
    />
  );
}

export const SocialIcon = ({ name, className, ...args }) => {
  return (
    <IconBase
      name={name}
      className={className}
      variant="social"
      style={{ width: "32px", height: "32px" }}
      {...args}
    />
  );
}

const iconPropTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
};

Icon.propTypes = iconPropTypes;
HalflingIcon.propTypes = iconPropTypes;
FiletypeIcon.propTypes = iconPropTypes;
SocialIcon.propTypes = iconPropTypes;
