import React from "react";
import FormattedNumber from "common/FormattedNumber";
import { ScreenerTableContext } from "Screener/Context";
import { useContext } from "react";

const Value = ({
  descriptorShortcode,
  shortcode,
  value,
  unit,
}) => {
  const {
    state,
  } = useContext(ScreenerTableContext);

  const valueDisplayType = state.indicatorsForQuery.find((indicator) => {
    return indicator.shortcode === shortcode
  }).valueDisplayType;

  const indicatorImageName = shortcode.toLowerCase().replace(":", "_");
  const imageLink = `/screener_plots/${descriptorShortcode}/${indicatorImageName}.png`

  return (
    <div className="min-w-24 p-1">
      <div className="text-right">
        {valueDisplayType === "plot" && (
          <img
            className="max-w-[280px] w-[280px] h-[210px]"
            align="right"
            loading="lazy"
            height="210px"
            src={imageLink}
            width="280px"
          />
        )}

        {valueDisplayType !== "plot" && (
          <FormattedNumber
            className="text-sm"
            number={value}
            unit={unit}
          />
        )}
      </div>
    </div>
  );
}

export default Value;
