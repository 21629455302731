import Link from "common/Link";
import React from "react";
import { SAVE_SCREENER_TOKEN } from "queries/screener";
import { isNil } from "lodash";
import { omitBy } from "lodash";
import { useEffect } from "react";
import { useMutation } from "@apollo/client";

const ScreenerLink = ({
  children,
  indicators,
  sortBy,
  sortDirection,
}) => {
  const [
    saveToken,
    {
      data: {
        findOrCreateScreenerToken: {
          screenerToken: {
            token =""
          } ={}
        } ={}
      } ={},
      loading
    }
  ] = useMutation(SAVE_SCREENER_TOKEN);

  const indicatorsForQuery = indicators.map((indicator) => {
    return omitBy(
      {
        screenerType: indicator.indicatorRecord.screenerType,
        shortcode: indicator.shortcode,
        showInTable: indicator.showInTable,
        value: indicator.value,
        valueDisplayType: indicator.valueDisplayType,
      }, isNil
    );
  });

  useEffect(() => {
    saveToken({
      variables: {
        indicatorsForQuery,
        sortBy,
        sortDirection,
      }
    });

  }, []);

  return (
    <React.Fragment>
      {!loading && (
        <Link
          url={`/screener/${token}`}
        >
          {children}
        </Link>
      )}
    </React.Fragment>
  );
}

export default ScreenerLink;
