import React from "react";
import { Helmet } from "react-helmet";
import { WIKI_QUERY } from "queries";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";

const Wiki = () => {
  const { slug } = useParams();

  const {
    data: {
      wikiPage: {
        description ="",
        html ="",
        keywords ="",
        title ="",
      } ={}
    } ={}
  } = useQuery(WIKI_QUERY, {
    variables: {
      slug,
    }
  });

  const articleClass = `
    [&>h2]:text-3xl
    [&>h2]:mt-7
    [&>h2]:mb-5
    [&>h2]:font-mono

    [&>h3]:text-xl
    [&>h3]:mt-5
    [&>h3]:mb-1
    [&>h3]:font-mono

    [&>ul]:list-disc
    [&>ul]:mb-10
    [&>ul>li]:text-base
    [&>ul>li]:mb-1

    [&>ol]:list-decimal
    [&>ol]:mb-10
    [&>ol>li]:text-base
    [&>ol>li]:mb-1

    [&>p]:mb-3
    [&>p]:text-lg

    [&_span.katex-html]:hidden
    [&_span.katex]:my-5
    [&_span.katex]:block
  `;

  return (
    <React.Fragment>
      <Helmet>
        <title>{title} — stockrow</title>
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
      </Helmet>

      <div className="max-w-3xl m-auto bg-white p-5">
        <h1 className="text-4xl mb-5 font-mono">
          {title}
        </h1>

        <div
          className={articleClass}
          dangerouslySetInnerHTML={{ __html: html }}
        />
      </div>
    </React.Fragment>
  )
}

export default Wiki;
