import Button from "common/Button";
import React from "react";
import TextInput from "common/TextInput";
import Trigger from "common/Trigger";
import { useMemo } from "react";
import { useState } from "react";

const Form = ({
  value = "",
  disableSave = false,
  handleSave = () => {},
  handleCancel = () => {},
  notifications = false
}) => {
  const [screenerName, setScreenerName] = useState(value);

  const disableButton = useMemo(() => {
    return disableSave || screenerName === "";
  }, [disableSave, screenerName])

  const handleKeypress = (event) => {
    switch (event.keyCode) {
      case 13: // ENTER
        event.preventDefault();
        fireSaveEvent();

        break;

      case 27: // ESC
        event.preventDefault();
        handleCancel();

        break;

      default:
        break;
    }
  }

  const fireSaveEvent = () => {
    setScreenerName("");
    handleSave(screenerName, notifications);
  }

  const fireCancelEvent = () => {
    setScreenerName("");
    handleCancel();
  }

  return (
    <div className="grow mb-5">
      <div className="mb-2">
        <TextInput
          autoFocus
          label="Screener name"
          onChange={(e) => setScreenerName(e.target.value)}
          colorVariant="white"
          value={screenerName}
          onKeyUp={handleKeypress}
        />
      </div>

      <div>
        <Button
          variant="small"
          disabled={disableButton}
          onClick={fireSaveEvent}
        >
          Save
        </Button> or <Trigger colorVariant="red" onClick={fireCancelEvent}>
          cancel
        </Trigger>
      </div>
    </div>
  );
}

export default Form;
