import DescriptorHeader from "DescriptorHeader";
import DescriptorNavigation from "DescriptorNavigation";
import React from "react";
import { Navigate } from "react-router-dom";
import { Outlet } from "react-router-dom";
import { useParams } from "react-router-dom";

const Table = () => {
  let {
    descriptorShortcode,
    fincialsTableCode,
    financialsTableParams,
  } = useParams();

  return (
    <React.Fragment>
      <DescriptorHeader />

      <div className="hidden md:block">
        <DescriptorNavigation />
      </div>

      {financialsTableParams !== undefined && (
        <Outlet />
      )}

      {financialsTableParams === undefined && (
        <Navigate
          replace={true}
          to={`/${descriptorShortcode}/${fincialsTableCode}/q-desc`}
        />
      )}
    </React.Fragment>
  )
}

export default Table;
