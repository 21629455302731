import React, { useContext } from "react";
import { HalflingIcon } from "common/Icon";
import { ScreenerFilterContext } from "Screener/Context";

const Boolean = ({
  indicator,
}) => {
  const {
    dispatch
  } = useContext(ScreenerFilterContext);

  const toggleValue = () => {
    dispatch({
      type: "SET_CRITERION_VALUE",
      id: indicator.id,
      shortcode: indicator.shortcode,
      value: indicator.value === "0.0" ? "1.0" : "0.0",
    });
  }

  return (
    <React.Fragment>
      <div className="mx-1">
        {indicator.indicatorRecord.name}
      </div>

      <div className="mx-3">
        <div>
          {indicator.value === "0.0" && (
            <HalflingIcon
              className="fill-slate-50 cursor-pointer"
              name="square-empty"
              onClick={toggleValue}
            />
          )}

          {indicator.value === "1.0" && (
            <HalflingIcon
              className="fill-slate-50 cursor-pointer"
              name="square-checkbox"
              onClick={toggleValue}
            />
          )}
        </div>
      </div>
    </React.Fragment>
  );
}

export default Boolean;
