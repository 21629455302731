import Button from "common/Button"
import React from "react";
import { NewsContext } from "common/News/Context";
import { useContext } from "react";

const Refresh = () => {
  const {
    dispatch,
    refetch,
    state: {
      buttonDisabled,
    } ={},
  } = useContext(NewsContext);

  const handleRefetch = async () => {
    dispatch({
      action: "DEFAULT",
      stateChanges: {
        buttonDisabled: true
      }
    });

    await refetch();

    dispatch({
      action: "DEFAULT",
      stateChanges: {
        buttonDisabled: false
      }
    });
  }

  return (
    <Button
      className="w-full"
      disabled={buttonDisabled}
      onClick={handleRefetch}
      variant="small"
      colorVariant="gray"
    >
      Refresh News
    </Button>
  );
}

export default Refresh;
