import React from "react";

const Boolean = ({
  value,
}) => {
  return (
    <div className="min-w-24 p-1">
      <div className="text-right text-sm">
        {value}
      </div>
    </div>
  );
}

export default Boolean;
