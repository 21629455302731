import PropTypes from "prop-types";
import React, { forwardRef } from "react";
import cx from "classnames";

const HtmlInput = forwardRef(
  ({
    ...props
  },
    ref
  ) => {
    return (
      <input
        ref={ref}
        {...props}
      />
    );
  }
);

const HtmlTextarea = forwardRef(
  ({
    ...props
  },
    ref
  ) => {
    return (
      <textarea
        ref={ref}
        {...props}
      />
    );
  }
);

const TextInput = forwardRef(
  (
    {
      area = false,
      id,
      label,
      colorVariant,
      variant,
      state,
      name,
      autoFocus,
      ...props
    },
    ref
  ) => {
    const isError = state === "error";

    const colorMap = {
      default: "bg-slate-100",
      white: "bg-white",
    }

    const inputClass = cx(`
      block
      px-2.5
      pb-2.5
      pt-5
      w-full
      text-sm
      border-0
      border-b-2
      appearance-none
      focus:outline-none
      focus:ring-0
      peer
    `,
    colorMap[colorVariant],
      {
        "border-slate-300": !isError,
        "focus:border-emerald-800": !isError,
        "border-red-800": isError,
        "focus:border-red-800": isError,
      }
    );

    const labelClass = cx(`
      absolute
      text-sm
      duration-300
      transform
      -translate-y-4
      scale-75
      top-4
      z-10
      origin-[0]
      left-2.5
      cursor-text
      peer-placeholder-shown:scale-100
      peer-placeholder-shown:translate-y-0
      peer-focus:scale-75
      peer-focus:-translate-y-4
    `,
      {
        "text-slate-400": !isError,
        "peer-focus:text-emerald-800": !isError,
        "text-red-800": isError,
        "peer-focus:text-red-800": isError,
      }
    );

    const InputComponent = area ? HtmlTextarea : HtmlInput;

    return (
      <div className="relative">
        <InputComponent
          autoFocus={autoFocus}
          ref={ref}
          type={variant}
          id={id}
          className={inputClass}
          placeholder=""
          name={name}
          {...props}
        />

        <label
          htmlFor={id}
          className={labelClass}
        >
          {label}
        </label>
      </div>
    )
  }
);

TextInput.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string.isRequired,
  colorVariant: PropTypes.oneOf(["default", "white"]),
  variant: PropTypes.oneOf(["text", "password"]),
  state: PropTypes.oneOf(["default", "error"]),
  name: PropTypes.string,
  autoFocus: PropTypes.bool,
};

TextInput.defaultProps = {
  autoFocus: false,
  colorVariant: "default",
  variant: "text",
  state: "default",
  id: Math.random().toString(36).slice(-5),
};

export default TextInput;
