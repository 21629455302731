import React from "react";
import Heading from "common/Heading";
import LoadingBox from "common/LoadingBox";
import { useQuery } from '@apollo/client';
import { NEWS_DAILY_SUMMARY_QUERY } from "queries"

const NewsSummary = () => {
  const {
    loading,
    data: {
      newsSummary: {
        summaryDate,
        text,
      } ={}
    } ={}
  } = useQuery(NEWS_DAILY_SUMMARY_QUERY);

  if (loading) { return <LoadingBox /> }

  return (
    <div>
      <div className="px-3 lg:px-10 pt-5 pb-2">
        <Heading>{`${summaryDate} Summary`}</Heading>
      </div>

      <div className="w-full m-auto bg-amber-50 p-3 lg:p-10">
        <div
          className="flex flex-col text-md lg:text-lg gap-5 lg:gap-7"
          dangerouslySetInnerHTML={{ __html: text }}
        />

        <div className="text-slate-400 font-mono text-xs mt-10">
          Summaries are generated daily at 7pm New York time
        </div>
      </div>
    </div>
  )
}

export default NewsSummary;
