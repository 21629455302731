import DataGrid from "./Table/DataGrid";
import EmptyScreener from "./Table/EmptyScreener";
import LoadingLines from "common/LoadingLines";
import React from "react";
import { SCREENER_QUERY } from "queries/screener";
import { ScreenerTableContext } from "Screener/Context";
import { isNil } from "lodash";
import { makeVar } from "@apollo/client";
import { omitBy } from "lodash";
import { useEffect } from "react";
import { useMemo } from "react";
import { useQuery } from "@apollo/client";
import { useReducer } from "react";

const reactiveTableState = makeVar({
  loading: true
});

const Table = ({
  indicators,
  sortBy = "shortcode",
  sortDirection = "ASC",
  handleTokenChange = () => {},
  sample = false
}) => {
  const indicatorsForQuery = useMemo(() => {
    return indicators.map((indicator) => {
      return omitBy(
        {
          screenerType: indicator.indicatorRecord.screenerType,
          shortcode: indicator.shortcode,
          showInTable: indicator.showInTable,
          value: indicator.value,
          valueDisplayType: indicator.valueDisplayType
        }, isNil
      );
    });
  }, [indicators]);

  const hasPlots = useMemo(() => {
    return indicators.map((indicator) => {
      return indicator.valueDisplayType
    }).includes("plot");
  }, [indicators]);

  useEffect(() => {
    dispatch({
      type: "DEFAULT",
      stateChanges: {
        sortBy,
        sortDirection
      }
    });
  }, [sortBy, sortDirection])

  const tableReducer = (state, action) => {
    switch (action.type) {
      case "CHANGE_SORT_DIRECTION":
        const { sortBy } = action;
        let sortDirection;

        if (action.sortBy === state.sortBy) {
          sortDirection = state.sortDirection === "ASC" ? "DESC" : "ASC";
        } else {
          sortDirection = state.sortDirection;
        }

        return {
          ...state,
          sortBy,
          sortDirection,
        }

      default:
        return {
          ...state,
          ...action.stateChanges
        }
    }
  }

  const [state, dispatch] = useReducer(tableReducer, {
    sortBy,
    sortDirection,
    indicatorsForQuery,
  });

  useEffect(() => {
    dispatch({
      type: "DEFAULT",
      stateChanges: {
        indicatorsForQuery,
      }
    });
  }, [indicatorsForQuery]);

  const {
    loading,
    data: {
      screener: {
        headers =[],
        rows =[],
        token ="",
        count =0,
      } ={}
    } ={}
  } = useQuery(SCREENER_QUERY, {
    fetchPolicy: "no-cache",
    variables: {
      sample,
      screenerParams: state,
    }
  });

  useEffect(() => {
    handleTokenChange(token);
  }, [token, handleTokenChange]);

  useEffect(() => {
    reactiveTableState({
      ...reactiveTableState(),
      loading
    });
  }, [loading]);

  if (loading) {
    return (
      <LoadingLines
        lines={5}
        height="190px"
        lineHeight="35px"
      />
    );
  }

  return (
    <ScreenerTableContext.Provider value={{ state, dispatch }}>
      {rows.length > 0 && (
        <React.Fragment>
          <div className="flex mb-3 gap-6 text-sm text-slate-600">
            <div>
              Matching companies: {count}
            </div>

            {hasPlots && (
              <React.Fragment>
                <div className="flex gap-1 h-auto">
                  <div className="border-t-2 border-dashed border-red-700 w-5 m-auto">
                  </div>

                  <div>
                    median
                  </div>
                </div>

                <div className="flex gap-1 h-auto">
                  <div className="border-t-2 border-blue-800 w-5 m-auto">
                  </div>

                  <div>
                    mean
                  </div>
                </div>

                <div className="flex gap-1 h-auto">
                  <div className="border-t border-dotted border-zinc-600 w-5 m-auto">
                  </div>

                  <div>
                    thirds
                  </div>
                </div>
              </React.Fragment>
            )}
          </div>

          <DataGrid
            headers={headers}
            rows={rows}
          />
        </React.Fragment>
      )}

      {rows.length === 0 && (
        <EmptyScreener />
      )}
    </ScreenerTableContext.Provider>
  )
}

export default Table;
export { reactiveTableState };
