import Checkbox from "common/Checkbox";
import React from "react";
import Trigger from "common/Trigger";
import { ScreenerFilterContext } from "Screener/Context";
import { sortBy } from "lodash";
import { useCallback } from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { createPortal } from "react-dom";

const SectorList = ({
  portalElement,
}) => {
  const {
    state,
    dispatch
  } = useContext(ScreenerFilterContext);

  const hideList = useCallback(() => {
    dispatch({
      type: "DEFAULT",
      stateChanges: {
        filterBoxStyle: "",
      }
    });
  }, [dispatch]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      switch (event.keyCode) {
        case 27: // ESC
          event.preventDefault();
          hideList();
          break;

        default:
          break;
      }
    }

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    }
  }, [hideList]);

  const sectorIndicator = state.indicatorsInQuery.find((indicator) => {
    return indicator.shortcode === "sector";
  });

  const [selectedSectors, setSelectedSectors] = useState(
    sectorIndicator.value
  );

  const handleChange = (event) => {
    let newSelectedSectors;

    if (event.target.checked) {
      const deconstructedSectors = [...selectedSectors];
      deconstructedSectors.push(event.target.name);

      newSelectedSectors = [
        ...new Set([...deconstructedSectors])
      ];
    } else {
      newSelectedSectors = selectedSectors.filter((sector) => {
        return sector !== event.target.id;
      });
    }

    setSelectedSectors(newSelectedSectors);

    dispatch({
      type: "SET_CRITERION_VALUE",
      shortcode: "sector",
      value: newSelectedSectors,
    });
  }

  const isChecked = (sectorName) => {
    return selectedSectors.includes(sectorName);
  }

  const clearSelection = () => {
    setSelectedSectors([]);

    dispatch({
      type: "SET_CRITERION_VALUE",
      shortcode: "sector",
      value: [],
    });
  }

  const sectorList = sortBy(state.sectors, ["name"]);

  return (
    <React.Fragment>
      {createPortal(
        (
          <Trigger onClick={clearSelection}>
            Clear Sector Selection
          </Trigger>
        ),
        portalElement
      )}

      <div className="grid grid-cols-4 w-full">
        {sectorList.map((descriptor) => (
          <div key={descriptor.shortcode}>
            <Checkbox
              id={descriptor.name}
              name={descriptor.name}
              type="checkbox"
              onChange={handleChange}
              checked={isChecked(descriptor.name)}
            >
              {descriptor.name}
            </Checkbox>
          </div>
        ))}
      </div>
    </React.Fragment>
  );
}

export default SectorList;
