import Body from "./Body";
import Header from "./Header";
import React from "react";

const DataGrid = ({
  headers,
  rows,
}) => {
  return (
    <div className="w-full overflow-scroll">
      <table className="w-full">
        <Header
          headers={headers}
        />

        <Body
          rows={rows}
        />
      </table>
    </div>
  )

}

export default DataGrid;
