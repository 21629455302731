import React from "react";
import FormattedNumber from "common/FormattedNumber";
import cx from "classnames";

const RegularPayout = ({
  dividend,
  gridBasis,
}) => {
  const totalClass = cx(
    `
      pr-3
      text-right
    `,
    {
      "italic": dividend.prediction,
    }
  );

  const gridMap = [
    "grid-cols-1",
    "grid-cols-2",
    "grid-cols-3",
    "grid-cols-4",
    "grid-cols-5",
    "grid-cols-6",
    "grid-cols-7",
    "grid-cols-8",
    "grid-cols-9",
    "grid-cols-10",
    "grid-cols-11",
    "grid-cols-12"
  ]

  const rowClass = `
    grid
    ${gridMap[gridBasis - 1]}
    text-center
  `;

  const payoutClass = (payout) => {
    if (payout.special) {
      return "bg-slate-100"
    }
  }

  return (
    <tr className=" font-mono">
      <td className="text-left text-xs text-slate-400">
        {dividend.year}
      </td>

      <td className={totalClass}>
        <FormattedNumber
          number={dividend.total}
          unit="cash ratio"
          className="text-sm"
        />
      </td>

      <td>
        <div className={rowClass}>
          {dividend.payouts.map((payout, index) => (
            <div
              key={index}
              className={payoutClass(payout)}
            >
              <FormattedNumber
                number={payout.value}
                unit="cash ratio"
              />

            </div>
          ))}
        </div>
      </td>
    </tr>
  )
};

export default RegularPayout;
