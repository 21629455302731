const getPublishTime = (publishedAt) => {
  const date = new Date(publishedAt);

  return date.toLocaleString(
    "en-US",
    {
      hour: "numeric",
      minute: "numeric",
      hour12: true
    }
  )
}

export default getPublishTime;
