import React from "react";
import cx from "classnames";

const Legend = ({
  label,
  legend,
  css,
  isLast,
}) => {
  const legendClass = cx(
    `
      items-center
      rounded-md
      px-2
      py-1
      text-xs
      font-medium
    `,
    css
  );

  return (
    <div className="text-xs font-mono text-slate-700">
      <span className={legendClass}>{label}</span> {legend} {!isLast && ","}
    </div>
  );
}

export default Legend;
