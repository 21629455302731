import Feed from "./News/Feed";
import Filter from "./News/Filter";
import LoadingLines from "common/LoadingLines";
import React from "react";
import { NEWS_QUERY } from "queries";
import { NewsContext } from "./News/Context";
import { useQuery } from '@apollo/client';
import { useReducer } from "react";

const News = ({
  initialState,
  shortcode = "",
}) => {
  const reducer = (state, action) => {
    return {
      ...state,
      ...action.stateChanges,
    }
  }

  const [state, dispatch] = useReducer(
    reducer,
    {
      activeScores: new Set([3, 4, 5]),
      availableFilters: ["general", "links"],
      buttonDisabled: false,
      coverage: ["market", "specific"],
      showLinks: true,
      ...initialState,
    }
  );

  const {
    loading,
    data : {
      newsArticles =[]
    } ={},
    refetch
  } = useQuery(NEWS_QUERY, {
    variables: {
      coverage: state.coverage,
      score: [...state.activeScores],
      shortcode
    }
  });

  return (
    <div className="grid gap-5">
      <NewsContext.Provider value={{ state, dispatch, refetch }}>
        <Filter />

        {loading && (
          <LoadingLines />
        )}

        {!loading && (
          <Feed
            newsArticles={newsArticles}
          />
        )}
      </NewsContext.Provider>
    </div>
  )
}

export default News;
