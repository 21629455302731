import News from "common/News";
import React from "react";
import { useParams } from "react-router-dom";

const DescriptorNews = () => {
  const { descriptorShortcode } = useParams();

  const newsProps = {
    initialState: {
      coverage: ["specific", "earnings", "transcript"],
      showJustification: true
    },
    shortcode: descriptorShortcode,
  }

  return (
    <News {...newsProps} />
  )
}

export default DescriptorNews;
