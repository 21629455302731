import Cell from "./InsiderDecisions/Cell";
import Heading from "common/Heading";
import InsiderOwnership from "./InsiderDecisions/InsiderOwnership";
import React from "react";
import TableLegend from "common/TableLegend";
import Totals from "./InsiderDecisions/Totals";
import { KeystatsContext } from "KeystatsReport/Context";
import { useContext } from "react";

const InsiderDecisions = () => {
  const {
    insiderDecisions,
    insiderOwnership,
  } = useContext(KeystatsContext);

  const labelClass = `
    text-sm
  `;

  const headerClass = `
    text-xs
    font-mono
    font-normal
    text-center
    py-1
    text-slate-400
  `;

  return (
    <div>
      <Heading variant="tiny">
        Insider Decisions
      </Heading>

      <div className="mb-3">
        <Totals
          buys={insiderDecisions.buys_total}
          sells={insiderDecisions.sells_total}
        />

        <TableLegend>
          in millions of $
        </TableLegend>
      </div>

      <table className="w-full table-fixed">
        <thead>
          <tr>
            <th style={{ width: "30px" }}>
            </th>

            <th
              colSpan="3"
              className={headerClass}
            >
              {insiderDecisions.headers[1]}
            </th>

            <th
              colSpan="3"
              className={headerClass}
            >
              {insiderDecisions.headers[4]}
            </th>

            <th
              colSpan="3"
              className={headerClass}
            >
              {insiderDecisions.headers[7]}
            </th>

            <th
              colSpan="3"
              className={headerClass}
            >
              {insiderDecisions.headers[10]}
            </th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td className={labelClass}>
              Buy
            </td>

            {insiderDecisions.buys.map((buy, index) => (
              <Cell
                key={index}
                number={buy.count}
              />
            ))}
          </tr>

          <tr>
            <td className={labelClass}>
              Sell
            </td>

            {insiderDecisions.sells.map((sell, index) => (
              <Cell
                key={index}
                number={sell.count}
              />
            ))}
          </tr>
        </tbody>
      </table>

      <InsiderOwnership insiderOwnership={insiderOwnership} />
    </div>
  )
}

export default InsiderDecisions;
