const chartOptions = (chartData) => {
  return {
    chart: {
      height: 585,
      margin: [0, 0, 0, 0],
      style: { fontFamily: "Gill Sans" },
    },
    plotOptions: {
      treemap: {
        tooltip: {
          followPointer: true,
          followTouchMove: true
        },
      }
    },
    title: { text: "" },
    navigator: { enabled: false },
    scrollbar: { enabled: false },
    exporting: { enabled: false },
    credits: { enabled: false },
    rangeSelector: { enabled: false },
    xAxis: {
    },
    yAxis: {
    },
    tooltip: {
      useHTML: true,
      formatter: function() {
        if (this?.point?.company === undefined) { return false }

        const formatPoint = (point) => {
          return(`
            <tr>
              <td class="px-1 font-sans">${point.name}</td>
              <td class="pl-5">${point.current_price}</td>
              <td class="pl-3 pr-1" style="color: ${point.color}">
                ${point.change}
              </td>
            </tr>
          `);
        }

        const parentGroup = this.point.parent;
        const groupName = this.series.data.find(point => point.id == parentGroup).name;

        const currentCompany = `
          <tr class="text-white" style="background: ${this.point.color}">
            <td class="px-1">
              <div class="text-sm font-sans">
                ${this.point.name}
              </div>

              <div class="text-xs font-sans">
                ${this.point.company}
              </div>
            </td>

            <td class="pl-5">
              ${this.point.current_price}
            </td>

            <td class="pl-3 pr-1">
              ${this.point.change}
            </td>
          </tr>
        `;

        const tableData = this.series.data
          .filter(point => point.parent == parentGroup)
          .sort((a, b) => (a.value > b.value) ? -1 : 1)
          .map(point => formatPoint(point))
          .join("")

        return `
        <div class="">
          <h3 className="font-sans">
            ${groupName}
          </h3>
          <table>
            ${currentCompany}
            ${tableData}
          </table>
        </div>
        `;
      }
    },
    series: [{
      type: "treemap",
      alternateStartingDirection: true,
      animation: false,
      levels: [
        {
          level: 1,
          allowOverlap: true,
          borderColor: "#ffffff",
          borderWidth: 6,
          layoutAlgorithm: "squarified",
          dataLabels: {
            enabled: true,
            overflow: "hide",
            align: 'center',
            backgroundColor: "#ffffff",
            color: "#000000",
            verticalAlign: 'top',
            style: {
              fontSize: '11px',
              fontFamily: "ET Book",
              fontWeight: 'normal'
            }
          },
        },
        {
          level: 2,
          layoutAlgorithm: 'squarified',
          borderColor: "#ffffff",
          borderWidth: 2,
          dataLabels: {
            enabled: false,
          },
        },
        {
          level: 3,
          borderColor: "#000000",
          borderWidth: 1,
          layoutAlgorithm: "squarified",
          dataLabels: {
            crop: true,
            overflow: "allow",
            useHTML: true,
            formatter() {

              const height = this.point.shapeArgs.height;
              const width = this.point.shapeArgs.width;

              if (height < 30 || width < 35) { return null; }
              if ((height * width) < 1100) { return null; }

              return `
                <div class="text-center text-white">
                  <div class="font-normal font-sans">${this.point.ticker}</div>
                  <div class="font-normal font-mono">${this.point.change}</div>
                </div>
              `;
            },
          }
        }
      ],
      data: chartData,
    }],
  }
}


export default chartOptions;
