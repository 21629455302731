import React from "react";
import ScreenerLink from "common/ScreenerLink";
import industryScreenerDefinition from "./industryScreenerDefinition";
import sectorScreenerDefinition from "./sectorScreenerDefinition";

const SectorIndustry = ({
  sectorName,
  industryName,
}) => {
  const sectorScreener = sectorScreenerDefinition(sectorName);
  const industryScreener = industryScreenerDefinition(industryName);

  return (
    <div className="text-sm">
      <ScreenerLink
        indicators={sectorScreener}
        sortBy="shortcode"
        sortDirection="ASC"
      >
        {sectorName}
      </ScreenerLink><span className="px-2">
        &mdash;
      </span><ScreenerLink
        indicators={industryScreener}
        sortBy="shortcode"
        sortDirection="ASC"
      >
        {industryName}
      </ScreenerLink>
    </div>
  )
}

export default SectorIndustry;
