import React from "react";
import News from "common/News";
import Heading from "common/Heading";

const MarketNews = () => {
  const newsProps = {
    initialState: {
      activeScores: new Set([4, 5]),
      availableFilters: ["links"],
      coverage: ["market"],
      showJustification: false
    }
  }

  return (
    <React.Fragment>
      <div className="pt-5 pb-2">
        <Heading>
          Latest News
        </Heading>
      </div>

      <div>
        <News {...newsProps} />
      </div>
    </React.Fragment>
  )
}

export default MarketNews;
