import BackgroundPattern from "./pattern.svg";
import Footer from "./Footer";
import Navigation from "Navigation";
import React from "react";
import { Outlet } from "react-router-dom";

const RootWithPattern = () => {
  return (
    <div
      style={{ backgroundImage: `url(${BackgroundPattern})` }}
      className="min-h-screen"
    >
      <Navigation />

      <div className="flex">
        <div className="h-full grow overflow-scroll">
          <div className="p-5">
            <Outlet />
          </div>

          <Footer />
        </div>
      </div>
    </div>
  );
}

export default RootWithPattern;
