import React from "react";
import ScreenerLink from "common/ScreenerLink";
import cx from "classnames";

const Flag = ({
  label,
  shortcode,
  css,
}) => {
  const screenerIndicators = [
    {
      "shortcode": "shortcode",
      "showInTable": true,
      "value": [],
      "indicatorRecord": {
        "screenerType": "shortcode"
      }
    },
    {
      "shortcode": "name",
      "showInTable": true,
      "value": "",
      "indicatorRecord": {
        "screenerType": "simple"
      }
    },
    {
      "shortcode": "sector",
      "showInTable": true,
      "value": [],
      "indicatorRecord": {
        "screenerType": "sector"
      }
    },
    {
      "shortcode": "industry",
      "showInTable": true,
      "value": [],
      "indicatorRecord": {
        "screenerType": "industry"
      }
    },
    {
      "shortcode": "DAILY_PRICE:D",
      "showInTable": true,
      "value": "",
      "indicatorRecord": {
        "screenerType": "value"
      }
    },
    {
      "shortcode": "MARKETCAP:N",
      "showInTable": true,
      "value": "",
      "indicatorRecord": {
        "screenerType": "value"
      }
    },
    {
      "shortcode": "PE:D",
      "showInTable": true,
      "value": "",
      "indicatorRecord": {
        "screenerType": "value"
      }
    },
    {
      "shortcode": shortcode,
      "showInTable": false,
      "value": "",
      "indicatorRecord": {
        "screenerType": "value"
      }
    }
  ];

  const flagClasses = cx(
    `
      items-center
      rounded-md
      px-2
      py-1
      text-xs
      font-medium
      mr-2
    `,
    css
  )

  return (
    <span className={flagClasses}>
      <ScreenerLink
        sortBy="shortcode"
        sortDirection="ASC"
        indicators={screenerIndicators}
      >
        {label}
      </ScreenerLink>
    </span>
  )
}

export default Flag;
