import Link from "common/Link";
import React from "react";
import { useLocation } from "react-router-dom";
import { useMemo } from "react";

const SharedLinks = () => {
  const location = useLocation();

  const showForgottenPassword = useMemo(() => {
    return !location.pathname.includes("account/forgotten_password");
  }, [location]);

  const showCreateAccount = useMemo(() => {
    return !location.pathname.includes("account/create");
  }, [location]);

  const showLogin = useMemo(() => {
    return !location.pathname.includes("account/login");
  }, [location]);

  return (
    <div className="flex gap-x-7 mt-10">
      {showForgottenPassword && (
        <div>
          <Link url="/account/forgotten_password/">
            Forgotten password?
          </Link>
        </div>
      )}

      {showCreateAccount && (
        <div>
          <Link url="/account/create/">
            Don’t have an account?
          </Link>
        </div>
      )}

      {showLogin && (
        <div>
          <Link url="/account/login/">
            Want to login?
          </Link>
        </div>
      )}
    </div>
  )
}

export default SharedLinks;
