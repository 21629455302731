import { gql } from '@apollo/client';

const ACCOUNT_QUERY = gql`
  query GetAccount {
    account {
      authorized
      fullname
      email
      powerpack
      foreverFree
      unauthorized
      nextFeatures
    }
  }
`;
export { ACCOUNT_QUERY };

const CHART_QUERY = gql`
  query GetTimeseriesChart($chartParams: JSON!, $startDate: String, $endDate: String) {
    timeseriesChart(chartParams: $chartParams, startDate: $startDate, endDate: $endDate) {
      chartData
    }
  }
`;
export { CHART_QUERY };


const DESCRIPTORS_QUERY = gql`
  query GetDescriptors($descriptorType: String!) {
    descriptors(descriptorType: $descriptorType) {
      id
      shortcode
      name
    }
  }
`;
export { DESCRIPTORS_QUERY };

const DESCRIPTOR_QUERY = gql`
  query GetDescriptor($shortcode: String!) {
    descriptor(shortcode: $shortcode) {
      businessDescription
      shortcode
      name
      sectorName
      industryName
    }
  }
`;
export { DESCRIPTOR_QUERY };

const FINANCIALS_TABLE_QUERY = gql`
  query GetFinacialsTable($shortcode: String!, $code: String!) {
    financialsTable(shortcode: $shortcode, code: $code) {
      tableData
    }
  }
`;
export { FINANCIALS_TABLE_QUERY };

const FUTURE_PRICES_QUERY = gql`
  query GetFuturesPrices($shortcodes: [String!]!) {
    futuresPrices(shortcodes: $shortcodes) {
      futureName
      close
      absoluteChange
      relativeChange
      shortcode
    }
  }
`;
export { FUTURE_PRICES_QUERY };

const INDICATORS_QUERY = gql`
  query GetIndicators {
    indicators {
      name
      unit
      keywords
      group
      shortcode
      screenerType
      screenerRemovable
      description
      chartable
      section
      code
    }
  }
`;
export { INDICATORS_QUERY }

const KEYSTATS_QUERY = gql`
  query GetKeystats($shortcode: String!) {
    keystats(shortcode: $shortcode) {
      annualGrowth
      assetsLiabilities
      capitalRatios
      dividendPayouts
      dividendRatios
      epsTable
      financials
      flags
      freeCashflowTable
      insiderDecisions
      insiderOwnership
      operatingCashflowTable
      recommendationRating
      revenueTable
      targetPrice
    }
  }
`;
export { KEYSTATS_QUERY };

const NEWS_QUERY = gql`
  query GetNewsArticles($coverage: [String!]!, $score: [Int!]!, $shortcode: String) {
    newsArticles(coverage: $coverage, score: $score, shortcode: $shortcode) {
      id
      title
      url
      source
      flag
      text
      score
      justification
      publishedAt
    }
  }
`;
export { NEWS_QUERY };

const NEWS_DAILY_SUMMARY_QUERY = gql`
  query GetNewsDailySummary {
    newsSummary {
      id
      text
      summaryDate
    }
  }
`;
export { NEWS_DAILY_SUMMARY_QUERY };

const TREEMAP_CHART_QUERY = gql`
  query GettTreemapChart($range: String!) {
    treemapChart(range: $range) {
      chartData
    }
  }
`;
export { TREEMAP_CHART_QUERY };

const VALUE_CHANGE_QUERY = gql`
  query GetValueChange($indicators: [String!]!, $shortcodes: [String!]!, $range: String) {
    valueChanges(indicators: $indicators, shortcodes: $shortcodes, range: $range) {
      absoluteChange
      relativeChange
      descriptorName
      currentValue
      shortcode
      verboseDateOfChange
    }
  }
`;
export { VALUE_CHANGE_QUERY };

const WIKI_QUERY = gql`
  query GetWikiPage($slug: String!) {
    wikiPage(slug: $slug) {
      description
      html
      keywords
      slug
      title
    }
  }
`
export { WIKI_QUERY };
