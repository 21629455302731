import DocumentHeader from "./AccountSettings/DocumentHeader";
import React from "react";
import { useQuery } from "@apollo/client";
import { ACCOUNT_QUERY } from "queries";
import Form from "./AccountSettings/Form";
import LoadingBox from "common/LoadingBox";

const AccountSettings = () => {
  const { data, loading } = useQuery(ACCOUNT_QUERY);

  if (loading) {
    return <LoadingBox />;
  }

  return (
    <React.Fragment>
      <DocumentHeader />

      <Form
        email={data.account.email}
        fullname={data.account.fullname}
      />
    </React.Fragment>
  );
}

export default AccountSettings;
