import numeral from "numeral";

const registerNumeralFormats = () => {
  numeral.register("format", "million", {
    regexps: {
      format: /(m)/,
      unformat: /(m)/
    },

    format: function(value, format, roundingFunction) {
      value = (value / 1000000).toFixed(2);
      value = numeral(value).format("(0,0.00)");

      return value;
    },

    unformat: function(string) {
      return numeral._.stringToNumber(string) * 1000000;
    }
  });
}

export default registerNumeralFormats;
