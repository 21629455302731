import React from "react";
import { Helmet } from "react-helmet";

const DocumentHeader = () => {
  const description =`Explore real-time financial market insights, index `+
  `and feature prices. Stay informed with the latest market news and ` +
  `utilize pre-configured stock screeners for comprehensive analysis.`;

  return (
    <React.Fragment>
      <Helmet>
        <title>stockrow</title>
        <meta name="description" content={description} />
        <meta name="keywords" content="401k, Business, Financial Information, Investing, Investor, Market News, Stock Research, Stock Valuation, business news, economy, finance, investment tools, mortgage, mutual funds, personal finance, quote, real estate, retirement, stock, stocks" />
      </Helmet>
    </React.Fragment>
  )
}

export default DocumentHeader;
