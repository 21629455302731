import React from "react";
import Row from "Screener/Table/Row";

const Simple = ({
  rows
}) => {
  return (
     <tbody>
        {rows.map((row, index) => (
          <Row row={row} key={index} />
        ))}
      </tbody>
  );
}

export default Simple;
