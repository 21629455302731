import Link from "common/Link";
import React from "react";
import { HalflingIcon } from "common/Icon";
import { useParams } from "react-router-dom";

const DescriptorNavigation = () => {
  const { descriptorShortcode } = useParams();

  return (
    <div className="px-3 py-2 mt-4 mb-5 -mx-3 bg-zinc-200">
      <div className="flex">
        <Link
          url={`/${descriptorShortcode}`}
          className="mr-10"
          end={true}
        >
          Overview
        </Link>

        <Link
          url={`/${descriptorShortcode}/income-statement`}
          className="mr-4"
        >
          Income Statement
        </Link>

        <Link
          url={`/${descriptorShortcode}/balance-sheet`}
          className="mr-4"
        >
          Balance Sheet
        </Link>

        <Link
          url={`/${descriptorShortcode}/cash-flow`}
          className="mr-4"
        >
          Cash Flow
        </Link>

        <Link
          url={`/${descriptorShortcode}/metrics-ratios`}
          className="mr-10"
        >
          Metrics & Ratios
        </Link>

        <Link
          className="mr-1"
          remote
          url={`https://www.sec.gov/cgi-bin/browse-edgar?action=getcompany&CIK=${descriptorShortcode}&type=10-q`}
          target="_blank"
          rel="noopener noreferrer"
        >
          10-Q
        </Link>
        <HalflingIcon
          className="mt-[3px] mr-4"
          name="square-new-window"
        />

        <Link
          className="mr-1"
          remote
          url={`https://www.sec.gov/cgi-bin/browse-edgar?action=getcompany&CIK=${descriptorShortcode}&type=10-k`}
          target="_blank"
          rel="noopener noreferrer"
        >
          10-K
        </Link>
        <HalflingIcon
          className="mt-[3px]"
          name="square-new-window"
        />

      </div>
    </div>
  );
}

export default DescriptorNavigation;
