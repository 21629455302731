import Flags from "./DescriptorHeader/Flags"
import LoadingBox from "common/LoadingBox";
import PriceChange from "./DescriptorHeader/PriceChange";
import React from "react";
import SectorIndustry from "./DescriptorHeader/SectorIndustry";
import { DESCRIPTOR_QUERY } from "queries";
import { VALUE_CHANGE_QUERY } from "queries";
import { useParams } from "react-router-dom";
import { useQuery } from '@apollo/client';

const DescriptorHeader = () => {
  const { descriptorShortcode } = useParams();

  const {
    loading: priceLoading,
    data: {
      valueChanges =[]
    } ={}
  } = useQuery(VALUE_CHANGE_QUERY, {
    variables: {
      indicators: ["DAILY_PRICE:D"],
      shortcodes: [`${descriptorShortcode}:company`],
      range: "intraday"
    },
    pollInterval: 2000,
  });

  const {
    loading: descriptorLoading,
    data: {
      descriptor: {
        name ="",
        shortcode ="",
        sectorName ="",
        industryName ="",
      } ={}
    } ={}
  } = useQuery(DESCRIPTOR_QUERY, {
    variables: {
      shortcode: descriptorShortcode,
    }
  });

  return (
    <div className="mb-3 flex flex-col lg:flex-row gap-10">
      <div>
        <div className="pb-2">
          {descriptorLoading && <LoadingBox height="28px" />}

          {!descriptorLoading && (
            <React.Fragment>
              <h1 className="text-2xl font-normal text-slate-900 pb-0">
                {name} <span className="font-mono text-sm pl-5 text-slate-400">{shortcode}</span>
              </h1>

              <SectorIndustry
                sectorName={sectorName}
                industryName={industryName}
              />
            </React.Fragment>
          )}
        </div>

        {priceLoading && <LoadingBox height="24px" />}

        {!priceLoading && valueChanges.map((price, index) =>
          <PriceChange
            key={index}
            price={price.currentValue}
            absoluteChange={price.absoluteChange}
            relativeChange={price.relativeChange}
            date={price.verboseDateOfChange}
          />
        )}
      </div>

      <Flags />
    </div>
  )
}

export default DescriptorHeader;
