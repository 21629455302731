import FormattedNumber from "common/FormattedNumber";
import Heading from "common/Heading";
import React from "react";
import { KeystatsContext } from "KeystatsReport/Context";
import { useContext } from "react";

const AnnualGrowth = () => {
  const {
    annualGrowth,
  } = useContext(KeystatsContext);

  return (
    <div>
      <Heading variant="tiny">
        Compound Annual Growth
      </Heading>

      <table className="w-full">
        <thead className="text-xs font-mono font-normal text-slate-400">
          <tr>
            <td></td>
            <td className="text-right pb-1">10y</td>
            <td className="text-right pb-1">5y</td>
            <td className="text-right pb-1">3y</td>
          </tr>
        </thead>

        <tbody>
          {annualGrowth.map((row, rowIndex) => (
            <tr key={rowIndex}>
              <td className="text-sm">
                {row.label}
              </td>

              {row.values.map((value, valueIndex) => (
                <td
                  className="text-right"
                  key={valueIndex}
                >
                  <FormattedNumber
                    number={value}
                    unit={row.unit}
                  />
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default AnnualGrowth;
