import React from "react";
import { useCallback } from "react";
import { useState } from "react";
import { useEffect } from "react";

const Time = () => {
  const [currentDate, setCurrentDate] = useState("");

  const getCurrentDate = useCallback(() => {
    const options = {
      timeZone: "America/New_York",
      hour: "numeric",
      minute: "numeric"
    }

    return new Intl.DateTimeFormat(
      "en-US", options
    ).format(new Date())
  });

  useEffect(() => {
    // const dateToSet = getCurrentDate();

    const interval = setInterval(() => {
      setCurrentDate(getCurrentDate())
    }, 1000);


    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className="m-auto">
      {currentDate}
    </div>
  );
}

export default Time;
