import Link from "common/Link";
import React from "react";
import cx from "classnames";
import { HalflingIcon } from "common/Icon";
import { useLocation } from "react-router-dom";
import { useMemo } from "react";

const ScreenerLink = ({
  token,
  name,
  handleDelete,
  handleEdit,
  notifications,
  toggleNotifications,
}) => {
  const location = useLocation();

  const showControls = useMemo(() => {
    return location.pathname.includes("screener");
  }, [location]);

  const notificationClass = cx(`
    mr-2
    cursor-pointer
  `,
    {
      "fill-[#79947a] hover:fill-green-800": notifications,
      "fill-[#8c6267] hover:fill-red-800": !notifications,
    }
  );

  return (
    <React.Fragment>
      <div className="grow mr-2 text-sm">
        <Link
          url={`/screener/${token}`}
        >
          {name}
        </Link>
      </div>

      {showControls && (
        <div className="shrink">
          <div className="flex">
            <HalflingIcon
              onClick={toggleNotifications}
              className={notificationClass}
              name={notifications ? "bell" : "bell-off"}
            />

            <HalflingIcon
              className="mr-2 cursor-pointer fill-neutral-400 hover:fill-green-800"
              name="pencil"
              onClick={handleEdit}
            />

            <HalflingIcon
              name="circle-remove"
              className="fill-neutral-400 hover:fill-red-900 cursor-pointer"
              onClick={handleDelete}
            />
          </div>
        </div>
      )}
    </React.Fragment>
  );
}

export default ScreenerLink;
