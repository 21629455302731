import React from "react";
import FormattedNumber from "common/FormattedNumber";

const Totals = ({
  buys,
  sells
}) => {
  const labelClass = `
    text-sm
    inline-block
    mr-1
  `;

  if (buys === 0 && sells === 0) {
    return null;
  }

  return (
    <div className="mb-0 bg-slate-100 px-2 -mx-2">
      {buys !== 0 && (
        <div className="flex py-0.5">
          <div className="w-1/2 text-sm m-auto">
            Total buys
          </div>

          <div className="w-1/2 text-right">
            <FormattedNumber
              number={buys}
              unit="cash"
              className="text-sm"
            />
          </div>
        </div>
      )}

      {sells !== 0 && (
        <div className="flex py-0.5">
          <div className="w-1/2 text-sm m-auto">
            Total sells
          </div>

          <div className="w-1/2 text-right">
            <FormattedNumber
              number={sells}
              unit="cash"
              className="text-sm"
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default Totals;
