import DescriptorHeader from "DescriptorHeader";
import DescriptorNavigation from "DescriptorNavigation";
import Main from "./Main";
import React from "react";
import Sidebar from "./Sidebar";
import UnauthorizedNotification from "common/UnauthorizedNotification";
import { KEYSTATS_QUERY } from "queries";
import { KeystatsContext } from "./Context";
import { useParams } from "react-router-dom";
import { useQuery } from '@apollo/client';

const Report = () => {
  const { descriptorShortcode } = useParams();

  const {
    loading,
    data: {
      keystats: {
        annualGrowth =[],
        assetsLiabilities ={},
        capitalRatios =[],
        dividendPayouts ={},
        dividendRatios =[],
        epsTable ={},
        financials ={},
        freeCashflowTable ={},
        insiderDecisions ={},
        insiderOwnership =[],
        operatingCashflowTable ={},
        recommendationRating = 0.0,
        recommendationTrends ={},
        revenueTable ={},
        targetPrice ={},
      } = {}
    } = {}
  } = useQuery(KEYSTATS_QUERY, {
    fetchPolicy: "no-cache",
    variables: {
      shortcode: descriptorShortcode
    }
  });

  const unauthorizedCopy = {
    title: `
      We limit data available in the Overview Report for users who do not have Powerpack
    `,
    description: `
      Get access to target analyst prices, predictions, compound annual growth rates and
      more than four years of historical data.
    `
  }

  if (loading) return (
    <div>
      Loading
    </div>
  );

  return (
    <React.Fragment>
      <React.Fragment>
        <DescriptorHeader />

        <div className="hidden md:block">
          <DescriptorNavigation />
        </div>

        <KeystatsContext.Provider value={{
          annualGrowth,
          assetsLiabilities,
          capitalRatios,
          dividendPayouts,
          dividendRatios,
          epsTable,
          financials,
          freeCashflowTable,
          insiderDecisions,
          insiderOwnership,
          operatingCashflowTable,
          recommendationRating,
          recommendationTrends,
          revenueTable,
          targetPrice,
        }}>
          <React.Fragment>
            <UnauthorizedNotification {...unauthorizedCopy} />

            <div className="flex flex-col gap-5 xl:flex-row">
              <div className="flex-none space-y-7 w-full order-2 xl:order-1 xl:w-[380px]">
                <Sidebar />
              </div>

              <div className="order-1 xl:order-2 xl:grow">
                <Main />
              </div>
            </div>
          </React.Fragment>
        </KeystatsContext.Provider>
      </React.Fragment>
    </React.Fragment>
  )
}

export default Report;
