import React from "react";
import Trigger from "common/Trigger";
import { ScreenerFilterContext } from "Screener/Context";
import { useContext } from "react";

const Shortcode = ({
  indicator,
}) => {
  const {
    dispatch,
    state,
  } = useContext(ScreenerFilterContext);

  const handleTickerEdit = () => {
    const filterBoxStyle = state.filterBoxStyle === "shortcode" ? "" : "shortcode";

    dispatch({
      type: "DEFAULT",
      stateChanges: {
        filterBoxStyle
      }
    });
  }

  const triggerText = indicator.value.length === 0 ?
    "All" : `${indicator.value.length}`;

  return (
    <React.Fragment>
      <div className="mx-1">
        {indicator.indicatorRecord.name}
      </div>

      <div className="mx-3">
        <Trigger
          colorVariant="white"
          onClick={handleTickerEdit}
        >
          ({triggerText})
        </Trigger>
      </div>
    </React.Fragment>
  );
}

export default Shortcode;
