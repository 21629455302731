import React from "react";
import Day from "./Day";
import { groupBy } from "lodash";

const Feed = ({
  newsArticles
}) => {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Spt",
    "Oct",
    "Nov",
    "Dec"
  ];

  const groupedNews = groupBy(
    newsArticles,
    (i) => {
      const date = new Date(i.publishedAt);
      return `${date.getDate()} ${months[date.getMonth()]}`;
    }
  );

  return (
    <React.Fragment>
      {Object.keys(groupedNews).map((key) => (
        <Day
          articles={groupedNews[key]}
          groupName={key}
          key={key}
        />
      ))}
    </React.Fragment>
  )
}

export default Feed;
