import React from "react";
import Row from "./FinancialsTable/Row";
import TableLegend from "common/TableLegend";
import { KeystatsContext } from "KeystatsReport/Context";
import { useContext } from "react";

const FinancialsTable = () => {
  const {
    financials
  } = useContext(KeystatsContext);

  return (
    <React.Fragment>
      <table className="w-full">
        <thead className="text-xs">
          <tr>
            {financials.headers.map((header, index) => (
              <th key={index} className="py-1 font-mono font-normal text-slate-400 text-right">
                {header}
              </th>
            ))}
            <th></th>
          </tr>
        </thead>

        <tbody>
          {financials.values.map((row, index) => (
            <Row
              key={index}
              financialsTableRow={row}
            />
          ))}
        </tbody>
      </table>

      <TableLegend
        className="italic"
      >
        predictions in italic, sparklines do not include predictions
      </TableLegend>
    </React.Fragment>
  )
}

export default FinancialsTable;
