import React from "react";

const Simple = ({
  indicator,
}) => {
  return (
    <React.Fragment>
      <div className="mx-1">
        {indicator.indicatorRecord.name}
      </div>
    </React.Fragment>
  );
}

export default Simple;
