import React from "react";
import { DESCRIPTOR_QUERY } from "queries";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { useQuery } from '@apollo/client';

const DocumentHeader = () => {
  const {
    descriptorShortcode,
    fincialsTableCode,
    financialsTableParams,
  } = useParams();

  const {
    loading: loading,
    data: {
      descriptor: {
        name ="",
        shortcode ="",
        sectorName ="",
        industryName ="",
      } ={}
    } ={}
  } = useQuery(DESCRIPTOR_QUERY, {
    variables: {
      shortcode: descriptorShortcode,
    }
  });

  if (loading) { return null; }

  const incomeStatementDescription = `Income statement of ${name} ` +
    `(${shortcode}). Ten year of data with focus on the revenue, expenses, ` +
    `gains, and losses as reported by the company.`

  const balanceSheetDescription = `Balance sheet of ${name} (${shortcode}). Ten ` +
    `year of data providing a snapshot of what a company owns and owes, as well as ` +
    `the amount invested by shareholders.`;

  const cashFlowDescription = `Cash flow statement of ${name} (${shortcode}). `+
    `Ten years of data representing the net cash and equivalents transferred in ` +
    `and out of a company. Sources and use of cash over time.`;

  const metrcisAndRatiosDescription = `Financial metrics and ratios of ${name} ` +
    `(${shortcode}). Ten years of data providing different insight into the ` +
    `operational efficiency of a company.`;

  let description;
  let canonical;
  let title;

  switch(fincialsTableCode) {
    case "income-statement":
      description = incomeStatementDescription;
      title = `${shortcode} Income Statement — stockrow`;
      if (financialsTableParams !== "q-desc") {
        canonical = `https://stockrow.com/${shortcode}/income-statement/q-desc`;
      }
      break;

    case "balance-sheet":
      description = balanceSheetDescription;
      title = `${shortcode} Balance Sheet — stockrow`;
      if (financialsTableParams !== "q-desc") {
        canonical = `https://stockrow.com/${shortcode}/balance-sheet/q-desc`;
      }
      break;

    case "cash-flow":
      description = cashFlowDescription;
      title = `${shortcode} Cash Flow Statement — stockrow`;
      if (financialsTableParams !== "q-desc") {
        canonical = `https://stockrow.com/${shortcode}/cash-flow/q-desc`;
      }
      break;

    case "metrics-ratios":
      description = metrcisAndRatiosDescription;
      title = `${shortcode} Metrics & Ratios — stockrow`;
      if (financialsTableParams !== "q-desc") {
        canonical = `https://stockrow.com/${shortcode}/metrics-ratios/q-desc`;
      }
      break;
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        {canonical && (
          <link rel="canonical" href={canonical} />
        )}
      </Helmet>
    </React.Fragment>
  )
}

export default DocumentHeader;
