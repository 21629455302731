import React from "react";
import FormattedNumber from "common/FormattedNumber";

const PriceChange = ({
  price,
  absoluteChange,
  relativeChange,
  date
}) => {
  return (
    <div className="flex">
      <div className="text-left pr-5">
        <FormattedNumber
          number={price}
          unit="price"
          className="text-lg align-bottom leading-none"
        />
      </div>

      <div className="text-right pr-5">
        <FormattedNumber
          number={absoluteChange}
          unit="price"
          className="text-base align-bottom leading-none"
          highlightPositive
        />
      </div>

      <div className="text-right pr-5">
        <FormattedNumber
          number={relativeChange}
          unit="percentage"
          className="text-base align-bottom leading-none"
          highlightPositive
        />
      </div>

      <div>
        <span className="text-sm text-neutral-500 leading-none align-bottom">
          as of {date}
        </span>
      </div>
    </div>
  )
};

export default PriceChange;
