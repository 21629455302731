import LoadingSpinner from "common/LoadingSpinner";
import React from "react";
import TextInput from "common/TextInput";
import Trigger from "common/Trigger";
import { DESCRIPTORS_QUERY } from "queries";
import { HalflingIcon } from "common/Icon";
import { ScreenerFilterContext } from "Screener/Context";
import { createPortal } from "react-dom";
import { intersection } from "lodash";
import { pull } from "lodash";
import { useContext } from "react";
import { useEffect } from "react";
import { useQuery } from "@apollo/client";
import { useRef } from "react";
import { useState } from "react";

const ShortcodeList = ({
  portalElement
}) => {
  const {
    state,
    dispatch
  } = useContext(ScreenerFilterContext);

  const [tickerInputValue, setTickerInputValue] = useState("");

  const inputRef = useRef(null);

  const {
    loading,
    data: {
      descriptors =[]
    } ={}
  } = useQuery(DESCRIPTORS_QUERY, {
    variables: {
      descriptorType: "company",
    }
  });

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  const shortcodeIndicator = state.indicatorsInQuery.find((indicator) => {
    return indicator.shortcode === "shortcode";
  });

  const clearSelection = () => {
    dispatch({
      type: "SET_CRITERION_VALUE",
      shortcode: "shortcode",
      value: [],
    });

    inputRef.current.focus();
  }

  const handleChange = (event) => {
    event.preventDefault();

    setTickerInputValue(event.target.value);
  }

  const handleRemove = (shortcode) => {
    const newValue = pull([...shortcodeIndicator.value], shortcode);

    dispatch({
      type: "SET_CRITERION_VALUE",
      shortcode: "shortcode",
      value: newValue,
    });
  }

  const handleSubmit = (event) => {
    event.preventDefault();

    const addedShortcodes = tickerInputValue.split(",").map((ticker) => {
      return ticker.replace(/([^a-z0-9.]+)/gi, "").toUpperCase();
    });

    const shortcodes = descriptors.map(d => d.shortcode);

    const newValue = [
      ...new Set(
        [
          ...intersection(shortcodes, addedShortcodes),
          ...shortcodeIndicator.value
        ].sort()
      )
    ];

    dispatch({
      type: "SET_CRITERION_VALUE",
      shortcode: "shortcode",
      value: newValue,
    });

    setTickerInputValue("");
  }

  if (loading) {
    return (
      <LoadingSpinner variant="large" />
    );
  }

  return (
    <React.Fragment>
      {createPortal(
        (
          <Trigger onClick={clearSelection}>
            Remove all Tickers from Filter
          </Trigger>
        ),
        portalElement
      )}

      <div className="mb-2">
        Enter tickers manually or copy and paste. Press Enter once finished.
      </div>

      <div className="mb-2">
        Example of valid input — <span className="bg-orange-100 font-mono px-3 pt-1 pb-0.5">
          AAPL,MSFT,TSLA</span>
      </div>

      <div className="mb-5">
        Input is case insensitive. Both <span className="bg-orange-100 font-mono px-3 pt-1 pb-0.5">AAPL
        </span> and <span className="bg-orange-100 font-mono px-3 pt-1 pb-0.5">aapl</span> will work.
      </div>

      <form
        className="mb-5"
        onSubmit={handleSubmit}
      >
        <TextInput
          label="Comma separated list of tickers"
          value={tickerInputValue}
          onChange={handleChange}
          ref={inputRef}
        />
      </form>

      {shortcodeIndicator.value.length > 0 && (
        <div key={shortcodeIndicator.shortcode}>
          <div className="flex mb-5">
            {shortcodeIndicator.value.map((value) => (
              <div className="bg-slate-200 flex px-2 mr-2">
                <div className="mx-2">
                  {value}
                </div>

                <div className="m-auto">
                  <HalflingIcon
                    name="circle-remove"
                    className="fill-slate-600 cursor-pointer"
                    onClick={() => handleRemove(value)}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </React.Fragment>
  );
}

export default ShortcodeList;
