import React from "react";
import { useContext } from "react";
import { ScreenerFilterContext } from "Screener/Context";

const Groups = ({
  activeGroup,
  indicators,
  setActiveGroup,
}) => {
  const {
    state,
  } = useContext(ScreenerFilterContext);

  const isChecked = (group) => {
    return group === activeGroup;
  }

  const handleGroupChange = (event) => {
    setActiveGroup(event.target.value);
  }

  const indicatorsInGroupCount = (groupName) => {
    if (groupName === "All") {
      return indicators.length;
    } else {
      const queryResult = indicators.filter((indicator) => {
        return indicator.group === groupName;
      });

      return queryResult.length;
    }
  }

  return (
    <React.Fragment>
      {state.groups.map((group, index) => (
        <div
          className="pb-2"
          key={index}
        >
          <label className="cursor-pointer">
            <input
              value={group}
              checked={isChecked(group)}
              onChange={handleGroupChange}
              type="radio"
            /> {group} ({indicatorsInGroupCount(group)})
          </label>
        </div>
      ))}
    </React.Fragment>
  );
}

export default Groups;
