import React from "react";
import cx from "classnames";

const Cell = ({
  number
}) => {
  const countClass = cx(
    `
      font-normal
      text-xs
      font-mono
      text-center
      odd:bg-slate-100
    `,
    {
      "text-slate-300": number === 0
    }
  );

  const displayValue = number === 0 ? "—" : number;

  return (
    <td
      className={countClass}
    >
      {displayValue}
    </td>
  );
}

export default Cell;
