import React from "react";
import Link from "common/Link";
import Heading from "common/Heading";
import errorAnimation from "assets/images/error.gif";

const Error = () => {
  return (
    <div className="m-10">
      <div>
        <img src={errorAnimation} />
      </div>

      <Heading variant="default">
        Sorry, something is broken
      </Heading>

      <div className="mt-10">
        <p className="mb-3">
          Things went wrong. Try reloading this page. If this error
          does not go away go back to <Link url="/">stockrow.com</Link>
        </p>

        <p className="mb-3">
          Don’t worry — we got it an will try to fix it as soon as we
          can
        </p>
      </div>
    </div>
  );
}

export default Error;
