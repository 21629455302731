import DocumentHeader from "./Terms/DocumentHeader";
import Heading from "common/Heading";
import Link from "common/Link";
import React from "react";

const Terms = () => {
  const termsAndConditions = [
    {
      children: [
        `
          By using the stockrow.com ("Service"), you are agreeing to be bound by
          the following terms and conditions ("Terms of Service").
        `,
        `
          The Service reserves the right to update and change these Terms of
          Service without notice.
        `,
        `
          Violation of any of the Terms of Service below may result in the
          termination of your account and access to The Service.
        `,
      ]
    },
    {
      heading: "Account Terms",
      children: [
        `
          You are responsible for maintaining the security of your account and
          password. The Service cannot and will not be liable for any loss or
          damage from your failure to comply with this security obligation.
        `,
        `
          You are responsible for all content posted and activity that occurs
          under your account (even when content is posted by third parties who
          are using your account to access The Service).
        `,
        `
          You may not use the Service for any illegal purpose or to violate any
          laws in your jurisdiction (including but not limited to copyright
          laws).
        `,
        `
          You must provide your legal full name, a valid email address, and any
          other information requested in order to complete the signup process.
        `,
        `
          Your login may only be used by one person — a single login shared by
          multiple people is not permitted. You may create separate logins for as
          many people as you'd like.
        `,
        `
          You must be a human. Accounts registered by “bots” or other automated
          methods are not permitted.
        `,
      ]
    },
    {
      heading: "Payment, Refunds, Upgrading and Downgrading Terms",
      children: [
        `
          Downgrading your Service may cause the loss of features or capacity of
          your account. The Service does not accept any liability for such loss.
        `,
        `
          The Service does not provide refunds and all charges are final.
        `,
      ]
    },
    {
      heading: "Cancellation and Termination",
      children: [
        `
          You are solely responsible for properly cancelling your subscription. An
          email or phone request to cancel your account is not considered
          cancellation. You can cancel your subscription at any time by using an
          in app functionality provided by The Service.
        `,
        `
          All of your content and selected features will be inaccessible from the
          Service at the end of your current paid up month.
        `,
        `
          If you cancel the Service before the end of your current paid up
          month, your cancellation will take effect at the end of your current
          paid up month, and you will not be charged again.
        `,
        `
          The Service, in its sole discretion, has the right to suspend or
          terminate your account immediately and refuse any and all current or
          future use of the Service for any reason at any time. Such
          termination of the Service will result in the deactivation or
          deletion of your Account or your access to your Account, and the
          forfeiture and relinquishment of all content in your account.
        `,
      ]
    },
    {
      heading: "Modifications to the Service and Prices",
      children: [
        `
          The Service reserves the right at any time to modify or discontinue,
          temporarily or permanently, any part of the Service with or without
          notice.
        `,
        `
          Prices of all Services are subject to change upon 30 days notice from
          us. Such notice may be provided at any time by posting the changes by
          email or the Service itself.
        `,
        `
          The Service shall not be liable to you or to any third party for any
          modification, price change, suspension or discontinuance of the
          Service.
        `,
      ]
    },
    {
      heading: "Copyright and Content Ownership",
      children: [
        `
          You obtain no ownership rights in the Services as a result of your use.
        `,
        `
          You agree not to reproduce, duplicate, copy, sell, resell or exploit
          any portion of The Services, use of The Services, or access to The
          Service without the express written permission from The Service.
        `,
        `
          The look and feel of the Service is copyright to the Service. All
          rights reserved. You may not duplicate, copy, or reuse any portion of
          the HTML, CSS, JavaScript, or visual design elements without express
          written permission from the Service.
        `,
        `
          You give The Service a limited license to use the content posted by
          you in order to provide The Services to you. The Service claims no
          ownership rights over those materials. All materials you submit to The
          Services remain yours.
        `,
        `
          The Service does not pre-screen content, but reserves the right (but
          not the obligation) based on sole discretion to refuse or remove any
          content that is available via The Service.
        `,
        `
          You must request permission to use the Service's logos for
          promotional purposes.
        `,
      ]
    },
    {
      heading: "General Conditions",
      children: [
        `
          Any new features that augment or enhance the current Service,
          including the release of new tools and resources, shall be subject to
          the Terms of Service. Continued use of the Service after any such
          changes shall constitute your consent to such changes.
        `,
        `
          The Service reserves a right to immediately remove content and
          accounts determined based on sole discretion that violate these Terms
          of Service without any notice.
        `,
        `
          Your use of the Service is at your sole risk. The service is provided
          on an “as is” and “as available” basis.
        `,
        `
          Technical support is only provided via email.
        `,
        `
          You understand that the Service uses third party vendors and hosting
          partners to provide the necessary hardware, software, networking,
          storage, and related technology required to run the Service.
        `,
        `
          You must not modify, adapt or hack the Service.
        `,
        `
          You must not modify another website so as to falsely imply that it is
          associated with the Service.
        `,
        `
          Verbal, physical, written or other abuse (including threats of abuse or
          retribution) of any Service customer, Service employee or officer will
          result in immediate account termination.
        `,
        `
          You must not, by any means, cause any sort of damage to the Service
          including, but not limited to, data scraping, spamming, commencing DDOS
          attacks etc.
        `,
        `
          You understand that the technical processing and transmission of the
          Service, including your content, may be transferred unencrypted and
          involve (a) transmissions over various networks; and (b) changes to
          conform and adapt to technical requirements of connecting networks or
          devices.
        `,
        `
          The Service reserves the right to refuse service to anyone for any
          reason at any time.
        `,
        `
          The Service does not warrant that (i) the service will meet your
          specific requirements, (ii) the service will be uninterrupted, timely,
          secure, or error-free, (iii) the results that may be obtained from the
          use of the service will be accurate or reliable, (iv) the quality of
          any products, services, information, or other material purchased or
          obtained by you through the service will meet your expectations, and
          (v) any errors in the Service will be corrected.
        `,
        `
          You expressly understand and agree that the Service shall not be liable
          for any direct, indirect, incidental, special, consequential or
          exemplary damages, including but not limited to, damages for loss of
          profits, goodwill, use, data or other intangible losses (even if the
          Service has been advised of the possibility of such damages), resulting
          from: (i) the use or the inability to use the service; (ii) the cost of
          procurement of substitute goods and services resulting from any goods,
          data, information or services purchased or obtained or messages
          received or transactions entered into through or from the service;
          (iii) unauthorized access to or alteration of your transmissions or
          data; (iv) statements or conduct of any third party on the service; (v)
          or any oth
        `,
        `
          The failure of the Service to exercise or enforce any right or
          provision of the Terms of Service shall not constitute a waiver of such
          right or provision. The Terms of Service constitutes the entire
          agreement between you and the Service and govern your use of the
          Service, superceding any prior agreements between you and the Service
          (including, but not limited to, any prior versions of the Terms of
          Service).
        `,
      ]
    }
  ];

  return (
    <div className="max-w-3xl m-auto bg-white p-5">
      <DocumentHeader />

      <Heading>
        Terms and Conditions
      </Heading>

      {termsAndConditions.map((section, sectionIndex) => (
        <React.Fragment key={sectionIndex}>
          {section.heading && (
            <Heading variant="small">
              {section.heading}
            </Heading>
          )}

          <ol className="list-decimal mb-10">
            {section.children.map((child, childIndex) => (
              <li
                key={childIndex}
                className="mb-3"
              >
                {child}
              </li>
            ))}
          </ol>
        </React.Fragment>
      ))}

      <div className="my-5 text-sm">
        Adapted from the <Link url="https://github.com/basecamp/policies" remote>Basecamp open-source policies</Link> / <Link href="https://creativecommons.org/licenses/by/4.0/" remote>CC BY 4.0</Link>
      </div>
    </div>
  );
}

export default Terms;
