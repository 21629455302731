import React from "react";
import PropTypes from "prop-types";
import FormattedNumber from "./FormattedNumber";
import Link from "./Link";

const PriceChange = ({
  label,
  price,
  absoluteChange,
  relativeChange,
  linkToLabel = false,
}) => {
  return (
    <div
      data-testid="price-change"
      className="flex"
    >
      {label && (
        <div
          className="text-left w-20"
        >
          {linkToLabel && (
            <Link url={`/${label}`}>
              {label}
            </Link>
          )}

          {!linkToLabel && <React.Fragment>{label}</React.Fragment>}
        </div>
      )}

      <div className="flex-grow">
        <div className="grid grid-cols-3">
          <div className="text-right">
            <FormattedNumber
              number={price}
              unit="price"
              className="text-sm"
            />
          </div>

          <div className="text-right">
            <FormattedNumber
              number={absoluteChange}
              unit="price"
              className="text-xs"
              highlightPositive
            />
          </div>

          <div className="text-right">
            <FormattedNumber
              number={relativeChange}
              unit="percentage"
              className="text-xs"
              highlightPositive
            />
          </div>
        </div>
      </div>

    </div>
  )
}

PriceChange.propTypes = {
  label: PropTypes.node,
  price: PropTypes.number.isRequired,
  absoluteChange: PropTypes.number.isRequired,
  relativeChange: PropTypes.number.isRequired,
}

export default PriceChange;
