import React from "react";
import { Helmet } from "react-helmet";

const DocumentHeader = () => {
  const description =`Terms and Conditions that specify acceptable usage of `+
    `stockrow.com`;

  return (
    <React.Fragment>
      <Helmet>
        <title>Terms and Conditions — stockrow</title>
        <meta name="description" content={description} />
      </Helmet>
    </React.Fragment>
  )
}

export default DocumentHeader;
