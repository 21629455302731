import Heading from "common/Heading";
import Payouts from "./Dividends/Payouts";
import Ratios from "./Dividends/Ratios";
import React from "react";
import { KeystatsContext } from "KeystatsReport/Context";
import { useContext } from "react";

const Dividends = () => {
  const {
    dividendRatios,
    dividendPayouts,
  } = useContext(KeystatsContext);

  return (
    <div className="mb-10">
      <Heading variant="tiny">
        Dividends
      </Heading>

      <div className="mb-5">
        <Ratios ratios={dividendRatios} />
      </div>

      {dividendPayouts.length > 0 && (
        <Payouts payouts={dividendPayouts} />
      )}
    </div>
  )
}

export default Dividends;
