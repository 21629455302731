import React from "react";
import { Helmet } from "react-helmet";

const DocumentHeader = () => {
  const description =`Advanced stock screener. Filter stocks by sector, ` +
    `industry and over 600 different metrics and ratios. Save your results, ` +
    `export them to Excel and get notified about screener changes.`;

  return (
    <React.Fragment>
      <Helmet>
        <title>Screener — stockrow</title>
        <meta name="description" content={description} />
      </Helmet>
    </React.Fragment>
  )
}

export default DocumentHeader;
