import Form from "./Form";
import React from "react";
import Trigger from "common/Trigger";
import { SAVED_SCREENERS_QUERY } from "queries/screener";
import { SAVE_SCREENER } from "queries/screener";
import { useMutation } from "@apollo/client";
import { useParams } from "react-router-dom";
import { useState } from "react";

const Screener = () => {
  const { screenerToken } = useParams();
  const [savingScreener, setSavingScreener] = useState(false);

  const [saveScreener, { loading: mutationLoading }] = useMutation(SAVE_SCREENER, {
    refetchQueries: [
      SAVED_SCREENERS_QUERY
    ]
  });

  const handleSave = (screenerName) => {
    setSavingScreener(false);

    saveScreener({
      variables: {
        token: screenerToken,
        name: screenerName,
        notifications: false,
      }
    });
  }

  return (
    <div className="mt-0 mb-2">
      {!savingScreener && (
        <div>
          <Trigger onClick={() => setSavingScreener(true)}>
            Save screener
          </Trigger>
        </div>
      )}

      {savingScreener && (
        <Form
          disableSave={mutationLoading}
          handleSave={handleSave}
          handleCancel={() => setSavingScreener(false)}
        />
      )}
    </div>
  );
}

export default Screener;
