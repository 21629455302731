import React from "react";
import Link from "common/Link";

const Hyperlink = ({
  label,
  value
}) => {
  return (
    <div className="p-1">
      <div className="text-sm">
        <Link
          url={`/${value}`}
        >
          {label}
        </Link>
      </div>
    </div>
  );
}

export default Hyperlink;
