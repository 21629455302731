import LoadingSpinner from "common/LoadingSpinner";
import Logo from "common/Logo";
import React from "react";
import makeRequest from "utils/makeRequest";
import { ACCOUNT_QUERY } from "queries";
import { useCallback } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";

const Subscribed = () => {
  const { sessionId } = useParams();
  const navigate = useNavigate();
  const { refetch } = useQuery(ACCOUNT_QUERY);
  const callback = useCallback(async () => {
    await refetch();
    navigate("/");
  }, [navigate, refetch])

  useEffect(() => {
    const request = async () => {
      await makeRequest({
        url: "/vector/billing/subscription",
        method: "POST",
        body: {
          session_id: sessionId
        }
      });

      callback();
    };

    request();
  }, [callback, sessionId]);

  return (
    <div className="max-w-2xl m-auto">
      <Logo
        layout="vertical"
        size="large"
        isLink={false}
      />

      <div className="bg-white">
        <div className="mt-0 mb-10">
          <p className="text-xl text-slate-700 pb-4 pt-10">
            Thank you for subscribing! We are setting everything up
            for you.
          </p>

          <p className="text-slate-600">
            Please don't reload or close this page. You will be
            redirected back to the app within next second or two.
          </p>
        </div>

        <LoadingSpinner
          variant="large"
        />
      </div>
    </div>
  )
}

export default Subscribed;
