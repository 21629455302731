import React from "react";
import Trigger from "common/Trigger";
import { ScreenerFilterContext } from "Screener/Context";
import { useCallback } from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";

const ListShell = ({
  innerContent,
}) => {
  const {
    dispatch
  } = useContext(ScreenerFilterContext);

  const hideList = useCallback(() => {
    dispatch({
      type: "DEFAULT",
      stateChanges: {
        filterBoxStyle: "",
      }
    });
  }, [dispatch]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      switch (event.keyCode) {
        case 27: // ESC
          event.preventDefault();
          hideList();
          break;

        default:
          break;
      }
    }

    document.addEventListener("keydown", handleKeyDown)

    return () => {
      document.removeEventListener("keydown", handleKeyDown)
    }
  }, [hideList]);

  const handleClick = (event) => {
    event.stopPropagation();
  }

  const portalReference = useRef(null);
  const [portalElement, setPortalElement] = useState(null);

  useEffect(() => {
    setPortalElement(portalReference.current);
  }, []);

  const listClass = `
    absolute
    border
    border-emerald-800
    bg-white
    shadow-xl
    w-full
    z-20
    flex
    flex-col
    mt-2
  `;

  const ChildComponent = innerContent;

  return (
    <div
      className={listClass}
      onClick={handleClick}
    >
      <div className="flex px-5 py-3 bg-neutral-200">
        <div ref={portalReference} />

        <div className="mx-5 text-slate-400">—</div>

        <div>
          <Trigger
            onClick={hideList}
            colorVariant="red"
          >
            Close (Esc)
          </Trigger>
        </div>
      </div>

      <div className="flex flex-1 my-3 px-5 py-3">
        <div className="max-h-[65vh] overflow-scroll w-full">
          {portalElement && (
            <ChildComponent
              portalElement={portalElement}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default ListShell;
