import React from "react";
import { Helmet } from "react-helmet";

const DocumentHeader = () => {
  const description =`Contact form to reach people behind stockrow.com`;

  return (
    <React.Fragment>
      <Helmet>
        <title>Contact Us — stockrow</title>
        <meta name="description" content={description} />
      </Helmet>
    </React.Fragment>
  )
}

export default DocumentHeader;
