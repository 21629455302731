import React from "react";
import cx from "classnames";

import FormattedNumber from "common/FormattedNumber";

const Price = ({
  label,
  change,
  value,
  variant
}) => {
  const className = cx(
    `
      border-t-2
    `,
    {
      "border-green-600": variant === "green",
      "border-amber-400": variant === "yellow",
      "border-dotted": variant === "yellow",
      "border-red-700": variant === "red",
    }
  );

  return (
    <div className={className}>
      <div className="flex pb-3">
        <div className="shrink text-sm m-auto">
          {label}
        </div>

        <div className="grow text-right m-auto">
          <FormattedNumber
            highlightPositive
            number={change}
            unit="percentage"
          />

        </div>

        <div className="text-right m-auto w-16">
          <FormattedNumber
            number={value}
            unit="price"
            className="text-sm"
          />
        </div>
      </div>
    </div>
  )
}

export default Price;
