import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { NavLink as RouterLink } from "react-router-dom";

const Link = forwardRef(
  (
    {
      url,
      children,
      className,
      remote,
      highlightVisited,
      target,
      underline = true,
      ...props
    },
    ref
  ) => {
    const baseClass = cx(
      `
        hover:text-red-700
        text-sky-700
      `, {
        "visited:text-fuchsia-600": highlightVisited,
        "visited:border-fuchsia-200": highlightVisited,
        "underline": underline
      }
    );

    const activeBaseClass = `
      border-0
      cursor-text
      font-bold
    `;

    const linkClass = cx(className, baseClass);
    const activeLinkClass = cx(className, activeBaseClass);

    if (remote) {
      return (
        <a
          ref={ref}
          data-testid="link"
          href={url}
          className={linkClass}
          target={target}
          {...props}
        >
          {children}
        </a>
      )
    }

    if (!remote) {
      return (
        <RouterLink
          ref={ref}
          data-testid="link"
          to={url}
          className={({ isActive }) =>
            isActive ? activeLinkClass : linkClass
          }
          {...props}
        >
          {children}
        </RouterLink>
      )
    }
  }
);

Link.propTypes = {
  url: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired,
  className: PropTypes.string,
  remote: PropTypes.bool,
  highlightVisited: PropTypes.bool,
  target: PropTypes.string,
};

Link.defaultProps = {
  remote: false,
  highlightVisited: false,
  target: "_self",
};

export default Link;
