import React from "react";
import Link from "common/Link";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <div className="p-10 text-center text-sm">
      <span className="inline-block pr-5">
        &copy; 2016–{currentYear} stockrow.com
      </span>

      <span className="inline-block pr-5">
        <Link url="/terms">
          Terms and Conditions
        </Link>
      </span>

      <span className="inline-block pr-5">
        <Link url="/indicators">
          Indicators
        </Link>
      </span>

      <span className="inline-block">
        <Link url="/contact">
          Contact Us
        </Link>
      </span>
    </div>
  );
}

export default Footer;
